import React, { useState, useEffect } from 'react';
import './UpdateUser.css';
import { areaOptions } from '../../config/areaOptions';
import { areaLabels } from '../../config/areaLabels';
import useUserCompany from '../../hooks/useUserCompany';
import useUserLanguage from '../../hooks/useUserLanguage'; // Importa el hook de idioma

function UpdateUser() {
  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [departamentos, setDepartamentos] = useState({});

  const { empresa: userCompany, error: companyError } = useUserCompany();
  const { language } = useUserLanguage(); // Obtiene el idioma del usuario

  // Traducciones
  const labels = {
    title: language === 'EN' ? 'Update User' : 'Actualizar Usuario',
    userIdLabel: language === 'EN' ? 'User ID' : 'ID del Usuario',
    searchButton: language === 'EN' ? 'Search User' : 'Buscar Usuario',
    errorMessage: language === 'EN' ? 'User not found' : 'Usuario no encontrado',
    companyErrorMessage: language === 'EN' 
      ? 'This user ID does not belong to your network' 
      : 'Ese ID de usuario no pertenece a tu red de trabajo',
    labels: {
      nombre: language === 'EN' ? 'First Name' : 'Nombre',
      apellidos: language === 'EN' ? 'Last Name' : 'Apellidos',
      email: 'Email',
      username: 'Username',
      idioma: language === 'EN' ? 'Language' : 'Idioma',
      areas: language === 'EN' ? 'Areas' : 'Áreas',
      departments: language === 'EN' ? 'Departments' : 'Departamentos',
      permissions: {
        ver: language === 'EN' ? 'View' : 'Ver',
        editar: language === 'EN' ? 'Edit' : 'Editar',
        enviar: language === 'EN' ? 'Send' : 'Enviar'
      },
      updateButton: language === 'EN' ? 'Update User' : 'Actualizar Usuario',
      selectLanguage: language === 'EN' ? 'Select a language' : 'Selecciona un idioma'
    }
  };

  const handleFetchUser = async (e) => {
    e.preventDefault();
    if (!userId) {
      setError(labels.errorMessage);
      return;
    }

    if (!userCompany) {
      setError(labels.companyErrorMessage);
      return;
    }

    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/users/${userId}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();

      if (response.ok) {
        if (data.empresa !== userCompany) {
          setError(labels.companyErrorMessage);
          setUserData(null);
          return;
        }

        setUserData(data);
        const fetchedDepartamentos = data.departamentos || {};

        const areasConPermisos = Object.keys(fetchedDepartamentos).reduce((acc, deptName) => {
          for (const area in areaOptions) {
            if (areaOptions[area].includes(deptName)) {
              if (!acc.includes(area)) acc.push(area);
              break;
            }
          }
          return acc;
        }, []);
        setSelectedAreas(areasConPermisos);

        const departamentosConValores = Object.keys(areaOptions).reduce((acc, area) => {
          acc[area] = areaOptions[area].reduce((deptAcc, dept) => {
            deptAcc[dept] = fetchedDepartamentos[dept] || { ver: false, editar: false, enviar: false };
            return deptAcc;
          }, {});
          return acc;
        }, {});

        setDepartamentos(departamentosConValores);
        setError('');
      } else {
        setError(labels.errorMessage);
      }
    } catch (error) {
      console.error('Error al obtener usuario:', error);
      setError(labels.errorMessage);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    if (!userData) return;

    const departamentosLimpiados = selectedAreas.reduce((acc, area) => {
      const filteredDepartments = Object.entries(departamentos[area] || {}).reduce((deptAcc, [dept, permisos]) => {
        if (permisos.ver || permisos.editar || permisos.enviar) {
          deptAcc[dept] = permisos;
        }
        return deptAcc;
      }, {});
      return { ...acc, ...filteredDepartments };
    }, {});

    const updatedUser = {
      ...userData,
      areas: selectedAreas,
      departamentos: departamentosLimpiados,
    };

    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/users/${userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(updatedUser),
      });

      if (response.ok) {
        alert(language === 'EN' ? 'User updated successfully' : 'Usuario actualizado con éxito');
      } else {
        const errorResponse = await response.text();
        console.error('Error en la respuesta:', errorResponse);
        alert(labels.errorMessage);
      }
    } catch (error) {
      console.error('Error al actualizar usuario:', error);
      alert(labels.errorMessage);
    }
  };

  const handleAreaToggle = (area) => {
    setSelectedAreas((prev) =>
      prev.includes(area) ? prev.filter((a) => a !== area) : [...prev, area]
    );

    if (!departamentos[area]) {
      setDepartamentos((prev) => ({
        ...prev,
        [area]: areaOptions[area].reduce((acc, dept) => {
          acc[dept] = { ver: false, editar: false, enviar: false };
          return acc;
        }, {})
      }));
    }
  };

  const handlePermissionChange = (area, dept, perm) => {
    setDepartamentos((prev) => ({
      ...prev,
      [area]: {
        ...prev[area],
        [dept]: {
          ...prev[area][dept],
          [perm]: !prev[area][dept]?.[perm]
        }
      }
    }));
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <div className="update-user-container">
        <h2 className="update-user-title">{labels.title}</h2>

        <form className="fetch-user-form" onSubmit={handleFetchUser}>
          <div className="form-group">
            <label htmlFor="userId">{labels.userIdLabel}</label>
            <input
              type="text"
              id="userId"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder={labels.userIdLabel}
              required
            />
          </div>
          <button type="submit" className="fetch-user-button">{labels.searchButton}</button>
        </form>

        {error && <p className="error">{error}</p>}
        {companyError && <p className="error">{companyError}</p>}

        {userData && (
          <form className="update-user-form" onSubmit={handleUpdateUser}>
            <div className="form-group">
              <label htmlFor="nombre">{labels.labels.nombre}</label>
              <input type="text" id="nombre" name="nombre" value={userData.nombre} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="apellidos">{labels.labels.apellidos}</label>
              <input type="text" id="apellidos" name="apellidos" value={userData.apellidos} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="email">{labels.labels.email}</label>
              <input type="email" id="email" name="email" value={userData.email} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="username">{labels.labels.username}</label>
              <input type="text" id="username" name="username" value={userData.username} onChange={handleChange} />
            </div>

            <div className="form-group">
              <label htmlFor="idioma">{labels.labels.idioma}</label>
              <select id="idioma" name="idioma" value={userData.idioma || ''} onChange={handleChange}>
                <option value="">{labels.labels.selectLanguage}</option>
                <option value="ES">Español</option>
                <option value="EN">Inglés</option>
              </select>
            </div>

            <div className="form-group">
              <label>{labels.labels.areas}</label>
              {Object.keys(areaOptions).map((area) => (
                <div key={area} className="checkbox-item">
                  <input type="checkbox" checked={selectedAreas.includes(area)} onChange={() => handleAreaToggle(area)} />
                  <label>{areaLabels[area] ? areaLabels[area][language] : area}</label>
                </div>
              ))}
            </div>

            {selectedAreas.map((area) => (
              <div key={area} className="form-group">
                <h3>{areaLabels[area][language] || area} - {labels.labels.departments}</h3>
                {areaOptions[area].map((dept) => (
                  <div key={dept} className="checkbox-item">
                    <strong>{dept}</strong>
                    <ul>
                      {['ver', 'editar', 'enviar'].map((perm) => (
                        <li key={perm}>
                          <label>{labels.labels.permissions[perm]}</label>
                          <input type="checkbox" checked={departamentos[area]?.[dept]?.[perm] || false} onChange={() => handlePermissionChange(area, dept, perm)} />
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
            <button type="submit" className="update-user-button">{labels.labels.updateButton}</button>
          </form>
        )}
      </div>
    </div>
  );
}

export default UpdateUser;
