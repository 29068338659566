import React, { createContext, useContext, useState } from 'react';

// Crear el contexto
const NavBarContext = createContext();

// Proveedor del contexto
export const NavBarProvider = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState('Tareas'); // Opción inicial

  return (
    <NavBarContext.Provider value={{ selectedOption, setSelectedOption }}>
      {children}
    </NavBarContext.Provider>
  );
};

// Hook para usar el contexto
export const useNavBar = () => {
  return useContext(NavBarContext);
};
