//frontend/src/hooks/formularios/useFinalizarProceso.js
import { useNavigate } from 'react-router-dom';
import { getProcessesToCreate } from '../../config/questionConfig';
import { ROUTE_COMPANY_COMPLETE } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

export default function useFinalizarProceso(
	formularios,
	procesoId,
	respuestas
) {
	const navigate = useNavigate();

	const handleFinalizarProceso = async () => {
		try {
			// Obtener el estado actual del proceso
			const currentState = formularios[0]?.estadoEmpresas;

			// Extraer el año del formulario
			const anyo =
				formularios[0]?.anyo ||
				(formularios[0]?.fechaCreacion
					? new Date(formularios[0]?.fechaCreacion).getFullYear()
					: null);

			// Marcamos el proceso actual como completado
			const payload = {
				nombreEmpresa: formularios[0]?.nombreEmpresa,
				procesoId: parseInt(procesoId),
				estadoEmpresas: 'Completada',
				fechaCreacion: formularios[0]?.fechaCreacion,
				mensajerevision: null, // Siempre limpiamos el mensaje al pasar a Completada
				anyo: anyo, // Añadimos el año al payload
			};

			debug('Marcando proceso como completado:', payload);
			debug('URL completa:', ROUTE_COMPANY_COMPLETE);

			// Verificar servidor con una solicitud simple
			try {
				const testResponse = await fetchWithAuth(
					'http://localhost:5000/api/test'
				);
				debug('Respuesta de prueba:', {
					ok: testResponse.ok,
					status: testResponse.status,
				});
			} catch (testError) {
				debugError('Error al probar la conexión con el servidor:', testError);
			}

			const response = await fetchWithAuth(ROUTE_COMPANY_COMPLETE, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(payload),
			});

			debug('Respuesta HTTP:', {
				status: response.status,
				statusText: response.statusText,
				url: response.url,
			});

			try {
				const errorText = await response.text();
				debug('Cuerpo de la respuesta:', errorText);
			} catch (e) {
				debug('No se pudo leer el cuerpo de la respuesta:', e);
			}

			if (!response.ok) {
				throw new Error(
					`Error al completar el proceso: ${response.status} ${response.statusText}`
				);
			}

			debug('Proceso completado exitosamente');

			// Logging de los procesos que se crearían (solo para depuración)
			if (parseInt(procesoId) === 1) {
				debug(
					'==================== PROCESOS QUE SE CREARÍAN AL ACABAR EL REQUISITO ===================='
				);
				const processesToCreate = getProcessesToCreate(
					respuestas,
					formularios[0].FormularioId,
					procesoId
				);
				debug('Resultados de getProcessesToCreate:', processesToCreate);
				debug(
					'=========================================================================='
				);
				debug(
					'NOTA: Estos procesos NO se crean aquí, sino cuando se cambia a estado Acabada'
				);
			}

			navigate('/casos');
		} catch (error) {
			debugError('Error en el proceso de finalización:', error);
			debugError('Error en detalle:', {
				name: error.name,
				message: error.message,
				stack: error.stack,
			});
		}
	};

	return handleFinalizarProceso;
}
