import { useState } from 'react';
import { ROUTE_EXPORT_PDF } from '../config/routeConfig';
import { getLocalizedConfig } from '../config/pdfReportConfig';
import { debug, debugError } from '../utils/debugHelper';
import { useTranslation } from 'react-i18next';
import { fetchWithAuth } from '../utils/fetchUtils';

const useExportarPdf = () => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const { i18n } = useTranslation();

	/**
	 * Valida los datos a exportar
	 * @param {Array} dataParaExportar - Datos a validar
	 * @param {string} language - Idioma actual
	 * @throws {Error} - Error si los datos no son válidos
	 */
	const validarDatosExportacion = (dataParaExportar, language) => {
		if (!Array.isArray(dataParaExportar) || dataParaExportar.length === 0) {
			throw new Error(
				language === 'ES'
					? 'No hay datos válidos para exportar'
					: 'No valid data to export'
			);
		}
	};

	/**
	 * Normaliza los datos de formularios para asegurar estructura correcta
	 * @param {Array} dataParaExportar - Datos de formularios
	 * @returns {Array} - Datos normalizados
	 */
	const normalizarFormularios = (dataParaExportar) => {
		return dataParaExportar.map((formulario) => {
			// Si no tiene preguntas, inicializamos un array vacío
			const preguntas = formulario.preguntas || [];

			// Nos aseguramos de que todas las preguntas tengan los campos necesarios
			const preguntasNormalizadas = preguntas.map((pregunta) => ({
				...pregunta,
				respuestaText: pregunta.respuestaText || '',
			}));

			return {
				...formulario,
				preguntas: preguntasNormalizadas,
			};
		});
	};

	/**
	 * Verifica y completa la configuración localizada
	 * @param {Object} localizedConfig - Configuración actual
	 * @param {string} normalizedLang - Idioma normalizado
	 * @returns {Object} - Configuración completada
	 */
	const completarConfiguracion = (localizedConfig, normalizedLang) => {
		const configCompleta = { ...localizedConfig };

		// Asegurarnos de que la configuración tenga las etiquetas correctas
		if (!configCompleta.global.labels) {
			configCompleta.global.labels = {
				company: normalizedLang === 'es' ? 'Empresa' : 'Company',
				process: normalizedLang === 'es' ? 'Proceso' : 'Process',
				year: normalizedLang === 'es' ? 'Año' : 'Year',
				creationDate:
					normalizedLang === 'es' ? 'Fecha de creación' : 'Creation date',
				reportDate:
					normalizedLang === 'es'
						? 'Fecha de generación del informe'
						: 'Report generation date',
				noAnswer: normalizedLang === 'es' ? 'Sin respuesta' : 'No answer',
				departmentsQuestion:
					normalizedLang === 'es'
						? 'Departamentos sobre los que se informará'
						: 'Departments that will be reported',
			};
		}

		return configCompleta;
	};

	/**
	 * Verificación y depuración de la configuración
	 * @param {Object} localizedConfig - Configuración localizada
	 * @param {number} procesosId - ID del proceso
	 * @param {string} normalizedLang - Idioma normalizado
	 * @param {Array} formulariosConData - Formularios normalizados
	 */
	const verificarConfiguracion = (
		localizedConfig,
		procesosId,
		normalizedLang,
		formulariosConData
	) => {
		// Verificar que los questionIds estén presentes
		if (
			localizedConfig.process.sections &&
			localizedConfig.process.sections.length > 0
		) {
			// Log para depuración de las secciones y sus questionIds
			debug('Verificando secciones y questionIds:');
			localizedConfig.process.sections.forEach((section) => {
				debug(
					`Sección: ${section.title}, FormId: ${section.formId}, IDs de preguntas:`,
					section.questionIds
						? `${section.questionIds.length} preguntas`
						: 'No definidos'
				);
			});
		}

		// Verificar si este proceso tiene la pregunta consolidada de departamentos
		if (localizedConfig.process.hasConsolidatedDepartmentsQuestion) {
			debug('Este proceso incluye la pregunta consolidada de departamentos');

			// Verificamos que se incluya la sección de orden correspondiente
			debug(
				'Orden de la sección consolidada:',
				localizedConfig.process.consolidatedQuestionSectionOrder
			);
		}

		// Imprimir la configuración para depuración
		debug('Configuración para PDF:', {
			procesosId,
			language: normalizedLang,
			processTitle: localizedConfig.process.title,
			headerTitle: localizedConfig.global.header.title,
			headerSubtitle: localizedConfig.global.header.subtitle,
			sectionsCount: localizedConfig.process.sections.length,
			hasConsolidatedQuestion:
				localizedConfig.process.hasConsolidatedDepartmentsQuestion || false,
			consolidatedOrder:
				localizedConfig.process.consolidatedQuestionSectionOrder || 0,
		});

		// Verificamos la estructura de los datos de formularios
		const primerFormulario = formulariosConData[0];
		if (
			primerFormulario &&
			primerFormulario.preguntas &&
			primerFormulario.preguntas.length > 0
		) {
			debug('Ejemplo de estructura de pregunta:', {
				id: primerFormulario.preguntas[0].id,
				text: primerFormulario.preguntas[0].text ? 'Presente' : 'Ausente',
				respuesta: primerFormulario.preguntas[0].respuestaText
					? 'Presente'
					: 'Ausente',
			});
		}
	};

	/**
	 * Genera y descarga el archivo PDF
	 * @param {Object} dataCompleta - Datos completos para el PDF
	 * @param {Array} dataParaExportar - Datos originales
	 * @param {string} language - Idioma seleccionado
	 * @param {Object} localizedConfig - Configuración localizada
	 * @returns {Promise<boolean>} - True si fue exitoso
	 * @throws {Error} - Error en caso de fallo
	 */
	const generarYDescargarPdf = async (
		dataCompleta,
		dataParaExportar,
		language,
		localizedConfig
	) => {
		// Realizar la petición al servidor utilizando fetchWithAuth
		const response = await fetchWithAuth(ROUTE_EXPORT_PDF, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(dataCompleta),
		});

		if (!response.ok) {
			const errorText = await response.text();
			throw new Error(
				language === 'ES'
					? `Error al generar el archivo PDF: ${errorText}`
					: `Error generating PDF file: ${errorText}`
			);
		}

		// Descargar el archivo
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;

		// Nombre del archivo con la empresa y el idioma
		const nombreEmpresa = dataParaExportar[0]?.nombreEmpresa || 'SinNombre';
		const sufijo = language === 'ES' ? 'Informe' : 'Report';
		const procesoSufijo = localizedConfig.process.title
			.replace(/[^\w\s]/gi, '')
			.trim()
			.split(' ')[0];

		a.download = `${sufijo}_${nombreEmpresa}_${procesoSufijo}_${language}.pdf`;

		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(url);

		return true;
	};

	/**
	 * Exporta los datos a un PDF
	 * @param {Array} dataParaExportar - Datos de formularios para exportar
	 * @param {string} language - Idioma a utilizar (ES/EN)
	 * @returns {Promise<boolean>} - True si exitoso, false si hay error
	 */
	const exportarPdf = async (dataParaExportar, language = 'ES') => {
		setLoading(true);
		setError(null);

		// Guardamos el idioma original de la aplicación
		const originalLanguage = i18n.language;

		try {
			// Validar datos de entrada
			validarDatosExportacion(dataParaExportar, language);

			// Normalizamos el idioma
			const normalizedLang = language === 'ES' ? 'es' : 'en';

			// Obtener el ID del proceso del primer formulario
			const procesosId = parseInt(dataParaExportar[0]?.procesosId) || 0;

			// Obtener la configuración localizada para este proceso
			let localizedConfig = getLocalizedConfig(procesosId, normalizedLang);

			// Normalizar los formularios
			const formulariosConData = normalizarFormularios(dataParaExportar);

			// Completar configuración con etiquetas
			localizedConfig = completarConfiguracion(localizedConfig, normalizedLang);

			// Verificar configuración (solo para depuración)
			verificarConfiguracion(
				localizedConfig,
				procesosId,
				normalizedLang,
				formulariosConData
			);

			// Crear el objeto de datos completo para enviar
			const dataCompleta = {
				formularios: formulariosConData,
				config: localizedConfig,
				language: normalizedLang,
			};

			// Generar y descargar el PDF
			return await generarYDescargarPdf(
				dataCompleta,
				dataParaExportar,
				language,
				localizedConfig
			);
		} catch (err) {
			debugError('Error al exportar documento PDF:', err);
			setError(
				err.message ||
					(language === 'ES'
						? 'Error al exportar documento PDF.'
						: 'Error exporting PDF document.')
			);
			return false;
		} finally {
			// Restauramos el idioma original de la aplicación
			if (i18n.language !== originalLanguage) {
				i18n.changeLanguage(originalLanguage);
			}
			setLoading(false);
		}
	};

	return { exportarPdf, error, loading };
};

export default useExportarPdf;
