// frontend/src/styles/quillStyles.js
export const quillStyles = {
	editor: {
		base: `w-full h-full transition-all duration-200`,
		required: '!bg-error/50',
		default: 'bg-secundario/25',
		container: `w-full h-[300px] rounded border border-gray-300 overflow-hidden`,
		toolbar: 'border-b bg-gray-50 p-2',
	},
	wrapper: 'w-full',
};
