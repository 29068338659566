//frontend/src/hooks/questions/usePreguntasVisibility.js
import { useState } from 'react';

export const usePreguntasVisibility = () => {
	const [mostrarPreguntas, setMostrarPreguntas] = useState({});

	const togglePreguntas = (formularioId) => {
		setMostrarPreguntas((prev) => ({
			...prev,
			[formularioId]: !prev[formularioId],
		}));
	};

	return { mostrarPreguntas, togglePreguntas };
};
