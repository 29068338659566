//frontend/src/styles/casosStyles.js
export const casosStyles = {
	container: 'flex min-h-screen',
	mainContent: 'flex-1 p-8',
	header: 'mb-8',
	title: `
      text-2xl font-bold 
      after:content-[''] after:block after:w-24 after:h-1
      after:bg-gradient-to-r after:from-acentuado after:to-fondosuavizado
      after:mt-2
    `,
	
};
