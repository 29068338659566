// hooks/useUserPermissions.js
import { useState, useEffect } from 'react';

function useUserPermissions() {
  const [permissions, setPermissions] = useState(null);
  const [error, setError] = useState(null);

  const fetchPermissions = async () => {
    try {
      const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/me/permissions', {
        method: 'GET',
        credentials: 'include', // Incluir cookies en la solicitud
      });

      if (response.ok) {
        const data = await response.json();
        setPermissions(data.permissions);
        
        // Agrega el console.log aquí para ver los permisos obtenidos
        console.log('Permisos obtenidos en useUserPermissions:', data.permissions);
        
      } else {
        console.error('Error al obtener permisos del usuario');
        setError('Error al obtener permisos del usuario');
      }
    } catch (error) {
      console.error('Error en la solicitud de permisos:', error);
      setError('Error en la solicitud de permisos');
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  return { permissions, error };
}

export default useUserPermissions;
