import React from 'react';
import { Link } from 'react-router-dom';
import useUserLanguage from '../hooks/useUserLanguage';
import { areaOptions } from '../config/areaOptions';

// Traducciones de nombres, departamentos y áreas
const processTranslations = {
  'Climate Change ESRS E1': { ES: 'Cambio Climático' },
  'Pollution ESRS E2': { ES: 'Contaminación' },
  'Water & marine resources ESRS E3': { ES: 'Recursos Hídricos y Marinos' },
  'Biodiversity & Ecosystems ESRS E4': { ES: 'Biodiversidad y Ecosistemas' },
  'Resource use & circular economy ESRS E5': { ES: 'Uso de Recursos y Economía Circular' },
  'Own Workforce ESRS S1': { ES: 'Personal Propio' },
  'Worker in the value chain ESRS S2': { ES: 'Trabajadores en la Cadena de Valor' },
  'Affected communities ESRS S3': { ES: 'Comunidades Afectadas' },
  'Consumers & end-users ESRS S4': { ES: 'Consumidores y Usuarios Finales' },
  'Business Conduct ESRS G1': { ES: 'Conducta Empresarial' },
  'General Principles ESRS1': { ES: 'Principios Generales' },
  'General Strategy ESRS 2': { ES: 'Estrategia General' },
  // Áreas y departamentos
  'Medio Ambiental': { EN: 'Environmental' },
  'Estandares Sociales': { EN: 'Social Standards' },
  'Gobierno Corporativo': { EN: 'Corporate Governance' },
  'ESG': { EN: 'ESG', ES: 'ESG' }
};

function TablaProcesos({ procesos, handleProcessClick }) {
  const { language } = useUserLanguage();
  console.log("Idioma detectado:", language);

  // Traducciones de etiquetas
  const labels = {
    areas: language === 'EN' ? 'Area' : 'Área',
    nombre: language === 'EN' ? 'Name' : 'Nombre',
    departamento: language === 'EN' ? 'Department' : 'Departamento',
    accion: language === 'EN' ? 'Action' : 'Acción',
    noProcesos: language === 'EN' ? 'No processes found.' : 'No se encontraron procesos.'
  };

  // Función para traducir el texto
  const translate = (text) => {
    const translation = processTranslations[text];
    if (translation) {
      return language === 'EN' && translation.EN ? translation.EN : language === 'ES' && translation.ES ? translation.ES : text;
    }
    return text; // Devuelve el texto original si no hay traducción
  };

  // Función para obtener el área de un proceso basado en su nombre o procesoId
  const getAreaFromProcess = (proceso) => {
    const { nombre, id } = proceso;

    console.log(`Evaluando proceso: ${id} - ${nombre}`);

    if ([1, 2, 3, 4, 5].includes(id)) {
      return 'Medio Ambiental';
    }
    if ([6, 7, 8, 9].includes(id)) {
      return 'Estandares Sociales';
    }
    if (id === 10) {
      return 'Gobierno Corporativo';
    }
    if ([11, 12].includes(id)) {
      return 'ESG';
    }

    // Buscar el área en base al nombre del proceso
    for (const [area, procesos] of Object.entries(areaOptions)) {
      if (procesos.some((proceso) => nombre.startsWith(proceso))) {
        return area;
      }
    }

    return '';
  };

  return (
    <table className="process-table">
      <thead>
        <tr>
          <th>{labels.areas}</th>
          <th>{labels.nombre}</th>
          <th>{labels.departamento}</th>
          <th>{labels.accion}</th>
        </tr>
      </thead>
      <tbody>
        {procesos.length === 0 ? (
          <tr>
            <td colSpan="4">{labels.noProcesos}</td>
          </tr>
        ) : (
          procesos.map((proceso) => {
            console.log("Nombre original:", proceso.nombre);
            console.log("Departamento original:", proceso.departamento);

            // Traduce el nombre y el departamento del proceso
            const nombreTraducido = translate(proceso.nombre);
            const departamentoTraducido = translate(proceso.departamento);

            // Determina el área basado en el procesoId o nombre del proceso y traduce el área
            const area = getAreaFromProcess(proceso);
            const areaTraducida = translate(area);

            console.log("Nombre traducido:", nombreTraducido);
            console.log("Departamento traducido:", departamentoTraducido);
            console.log("Área asignada traducida:", areaTraducida);

            return (
              <tr key={proceso.id}>
                <td>{areaTraducida}</td>
                <td>{nombreTraducido}</td>
                <td>{departamentoTraducido}</td>
                <td>
                  <Link to={`/create-company/${proceso.id}`}>
                    <button className="action-btn">
                      <span className="arrow">▶</span>
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default TablaProcesos;
