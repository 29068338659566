// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Casos_casosPage__PYdoC {
  display: flex;
  height: 100vh;
}

.Casos_layout__\\+H\\+gY {
  display: flex;
  width: 100%;
  gap: 20px; /* Añade un espacio entre el sidebar y el contenido */
}

.Casos_sidebar__gxtBU {
  width: 20%;
  background-color: #f4f4f4;
}

.Casos_casosContainer__W5XYM {
  width: 80%;
  padding: 20px;
  overflow: auto;
}

.Casos_casosTable__0PzWM {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.Casos_casosTable__0PzWM th,
.Casos_casosTable__0PzWM td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.Casos_casosTable__0PzWM th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.Casos_casosTable__0PzWM tbody tr:hover {
  background-color: #f0f8ff;
}
`, "",{"version":3,"sources":["webpack://./src/views/Responsables/Casos.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS,EAAE,qDAAqD;AAClE;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".casosPage {\n  display: flex;\n  height: 100vh;\n}\n\n.layout {\n  display: flex;\n  width: 100%;\n  gap: 20px; /* Añade un espacio entre el sidebar y el contenido */\n}\n\n.sidebar {\n  width: 20%;\n  background-color: #f4f4f4;\n}\n\n.casosContainer {\n  width: 80%;\n  padding: 20px;\n  overflow: auto;\n}\n\n.casosTable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.casosTable th,\n.casosTable td {\n  padding: 10px;\n  border: 1px solid #ccc;\n  text-align: left;\n}\n\n.casosTable th {\n  background-color: #f5f5f5;\n  font-weight: bold;\n}\n\n.casosTable tbody tr:hover {\n  background-color: #f0f8ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casosPage": `Casos_casosPage__PYdoC`,
	"layout": `Casos_layout__+H+gY`,
	"sidebar": `Casos_sidebar__gxtBU`,
	"casosContainer": `Casos_casosContainer__W5XYM`,
	"casosTable": `Casos_casosTable__0PzWM`
};
export default ___CSS_LOADER_EXPORT___;
