//frontend/src/utils/casosHelpers.js
export const handleNavigateToQuestion = (
	navigate,
	preguntasId,
	empresaId,
	procesoId,
	respuestaId
) => {
	navigate(
		`/process/${empresaId}/form/${procesoId}/question/${preguntasId}?respuestaId=${respuestaId}`
	);
};

export const handleNavigateToForm = (
	navigate,
	FormularioId,
	empresaId,
	mensajerevision
) => {
	const queryParams = mensajerevision
		? `?mensajerevision=${encodeURIComponent(mensajerevision)}`
		: '';
	navigate(`/process/${FormularioId}/forms/${empresaId}${queryParams}`);
};
