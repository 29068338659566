import React, { useState, useEffect } from 'react';
import { Calendar } from 'lucide-react';
import { datePickerStyles } from '../styles/datePickerStyles';
import useI18n from '../hooks/i18n/useI18n';

// Estilos específicos para evitar conflictos
const calendarStyles = datePickerStyles;

const DatePicker = ({ value, onChange, isDisabled = false }) => {
	const { t, language } = useI18n();

	const [showCalendar, setShowCalendar] = useState(false);
	const [selectedDate, setSelectedDate] = useState(
		value ? new Date(value) : null
	);
	const [currentMonth, setCurrentMonth] = useState(new Date());
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

	useEffect(() => {
		if (value) {
			const date = new Date(value);
			setSelectedDate(date);
			setCurrentMonth(date);
			setCurrentYear(date.getFullYear());
		}
	}, [value]);

	const handleDateSelect = (day) => {
		// Creamos la fecha usando la zona horaria local
		const newDate = new Date(currentYear, currentMonth.getMonth(), day);
		setSelectedDate(newDate);

		// Esto garantiza que la fecha en ISO sea exactamente la que seleccionó el usuario
		const year = newDate.getFullYear();
		const month = String(newDate.getMonth() + 1).padStart(2, '0');
		const dayStr = String(day).padStart(2, '0');
		const dateString = `${year}-${month}-${dayStr}`;

		onChange(dateString);
		setShowCalendar(false);
	};

	const handleMonthChange = (increment) => {
		const newDate = new Date(
			currentYear,
			currentMonth.getMonth() + increment,
			1
		);
		setCurrentMonth(newDate);
		setCurrentYear(newDate.getFullYear());
	};

	const handleYearChange = (increment) => {
		const newYear = currentYear + increment;
		setCurrentYear(newYear);
		setCurrentMonth(new Date(newYear, currentMonth.getMonth(), 1));
	};

	const renderCalendar = () => {
		const daysInMonth = new Date(
			currentYear,
			currentMonth.getMonth() + 1,
			0
		).getDate();
		const days = [];

		for (let day = 1; day <= daysInMonth; day++) {
			const isSelected =
				selectedDate &&
				selectedDate.getDate() === day &&
				selectedDate.getMonth() === currentMonth.getMonth() &&
				selectedDate.getFullYear() === currentYear;

			days.push(
				<button
					key={day}
					onClick={() => handleDateSelect(day)}
					className={`${calendarStyles.dayButton}
						${isSelected ? calendarStyles.selectedDay : {}}
					`}>
					{day}
				</button>
			);
		}

		return days;
	};

	// Función auxiliar para obtener el locale según el idioma
	const getDateLocale = (language) => {
		return language === 'EN' ? 'en-US' : 'es-ES';
	};

	// Usar los meses traducidos desde i18n
	const getMonth = (monthIndex) => {
		return t(`datePicker.months.${monthIndex}`, {
			defaultValue:
				language === 'EN'
					? [
							'January',
							'February',
							'March',
							'April',
							'May',
							'June',
							'July',
							'August',
							'September',
							'October',
							'November',
							'December',
					  ][monthIndex]
					: [
							'Enero',
							'Febrero',
							'Marzo',
							'Abril',
							'Mayo',
							'Junio',
							'Julio',
							'Agosto',
							'Septiembre',
							'Octubre',
							'Noviembre',
							'Diciembre',
					  ][monthIndex],
		});
	};

	return (
		<div className={calendarStyles.container}>
			<div
				className={calendarStyles.input}
				onClick={() => !isDisabled && setShowCalendar(!showCalendar)}>
				<Calendar
					size={16}
					color='#666'
				/>
				<input
					type='text'
					readOnly
					value={
						selectedDate
							? selectedDate.toLocaleDateString(getDateLocale(language), {
									day: '2-digit',
									month: '2-digit',
									year: 'numeric',
							  })
							: ''
					}
					placeholder={language === 'EN' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'}
					className={calendarStyles.inputField}
					disabled={isDisabled}
				/>
			</div>

			{showCalendar && !isDisabled && (
				<div className={calendarStyles.calendarContainer}>
					{/* Año */}
					<div className={calendarStyles.header}>
						<button
							onClick={() => handleYearChange(-1)}
							className={calendarStyles.arrowButton}>
							←
						</button>
						<span>{currentYear}</span>
						<button
							onClick={() => handleYearChange(1)}
							className={calendarStyles.arrowButton}>
							→
						</button>
					</div>

					{/* Mes */}
					<div className={calendarStyles.header}>
						<button
							onClick={() => handleMonthChange(-1)}
							className={calendarStyles.arrowButton}>
							←
						</button>
						<span>{getMonth(currentMonth.getMonth())}</span>
						<button
							onClick={() => handleMonthChange(1)}
							className={calendarStyles.arrowButton}>
							→
						</button>
					</div>

					{/* Días */}
					<div className={calendarStyles.daysGrid}>{renderCalendar()}</div>
				</div>
			)}
		</div>
	);
};

export default DatePicker;
