// src/hooks/useEnviarNotificacionRedireccion.js
import { useCallback } from 'react';

function useEnviarNotificacionRedireccion() {
  const enviarCorreo = useCallback(async (empresa, area, departamento, preguntaTexto, language) => {
    try {
      // Selecciona la versión de `preguntaTexto` en el idioma correspondiente
      const textoPregunta = preguntaTexto[language] || "Texto no disponible en el idioma seleccionado";

      // Construir el payload
      const payload = {
        empresa,
        area,
        departamento,
        preguntaTexto: { ES: preguntaTexto.ES, EN: preguntaTexto.EN } // Manda ambos idiomas para el backend
      };

      const response = await fetch('https://risincome.aicrumit.com/backend/api/notificaciones/redireccion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error('Error al enviar la notificación por correo');
      console.log('Correo de notificación enviado con éxito');
    } catch (error) {
      console.error('Error al enviar notificación por correo:', error);
    }
  }, []);

  return { enviarCorreo };
}

export default useEnviarNotificacionRedireccion;
