// useUserLanguage.js
import { useState, useEffect } from 'react';

function useUserLanguage() {
  const [language, setLanguage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLanguage = async () => {
      try {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/me/language', {
          method: 'GET',
          credentials: 'include', // Incluir cookies en la solicitud
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log('Idioma obtenido en Hook useUserLanguage:', data.language);
          setLanguage(data.language);
        } else {
          console.error('Error al obtener el idioma del usuario');
          setError('Error al obtener el idioma del usuario');
        }
      } catch (error) {
        console.error('Error en la solicitud de idioma:', error);
        setError('Error en la solicitud de idioma');
      }
    };

    fetchLanguage();
  }, []);

  return { language, error };
}

export default useUserLanguage;
