import React, { useState, useEffect } from 'react';
import { extraccionStyles as styles } from '../../styles/extraccionStyles';
import useI18n from '../../hooks/i18n/useI18n';
import {
	ROUTE_COMPANY_FINISHED_COMPANIES,
	ROUTE_RESTRICTED_EXPORT,
} from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

const ExtraccionDashboard = () => {
	const [anio, setAnio] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [exportStatus, setExportStatus] = useState('');
	const [empresasDisponibles, setEmpresasDisponibles] = useState([]);
	const [aniosDisponibles, setAniosDisponibles] = useState([]);
	const [loadingError, setLoadingError] = useState(null);

	const { t, language, languageError } = useI18n();

	useEffect(() => {
		const fetchEmpresasDisponibles = async () => {
			try {
				debug('📌 Iniciando carga de empresas');

				const response = await fetchWithAuth(ROUTE_COMPANY_FINISHED_COMPANIES);
				debug('📌 Respuesta API recibida, estado:', response.status);

				if (response.ok) {
					const empresas = await response.json();
					debug('📌 Empresas recibidas:', empresas);
					debug('📌 Cantidad de empresas:', empresas.length);
					debug('📌 Primera empresa:', empresas[0]);

					// Verificar si hay empresas con estado "Acabada"
					const empresasAcabadas = empresas.filter(
						(emp) => emp.estadoEmpresas === 'Acabada'
					);
					debug("📌 Empresas con estado 'Acabada':", empresasAcabadas.length);
					debug("📌 Detalles empresas 'Acabada':", empresasAcabadas);

					// Crear mapa de empresa -> años
					const empresaAnios = new Map();

					empresas.forEach((empresa, index) => {
						debug(`📌 Procesando empresa[${index}]:`, empresa.nombreEmpresa);
						debug(`   - Estado: ${empresa.estadoEmpresas}`);
						debug(`   - Anyo: ${empresa.anyo}`);
						debug(`   - FechaCreacion: ${empresa.fechaCreacion}`);

						// Intentamos usar anyo primero, si no está disponible usamos fechaCreacion
						let year = empresa.anyo;

						// Si anyo no está definido, extraemos el año de fechaCreacion
						if (year === undefined && empresa.fechaCreacion) {
							year = new Date(empresa.fechaCreacion).getFullYear();
							debug(`   - Año calculado de fechaCreacion: ${year}`);
						}

						// Verificamos el estado
						if (empresa.estadoEmpresas === 'Acabada') {
							debug(`   - Es 'Acabada', añadiendo a empresaAnios`);

							if (!empresaAnios.has(empresa.nombreEmpresa)) {
								empresaAnios.set(empresa.nombreEmpresa, new Set());
							}

							// Solo añadimos el año si es válido
							if (year && !isNaN(year)) {
								empresaAnios.get(empresa.nombreEmpresa).add(year);
								debug(`   - Año ${year} añadido`);
							} else {
								debug(`   - Año inválido: ${year}, no añadido`);
							}
						} else {
							debug(`   - No es 'Acabada', ignorando`);
						}
					});

					debug('📌 Mapa empresa-años:', Object.fromEntries(empresaAnios));

					// Filtrar empresas con estado 'Acabada' directamente de la lista original
					const empresasUnicas = [
						...new Set(
							empresas
								.filter((emp) => emp.estadoEmpresas === 'Acabada')
								.map((emp) => emp.nombreEmpresa)
						),
					];

					debug('📌 Empresas únicas:', empresasUnicas);

					// Obtener todos los años únicos
					const todosLosAnios = new Set();
					empresaAnios.forEach((anios) => {
						anios.forEach((anio) => todosLosAnios.add(anio));
					});

					debug('📌 Años únicos:', [...todosLosAnios]);

					// Actualizamos el estado
					setEmpresasDisponibles(empresasUnicas);
					setAniosDisponibles([...todosLosAnios].sort());

					debug('📌 Estado actualizado - empresasDisponibles:', empresasUnicas);
					debug(
						'📌 Estado actualizado - aniosDisponibles:',
						[...todosLosAnios].sort()
					);
				} else {
					console.error(
						'📌 Error en la respuesta:',
						response.status,
						response.statusText
					);
					const errorText = await response.text();
					console.error('📌 Detalles del error:', errorText);
					setLoadingError(
						`Error al cargar empresas: ${response.status} ${response.statusText}`
					);
				}
			} catch (error) {
				console.error('📌 Excepción capturada:', error);
				debugError('Error en la carga de empresas disponibles:', error);
				setLoadingError(`Error en la carga: ${error.message}`);
			}
		};

		fetchEmpresasDisponibles();
	}, []);

	const handleExport = async () => {
		try {
			debug('📌 Iniciando exportación para:', empresa, anio);

			const queryParams = new URLSearchParams({
				anio,
				empresa,
			}).toString();

			debug(
				'📌 URL de exportación:',
				`${ROUTE_RESTRICTED_EXPORT}?${queryParams}`
			);

			const response = await fetchWithAuth(
				`${ROUTE_RESTRICTED_EXPORT}?${queryParams}`,
				{
					method: 'GET',
				}
			);

			debug('📌 Respuesta de exportación recibida:', response.status);

			if (response.ok) {
				const data = await response.json();
				debug('📌 Datos exportados recibidos:', data);

				const blob = new Blob([JSON.stringify(data, null, 2)], {
					type: 'application/json',
				});
				const url = URL.createObjectURL(blob);

				const a = document.createElement('a');
				a.href = url;
				a.download = `export_${empresa}_${anio}.json`;
				a.click();
				URL.revokeObjectURL(url);

				setExportStatus(t('extraccionDashboard.exportSuccess'));
				debug('📌 Exportación completada con éxito');
			} else {
				console.error('📌 Error en la exportación:', response.status);
				const errorText = await response.text();
				console.error('📌 Detalles del error:', errorText);
				setExportStatus(t('extraccionDashboard.exportError'));
			}
		} catch (error) {
			console.error('📌 Excepción en exportación:', error);
			debugError('Error en el proceso de exportación:', error);
			setExportStatus(t('extraccionDashboard.exportError'));
		}
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	// Si hay un error de carga, lo mostramos
	if (loadingError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{loadingError}</p>
			</div>
		);
	}

	debug('📌 Renderizando con empresasDisponibles:', empresasDisponibles);
	debug('📌 Renderizando con aniosDisponibles:', aniosDisponibles);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h1 className={styles.title}>{t('extraccionDashboard.title')}</h1>
				<p className={styles.subtitle}>{t('extraccionDashboard.welcome')}</p>
			</div>

			<div className={styles.formGroup}>
				<label className={styles.label}>
					{t('extraccionDashboard.empresa')}:
				</label>
				<select
					className={styles.select}
					value={empresa}
					onChange={(e) => {
						debug('📌 Empresa seleccionada:', e.target.value);
						setEmpresa(e.target.value);
						setAnio('');
					}}>
					<option value=''>{t('extraccionDashboard.selectEmpresa')}</option>
					{empresasDisponibles.map((nombre) => (
						<option
							key={nombre}
							value={nombre}>
							{nombre}
						</option>
					))}
				</select>
				<p>Empresas disponibles: {empresasDisponibles.length}</p>
			</div>

			{empresa && (
				<div className={styles.formGroup}>
					<label className={styles.label}>
						{t('extraccionDashboard.anio')}:
					</label>
					<select
						className={styles.select}
						value={anio}
						onChange={(e) => {
							debug('📌 Año seleccionado:', e.target.value);
							setAnio(e.target.value);
						}}>
						<option value=''>{t('extraccionDashboard.selectAnio')}</option>
						{aniosDisponibles.map((anio) => (
							<option
								key={anio}
								value={anio}>
								{anio}
							</option>
						))}
					</select>
					<p>Años disponibles: {aniosDisponibles.length}</p>
				</div>
			)}

			<div className={styles.buttonContainer}>
				<button
					onClick={handleExport}
					disabled={!empresa || !anio}
					className={styles.button}>
					{t('extraccionDashboard.exportData')}
				</button>
			</div>

			{exportStatus && <p className={styles.statusMessage}>{exportStatus}</p>}
		</div>
	);
};

export default ExtraccionDashboard;
