//frontend/src/styles/preguntasTableStyles.js
export const preguntasTableStyles = {
	container: 'w-full',
	filterContainer: 'mb-8 flex justify-center items-center space-x-4',
	table: 'min-w-full divide-y divide-gray-200',
	header: 'bg-fondosuavizado',
	headerCell: 'px-6 py-3 text-left text-sm font-semibold text-acentuado',
	row: 'hover:bg-fondosuavizado/30 transition-colors duration-150',
	cell: 'px-6 py-4 text-sm text-gray-700 h-12',
	buttonContainer: 'flex flex-row items-center gap-2 min-w-[200px]',
	button: {
		base: `
            inline-flex items-center justify-center
            px-4 py-2 rounded-lg font-medium text-sm
            transition-all duration-200
            min-w-[140px]
            focus:outline-none focus:ring-2 focus:ring-offset-2
            shadow-md hover:shadow-lg
        `,
		primary: `
            bg-secundario text-fondo
            hover:bg-primario
            focus:ring-primario
        `,
		disabled: `
            bg-warn 
            cursor-not-allowed
        `,
	},
	noData: 'text-center py-4 text-secundario bg-gray-50',
};
