// frontend/src/utils/htmlUtils.js
/**
 * Limpia las etiquetas <p> innecesarias del contenido HTML del editor Quill
 *
 * @param {string} htmlContent - El contenido HTML del editor
 * @returns {string} - El contenido HTML limpio
 */
export const cleanQuillHtml = (htmlContent) => {
	if (!htmlContent) return '';

	// Si es un string vacío o solo contiene espacios en blanco
	if (!htmlContent.trim()) return '';

	// Si es solo texto plano (o cadenas especiales como 'negative')
	if (!htmlContent.includes('<')) return htmlContent;

	// Caso especial para respuestas que son solo texto dentro de párrafos
	// Por ejemplo: "<p>Mi respuesta</p>"
	const simpleParaRegex = /^<p>(.*?)<\/p>$/s;
	if (simpleParaRegex.test(htmlContent)) {
		// Devuelve solo el contenido dentro de las etiquetas <p>
		return htmlContent.replace(simpleParaRegex, '$1');
	}

	// Caso especial para respuestas que son párrafos vacíos
	// Por ejemplo: "<p><br></p>"
	const emptyParaRegex = /^<p><br><\/p>$/;
	if (emptyParaRegex.test(htmlContent)) {
		return '';
	}

	// Caso para múltiples párrafos - CONSERVAMOS LA ESTRUCTURA para evitar problemas
	// NO intentamos limpiar HTML complejo, solo los casos simples de arriba
	return htmlContent;
};

/**
 * Aplica la limpieza de HTML a un objeto de respuesta
 *
 * @param {object} respuestaObj - El objeto de respuesta que contiene una propiedad 'text'
 * @returns {object} - El objeto de respuesta con el texto limpio
 */
export const cleanRespuestaHtml = (respuestaObj) => {
	if (!respuestaObj) return { text: '', image: '' };

	// Creamos un objeto nuevo para evitar mutar el original
	return {
		...respuestaObj,
		text: cleanQuillHtml(respuestaObj.text || ''),
	};
};
