// frontend/src/hooks/i18n/useI18n.js
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useUserLanguage from '../user/useUserLanguage';
import { debug, debugError } from '../../utils/debugHelper';

function useI18n() {
	const { language, error: languageError } = useUserLanguage();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (language) {
			debug('Configurando i18n con idioma:', language);

			try {
				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}
			} catch (error) {
				debugError('Error al cambiar el idioma en i18n:', error);
			}
		}
	}, [language, i18n]);

	return {
		t,
		i18n,
		language,
		languageError,
	};
}

export default useI18n;
