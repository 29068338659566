import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavBar } from '../NavBarContext';
import useUserLanguage from '../hooks/useUserLanguage';
import useUserArea from '../hooks/useUserArea';
import useUserPermissions from '../hooks/useUserPermissions';
import './NavBar.css';

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedOption, setSelectedOption } = useNavBar();
  const { language: userLanguage, error: languageError } = useUserLanguage();
  const { area } = useUserArea();
  const { permissions } = useUserPermissions();

  const pathToOption = {
    '/dashboard': 'tasks',
    '/casos': 'openCases',
    '/procesos': 'processes',
    '/other-requests': 'otherRequests',
    '/system-dashboard': 'systemDashboard',
    '/create-user': 'createUser',
    '/update-user': 'updateUser',
    '/delete-user': 'deleteUser',
    '/extraccion': 'extraccion'  // Agregar nueva opción para Extracción
  };

  const translations = {
    tasks: { ES: 'Tareas', EN: 'Tasks' },
    openCases: { ES: 'Casos', EN: 'Cases' },
    processes: { ES: 'Requisitos', EN: 'Requirements' },
    otherRequests: { ES: 'Solicitud de otros departamentos', EN: 'Other Departments Requests' },
    systemDashboard: { ES: 'Panel del sistema', EN: 'System Dashboard' },
    createUser: { ES: 'Crear Usuario', EN: 'Create User' },
    updateUser: { ES: 'Actualizar Usuario', EN: 'Update User' },
    deleteUser: { ES: 'Eliminar Usuario', EN: 'Delete User' },
    extraccion: { ES: 'Extracción', EN: 'Extraction' }, // Traducción para Extracción
    logout: { ES: 'Cerrar sesión', EN: 'Logout' }
  };

  useEffect(() => {
    const activeOption = pathToOption[location.pathname];
    if (activeOption) setSelectedOption(activeOption);
  }, [location.pathname, setSelectedOption]);

  const toggleMenu = () => setShowMenu(!showMenu);

  const handleLogout = () => {
    fetch('https://risincome.aicrumit.com/backend/api/auth/logout', {
      method: 'POST',
      credentials: 'include'
    })
      .then((response) => {
        if (response.ok) {
          setSelectedOption(null);
          navigate('/');
          window.location.reload();
        } else {
          console.error('Error al cerrar sesión');
        }
      })
      .catch((error) => console.error('Error en la solicitud de logout:', error));
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const optionToPath = {
      tasks: '/dashboard',
      openCases: '/casos',
      processes: '/procesos',
      otherRequests: '/other-requests',
      systemDashboard: '/system-dashboard',
      createUser: '/create-user',
      updateUser: '/update-user',
      deleteUser: '/delete-user',
      extraccion: '/extraccion' // Ruta para Extracción
    };
    const path = optionToPath[option];
    if (path) navigate(path);
  };

  // Opciones de menú por área
  const areaOptions = {
    System: ['systemDashboard', 'createUser', 'updateUser', 'deleteUser'],
    ESG: ['openCases'],
    MedioAmbiental: ['openCases'],
    EstandaresSociales: ['openCases'],
    GobiernoCorporativo: ['openCases'],
    Financiero: ['openCases'],
    Extraccion: ['extraccion'] // Mostrar "Extracción" si el área es "Extraccion"
  };

  // Verificar datos en área y permisos para debug
  console.log("Área actual:", area);
  console.log("Permisos obtenidos:", permissions);

  // Comprobar si "ESG" está en alguna posición del array y verificar permisos
  const hasESG = Array.isArray(area) && area.includes('ESG');
  if (hasESG && permissions && Object.values(permissions).some(perm => perm.ver && perm.editar)) {
    console.log("Permisos de edición y visualización encontrados para ESG, añadiendo 'processes'");
    areaOptions.ESG.push('processes');
  } else {
    console.log("No se cumplen los requisitos para añadir 'processes'");
  }

  // Selección dinámica de opciones basadas en el área actual
const filteredOptions = Array.isArray(area)
? Array.from(new Set(area.flatMap(a => areaOptions[a] || []))) // Combina opciones para múltiples áreas
: areaOptions[area] || [];

  return (
    <nav className="navbar">
      <ul>
        {filteredOptions.includes('openCases') && (
          <li
            className={selectedOption === 'openCases' ? 'active' : ''}
            onClick={() => handleOptionClick('openCases')}
          >
            {translations.openCases[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('processes') && (
          <li
            className={selectedOption === 'processes' ? 'active' : ''}
            onClick={() => handleOptionClick('processes')}
          >
            {translations.processes[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('systemDashboard') && (
          <li
            className={selectedOption === 'systemDashboard' ? 'active' : ''}
            onClick={() => handleOptionClick('systemDashboard')}
          >
            {translations.systemDashboard[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('createUser') && (
          <li
            className={selectedOption === 'createUser' ? 'active' : ''}
            onClick={() => handleOptionClick('createUser')}
          >
            {translations.createUser[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('updateUser') && (
          <li
            className={selectedOption === 'updateUser' ? 'active' : ''}
            onClick={() => handleOptionClick('updateUser')}
          >
            {translations.updateUser[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('deleteUser') && (
          <li
            className={selectedOption === 'deleteUser' ? 'active' : ''}
            onClick={() => handleOptionClick('deleteUser')}
          >
            {translations.deleteUser[userLanguage]}
          </li>
        )}
        {filteredOptions.includes('extraccion') && (
          <li
            className={selectedOption === 'extraccion' ? 'active' : ''}
            onClick={() => handleOptionClick('extraccion')}
          >
            {translations.extraccion[userLanguage]}
          </li>
        )}
        <li className="profile-menu">
          <img
            src="/profile.png"
            alt="Perfil"
            className="profile-icon"
            onClick={toggleMenu}
          />
          {showMenu && (
            <div className="menu-dropdown">
              <ul>
                <li onClick={handleLogout}>{translations.logout[userLanguage]}</li>
              </ul>
            </div>
          )}
        </li>
      </ul>
      {languageError && <p style={{ color: 'red' }}>{languageError}</p>}
    </nav>
  );
}

export default NavBar;
