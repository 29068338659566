// frontend/src/components/VistaPregunta.js
export const vistaPreguntaStyles = {
	container: `
      max-w-[900px]
      mx-auto
      p-6
    `,
	card: `
      bg-secundario/10
      rounded-lg
      p-6
      w-full
      border
    `,
	questionTitle: `
      text-primario
      text-2xl
      font-medium
      mb-4
    `,
	questionText: `
      mb-6
      text-secundario
    `,
	questionLink: `
      text-blue-600
      hover:text-blue-800
      hover:underline
      cursor-pointer
    `,
	editorContainer: `
      w-full
      mb-4
      rounded-lg
      min-h-[300px]
      overflow-hidden
   
    `,
	editorRequired: `
      bg-error/30
    `,
  editorOptional:`
  bg-fondosuavizado`,
	fileInputContainer: `
      flex
      items-center
      justify-between
      gap-4
      mt-6
    `,
	fileInput: `
      flex-1
      inline-flex
      items-center
      px-4
      py-2
      bg-white
      border
      border-gray-300
      rounded
      hover:bg-gray-50
    `,
	button: `
      bg-secundario
      text-fondo
      px-6
      py-2
      rounded
      hover:bg-primario
      transition-colors
      duration-200
      min-w-[200px]
      font-medium
    `,
	statusMessage: {
		success: `mt-4 p-4 bg-green-50 text-green-700 rounded-lg`,
		error: `mt-4 p-4 bg-error/30 text-error rounded-lg`,
	},
  requiredTitle:`
  text-error
  text-2xl
  `
};
