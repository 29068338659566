import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserCompany from '../hooks/user/useUserCompany';
import useUserPermissions from '../hooks/user/useUserPermissions';
import useUserArea from '../hooks/user/useUserArea';
import useExportarPdf from '../hooks/useExportarPdf';
import useExportarWord from '../hooks/useExportarWord';
import useI18n from '../hooks/i18n/useI18n';
import { casosTableStyles as styles } from '../styles/casosTableStyles';
import {
	filterCasos,
	handleExportToPdf,
	handleExportToWord,
	handleViewAcabadoProcess,
} from '../utils/casosTableHelpers';
import { debug, debugError } from '../utils/debugHelper';
import { isUserESG, hasSendPermission } from '../utils/permissionsUtils'; // Importar funciones de permisos

// Componente para renderizar las celdas básicas de información
const RenderBasicCells = ({ caso, t }) => (
	<>
		<td className={styles.cell}>{caso.id}</td>
		<td className={styles.cell}>
			{caso.proceso?.nombre
				? (() => {
						// Extraer el código (primeras palabras, por ejemplo "ESRS E1" o "ESRS 2")
						const processCode = caso.proceso.nombre
							.split(' ')
							.slice(0, 2)
							.join(' ');
						// Buscar la traducción usando solo el código
						return t(`casosTable.procesos.${processCode}`, caso.proceso.nombre);
				  })()
				: t('casosTable.sinNombre')}
		</td>
		<td className={styles.cell}>
			{caso.nombreEmpresa || t('casosTable.sinNombre')}
		</td>
		<td className={styles.cell}>
			{new Date(caso.fechaCreacion).toLocaleDateString()}
		</td>
		<td className={styles.cell}>{caso.anyo}</td>
		<td className={`${styles.cell}`}>
			<span className={styles.stateCell(caso.estadoEmpresas)}>
				{caso.estadoEmpresas &&
					t(`casosTable.estados.${caso.estadoEmpresas}`, caso.estadoEmpresas)}
			</span>
		</td>
	</>
);

// Componente para el botón de requisitos pendientes
const PendingButton = ({ caso, handleFillForm, t }) => (
	<button
		onClick={() => handleFillForm(caso.FormularioId, caso.id, caso.procesosId)}
		className={`${styles.button.base} ${styles.button.visualizar}`}>
		{t('casosTable.rellenarRequisito')}
	</button>
);

// Componente para el botón de revisión
const RevisionButton = ({ caso, handleFillForm, t }) => (
	<button
		onClick={() =>
			handleFillForm(
				caso.FormularioId,
				caso.id,
				caso.procesosId,
				caso.mensajerevision
			)
		}
		className={`${styles.button.base} ${styles.button.visualizar}`}>
		{t('casosTable.editarRequisito', 'Editar Requisito')}
	</button>
);

// Componente para el botón de visualizar
const ViewButton = ({ caso, onViewAcabadoProcess, t, debug }) => (
	<button
		onClick={() => {
			debug(
				`CasosTable - Click en Visualizar Requisito: caso.id=${caso.id}, caso.procesosId=${caso.procesosId}, caso.anyo=${caso.anyo}`
			);
			onViewAcabadoProcess(caso.id, caso.procesosId, caso.anyo);
		}}
		className={`${styles.button.base} ${styles.button.visualizar}`}>
		{t('casosTable.visualizarRequisito')}
	</button>
);

// Componente para el botón de confirmar
const ConfirmButton = ({ caso, onViewAcabadoProcess, t, isESG, debug }) => (
	<button
		onClick={() => {
			debug(
				`CasosTable - Click en Confirmar Requisito: caso.id=${caso.id}, caso.procesosId=${caso.procesosId}, caso.anyo=${caso.anyo}`
			);
			onViewAcabadoProcess(caso.id, caso.procesosId, caso.anyo);
		}}
		className={`${styles.button.base} ${styles.button.visualizar}`}>
		{isESG
			? t('casosTable.confirmarRequisito')
			: t('casosTable.visualizarRequisito')}
	</button>
);

// Componente para los botones de exportación (modificado para incluir visualización)
const ExportButtons = ({
	caso,
	onExportToPdf,
	onExportToWord,
	onViewAcabadoProcess,
	t,
	debug,
}) => (
	<div className={styles.exportButtonsContainer}>
		{/* Botón para visualizar el requisito */}
		<button
			onClick={() => {
				debug(
					`CasosTable - Click en Visualizar Requisito (Acabada): caso.id=${caso.id}, caso.procesosId=${caso.procesosId}, caso.anyo=${caso.anyo}`
				);
				onViewAcabadoProcess(caso.id, caso.procesosId, caso.anyo);
			}}
			className={`${styles.button.base} ${styles.button.visualizar}`}>
			{t('casosTable.visualizarRequisito')}
		</button>

		{/* Grupo de botones PDF */}
		<div className={styles.exportGroup}>
			<button
				onClick={() => onExportToPdf(caso, 'ES')}
				className={`${styles.button.base} ${styles.button.export.es}`}>
				{t('casosTable.exportarPdfES', 'PDF (ES)')}
			</button>
			<button
				onClick={() => onExportToPdf(caso, 'EN')}
				className={`${styles.button.base} ${styles.button.export.en}`}>
				{t('casosTable.exportarPdfEN', 'PDF (EN)')}
			</button>
		</div>

		{/* Grupo de botones Word */}
		<div className={styles.exportGroup}>
			<button
				onClick={() => onExportToWord(caso, 'ES')}
				className={`${styles.button.base} ${styles.button.export.es}`}>
				{t('casosTable.exportarWordES', 'Word (ES)')}
			</button>
			<button
				onClick={() => onExportToWord(caso, 'EN')}
				className={`${styles.button.base} ${styles.button.export.en}`}>
				{t('casosTable.exportarWordEN', 'Word (EN)')}
			</button>
		</div>
	</div>
);

// Función para determinar qué botones mostrar según el estado
const RenderActionButtons = ({
	caso,
	selectedOption,
	permisoDept,
	isESG,
	canSend,
	handleFillForm,
	onViewAcabadoProcess,
	onExportToPdf,
	onExportToWord,
	t,
	debug,
	viewOnlyUser, // Propiedad para usuarios de solo lectura
}) => {
	try {
		// Si el usuario solo tiene permiso de visualización, siempre mostrar el botón de visualización
		if (viewOnlyUser) {
			debug(
				`CasosTable - Usuario es solo visualización, mostrando botón de Visualizar para caso ${caso.id}`
			);
			return (
				<ViewButton
					caso={caso}
					onViewAcabadoProcess={onViewAcabadoProcess}
					t={t}
					debug={debug}
				/>
			);
		}

		// Si es usuario ESG con permiso enviar, determinar botones según el estado
		if (isESG && canSend) {
			debug(
				`CasosTable - Usuario ESG+Enviar, determinando botones para estado: ${selectedOption}`
			);

			switch (selectedOption) {
				case 'Pendiente':
				case 'Revision':
					return (
						<PendingButton
							caso={caso}
							handleFillForm={handleFillForm}
							t={t}
						/>
					);
				case 'Completada':
					return (
						<ConfirmButton
							caso={caso}
							onViewAcabadoProcess={onViewAcabadoProcess}
							t={t}
							isESG={true}
							debug={debug}
						/>
					);
				case 'Acabada':
					return (
						<ExportButtons
							caso={caso}
							onExportToPdf={onExportToPdf}
							onExportToWord={onExportToWord}
							onViewAcabadoProcess={onViewAcabadoProcess}
							t={t}
							debug={debug}
						/>
					);
				default:
					return (
						<ViewButton
							caso={caso}
							onViewAcabadoProcess={onViewAcabadoProcess}
							t={t}
							debug={debug}
						/>
					);
			}
		}

		// Para usuarios normales, mantener la lógica anterior
		if (
			selectedOption === 'Pendiente' &&
			caso.estadoEmpresas !== 'Acabada' &&
			permisoDept.editar
		) {
			return (
				<PendingButton
					caso={caso}
					handleFillForm={handleFillForm}
					t={t}
				/>
			);
		}

		if (selectedOption === 'Revision') {
			return (
				<RevisionButton
					caso={caso}
					handleFillForm={handleFillForm}
					t={t}
				/>
			);
		}

		if (selectedOption !== 'Completada' && selectedOption !== 'Acabada') {
			return (
				<ViewButton
					caso={caso}
					onViewAcabadoProcess={onViewAcabadoProcess}
					t={t}
					debug={debug}
				/>
			);
		}

		if (selectedOption === 'Completada') {
			return (
				<ConfirmButton
					caso={caso}
					onViewAcabadoProcess={onViewAcabadoProcess}
					t={t}
					isESG={isESG}
					debug={debug}
				/>
			);
		}

		if (selectedOption === 'Acabada') {
			return isESG ? (
				<ExportButtons
					caso={caso}
					onExportToPdf={onExportToPdf}
					onExportToWord={onExportToWord}
					onViewAcabadoProcess={onViewAcabadoProcess}
					t={t}
					debug={debug}
				/>
			) : (
				<ViewButton
					caso={caso}
					onViewAcabadoProcess={onViewAcabadoProcess}
					t={t}
					debug={debug}
				/>
			);
		}

		return null;
	} catch (error) {
		debugError('Error en RenderActionButtons:', error);
		return <div className='text-red-500'>Error al renderizar botones</div>;
	}
};

function CasosTable({
	casos,
	handleFillForm,
	selectedOption,
	viewOnlyUser,
	permissions = {},
	area,
}) {
	const navigate = useNavigate();
	const { empresa: userCompany } = useUserCompany();
	const localPermissions = useUserPermissions().permissions || permissions;
	const localArea = useUserArea().area || area;
	const { exportarPdf } = useExportarPdf();
	const { exportarWord } = useExportarWord();
	const { t, language, languageError } = useI18n();
	const [selectedYear, setSelectedYear] = useState('');
	const [error, setError] = useState(null);

	// Verificar si es un usuario ESG con permiso de enviar
	const esESG = isUserESG(localArea);
	const tienePermisoEnviar = hasSendPermission(localPermissions);
	const esESGConEnviar = esESG && tienePermisoEnviar;

	debug('CasosTable - Casos recibidos:', casos);
	debug('CasosTable - Selected Option:', selectedOption);
	debug('CasosTable - Usuario es solo visualización:', viewOnlyUser);
	debug('CasosTable - Usuario es ESG con permiso enviar:', esESGConEnviar);

	// Verificar que las traducciones estén disponibles
	try {
		debug('Verificando traducciones disponibles para casosTable');
		debug('Traducciones para casosTable.ano:', t('casosTable.ano', 'Año'));
		debug(
			'Traducciones para casosTable.todosLosAnos:',
			t('casosTable.todosLosAnos', 'Todos los años')
		);
	} catch (error) {
		debugError('Error al acceder a traducciones:', error);
	}

	const handleYearChange = (event) => {
		setSelectedYear(event.target.value);
	};

	const casosFiltradosSinOrdenar = filterCasos(
		casos,
		selectedYear,
		localPermissions,
		userCompany,
		localArea
	);

	// y tomar solo el caso con ID más bajo para cada grupo
	const casosPorGrupo = {};
	casosFiltradosSinOrdenar.forEach((caso) => {
		const key = `${caso.nombreEmpresa}-${caso.procesosId}-${caso.anyo}`;
		if (!casosPorGrupo[key] || caso.id < casosPorGrupo[key].id) {
			casosPorGrupo[key] = caso;
		}
	});

	// Convertir de vuelta a un array y ordenar por ID
	const filteredCasos = Object.values(casosPorGrupo).sort((a, b) => {
		// Primero ordena por empresa y proceso
		if (a.nombreEmpresa !== b.nombreEmpresa) {
			return a.nombreEmpresa.localeCompare(b.nombreEmpresa);
		}
		if (a.procesosId !== b.procesosId) {
			return a.procesosId - b.procesosId;
		}

		// Luego por ID
		return a.id - b.id;
	});

	// Función para exportar a PDF
	const onExportToPdf = async (caso, language) => {
		debug(`Iniciando exportación a PDF en idioma: ${language}`);
		await handleExportToPdf(caso, language, exportarPdf, setError);
	};

	// Función para exportar a Word
	const onExportToWord = async (caso, language) => {
		debug(`Iniciando exportación a Word en idioma: ${language}`);
		await handleExportToWord(caso, language, exportarWord, setError);
	};

	// Actualizada para incluir el año
	const onViewAcabadoProcess = (empresaId, procesoId, anyo) => {
		debug(
			`CasosTable - onViewAcabadoProcess llamado con: empresaId=${empresaId}, procesoId=${procesoId}, anyo=${anyo}`
		);
		handleViewAcabadoProcess(navigate, empresaId, procesoId, anyo);
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	// Si no hay casos, mostrar mensaje informativo
	if (!casos || casos.length === 0) {
		return (
			<div className={styles.container}>
				<div className='p-4 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded'>
					<p>{t('casosTable.noHayCasos', 'No hay casos disponibles')}</p>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			{error && <p className={styles.error}>{error}</p>}

			<div className={styles.filterContainer}>
				<label htmlFor='yearFilter'>{t('casosTable.ano', 'Año')}:</label>
				<select
					id='yearFilter'
					value={selectedYear}
					onChange={handleYearChange}
					className={styles.select}>
					<option value=''>
						{t('casosTable.todosLosAnos', 'Todos los años')}
					</option>
					{Array.from(new Set(casos.map((caso) => caso.anyo)))
						.sort((a, b) => a - b)
						.map((year) => (
							<option
								key={year}
								value={year}>
								{year}
							</option>
						))}
				</select>
			</div>

			<table className={styles.table}>
				<thead className={styles.header}>
					<tr>
						<th className={styles.headerCell}>
							{t('casosTable.idCaso', 'ID Caso')}
						</th>
						<th className={styles.headerCell}>
							{t('casosTable.nombreProceso', 'Nombre del Proceso')}
						</th>
						<th className={styles.headerCell}>
							{t('casosTable.nombreEmpresa', 'Nombre de la Empresa')}
						</th>
						<th className={styles.headerCell}>
							{t('casosTable.fechaCreacion', 'Fecha de Creación')}
						</th>
						<th className={styles.headerCell}>{t('casosTable.ano', 'Año')}</th>
						<th className={styles.headerCell}>
							{t('casosTable.estado', 'Estado')}
						</th>
						<th className={styles.headerCell}>
							{t('casosTable.accion', 'Acción')}
						</th>
					</tr>
				</thead>
				<tbody>
					{filteredCasos.length > 0 ? (
						filteredCasos.map((caso) => {
							const departamentoCaso =
								caso.departamento || caso.proceso?.departamento;
							const permisoDept = localPermissions?.[departamentoCaso] || {};
							const isESG = esESG;
							const canSend = tienePermisoEnviar;
							const ano = caso.anyo;

							debug(
								`CasosTable - Renderizando caso ID=${caso.id}, ano=${ano}, departamento=${departamentoCaso}`
							);

							return (
								<tr
									key={caso.id}
									className={styles.row}>
									<RenderBasicCells
										caso={caso}
										t={t}
									/>
									<td className={styles.cell}>
										<div className={styles.buttonContainer}>
											<RenderActionButtons
												caso={caso}
												selectedOption={selectedOption}
												permisoDept={permisoDept}
												isESG={isESG}
												canSend={canSend}
												handleFillForm={handleFillForm}
												onViewAcabadoProcess={onViewAcabadoProcess}
												onExportToPdf={onExportToPdf}
												onExportToWord={onExportToWord}
												t={t}
												debug={debug}
												viewOnlyUser={viewOnlyUser}
											/>
										</div>
									</td>
								</tr>
							);
						})
					) : (
						<tr>
							<td
								colSpan='7'
								className={styles.noData}>
								{t('casosTable.noHayCasos', 'No hay casos disponibles')}
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default CasosTable;
