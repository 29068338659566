import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../componentes/Sidebar';
import CasosTable from '../../componentes/CasosTable';
import PreguntasTable from '../../componentes/PreguntasTable';
import useUserPermissions from '../../hooks/user/useUserPermissions';
import { useCasosData } from '../../hooks/useCasosData';
import { casosStyles as styles } from '../../styles/casosStyles';
import {
	handleNavigateToQuestion,
	handleNavigateToForm,
} from '../../utils/casosHelpers';
import { casosOptions } from '../../config/casosConfig';
import useI18n from '../../hooks/i18n/useI18n';
import { debug, debugError } from '../../utils/debugHelper';
import {
	isViewOnlyUser,
	getAvailableSidebarOptions,
} from '../../utils/permissionsUtils';
import useUserArea from '../../hooks/user/useUserArea';

function Casos() {
	const { permissions } = useUserPermissions();
	const { area } = useUserArea();
	const [selectedOption, setSelectedOption] = useState(
		casosOptions.defaultStatus
	);
	const navigate = useNavigate();
	const {
		casos,
		preguntas,
		error: casosError,
	} = useCasosData(selectedOption, permissions);
	const { t, language, languageError } = useI18n();

	// Determinar si el usuario solo tiene permisos de visualización
	const viewOnlyUser = isViewOnlyUser(permissions);

	// Verificar si el usuario tiene acceso a la opción seleccionada
	useEffect(() => {
		if (permissions) {
			const availableOptions = getAvailableSidebarOptions(permissions, area);

			// Si la opción seleccionada no está disponible, redirigir a la primera disponible
			if (
				!availableOptions.includes(selectedOption) &&
				availableOptions.length > 0
			) {
				debug(
					`La opción ${selectedOption} no está disponible, redirigiendo a ${availableOptions[0]}`
				);
				setSelectedOption(availableOptions[0]);
			}
		}
	}, [permissions, area, selectedOption]);

	debug('Casos - Componente inicializado con idioma:', language);
	debug('Casos - Usuario es solo visualización:', viewOnlyUser);

	const handleFilterChange = (status) => setSelectedOption(status);

	const onAnswerQuestion = (preguntasId, empresaId, procesoId, respuestaId) => {
		handleNavigateToQuestion(
			navigate,
			preguntasId,
			empresaId,
			procesoId,
			respuestaId
		);
	};

	const onFillForm = (FormularioId, empresaId, procesoId, mensajerevision) => {
		handleNavigateToForm(navigate, FormularioId, empresaId, mensajerevision);
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		debugError('Casos - Error de idioma:', languageError);
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<Sidebar
				onFilterChange={handleFilterChange}
				selectedOption={selectedOption}
			/>

			<div className={styles.mainContent}>
				<h1 className={styles.title}>
					{selectedOption === casosOptions.viewTypes.redirected
						? t('cases.redirectedQuestions', 'Preguntas Redirigidas')
						: t('cases.title', 'Casos')}
				</h1>

				{casosError && <p className={styles.error}>{casosError}</p>}

				{selectedOption === casosOptions.viewTypes.redirected ? (
					<PreguntasTable
						preguntas={preguntas}
						handleAnswerQuestion={onAnswerQuestion}
					/>
				) : (
					<CasosTable
						casos={casos}
						handleFillForm={onFillForm}
						selectedOption={selectedOption}
						viewOnlyUser={viewOnlyUser}
						permissions={permissions}
						area={area}
					/>
				)}
			</div>
		</div>
	);
}

export default Casos;
