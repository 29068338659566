// frontend/src/styles/createUserStyles.js
export const createUserStyles = {
	container: 'max-w-6xl mx-auto py-8 px-4',
	card: 'bg-fondo rounded-xl shadow-lg overflow-hidden',
	header: 'bg-secundario p-6 text-fondo',
	form: 'p-8 space-y-10',
	label: 'block text-sm font-medium text-gray-700 mb-1',
	input:
		'w-full px-4 py-2 border  rounded-lg focus:ring-2 focus:ring-secundario  transition-all duration-200',
	checkbox: 'w-5 h-5  rounded focus:ring-secundario accent-secundario',
	button:
		'w-full py-3 px-6 bg-secundario text-fondo font-medium rounded-lg shadow-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secundario transition-all duration-200',
	areaSection: 'mt-12',
	areaHeader: 'flex items-center space-x-4 mb-6',
	areaTitle: 'text-xl font-semibold text-secundario',
	areaLine: 'h-px flex-1 bg-gradient-to-r from-secundario to-transparent',
	areaContainer:
		'bg-secundario/10  rounded-xl p-6 shadow-inner',
	areaGrid: 'grid grid-cols-1 md:grid-cols-2 gap-4',
	areaItem: 'flex items-center space-x-3 bg-fondo p-4 rounded-lg shadow-sm',
	departmentContainer:
		'mt-8 bg-secundario/10 rounded-xl p-6 shadow-inner',
	departmentItem: 'bg-fondo p-5 rounded-lg shadow-sm',
	departmentName: 'block text-sm font-medium  mb-3',
	permissionsContainer: 'flex flex-wrap gap-6',
	permissionLabel: 'flex items-center space-x-2 cursor-pointer',
	permissionText: 'text-sm ',
	loader: 'flex justify-center items-center min-h-screen',
	loaderSpinner:
		'animate-spin rounded-full h-12 w-12 border-b-2 border-secundario mx-auto',
	loaderText: 'mt-4 ',
	errorContainer: 'p-4 bg-error/30 border-l-4 border-error text-error',
	titleContainer: 'text-center mb-8',
	titleText: 'text-4xl font-bold text-secundario mb-2',
	titleUnderline:
		'h-1 w-24 bg-gradient-to-r from-secundario to-transparent mx-auto rounded-full',
};
