// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CasosTable_casosTable__iR-Sn {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.CasosTable_casosTable__iR-Sn th,
.CasosTable_casosTable__iR-Sn td {
  padding: 12px 15px;
  border: 1px solid #ccc;
  text-align: left;
}

.CasosTable_casosTable__iR-Sn th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.CasosTable_casosTable__iR-Sn tbody tr:hover {
  background-color: #f0f8ff;
}

.CasosTable_casosTable__iR-Sn button {
  padding: 8px 12px;
  background-color: #007bff; /* Color azul del navbar */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.CasosTable_casosTable__iR-Sn button:hover {
  background-color: #0056b3; /* Un tono más oscuro al pasar el mouse */
}

.CasosTable_exportButton__U8iOJ {
  margin-top: 10px;
  background-color: #28a745 !important; /* Verde */
  color: white !important;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.CasosTable_exportButton__U8iOJ:hover {
  background-color: #218838 !important; /* Verde más oscuro al hacer hover */
}`, "",{"version":3,"sources":["webpack://./src/componentes/CasosTable.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;EAClB,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,yBAAyB,EAAE,0BAA0B;EACrD,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sCAAsC;EACtC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB,EAAE,yCAAyC;AACtE;;AAEA;EACE,gBAAgB;EAChB,oCAAoC,EAAE,UAAU;EAChD,uBAAuB;EACvB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,oCAAoC,EAAE,oCAAoC;AAC5E","sourcesContent":[".casosTable {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.casosTable th,\n.casosTable td {\n  padding: 12px 15px;\n  border: 1px solid #ccc;\n  text-align: left;\n}\n\n.casosTable th {\n  background-color: #f5f5f5;\n  font-weight: bold;\n}\n\n.casosTable tbody tr:hover {\n  background-color: #f0f8ff;\n}\n\n.casosTable button {\n  padding: 8px 12px;\n  background-color: #007bff; /* Color azul del navbar */\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  font-weight: bold;\n}\n\n.casosTable button:hover {\n  background-color: #0056b3; /* Un tono más oscuro al pasar el mouse */\n}\n\n.exportButton {\n  margin-top: 10px;\n  background-color: #28a745 !important; /* Verde */\n  color: white !important;\n  border: none;\n  padding: 5px 10px;\n  border-radius: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.exportButton:hover {\n  background-color: #218838 !important; /* Verde más oscuro al hacer hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casosTable": `CasosTable_casosTable__iR-Sn`,
	"exportButton": `CasosTable_exportButton__U8iOJ`
};
export default ___CSS_LOADER_EXPORT___;
