//frontend/src/config/formulariosConfig.js
export const formulariosOrder = {
	'ESRS 2': [
		'IRO-1',
		'IRO-2',
		'BP-1',
		'BP-2',
		'GOV-1',
		'GOV-2',
		'GOV-3',
		'GOV-4',
		'GOV-5',
		'SBM-1',
		'SBM-2',
		'SBM-3',
	],
	'ESRS E1': [
		'E1-GOV-3',
		'E1-1',
		'E1-SBM-3',
		'E1-IRO-1',
		'E1-2',
		'E1-3',
		'E1-4',
		'E1-5',
		'E1-6',
		'E1-7',
		'E1-8',
		'E1-9',
	],
	'ESRS E2': ['E2-IRO-1', 'E2-1', 'E2-2', 'E2-3', 'E2-4', 'E2-5', 'E2-6'],
	'ESRS E3': ['E3-IRO-1', 'E3-1', 'E3-2', 'E3-3', 'E3-4', 'E3-5'],
	'ESRS E4': [
		'E4-SBM-3',
		'E4-IRO-1',
		'E4-1',
		'E4-2',
		'E4-3',
		'E4-4',
		'E4-5',
		'E4-6',
	],
	'ESRS E5': ['E5-IRO-1', 'E5-1', 'E5-2', 'E5-3', 'E5-4', 'E5-5', 'E5-6'],
	'ESRS S1': [
		'S1-SBM-3',
		'S1-1',
		'S1-2',
		'S1-3',
		'S1-4',
		'S1-5',
		'S1-6',
		'S1-7',
		'S1-8',
		'S1-9',
		'S1-10',
		'S1-11',
		'S1-12',
		'S1-13',
		'S1-14',
		'S1-15',
		'S1-16',
		'S1-17',
	],
	'ESRS S2': ['S2-SBM-3', 'S2-1', 'S2-2', 'S2-3', 'S2-4', 'S2-5'],
	'ESRS S3': ['S3-SBM-3', 'S3-1', 'S3-2', 'S3-3', 'S3-4', 'S3-5'],
	'ESRS S4': ['S4-SBM-3', 'S4-1', 'S4-2', 'S4-3', 'S4-4', 'S4-5'],
	'ESRS G1': ['G1-GOV-1', 'G1-1', 'G1-2', 'G1-3', 'G1-4', 'G1-5', 'G1-6'],
};
