//frontend/src/hooks/formularios/useFormularioValidation.js
export const useFormularioValidation = (
	formularios,
	empresaId,
	procesoId,
	respuestasEnviadas
) => {
	const todasPreguntasContestadas = () => {
		return formularios.every((formulario) =>
			formulario.preguntas.every(
				(pregunta) =>
					respuestasEnviadas[
						`${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`
					]?.estadopregunta === 'Contestada'
			)
		);
	};

	return { todasPreguntasContestadas };
};
