import React, { useMemo, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTableQuestion } from '../../hooks/questions/useQuestionType/useTableQuestion';
import { tableConfigs } from '../../config/questionConfig';
import { questionStyles } from '../../styles/questionStyles';

// Componente optimizado para una sola fila de la tabla
const TableRow = memo(
	({
		row,
		rowIndex,
		styles,
		handleCheckboxChange,
		formulario,
		canEdit,
		isRedirigida,
		language,
		t,
		tableKey,
	}) => {
		// Traducir subtópicos
		const getTranslatedSubtopics = (subtopic) => {
			if (!subtopic) return '';

			const subtopics = subtopic.split('-');
			return subtopics.map((topic, i) => {
				const trimmedTopic = topic.trim();
				// Intentar traducir usando i18next
				const translatedTopic = t(
					`tableQuestion.subtopics.${trimmedTopic}`,
					trimmedTopic
				);

				return (
					<div
						key={i}
						className={styles.subtemaItem}>
						<span className={styles.bullet}>•</span>
						<span>{translatedTopic}</span>
					</div>
				);
			});
		};

		// Función para traducción de requisitos
		const getTranslatedDisclosureRequirement = (requirement, topic) => {
			// Si no hay requisito, devolver texto original
			if (!requirement) return requirement;

			// Normalizar el requisito (eliminar espacios extras)
			const normalizedRequirement = requirement.trim();

			// Si ya está en inglés (empieza con palabras en inglés), devolverlo así
			if (
				normalizedRequirement.startsWith('Description') ||
				normalizedRequirement.startsWith('Disclosure') ||
				normalizedRequirement.startsWith('Information') ||
				normalizedRequirement.startsWith('Amounts') ||
				normalizedRequirement.startsWith('Production') ||
				normalizedRequirement.startsWith('DR related') ||
				normalizedRequirement.startsWith('The interests') ||
				normalizedRequirement.startsWith('Taking action') ||
				normalizedRequirement.startsWith('Targets related') ||
				normalizedRequirement.startsWith('The role of') ||
				normalizedRequirement.startsWith('Corporate') ||
				normalizedRequirement.startsWith('Management of') ||
				normalizedRequirement.startsWith('Prevention') ||
				normalizedRequirement.startsWith('Confirmed') ||
				normalizedRequirement.startsWith('Political') ||
				normalizedRequirement.startsWith('Anticipated') ||
				normalizedRequirement.startsWith('Resource')
			) {
				return requirement;
			}

			// Extraer código de estándar del topic para la búsqueda en traducciones
			let standardCode = '';
			if (topic) {
				const match = topic.match(/ESRS\s+([A-Z][0-9]+)/i);
				if (match && match[1]) {
					standardCode = match[1].toLowerCase();
				}
			}

			// 1. Intentar búsqueda por requisito completo (con el texto normalizado)
			const fullKey = `tableQuestion.requirements.${standardCode}.${normalizedRequirement}`;
			const fullTranslation = t(fullKey, null);
			if (fullTranslation !== null) {
				return fullTranslation;
			}

			// 2. Intentar búsqueda por código de requisito (E1-1, E2-2, etc.)
			const codeMatch = normalizedRequirement.match(/^([A-Z][0-9]+-[0-9]+)/);
			if (codeMatch) {
				const code = codeMatch[0];
				const codeKey = `tableQuestion.requirements.${standardCode}.${code}`;
				const codeTranslation = t(codeKey, null);
				if (codeTranslation !== null) {
					return codeTranslation;
				}
			}

			// 3. Intentar traducción directa por clave simple (con el texto normalizado)
			const simpleKey = `tableQuestion.requirements.${normalizedRequirement}`;
			const simpleTranslation = t(simpleKey, null);
			if (simpleTranslation !== null) {
				return simpleTranslation;
			}

			// 4. Si todo falla, devolver el texto original
			return requirement;
		};

		// Traducir pilar de divulgación
		const getTranslatedPillar = (pillar) => {
			if (!pillar) return pillar;

			// Intentar traducir usando i18next
			const translatedPillar = t(`tableQuestion.pillars.${pillar}`, null);
			if (translatedPillar !== null) {
				return translatedPillar;
			}

			// Si no hay traducción específica, buscar en el mapa común de pilares
			const commonPillarKey = `tableQuestion.commonPillars.${pillar}`;
			const commonTranslation = t(commonPillarKey, null);
			if (commonTranslation !== null) {
				return commonTranslation;
			}

			// Si todo falla, devolver el original
			return pillar;
		};

		return (
			<tr>
				<td className={styles.tableCell}>
					<div className={styles.subtemaCell}>
						{getTranslatedSubtopics(row.Subtopic)}
					</div>
				</td>
				<td
					className={`
						${styles.tableCell} 
						${styles.pillarCell}
					`}>
					{getTranslatedPillar(row.PilarDeDivulgacion)}
				</td>
				<td
					className={`
						${styles.tableCell} 
						${styles.requisitosCell}
					`}>
					{getTranslatedDisclosureRequirement(
						row.RequisitoDeDivulgacion,
						row.Topic
					)}
				</td>
				<td
					className={`
						${styles.tableCell} 
						${styles.checkboxCell}
					`}>
					<input
						type='checkbox'
						checked={row.defaultChecked}
						onChange={() => handleCheckboxChange(rowIndex, formulario)}
						className={styles.checkbox}
						disabled={!canEdit || isRedirigida}
					/>
				</td>
			</tr>
		);
	}
);

// Componente principal TableQuestion optimizado
const TableQuestion = ({
	pregunta,
	formulario,
	procesoId,
	respuestas,
	handleEditorChange,
	handleSubmit,
	canEdit,
	isRedirigida,
	language = 'ES',
}) => {
	// Usar i18n directamente
	const { t, i18n } = useTranslation();

	// Usar el hook optimizado con control de actualizaciones por lotes
	const {
		selectedTableOption,
		showTable,
		tableData,
		tableInfo,
		handleCheckboxChange,
		handleTableOptionChange,
	} = useTableQuestion(
		pregunta,
		formulario.FormularioId,
		procesoId,
		respuestas,
		handleEditorChange,
		handleSubmit
	);

	// Memorizar el cálculo de la configuración de la tabla
	const tableKey = useMemo(
		() => `${formulario.FormularioId}-${procesoId}-${pregunta.id}`,
		[formulario.FormularioId, procesoId, pregunta.id]
	);

	// Memorizar la obtención de la configuración desde tableConfigs
	const tableConfig = useMemo(() => tableConfigs[tableKey], [tableKey]);

	// Verificar idioma cada vez que cambie
	useEffect(() => {}, [language, i18n.language]);

	// Abortar el renderizado si no hay configuración
	if (!tableConfig) {
		return null;
	}

	// Verificar si es la tabla especial 3-1-22
	const isInfoOnlyTable = tableKey === '3-1-22';

	// Usar los estilos JS del nuevo sistema de estilos
	const styles = questionStyles.tableJS;

	// Función para manejar los cambios en las opciones de radio
	const handleRadioChange = (e) => {
		// Usar requestAnimationFrame para posponer el cálculo pesado hasta después del renderizado
		requestAnimationFrame(() => {
			handleTableOptionChange(e.target.value, formulario);
		});
	};

	// Función optimizada para manejar cambios en checkbox con debounce implícito
	const handleCheckboxChangeOptimized = (rowIndex, formulario) => {
		// Usar requestAnimationFrame para posponer el cálculo pesado hasta después del renderizado
		requestAnimationFrame(() => {
			handleCheckboxChange(rowIndex, formulario);
		});
	};

	// Obtener opciones traducidas para los botones de radio usando i18next
	const getTranslatedOption = (optionType) => {
		if (tableConfig?.showConditions?.[optionType]) {
			// Si hay una opción específica en la configuración, intentar traducirla
			const configOption = tableConfig.showConditions[optionType];

			// Intentar traducir usando i18next
			const translatedOption = t(`tableQuestion.options.${configOption}`, null);

			// Si se encontró una traducción
			if (translatedOption !== null) {
				return translatedOption;
			}

			// Usar opciones genéricas si no hay traducción específica
			return t(
				`tableQuestion.${optionType}Option`,
				optionType === 'positive'
					? 'Yes, it is material'
					: 'No, it is not material'
			);
		}

		// Usar las traducciones genéricas
		return t(
			`tableQuestion.${optionType}Option`,
			optionType === 'positive'
				? 'Yes, it is material'
				: 'No, it is not material'
		);
	};

	// Obtener el encabezado de la tabla traducido
	// Función auxiliar para normalizar texto (quita acentos y convierte a minúsculas)
	const normalizeForComparison = (text) => {
		if (!text) return '';
		return text
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '');
	};

	// Función auxiliar para buscar coincidencias en las traducciones
	const findBestMatch = (rightHeader, translationsByCode) => {
		const normalizedRightHeader = normalizeForComparison(rightHeader);

		// Extraer el código ESRS (para búsqueda más específica)
		const esrsCodeMatch = rightHeader.match(/ESRS\s+([A-Z][0-9]+)/i);
		const esrsCode = esrsCodeMatch ? esrsCodeMatch[0] : '';
		const normalizedEsrsCode = normalizeForComparison(esrsCode);

		// Buscar la traducción que mejor coincida
		for (const key in translationsByCode) {
			const normalizedKey = normalizeForComparison(key);

			// Verificar coincidencia exacta normalizada
			if (normalizedKey === normalizedRightHeader) {
				return translationsByCode[key];
			}

			// Verificar coincidencia por código ESRS + parte del texto
			if (
				normalizedEsrsCode &&
				normalizedKey.includes(normalizedEsrsCode) &&
				(normalizedKey.includes('cambio') || normalizedKey.includes('clima'))
			) {
				return translationsByCode[key];
			}
		}

		return null;
	};

	// Obtener el encabezado de la tabla traducido
	const getTranslatedHeader = (headerType) => {
		// Caso para encabezado izquierdo (simple)
		if (headerType === 'leftHeader') {
			return tableConfig?.headers?.leftHeader;
		}

		// Si no es rightHeader, devolver vacío
		if (headerType !== 'rightHeader') {
			return '';
		}

		const rightHeader = tableConfig?.headers?.rightHeader;

		// Si no hay encabezado configurado, devolver valor por defecto
		if (!rightHeader) return '';

		// Obtener todas las traducciones disponibles
		const translationsByCode = t(
			`tableQuestion.headers.rightHeader`,
			{},
			{ returnObjects: true }
		);

		// Buscar la mejor coincidencia si hay traducciones disponibles
		if (typeof translationsByCode === 'object') {
			const bestMatch = findBestMatch(rightHeader, translationsByCode);
			if (bestMatch) {
				return bestMatch;
			}
		}

		// Intentar traducción directa como último recurso
		const translatedHeader = t(
			`tableQuestion.headers.rightHeader.${rightHeader}`,
			null
		);

		if (translatedHeader !== null) {
			return translatedHeader;
		}

		// Si todo falla, usar el valor original
		return rightHeader;
	};

	// Obtener traducciones para las columnas
	const getColumnTranslation = (columnName) => {
		// Intentar traducir usando i18next
		return t(`tableQuestion.columns.${columnName}`, columnName);
	};

	return (
		<div>
			{/* Radio buttons - Solo mostrar si NO es la tabla informativa */}
			{!isInfoOnlyTable && (
				<div className={styles.materialityOptions}>
					<div className={styles.radioGroup}>
						<label
							className={`
								${styles.radioLabel} 
								${selectedTableOption === 'positive' ? styles.radioLabelChecked : {}}
							`}>
							<input
								type='radio'
								name={`material-option-${pregunta.id}`}
								value='positive'
								checked={selectedTableOption === 'positive'}
								onChange={handleRadioChange}
								disabled={!canEdit || isRedirigida}
								className={styles.radioInput}
							/>
							<span className={styles.radioText}>
								{getTranslatedOption('positive')}
							</span>
						</label>
						<label
							className={`
								${styles.radioLabel}
								${selectedTableOption === 'negative' ? styles.radioLabelChecked : {}}
							`}>
							<input
								type='radio'
								name={`material-option-${pregunta.id}`}
								value='negative'
								checked={selectedTableOption === 'negative'}
								onChange={handleRadioChange}
								disabled={!canEdit || isRedirigida}
								className={styles.radioInput}
							/>
							<span className={styles.radioText}>
								{getTranslatedOption('negative')}
							</span>
						</label>
					</div>
				</div>
			)}

			{/* Tabla - Mostrar siempre para la tabla informativa o cuando showTable es true */}
			{(isInfoOnlyTable || showTable) && (
				<div className={styles.tableContainer}>
					<table className={styles.table}>
						<thead>
							<tr>
								<th
									colSpan='1'
									className={styles.tableHeaderTop}>
									{getTranslatedHeader('leftHeader')}
								</th>
								<th
									colSpan='2'
									className={styles.tableHeaderTop}>
									{getTranslatedHeader('rightHeader')}
								</th>
								<th
									rowSpan='2'
									className={styles.tableHeader}>
									{getColumnTranslation('No informar')}
								</th>
							</tr>
							<tr>
								<th className={styles.tableHeader}>
									{getColumnTranslation('Subtema')}
								</th>
								<th className={styles.tableHeader}>
									{getColumnTranslation('Pilar de divulgación')}
								</th>
								<th className={styles.tableHeader}>
									{getColumnTranslation('Requisito de divulgación')}
								</th>
							</tr>
						</thead>
						<tbody>
							{tableData.map((row, rowIndex) => {
								return (
									<TableRow
										key={`${pregunta.id}-row-${rowIndex}`}
										row={row}
										rowIndex={rowIndex}
										styles={styles}
										handleCheckboxChange={handleCheckboxChangeOptimized}
										formulario={formulario}
										canEdit={canEdit}
										isRedirigida={isRedirigida}
										language={language}
										t={t}
										tableKey={tableKey}
									/>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

// Aplicar memo al componente completo para evitar re-renderizados innecesarios
export default memo(TableQuestion);
