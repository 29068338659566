// frontend/src/hooks/user/useUserCompany.js
import { useState, useEffect } from 'react';
import { ROUTE_AUTH_ME_COMPANY } from '../../config/routeConfig';
import { debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useUserCompany() {
	const [empresa, setEmpresa] = useState('');
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchUserCompany = async () => {
			try {
				const response = await fetchWithAuth(ROUTE_AUTH_ME_COMPANY, {
					method: 'GET',
				});

				if (response.ok) {
					const data = await response.json();
					setEmpresa(data.empresa);
				} else {
					debugError('Error al obtener la empresa del usuario');
					setError('Error al obtener la empresa');
				}
			} catch (error) {
				debugError('Error en la solicitud de empresa:', error);
				setError('Error en la solicitud de empresa');
			}
		};

		fetchUserCompany();
	}, []);

	return { empresa, error };
}

export default useUserCompany;
