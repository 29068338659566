// frontend/src/utils/fetchUtils.js
export const fetchWithAuth = async (url, options = {}) => {
	const defaultOptions = {
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			...options.headers,
		},
	};

	const fetchOptions = { ...defaultOptions, ...options };

	try {
		const response = await fetch(url, fetchOptions);

		// Ayuda a identificar problemas de autenticación específicos
		if (response.status === 401) {
			console.error('Error de autenticación en:', url);
		}

		return response;
	} catch (error) {
		console.error('Error en fetchWithAuth:', url, error);
		throw error;
	}
};
