//frontend/src/hooks/formularios/useFormularioSort.js
import { formulariosOrder } from '../../config/formulariosConfig';

function useFormularioSort() {
	// Cambiado a function en lugar de const
	const sortFormularios = (formularios) => {
		if (!formularios || !Array.isArray(formularios)) return [];

		return formularios
			.filter((formulario) => formulario.estadoEmpresas !== 'Acabada')
			.sort((a, b) => {
				const processOrder = formulariosOrder[a.proceso?.nombre] || [];
				const indexA = processOrder.indexOf(a.formulario?.nombre);
				const indexB = processOrder.indexOf(b.formulario?.nombre);
				if (indexA === -1) return 1;
				if (indexB === -1) return -1;
				return indexA - indexB;
			});
	};

	return { sortFormularios };
}

export default useFormularioSort;
