// frontend/src/hooks/questions/useQuestionType/useConditionalQuestion.js
import { useState, useRef, useEffect } from 'react';
import { questionOptions } from '../../../config/questionConfig';
import {
	checkIfStartsWithOption,
	getTextFromHtml,
} from '../../../utils/questionUtils';
import { debug } from '../../../utils/debugHelper';

// Función auxiliar para verificar y obtener la configuración de la pregunta
const getQuestionConfig = (formularioId, procesoId, preguntaId) => {
	const configKey = `${formularioId}-${procesoId}-${preguntaId}`;
	const configExists = !!questionOptions[configKey];
	debug(`configKey: ${configKey}, existe en questionOptions: ${configExists}`);

	if (configExists) {
		debug(`questionConfig para ${preguntaId}:`, questionOptions[configKey]);
		return questionOptions[configKey];
	}

	debug(
		`No se encontró configuración específica para la pregunta ${formularioId}-${procesoId}-${preguntaId}. Usando configuración por defecto.`
	);
	return null;
};

// Función auxiliar para buscar respuesta guardada
const findRespuestaGuardada = (
	respuestasEnviadas,
	formularioId,
	procesoId,
	preguntaId
) => {
	for (const key in respuestasEnviadas) {
		if (
			key.includes(`${formularioId}-`) &&
			key.includes(`-${procesoId}-`) &&
			key.includes(`-${preguntaId}`)
		) {
			return respuestasEnviadas[key]?.respuesta?.text || '';
		}
	}
	return '';
};

// Función auxiliar para determinar la opción inicial a partir de una respuesta
const determineInitialOption = (respuestaPlainText, questionConfig) => {
	// Si hay un questionConfig, verificar con las opciones definidas
	if (questionConfig) {
		debug(`getInitialOption - verificando con questionConfig`);

		if (
			checkIfStartsWithOption(respuestaPlainText, questionConfig.positiveOption)
		) {
			debug(`getInitialOption - match con positiveOption encontrado`);
			return questionConfig.positiveOption;
		}

		if (
			checkIfStartsWithOption(respuestaPlainText, questionConfig.negativeOption)
		) {
			debug(`getInitialOption - match con negativeOption encontrado`);
			return questionConfig.negativeOption;
		}
	} else {
		debug(`getInitialOption - verificando con opciones genéricas`);
		// Opciones por defecto "Si" y "No"
		if (checkIfStartsWithOption(respuestaPlainText, 'Si')) {
			debug(`getInitialOption - match con "Si" encontrado`);
			return 'compliant';
		}
		if (checkIfStartsWithOption(respuestaPlainText, 'No')) {
			debug(`getInitialOption - match con "No" encontrado`);
			return 'non-compliant';
		}

		// Opciones para Sí/Yes en inglés
		if (checkIfStartsWithOption(respuestaPlainText, 'Yes')) {
			debug(`getInitialOption - match con "Yes" encontrado`);
			return 'compliant';
		}
	}

	debug(`getInitialOption - no match encontrado, retornando vacío`);
	return '';
};

// Función auxiliar para decidir si debemos auto-rellenar preguntas controladas
const shouldAutoFillControlledQuestions = (
	plainTextResponse,
	questionConfig
) => {
	if (!questionConfig || !questionConfig.controlBehavior) {
		return false;
	}

	let shouldAutoFill = false;

	if (questionConfig.controlBehavior === 'positive') {
		// Verificar si la respuesta coincide con la opción positiva
		shouldAutoFill = checkIfStartsWithOption(
			plainTextResponse,
			questionConfig.positiveOption
		);
		debug(
			`shouldAutoFillControlledQuestions - controlBehavior: positive, shouldAutoFill: ${shouldAutoFill}`
		);

		// Si aún no hay coincidencia, verificar coincidencia exacta
		if (!shouldAutoFill) {
			shouldAutoFill =
				plainTextResponse.trim() === questionConfig.positiveOption.trim();
			debug(
				`shouldAutoFillControlledQuestions - forzando autorellenado para opción positiva exacta: ${shouldAutoFill}`
			);
		}
	} else if (questionConfig.controlBehavior === 'negative') {
		// Verificar si la respuesta coincide con la opción negativa
		shouldAutoFill = checkIfStartsWithOption(
			plainTextResponse,
			questionConfig.negativeOption
		);
		debug(
			`shouldAutoFillControlledQuestions - controlBehavior: negative, shouldAutoFill: ${shouldAutoFill}`
		);

		// Si aún no hay coincidencia, verificar coincidencia exacta
		if (!shouldAutoFill) {
			shouldAutoFill =
				plainTextResponse.trim() === questionConfig.negativeOption.trim();
			debug(
				`shouldAutoFillControlledQuestions - forzando autorellenado para opción negativa exacta: ${shouldAutoFill}`
			);
		}
	}

	return shouldAutoFill;
};

// Función auxiliar para auto-rellenar preguntas controladas
const autoFillControlledQuestions = (
	questionConfig,
	currentContent,
	formulario,
	handleEditorChange,
	handleSubmit
) => {
	debug(
		`autoFillControlledQuestions - autollenando preguntas controladas con el mismo contenido: "${currentContent}"`
	);

	questionConfig.controlledQuestions.forEach((controlledId) => {
		const copiedRespuesta = {
			text: currentContent,
			image: '',
		};

		debug(
			`Copiando a pregunta ${controlledId} el contenido: "${currentContent}"`
		);

		handleEditorChange(controlledId, formulario.FormularioId, currentContent);
		handleSubmit(controlledId, formulario.FormularioId, copiedRespuesta);
	});
};

// Hook principal refactorizado
export const useConditionalQuestion = (
	respuestasEnviadas,
	formularioId,
	procesoId,
	preguntaId,
	pregunta
) => {
	// Obtener configuración de la pregunta
	const questionConfig = getQuestionConfig(formularioId, procesoId, preguntaId);

	// Estados para el hook
	const [selectedOption, setSelectedOption] = useState('');
	const [inputValue, setInputValue] = useState('');

	// Referencias para elementos del DOM y tracking
	const selectRef = useRef(null);
	const updateQueued = useRef(false);
	const initialOption = useRef('');

	// Buscar respuesta guardada para esta pregunta
	const respuestaGuardada = findRespuestaGuardada(
		respuestasEnviadas,
		formularioId,
		procesoId,
		preguntaId
	);
	debug(`respuestaGuardada para ${preguntaId}:`, respuestaGuardada);

	// Función para obtener la opción inicial basada en respuesta guardada
	const getInitialOption = () => {
		const respuestaPlainText = getTextFromHtml(respuestaGuardada).trim();
		debug(`getInitialOption - respuestaPlainText:`, respuestaPlainText);

		return determineInitialOption(respuestaPlainText, questionConfig);
	};

	// Efecto para inicializar la opción seleccionada desde la respuesta guardada
	useEffect(() => {
		const newOption = getInitialOption();
		debug(`useEffect - newOption:`, newOption);

		if (newOption) {
			setSelectedOption(newOption);
			initialOption.current = newOption;
		}
	}, [respuestaGuardada]);

	// Función para manejar el cambio de opción
	const handleOptionChange = (e) => {
		const newOption = e.target.value;
		debug(`handleOptionChange - nueva opción: ${newOption}`);
		setSelectedOption(newOption);

		// Si es un valor vacío, limpiar el input
		if (!newOption) {
			setInputValue('');
			return;
		}

		// Generar texto inicial según la opción seleccionada
		let initialText = '';
		if (questionConfig) {
			initialText = newOption;
		} else {
			initialText = newOption === 'compliant' ? 'Si' : 'No';
		}

		setInputValue(initialText);
		updateQueued.current = true;
	};

	// Función para procesar pregunta condicional
	const processConditionalQuestion = (
		pregunta,
		formulario,
		procesosId,
		currentContent,
		handleEditorChange,
		handleSubmit
	) => {
		// Verificar si hay preguntas controladas por esta
		const localConfigKey = `${formulario.FormularioId}-${procesosId}-${pregunta.id}`;
		const localQuestionConfig = questionOptions[localConfigKey];

		// Si no hay preguntas controladas, no hacer nada más
		if (!localQuestionConfig?.controlledQuestions?.length) {
			return;
		}

		debug(
			`processConditionalQuestion - ¿Hay preguntas controladas? ${true}`,
			localQuestionConfig.controlledQuestions
		);

		// Procesar cada pregunta controlada
		const plainTextResponse = getTextFromHtml(currentContent);
		debug(
			`processConditionalQuestion - plainTextResponse: ${plainTextResponse}`
		);

		// Determinar si debemos autorellenar
		const shouldAutoFill = shouldAutoFillControlledQuestions(
			plainTextResponse,
			localQuestionConfig
		);

		// Si debemos autorellenar, hacerlo para todas las preguntas controladas
		if (shouldAutoFill) {
			autoFillControlledQuestions(
				localQuestionConfig,
				currentContent,
				formulario,
				handleEditorChange,
				handleSubmit
			);
		} else {
			debug(
				`processConditionalQuestion - NO se autollenan preguntas controladas`
			);
		}
	};

	// Función para manejar el submit con lógica condicional
	const handleSubmitWithConditional = (
		pregunta,
		formulario,
		respuestas,
		handleEditorChange,
		handleSubmit,
		options,
		procesosId,
		forcedRespuestaObj = null
	) => {
		debug(`handleSubmitWithConditional - inicio para pregunta: ${pregunta.id}`);
		if (!pregunta) return;

		// Obtener el contenido actual del editor o usar el forzado
		const currentContent =
			forcedRespuestaObj?.text ||
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text ||
			'';

		debug(`handleSubmitWithConditional - currentContent: ${currentContent}`);

		// Si no hay contenido, no hacer nada
		if (!currentContent) return;

		// Crear objeto de respuesta
		const respuestaObj = forcedRespuestaObj || {
			text: currentContent,
			image: '',
		};

		// Guardar la respuesta principal
		handleSubmit(pregunta.id, formulario.FormularioId, respuestaObj);
		debug(`handleSubmitWithConditional - guardada respuesta principal`);

		// Procesar la lógica condicional
		processConditionalQuestion(
			pregunta,
			formulario,
			procesosId,
			currentContent,
			handleEditorChange,
			handleSubmit
		);
	};

	return {
		selectedOption,
		inputValue,
		setInputValue,
		selectRef,
		handleOptionChange,
		handleSubmitWithConditional,
	};
};
