import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUserId from '../../hooks/useUserId';
import useUserCompany from '../../hooks/useUserCompany';
import useUserLanguage from '../../hooks/useUserLanguage';
import styles from './CrearEmpresa.module.css';

function CrearEmpresa() {
  const { procesoId } = useParams();
  const [nombreProceso, setNombreProceso] = useState('');
  const [formularios, setFormularios] = useState([]);
  const { userId, error: userIdError } = useUserId();
  const { empresa, error: empresaError } = useUserCompany();
  const { language } = useUserLanguage();
  const navigate = useNavigate();

  // Leer el año seleccionado desde localStorage y convertirlo a número
  const selectedYear = parseInt(localStorage.getItem('selectedYear'), 10);

  useEffect(() => {
    const fetchProceso = async () => {
      try {
        const response = await fetch(`https://risincome.aicrumit.com/backend/api/procesos/${procesoId}`, {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        setNombreProceso(data.nombre);
      } catch (error) {
        console.error('Error al cargar el proceso:', error);
      }
    };

    const fetchFormularios = async () => {
      try {
        const response = await fetch(`https://risincome.aicrumit.com/backend/api/procesos/${procesoId}/forms`, {
          method: 'GET',
          credentials: 'include',
        });
        const data = await response.json();
        setFormularios(data);
      } catch (error) {
        console.error('Error al cargar formularios:', error);
      }
    };

    fetchProceso();
    fetchFormularios();
  }, [procesoId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      console.error('UserId no disponible');
      return;
    }

    if (!empresa) {
      console.error('Empresa no disponible');
      return;
    }

    if (!selectedYear) {
      console.error('Año seleccionado no disponible');
      return;
    }

    try {
      const response = await fetch('https://risincome.aicrumit.com/backend/api/empresas/create-with-forms', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          nombreEmpresa: empresa,
          procesosId: procesoId,
          userId,
          anyo: selectedYear // Incluye el año seleccionado en la solicitud
        }),
      });

      if (response.ok) {
        alert(language === 'EN' ? 'Requirement and associated forms created successfully.' : 'Requisito y formularios asociados creados con éxito.');
        navigate('/casos');
      } else {
        const errorData = await response.json();
        console.error(language === 'EN' ? 'Error creating the requirement:' : 'Error al crear el requisito:', errorData);
      }
    } catch (error) {
      console.error(language === 'EN' ? 'Error creating the requirement:' : 'Error al crear el requisito:', error);
    }
  };

  const labels = {
    title: language === 'EN' ? 'Create Requirement for Process:' : 'Crear Requisito para el Proceso:',
    company: language === 'EN' ? 'Company' : 'Empresa',
    createRequirement: language === 'EN' ? 'Create Requirement' : 'Crear Requisito',
    associatedForms: language === 'EN' ? 'Associated Forms:' : 'Formularios Asociados:',
    noForms: language === 'EN' ? 'No associated forms found.' : 'No se encontraron formularios asociados.',
  };

  return (
    <div className={styles.crearEmpresaContainer}>
      <h2>{labels.title} {nombreProceso}</h2>
      {userIdError && <p className={styles.errorText}>{userIdError}</p>}
      {empresaError && <p className={styles.errorText}>{empresaError}</p>}
      <form className={styles.crearEmpresaForm} onSubmit={handleSubmit}>
        <p><strong>{labels.company}:</strong> {empresa}</p>
        <button type="submit">{labels.createRequirement}</button>
      </form>
      {formularios.length > 0 ? (
        <div className={styles.formulariosAsociados}>
          <h3>{labels.associatedForms}</h3>
          <ul>
            {formularios.map((formulario) => (
              <li key={formulario.id}>{formulario.nombre}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>{labels.noForms}</p>
      )}
    </div>
  );
}

export default CrearEmpresa;
