// frontend/src/hooks/questions/useQuestionValidation.js

/**
 * Función segura para limpiar HTML sin usar expresiones regulares vulnerables
 * @param {string} html - Texto HTML a limpiar
 * @returns {string} - Texto sin etiquetas HTML
 */
const removeHtmlTags = (html) => {
	if (!html) return '';

	// Limitar el tamaño máximo para prevenir ataques DoS
	const maxLength = 100000; // 100KB
	const textToProcess =
		html.length > maxLength ? html.substring(0, maxLength) : html;

	let result = '';
	let inTag = false;

	// Recorremos el texto caracter por caracter
	for (let i = 0; i < textToProcess.length; i++) {
		const char = textToProcess[i];

		if (char === '<') {
			inTag = true;
		} else if (char === '>') {
			inTag = false;
		} else if (!inTag) {
			result += char;
		}
	}

	return result.trim();
};

/**
 * Valida un año de manera segura sin usar regex
 * @param {string} value - Valor a validar
 * @returns {boolean} - Verdadero si es un año válido (4 dígitos)
 */
const isValidYear = (value) => {
	if (!value || value.length !== 4) return false;

	// Verificar que todos los caracteres son dígitos
	for (let i = 0; i < value.length; i++) {
		const char = value[i];
		if (char < '0' || char > '9') return false;
	}

	return true;
};

/**
 * Verifica si el texto contiene una imagen incrustada
 * @param {string} text - Texto a verificar
 * @returns {boolean} - Verdadero si contiene una etiqueta de imagen
 */
const containsImageTag = (text) => {
	if (!text) return false;

	// Limitar el tamaño máximo para prevenir ataques DoS
	const maxLength = 100000; // 100KB
	const textToProcess =
		text.length > maxLength ? text.substring(0, maxLength) : text;

	// Buscar la secuencia '<img' de manera segura
	return textToProcess.indexOf('<img') !== -1;
};

export const useQuestionValidation = (
	respuestas,
	getQuestionOptions,
	procesoId,
	selectedArea,
	selectedDepartamento
) => {
	/**
	 * Verifica condiciones básicas de validez
	 * @param {string} formularioId - ID del formulario
	 * @param {object} pregunta - Objeto pregunta a validar
	 * @returns {boolean|null} - true si válido, false si inválido, null para continuar validación
	 */
	const verificarCondicionesBasicas = (formularioId, pregunta) => {
		// Verificar parámetros necesarios
		if (!formularioId || !pregunta || !pregunta.id) return false;

		// Si no es requerida, es válida (comprobar tanto required como requerida)
		if (pregunta.requerida === false || pregunta.required === false)
			return true;

		// Verificar si la pregunta está siendo redirigida
		if (selectedArea[pregunta.id] && selectedDepartamento[pregunta.id]) {
			return true;
		}

		// Continuar con más validaciones
		return null;
	};

	/**
	 * Verifica tipos especiales de preguntas
	 * @param {string} formularioId - ID del formulario
	 * @param {object} pregunta - Objeto pregunta a validar
	 * @param {string} respuestaTexto - Texto de la respuesta
	 * @returns {boolean|null} - true si válido, false si inválido, null para continuar validación
	 */
	const verificarTiposEspeciales = (formularioId, pregunta, respuestaTexto) => {
		// Validación específica para tipo gYear
		if (pregunta.questionType === 'gYear') {
			return isValidYear(respuestaTexto);
		}

		// Validación para preguntas tipo tabla
		if (pregunta.questionType === 'table') {
			return true;
		}

		// Validación para preguntas condicionales
		if (pregunta.type === 'conditional') {
			return respuestaTexto.length > 0;
		}

		return null;
	};

	/**
	 * Verifica preguntas dependientes
	 * @param {string} formularioId - ID del formulario
	 * @param {object} pregunta - Objeto pregunta a validar
	 * @returns {boolean|null} - true si válido, null para continuar validación
	 */
	const verificarPreguntasDependientes = (formularioId, pregunta) => {
		if (pregunta.type === 'dependent' && pregunta.conditional?.dependsOn) {
			const parentId = pregunta.conditional.dependsOn;
			const parentResponse =
				respuestas[`${formularioId}-${parentId}`]?.text || '';
			const options = getQuestionOptions(formularioId, procesoId, parentId);

			if (parentResponse.includes(options.positiveOption)) {
				return true;
			}
		}

		return null;
	};

	/**
	 * Verifica subpreguntas
	 * @param {object} pregunta - Objeto pregunta a validar
	 * @param {string} respuestaTexto - Texto de la respuesta
	 * @returns {boolean|null} - true si válido, null para continuar validación
	 */
	const verificarSubpreguntas = (pregunta, respuestaTexto) => {
		if (pregunta.subquestions?.length > 0) {
			return pregunta.subquestions.every((subq) => {
				if (!subq.required) return true;
				const subRespuesta =
					respuestaTexto.split('|')[pregunta.subquestions.indexOf(subq) + 1] ||
					'';
				return removeHtmlTags(subRespuesta).length > 0;
			});
		}

		return null;
	};

	const esRespuestaValida = (formularioId, pregunta) => {
		// PRIMERO: Verificar si la pregunta es explícitamente NO requerida
		// Comprobamos todas las posibles propiedades que podrían indicar que no es requerida
		if (pregunta.required === false || pregunta.requerida === false) {
			console.log(
				'Pregunta NO requerida, permitiendo respuesta vacía para ID:',
				pregunta.id
			);
			return true;
		}

		// Si la pregunta no tiene un indicador explícito, tratarla como no requerida por defecto
		if (pregunta.required === undefined && pregunta.requerida === undefined) {
			console.log(
				'Pregunta sin indicador de requerido, tratándola como NO requerida:',
				pregunta.id
			);
			return true;
		}

		// Verificar condiciones básicas
		// Verificar parámetros necesarios
		if (!formularioId || !pregunta || !pregunta.id) return false;

		// Verificar si la pregunta está siendo redirigida
		if (selectedArea[pregunta.id] && selectedDepartamento[pregunta.id]) {
			return true;
		}

		// Obtener el texto de la respuesta
		const respuestaTexto =
			respuestas[`${formularioId}-${pregunta.id}`]?.text || '';

		// Verificar tipos especiales de preguntas
		// Validación específica para tipo gYear
		if (pregunta.questionType === 'gYear') {
			return isValidYear(respuestaTexto);
		}

		// Validación para preguntas tipo tabla
		if (pregunta.questionType === 'table') {
			return true;
		}

		// Validación para preguntas condicionales
		if (pregunta.type === 'conditional') {
			return respuestaTexto.length > 0;
		}

		// Verificar preguntas dependientes
		if (pregunta.type === 'dependent' && pregunta.conditional?.dependsOn) {
			const parentId = pregunta.conditional.dependsOn;
			const parentResponse =
				respuestas[`${formularioId}-${parentId}`]?.text || '';
			const options = getQuestionOptions(formularioId, procesoId, parentId);

			if (parentResponse.includes(options.positiveOption)) {
				return true;
			}
		}

		// Verificar subpreguntas
		if (pregunta.subquestions?.length > 0) {
			return pregunta.subquestions.every((subq) => {
				if (!subq.required) return true;
				const subRespuesta =
					respuestaTexto.split('|')[pregunta.subquestions.indexOf(subq) + 1] ||
					'';
				return removeHtmlTags(subRespuesta).length > 0;
			});
		}

		// Verificar si hay contenido de respuesta (texto limpio o imagen)
		const respuestaLimpia = removeHtmlTags(respuestaTexto);
		const respuestaImagen =
			respuestas[`${formularioId}-${pregunta.id}`]?.image || null;
		const contieneImagenIncrustada = containsImageTag(respuestaTexto);

		// Solo validar contenido si la pregunta está marcada explícitamente como requerida
		return (
			respuestaLimpia.length > 0 || respuestaImagen || contieneImagenIncrustada
		);
	};

	return { esRespuestaValida };
};
