import { useState } from 'react';
import { ROUTE_EXPORT_WORD } from '../config/routeConfig';
import { getLocalizedConfig } from '../config/pdfReportConfig';
import i18n from '../i18n/i18n';
import { debug, debugError } from '../utils/debugHelper';
import { fetchWithAuth } from '../utils/fetchUtils';

const useExportarWord = () => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	/**
	 * Valida los datos a exportar
	 * @param {Array} dataParaExportar - Datos a validar
	 * @param {string} language - Idioma actual
	 * @throws {Error} - Error si los datos no son válidos
	 */
	const validarDatosExportacion = (dataParaExportar, language) => {
		if (!Array.isArray(dataParaExportar) || dataParaExportar.length === 0) {
			throw new Error(
				language === 'ES'
					? 'No hay datos válidos para exportar'
					: 'No valid data to export'
			);
		}
	};

	/**
	 * Normaliza los datos de formularios para asegurar estructura correcta
	 * @param {Array} dataParaExportar - Datos de formularios
	 * @returns {Array} - Datos normalizados
	 */
	const normalizarFormularios = (dataParaExportar) => {
		return dataParaExportar.map((formulario) => {
			// Si no tiene preguntas, inicializamos un array vacío
			const preguntas = formulario.preguntas || [];

			// Nos aseguramos de que todas las preguntas tengan los campos necesarios
			const preguntasNormalizadas = preguntas.map((pregunta) => ({
				...pregunta,
				respuestaText: pregunta.respuestaText || '',
			}));

			return {
				...formulario,
				preguntas: preguntasNormalizadas,
			};
		});
	};

	/**
	 * Completa las etiquetas en la configuración localizada
	 * @param {Object} localizedConfig - Configuración a completar
	 * @param {string} normalizedLang - Idioma normalizado (es/en)
	 * @returns {Object} - Configuración con etiquetas completas
	 */
	const completarEtiquetas = (localizedConfig, normalizedLang) => {
		const configActualizada = { ...localizedConfig };

		if (!configActualizada.global.labels) {
			configActualizada.global.labels = {
				company: i18n.t('pdfReport.labels.company', {
					defaultValue: normalizedLang === 'es' ? 'Empresa' : 'Company',
				}),
				process: i18n.t('pdfReport.labels.process', {
					defaultValue: normalizedLang === 'es' ? 'Proceso' : 'Process',
				}),
				year: i18n.t('pdfReport.labels.year', {
					defaultValue: normalizedLang === 'es' ? 'Año' : 'Year',
				}),
				creationDate: i18n.t('pdfReport.labels.creationDate', {
					defaultValue:
						normalizedLang === 'es' ? 'Fecha de creación' : 'Creation date',
				}),
				reportDate: i18n.t('pdfReport.labels.reportDate', {
					defaultValue:
						normalizedLang === 'es'
							? 'Fecha de generación del informe'
							: 'Report generation date',
				}),
				noAnswer: i18n.t('pdfReport.labels.noAnswer', {
					defaultValue: normalizedLang === 'es' ? 'Sin respuesta' : 'No answer',
				}),
				departmentsQuestion: i18n.t('pdfReport.labels.departmentsQuestion', {
					defaultValue:
						normalizedLang === 'es'
							? 'Departamentos sobre los que se informará'
							: 'Departments that will be reported',
				}),
			};
		}

		return configActualizada;
	};

	/**
	 * Registra información de depuración sobre la configuración
	 * @param {Object} localizedConfig - Configuración localizada
	 * @param {number} procesosId - ID del proceso
	 * @param {string} normalizedLang - Idioma normalizado
	 */
	const registrarDebug = (localizedConfig, procesosId, normalizedLang) => {
		if (
			localizedConfig.process.sections &&
			localizedConfig.process.sections.length > 0
		) {
			debug('Verificando secciones y questionIds para Word:');
			localizedConfig.process.sections.forEach((section) => {
				debug(
					`Sección: ${section.title}, FormId: ${section.formId}, IDs de preguntas:`,
					section.questionIds
						? `${section.questionIds.length} preguntas`
						: 'No definidos'
				);
			});
		}

		if (localizedConfig.process.hasConsolidatedDepartmentsQuestion) {
			debug(
				'Este proceso incluye la pregunta consolidada de departamentos para Word'
			);

			debug(
				'Orden de la sección consolidada:',
				localizedConfig.process.consolidatedQuestionSectionOrder
			);
		}

		debug('Configuración para Word:', {
			procesosId,
			language: normalizedLang,
			processTitle: localizedConfig.process.title,
			headerTitle: localizedConfig.global.header.title,
			headerSubtitle: localizedConfig.global.header.subtitle,
			sectionsCount: localizedConfig.process.sections.length,
			hasConsolidatedQuestion:
				localizedConfig.process.hasConsolidatedDepartmentsQuestion || false,
			consolidatedOrder:
				localizedConfig.process.consolidatedQuestionSectionOrder || 0,
		});
	};

	/**
	 * Genera y descarga el archivo Word
	 * @param {Object} dataCompleta - Datos completos para generar el documento
	 * @param {Array} dataParaExportar - Datos originales para el nombre de archivo
	 * @param {string} language - Idioma seleccionado
	 * @param {Object} localizedConfig - Configuración localizada
	 * @returns {Promise<boolean>} - True si fue exitoso
	 * @throws {Error} - Error en caso de fallo
	 */
	const generarYDescargarWord = async (
		dataCompleta,
		dataParaExportar,
		language,
		localizedConfig
	) => {
		// Reemplazamos fetch por fetchWithAuth
		const response = await fetchWithAuth(ROUTE_EXPORT_WORD, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(dataCompleta),
		});

		if (!response.ok) {
			const errorText = await response.text();
			throw new Error(
				language === 'ES'
					? `Error al generar el archivo Word: ${errorText}`
					: `Error generating Word document: ${errorText}`
			);
		}

		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;

		const nombreEmpresa = dataParaExportar[0]?.nombreEmpresa || 'SinNombre';
		const sufijo = language === 'ES' ? 'Informe' : 'Report';
		const procesoSufijo = localizedConfig.process.title
			.replace(/[^\w\s]/gi, '')
			.trim()
			.split(' ')[0];

		a.download = `${sufijo}_${nombreEmpresa}_${procesoSufijo}_${language}.docx`;

		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(url);

		return true;
	};

	/**
	 * Exporta los datos a un documento Word
	 * @param {Array} dataParaExportar - Datos de formularios para exportar
	 * @param {string} language - Idioma a utilizar (ES/EN)
	 * @returns {Promise<boolean>} - True si exitoso, false si hay error
	 */
	const exportarWord = async (dataParaExportar, language = 'ES') => {
		setLoading(true);
		setError(null);

		try {
			// Validar datos de entrada
			validarDatosExportacion(dataParaExportar, language);

			// Normalizar idioma
			const normalizedLang = language === 'ES' ? 'es' : 'en';

			// Obtener ID del proceso
			const procesosId = parseInt(dataParaExportar[0]?.procesosId) || 0;

			// Obtener configuración localizada
			let localizedConfig = getLocalizedConfig(procesosId, normalizedLang);

			// Normalizar formularios
			const formulariosConData = normalizarFormularios(dataParaExportar);

			// Completar etiquetas
			localizedConfig = completarEtiquetas(localizedConfig, normalizedLang);

			// Registrar información de depuración
			registrarDebug(localizedConfig, procesosId, normalizedLang);

			// Crear objeto de datos completo
			const dataCompleta = {
				formularios: formulariosConData,
				config: localizedConfig,
				language: normalizedLang,
			};

			// Generar y descargar documento
			return await generarYDescargarWord(
				dataCompleta,
				dataParaExportar,
				language,
				localizedConfig
			);
		} catch (err) {
			debugError('Error al exportar documento Word:', err);
			setError(
				err.message ||
					(language === 'ES'
						? 'Error al exportar documento Word.'
						: 'Error exporting Word document.')
			);
			return false;
		} finally {
			setLoading(false);
		}
	};

	return { exportarWord, error, loading };
};

export default useExportarWord;
