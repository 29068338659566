import React, { useState, useEffect, useMemo } from 'react';
import useI18n from '../../hooks/i18n/useI18n'; // Usa el hook personalizado en lugar de useTranslation
import { dashboardHandlers } from '../../utils/handlers/userHandlers';
import UserFilters from '../../componentes/UserFilters.js';
import { dashboardSystemStyles as styles } from '../../styles/dashboardSystemStyles';
import { fetchWithAuth } from '../../utils/fetchUtils'; // Importamos fetchWithAuth

/**
 * Componente para renderizar la celda de áreas del usuario
 */
const UserAreaCell = ({ user, t }) => {
	const areas = (
		Array.isArray(user.area)
			? user.area
			: Array.isArray(user.areas)
			? user.areas
			: [user.area]
	).filter(Boolean);

	return (
		<td className={styles.standardCell}>
			<div className={styles.areaContainer}>
				{areas.map((area, index) => (
					<span
						key={index}
						className={styles.areaBadge}>
						{t(`departamentos.${area}`, {
							defaultValue: area,
						})}
					</span>
				))}
			</div>
		</td>
	);
};

/**
 * Componente para renderizar la celda de departamentos del usuario
 */
const UserDepartmentsCell = ({ user, t }) => {
	// Si departamentos es un array
	if (Array.isArray(user.departamentos)) {
		return (
			<td className={styles.standardCell}>
				<div className={styles.departmentContainer}>
					{user.departamentos
						.filter(
							(department) =>
								department.ver === 'Sí' ||
								department.editar === 'Sí' ||
								department.enviar === 'Sí'
						)
						.map((department, index) => (
							<div
								key={index}
								className={styles.departmentItem}>
								<div className={styles.departmentName}>
									{t(`departamentos.${department.nombre}`, {
										defaultValue: department.nombre,
									})}
								</div>
								<div className={styles.permissionsContainer}>
									{['ver', 'editar', 'enviar'].map(
										(perm) =>
											department[perm] === 'Sí' && (
												<span
													key={perm}
													className={styles.permissionText}>
													{t(`dashboard.permisos.${perm}`)}
												</span>
											)
									)}
								</div>
							</div>
						))}
				</div>
			</td>
		);
	}

	// Si departamentos es un objeto
	if (typeof user.departamentos === 'object' && user.departamentos !== null) {
		return (
			<td className={styles.standardCell}>
				<div className={styles.departmentContainer}>
					{Object.entries(user.departamentos)
						.filter(
							([_, permisos]) =>
								permisos.ver || permisos.editar || permisos.enviar
						)
						.map(([deptName, permisos], index) => (
							<div
								key={index}
								className={styles.departmentItem}>
								<div className={styles.departmentName}>
									{t(`departamentos.${deptName}`, {
										defaultValue: deptName,
									})}
								</div>
								<div className={styles.permissionsContainer}>
									{Object.entries(permisos).map(
										([perm, value]) =>
											(value === true || value === 'Sí') && (
												<span
													key={perm}
													className={styles.permissionText}>
													{t(`dashboard.permisos.${perm}`)}
												</span>
											)
									)}
								</div>
							</div>
						))}
				</div>
			</td>
		);
	}

	// Si no hay departamentos
	return (
		<td className={styles.standardCell}>
			<span className={styles.noDepartments}>
				{t('dashboard.sinDepartamentos')}
			</span>
		</td>
	);
};

/**
 * Componente para renderizar una fila de usuario
 */
const UserRow = ({ user, t }) => (
	<tr
		key={user.id}
		className={styles.tableRow}>
		<td className={styles.idCell}>
			<span className={styles.idBadge}>{user.id}</span>
		</td>
		<td className={styles.standardCell}>
			<div className={styles.standardText}>{user.nombre}</div>
		</td>
		<td className={styles.standardCell}>
			<div className={styles.plainText}>{user.apellidos}</div>
		</td>
		<td className={styles.standardCell}>
			<div className={styles.emailText}>{user.email}</div>
		</td>
		<td className={styles.standardCell}>
			<div className={styles.plainText}>{user.username}</div>
		</td>
		<UserAreaCell
			user={user}
			t={t}
		/>
		<UserDepartmentsCell
			user={user}
			t={t}
		/>
		<td className={styles.languageCell}>
			<span className={styles.languageBadge}>{user.idioma}</span>
		</td>
	</tr>
);

function DashboardSystem() {
	const { t } = useI18n(); // Usa useI18n
	const [allUsers, setAllUsers] = useState([]); // Todos los usuarios sin paginar
	const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
	const [currentPage, setCurrentPage] = useState(1);
	const usersPerPage = 10;

	// Estados para los filtros
	const [searchName, setSearchName] = useState('');
	const [searchEmail, setSearchEmail] = useState('');
	const [searchUsername, setSearchUsername] = useState('');
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [selectedArea, setSelectedArea] = useState(null);

	// Cargar todos los usuarios una sola vez
	useEffect(() => {
		setIsLoading(true);
		dashboardHandlers
			.fetchAllUsers(setAllUsers)
			.finally(() => setIsLoading(false));
	}, []);

	// Lista de departamentos únicos para el filtro
	const allDepartments = useMemo(() => {
		const departmentsSet = new Set();
		allUsers.forEach((user) => {
			if (Array.isArray(user.departamentos)) {
				user.departamentos.forEach((dept) => {
					if (dept && dept.nombre) {
						departmentsSet.add(dept.nombre);
					}
				});
			} else if (
				typeof user.departamentos === 'object' &&
				user.departamentos !== null
			) {
				// Si departamentos es un objeto y no un array
				Object.keys(user.departamentos).forEach((deptName) => {
					departmentsSet.add(deptName);
				});
			}
		});
		return Array.from(departmentsSet).sort();
	}, [allUsers]);

	// Lista de áreas únicas para el filtro
	const allAreas = useMemo(() => {
		const areasSet = new Set();
		allUsers.forEach((user) => {
			if (Array.isArray(user.area)) {
				user.area.forEach((area) => areasSet.add(area));
			} else if (user.area) {
				areasSet.add(user.area);
			}
			// Comprobar también el campo 'areas' que puede estar presente
			if (Array.isArray(user.areas)) {
				user.areas.forEach((area) => areasSet.add(area));
			}
		});
		return Array.from(areasSet).sort();
	}, [allUsers]);

	// Filtrado de usuarios
	const filteredUsers = useMemo(() => {
		let filtered = allUsers;

		// Filtrar por nombre
		if (searchName.trim() !== '') {
			filtered = filtered.filter((user) =>
				user.nombre?.toLowerCase().startsWith(searchName.toLowerCase())
			);
		}

		// Filtrar por email
		if (searchEmail.trim() !== '') {
			filtered = filtered.filter((user) =>
				user.email?.toLowerCase().startsWith(searchEmail.toLowerCase())
			);
		}

		// Filtrar por username
		if (searchUsername.trim() !== '') {
			filtered = filtered.filter((user) =>
				user.username?.toLowerCase().startsWith(searchUsername.toLowerCase())
			);
		}

		// Filtrar por departamento
		if (selectedDepartment) {
			filtered = filtered.filter((user) => {
				if (Array.isArray(user.departamentos)) {
					return user.departamentos.some(
						(department) => department.nombre === selectedDepartment
					);
				} else if (
					typeof user.departamentos === 'object' &&
					user.departamentos !== null
				) {
					// Si departamentos es un objeto y no un array
					return Object.keys(user.departamentos).includes(selectedDepartment);
				}
				return false;
			});
		}

		// Filtrar por área
		if (selectedArea) {
			filtered = filtered.filter((user) => {
				if (Array.isArray(user.area)) {
					return user.area.includes(selectedArea);
				} else if (Array.isArray(user.areas)) {
					return user.areas.includes(selectedArea);
				} else if (typeof user.area === 'string') {
					return user.area === selectedArea;
				}
				return false;
			});
		}

		return filtered;
	}, [
		allUsers,
		searchName,
		searchEmail,
		searchUsername,
		selectedDepartment,
		selectedArea,
	]);

	// Paginar los usuarios filtrados
	const paginatedUsers = useMemo(() => {
		const startIndex = (currentPage - 1) * usersPerPage;
		const endIndex = startIndex + usersPerPage;
		return filteredUsers.slice(startIndex, endIndex);
	}, [filteredUsers, currentPage, usersPerPage]);

	// Calcular el total de páginas basado en los usuarios filtrados
	const totalPages = useMemo(() => {
		return Math.max(1, Math.ceil(filteredUsers.length / usersPerPage));
	}, [filteredUsers, usersPerPage]);

	// Asegurarse de que la página actual no exceda el número total de páginas
	useEffect(() => {
		if (currentPage > totalPages && totalPages > 0) {
			setCurrentPage(1);
		}
	}, [currentPage, totalPages]);

	if (isLoading) {
		return (
			<div className={styles.loadingContainer}>
				<div className={styles.loadingInner}>
					<div className={styles.spinner}></div>
					<p className={styles.loadingText}>{t('common.loading')}</p>
				</div>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<h2 className={styles.title}>{t('dashboard.title')}</h2>

				{/* Componente de Filtros */}
				<UserFilters
					searchName={searchName}
					setSearchName={setSearchName}
					searchEmail={searchEmail}
					setSearchEmail={setSearchEmail}
					searchUsername={searchUsername}
					setSearchUsername={setSearchUsername}
					selectedDepartment={selectedDepartment}
					setSelectedDepartment={setSelectedDepartment}
					selectedArea={selectedArea}
					setSelectedArea={setSelectedArea}
					allDepartments={allDepartments}
					allAreas={allAreas}
					t={t} // Pasamos la función de traducción
				/>

				{/* Tabla de usuarios */}
				<div className={styles.tableContainer}>
					<div className={styles.tableWrapper}>
						<table className={styles.table}>
							<thead>
								<tr className={styles.tableHeader}>
									{[
										'id',
										'nombre',
										'apellidos',
										'email',
										'username',
										'area',
										'departamentosHeader',
										'idioma',
									].map((header) => (
										<th
											key={header}
											className={styles.headerCell}>
											{t(`dashboard.${header}`)}
										</th>
									))}
								</tr>
							</thead>
							<tbody className={styles.tableBody}>
								{paginatedUsers.length > 0 ? (
									paginatedUsers.map((user) => (
										<UserRow
											key={user.id}
											user={user}
											t={t}
										/>
									))
								) : (
									<tr>
										<td
											colSpan='8'
											className={styles.noResultsCell}>
											{t('dashboard.filtros.sinResultados')}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

				{/* Paginación */}
				{totalPages > 1 && (
					<div className={styles.paginationContainer}>
						{[...Array(totalPages)].map((_, index) => (
							<button
								key={index}
								onClick={() => setCurrentPage(index + 1)}
								className={`${styles.paginationButton.base} ${
									index + 1 === currentPage
										? styles.paginationButton.active
										: styles.paginationButton.inactive
								}`}>
								{index + 1}
							</button>
						))}
					</div>
				)}
			</div>
		</div>
	);
}

export default DashboardSystem;
