export const navBarStyles = {
	container: 'bg-fondosuavizado border-b border-[#d4a976]',
	wrapper: 'max-w-screen-2xl mx-auto',
	mainDiv: 'flex items-center justify-between px-8 h-20',
	logoContainer: 'w-48 flex items-center',
	logo: 'h-14 w-auto',
	navContainer: 'flex-1 flex justify-center',
	buttonContainer: 'flex space-x-8',
	navButton: (isSelected) => `
	  !px-6 !py-3 !text-sm !font-medium !rounded-lg
	  !transition-all !duration-200 !ease-in-out
	  ${
			isSelected
				? '!bg-primario !text-white !shadow-md !transform !scale-105'
				: '!bg-secundario !text-white hover:!bg-primario hover:!transform hover:!scale-102'
		}
	`,
	profileContainer: 'w-48 flex justify-end',
	profileButton: `
	  flex items-center space-x-3 px-4 py-2 rounded-lg
	  bg-secundario text-white hover:bg-primario
	  transition-all duration-200 ease-in-out
	`,
	profileImage: 'h-8 w-8 rounded-full  shadow-sm',
	profileName: 'hidden md:block font-medium text-center',
	dropdownMenu:
		'absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-white border border-[#d4a976] overflow-hidden',
	logoutButton:
		'w-full text-left px-4 py-3 text-sm  hover:bg-primario/30 flex items-center space-x-2',
};
