//frontend/src/hooks/formularios/useFormularioData.js
import { useState, useEffect } from 'react';
import { ROUTE_COMPANY_LIST, ROUTE_FORMS } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useFormularioData(empresaId) {
	const [formularios, setFormularios] = useState([]);
	const [error, setError] = useState(null);
	const [procesoId, setProcesoId] = useState(null); // Agregar procesoId en el estado

	useEffect(() => {
		if (empresaId) {
			fetchFormularios();
		}
	}, [empresaId]);

	// Función para obtener los formularios de una empresa y sus preguntas asociadas
	const fetchFormularios = async () => {
		try {
			const response = await fetchWithAuth(ROUTE_COMPANY_LIST);

			if (!response.ok) {
				const errorText = await response.text();
				console.error('❌ Contenido de error:', errorText);
				throw new Error('Error al obtener la empresa');
			}

			const data = await response.json();

			const empresaData = data.find((item) => item.id === parseInt(empresaId));

			if (empresaData) {
				const { procesosId, nombreEmpresa, anyo } = empresaData;
				setProcesoId(procesosId);

				// Filtrar solo por procesoId, nombreEmpresa y año
				const formulariosRelacionados = data.filter(
					(item) =>
						item.procesosId === procesosId &&
						item.nombreEmpresa === nombreEmpresa &&
						item.anyo === anyo // Usar año en lugar de ventana de tiempo
				);

				const formulariosConPreguntas = await Promise.all(
					formulariosRelacionados.map(async (formulario) => {
						const preguntas = await fetchPreguntas(formulario.FormularioId);
						return { ...formulario, preguntas };
					})
				);

				setFormularios(formulariosConPreguntas);
			} else {
				setError('Empresa no encontrada');
				console.error('❌ Empresa no encontrada con ID:', empresaId);
			}
		} catch (error) {
			console.error('❌ Fetch Error:', error);
			setError('No se pudo cargar los formularios de la empresa.');
		}
	};

	// Función para obtener preguntas asociadas a un formulario específico
	const fetchPreguntas = async (formularioId) => {
		try {
			const response = await fetchWithAuth(
				`${ROUTE_FORMS}/${formularioId}/preguntas`
			);

			if (!response.ok) {
				const errorText = await response.text();
				console.error(`❌ Error para formulario ${formularioId}:`, errorText);
				throw new Error('Error al obtener preguntas del formulario');
			}

			const data = await response.json();

			return data.preguntas.map((pregunta) => ({
				...pregunta,
				link: pregunta.link || '',
				required: pregunta.required || false,
			}));
		} catch (error) {
			console.error(
				`❌ Error al obtener preguntas para Formulario ID ${formularioId}:`,
				error
			);
			return [];
		}
	};

	return { formularios, error, procesoId }; // Devuelve procesoId junto con formularios y error
}

export default useFormularioData;
