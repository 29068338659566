// frontend/src/utils/handlers/userHandlers/commonUserHandlers.js
export const commonUserHandlers = {
	handleAreaChange: (
		area,
		selectedAreas,
		setSelectedAreas,
		departamentos,
		setDepartamentos,
		areaOptions
	) => {
		setSelectedAreas((prevSelectedAreas) =>
			prevSelectedAreas.includes(area)
				? prevSelectedAreas.filter((a) => a !== area)
				: [...prevSelectedAreas, area]
		);

		if (!departamentos[area]) {
			setDepartamentos((prev) => ({
				...prev,
				[area]: areaOptions[area].reduce((acc, dept) => {
					acc[dept] = {
						ver: area === 'ESG' ? true : false,
						editar: false,
						enviar: false,
					};
					return acc;
				}, {}),
			}));
		}
	},

	handlePermissionChange: (area, dept, perm, setDepartamentos) => {
		setDepartamentos((prev) => {
			// Asegurarse de que la estructura existe
			const areaPerms = prev[area] || {};
			const deptPerms = areaPerms[dept] || {
				ver: false,
				editar: false,
				enviar: false,
			};

			return {
				...prev,
				[area]: {
					...areaPerms,
					[dept]: {
						...deptPerms,
						[perm]: !deptPerms[perm],
					},
				},
			};
		});
	},
};
