//frontend/src/hooks/answers/useAnswerSubmission.js
import { ROUTE_RESPONSES } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { cleanRespuestaHtml } from '../../utils/htmlUtils'; // Importamos la nueva utilidad
import { fetchWithAuth } from '../../utils/fetchUtils';

// Función auxiliar para determinar el año del formulario
const obtenerAnyoFormulario = (anyoOverride, formularioId, formularios) => {
	if (anyoOverride) {
		return anyoOverride;
	}

	const formulario = formularios.find((f) => f.FormularioId === formularioId);
	if (formulario && formulario.anyo) {
		return formulario.anyo;
	}

	const errorMsg = `No se encontró el año para el formulario ${formularioId}`;
	debugError(errorMsg);
	throw new Error(errorMsg);
};

// Función auxiliar para obtener el texto de la pregunta
const obtenerTextoPregunta = (pregunta, preguntaId) => {
	if (pregunta?.pregunta) {
		if (typeof pregunta.pregunta === 'object') {
			debug('Usando pregunta.pregunta como objeto:', pregunta.pregunta);
			return pregunta.pregunta;
		}
		if (typeof pregunta.pregunta === 'string') {
			const texto = {
				ES: pregunta.pregunta,
				EN: pregunta.pregunta,
			};
			debug('Creando objeto a partir de pregunta.pregunta string:', texto);
			return texto;
		}
	}

	if (pregunta?.text) {
		const texto = {
			ES: pregunta.text,
			EN: pregunta.text,
		};
		debug('Usando pregunta.text como texto:', texto);
		return texto;
	}

	const textoDefault = {
		ES: `Pregunta ID: ${preguntaId}`,
		EN: `Question ID: ${preguntaId}`,
	};
	debug('Usando ID como texto de pregunta (fallback):', textoDefault);
	return textoDefault;
};

// Función auxiliar para preparar el objeto de respuesta
const prepararObjetoRespuesta = (params) => {
	const {
		preguntaId,
		empresaId,
		procesoId,
		formularioId,
		respuestaActual,
		isRedirigida,
		selectedArea,
		selectedDepartamento,
		userId,
		anyo,
	} = params;

	return {
		preguntasid: preguntaId,
		empresasid: parseInt(empresaId),
		procesosid: parseInt(procesoId),
		formularioid: formularioId,
		respuesta: respuestaActual,
		redirigira: isRedirigida
			? `${selectedArea[preguntaId]} - ${selectedDepartamento[preguntaId]}`
			: null,
		estadopregunta: isRedirigida ? 'Redirigida' : 'Contestada',
		respondidoporuserid: isRedirigida ? null : userId,
		contestadafecha: isRedirigida ? null : new Date().toISOString(),
		redirigidafecha: isRedirigida ? new Date().toISOString() : null,
		anyo: anyo,
	};
};

// Función auxiliar para enviar la respuesta al servidor
const enviarRespuestaAlServidor = async (
	respuestaObj,
	respuestasEnviadas,
	respuestaKey
) => {
	const requestMethod = respuestasEnviadas[respuestaKey] ? 'PUT' : 'POST';
	const requestUrl = respuestasEnviadas[respuestaKey]
		? `${ROUTE_RESPONSES}/${respuestasEnviadas[respuestaKey].id}`
		: ROUTE_RESPONSES;

	debug('Enviando respuesta al servidor:', respuestaObj);
	debug('Método:', requestMethod);
	debug('URL:', requestUrl);

	const response = await fetchWithAuth(requestUrl, {
		method: requestMethod,
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(respuestaObj),
	});

	if (!response.ok) {
		const errorText = await response.text();
		debugError('Error en la respuesta:', errorText);
		throw new Error(
			`Error al ${
				requestMethod === 'POST' ? 'enviar' : 'actualizar'
			} la respuesta: ${errorText}`
		);
	}

	return await response.json();
};

export const useAnswerSubmission = (
	empresaId,
	procesoId,
	userId,
	respuestas,
	respuestasEnviadas,
	setRespuestasEnviadas,
	enviarCorreo,
	formularios
) => {
	const handleSubmit = async (
		preguntaId,
		formularioId,
		selectedArea = {},
		selectedDepartamento = {},
		respuestaOverride = null,
		anyoOverride = null
	) => {
		debug('useAnswerSubmission.handleSubmit - Inicio:', {
			preguntaId,
			formularioId,
			selectedArea,
			selectedDepartamento,
			empresaId,
			procesoId,
		});

		// Verificar si hay un área y departamento seleccionados para esta pregunta
		const isRedirigida =
			selectedArea[preguntaId] && selectedDepartamento[preguntaId];
		debug('¿Es una redirección?', isRedirigida);
		debug('Área seleccionada:', selectedArea[preguntaId]);
		debug('Departamento seleccionado:', selectedDepartamento[preguntaId]);

		// Validación de datos básicos
		if (!formularioId || !procesoId || isNaN(procesoId) || !empresaId) {
			debugError('Error: Datos inválidos', {
				formularioId,
				procesoId,
				empresaId,
			});
			return;
		}

		// Obtener el año del formulario
		const anyo = obtenerAnyoFormulario(anyoOverride, formularioId, formularios);
		debug(`Guardando respuesta para formulario ${formularioId}, año: ${anyo}`);

		// Log para mostrar todas las preguntas disponibles
		debug(
			'Todas las preguntas disponibles:',
			formularios.flatMap((f) =>
				f.preguntas.map((p) => ({
					id: p.id,
					text: p.text,
					pregunta: p.pregunta,
				}))
			)
		);

		// Encontrar la pregunta correspondiente
		const pregunta = formularios
			.flatMap((f) => f.preguntas)
			.find((p) => p.id === preguntaId);

		debug(`Pregunta con ID ${preguntaId} encontrada:`, pregunta);

		if (pregunta) {
			debug('Propiedades de la pregunta:', Object.keys(pregunta));
			debug('Valor de pregunta.text:', pregunta.text);
			debug('Valor de pregunta.pregunta:', pregunta.pregunta);
			debug('Tipo de pregunta.pregunta:', typeof pregunta.pregunta);
		}

		// Obtener el texto de la pregunta
		const preguntaTexto = obtenerTextoPregunta(pregunta, preguntaId);

		const nombreEmpresa = formularios[0]?.nombreEmpresa;
		const respuestaKey = `${formularioId}-${empresaId}-${procesoId}-${preguntaId}`;

		// Obtenemos la respuesta actual, con la opción de usar un override
		let respuestaActual = respuestaOverride ||
			respuestas[`${formularioId}-${preguntaId}`] || { text: '', image: '' };

		// Limpiamos el HTML de la respuesta
		respuestaActual = cleanRespuestaHtml(respuestaActual);

		// Preparar el objeto de respuesta
		const respuestaObj = prepararObjetoRespuesta({
			preguntaId,
			empresaId,
			procesoId,
			formularioId,
			respuestaActual,
			isRedirigida,
			selectedArea,
			selectedDepartamento,
			userId,
			anyo,
		});

		try {
			// Enviar la respuesta al servidor
			const data = await enviarRespuestaAlServidor(
				respuestaObj,
				respuestasEnviadas,
				respuestaKey
			);

			debug('Respuesta guardada con éxito:', data);
			setRespuestasEnviadas((prev) => ({ ...prev, [respuestaKey]: data }));

			// Manejar el caso de redirección
			if (isRedirigida) {
				debug('Enviando correo de notificación de redirección');
				debug('Datos para el correo:', {
					empresa: nombreEmpresa,
					area: selectedArea[preguntaId],
					departamento: selectedDepartamento[preguntaId],
					preguntaTexto,
				});

				await enviarCorreo(
					nombreEmpresa,
					selectedArea[preguntaId],
					selectedDepartamento[preguntaId],
					preguntaTexto
				);
				debug('Correo enviado con éxito');
			}

			return data;
		} catch (error) {
			debugError('Error en submit:', error);
			throw error;
		}
	};

	return { handleSubmit };
};
