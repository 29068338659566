import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuestionValidation } from '../../hooks/questions/useQuestionValidation';
import { useAnswerSubmission } from '../../hooks/answers/useAnswerSubmission';
import useFormularioSort from '../../hooks/formularios/useFormularioSort';
import { useQuillManager } from '../../hooks/editor/useQuillManager';
import { usePreguntasVisibility } from '../../hooks/questions/usePreguntasVisibility';
import { useFormularioValidation } from '../../hooks/formularios/useFormularioValidation';
import { getQuestionOptions } from '../../utils/questionUtils';
import useI18n from '../../hooks/i18n/useI18n'; // Cambiamos a useI18n
import useUserId from '../../hooks/user/useUserId';
import useGuardarTodo from '../../hooks/formularios/useGuardarTodo';
import useFormularioData from '../../hooks/formularios/useFormularioData';
import useRespuestas from '../../hooks/useRespuestas';
import useFinalizarProceso from '../../hooks/formularios/useFinalizarProceso';
import useEnviarNotificacionRedireccion from '../../hooks/formularios/useEnviarNotificacionRedireccion';
import FormularioCard from '../../componentes/FormularioCard/index';
import { vistaFormularioStyles as styles } from '../../styles/vistaFormularioStyles';
import { ROUTE_RESPONSES } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';
import useUserPermissions from '../../hooks/user/useUserPermissions'; // Añadido
import { hasSendPermission } from '../../utils/permissionsUtils'; // Añadido

function VistaFormulario() {
	const { empresaId } = useParams();
	const [searchParams] = useSearchParams();
	const [showMensajeRevision, setShowMensajeRevision] = useState(false);
	const mensajerevision = searchParams.get('mensajerevision');
	const { formularios, error, procesoId } = useFormularioData(empresaId);
	const { t, language } = useI18n(); // Usamos useI18n en lugar de useUserLanguage
	const { userId } = useUserId();
	const { enviarCorreo } = useEnviarNotificacionRedireccion();
	const [tableState, setTableState] = useState([]);
	const { permissions } = useUserPermissions(); // Añadido para obtener permisos

	// Verificar si el usuario tiene permiso de enviar
	const canSendRequirements = hasSendPermission(permissions); // Añadido
	debug(
		'VistaFormulario - Usuario puede enviar requisitos:',
		canSendRequirements
	);

	const { quillRefs, handleQuillRef } = useQuillManager();
	const { mostrarPreguntas, togglePreguntas } = usePreguntasVisibility();
	const { sortFormularios } = useFormularioSort();

	useEffect(() => {
		if (mensajerevision) {
			setShowMensajeRevision(true);
		}
	}, [mensajerevision]);

	const {
		respuestas,
		respuestasEnviadas,
		redirigirPregunta,
		selectedArea,
		selectedDepartamento,
		setRespuestasEnviadas,
		handleEditorChange,
		handleFileChange,
		toggleRedirigir,
		handleAreaChange,
		handleDepartamentoChange,
		updateRedirectStatus,
	} = useRespuestas(empresaId, procesoId, formularios);

	const { esRespuestaValida } = useQuestionValidation(
		respuestas,
		getQuestionOptions,
		procesoId,
		selectedArea,
		selectedDepartamento
	);

	const { todasPreguntasContestadas } = useFormularioValidation(
		formularios,
		empresaId,
		procesoId,
		respuestasEnviadas
	);

	const { handleSubmit: originalHandleSubmit } = useAnswerSubmission(
		empresaId,
		procesoId,
		userId,
		respuestas,
		respuestasEnviadas,
		setRespuestasEnviadas,
		enviarCorreo,
		formularios
	);

	// Función para obtener el año actual del formulario
	const obtenerAnioFormulario = (formularioId) => {
		const formulario = formularios.find((f) => f.FormularioId === formularioId);
		if (formulario && formulario.anyo) {
			return formulario.anyo;
		}

		// Si no hay campo anyo, lanzar un error
		debugError(
			`Error: No se encontró el año para el formulario ${formularioId}`
		);
		throw new Error(`No se encontró el año para el formulario ${formularioId}`);
	};

	// Tabla de textos de respaldo para las preguntas comunes
	const textosRespaldo = {
		1: 'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de contaminantes atmosféricos y las respectivas cargas específicas',
		2: 'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de emisiones al agua y las respectivas cargas específicas',
		3: 'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de la contaminación del suelo y las respectivas cargas específicas',
		4: 'Divulgación de si y cómo el objetivo se relaciona con la prevención y el control de sustancias preocupantes y sustancias altamente preocupantes',
	};

	/**
	 * Crea una respuesta inicial para una pregunta redirigida
	 * @param {string|number} preguntaId - ID de la pregunta
	 * @param {string|number} formularioId - ID del formulario
	 * @param {string|number} anyo - Año del formulario
	 * @returns {Promise<string>} - ID de la respuesta creada
	 */
	const crearRespuestaInicial = async (preguntaId, formularioId, anyo) => {
		const respuestaKey = `${formularioId}-${empresaId}-${procesoId}-${preguntaId}`;

		// Si ya existe una respuesta, devolver su ID
		if (respuestasEnviadas[respuestaKey]) {
			return respuestasEnviadas[respuestaKey].id;
		}

		// Crear nueva respuesta
		const respuestaInicial = {
			empresasid: empresaId,
			procesosid: procesoId,
			formularioid: formularioId,
			preguntasid: preguntaId,
			respuesta: {
				text: '',
				image: '',
			},
			estadopregunta: 'Redirigida',
			respondidoporuserid: null,
			redirigira: `${selectedArea[preguntaId]} - ${selectedDepartamento[preguntaId]}`,
			redirigidafecha: new Date().toISOString(),
			anyo: anyo,
		};

		const response = await fetchWithAuth(ROUTE_RESPONSES, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(respuestaInicial),
		});

		if (!response.ok) {
			throw new Error('Error al crear la respuesta inicial');
		}

		const nuevaRespuesta = await response.json();
		setRespuestasEnviadas((prev) => ({
			...prev,
			[respuestaKey]: nuevaRespuesta,
		}));

		return nuevaRespuesta.id;
	};

	/**
	 * Busca el texto de la pregunta
	 * @param {string|number} preguntaId - ID de la pregunta
	 * @param {string|number} formularioId - ID del formulario
	 * @returns {string} - Texto de la pregunta encontrado
	 */
	const buscarTextoPregunta = (preguntaId, formularioId) => {
		let textoFinal = '';

		// 1. Buscar PRIMERO en el formulario específico con los IDs exactos
		debug(
			`BUSCANDO: Pregunta ID ${preguntaId} en Formulario ID ${formularioId}`
		);

		const formularioExacto = formularios.find(
			(f) => f.FormularioId === formularioId
		);

		if (formularioExacto) {
			const preguntaExacta = formularioExacto.preguntas.find(
				(p) => p.id === preguntaId
			);

			if (preguntaExacta) {
				debug('ÉXITO: Encontrada pregunta exacta en formulario específico:', {
					formularioId: formularioExacto.FormularioId,
					preguntaId: preguntaExacta.id,
					pregunta: JSON.stringify(preguntaExacta, null, 2),
				});

				// Extraer texto de la pregunta exacta
				if (typeof preguntaExacta.text === 'string') {
					textoFinal = preguntaExacta.text;
				} else if (typeof preguntaExacta.text === 'object') {
					textoFinal = preguntaExacta.text.ES || preguntaExacta.text.EN;
				} else if (typeof preguntaExacta.pregunta === 'object') {
					textoFinal = preguntaExacta.pregunta.ES || preguntaExacta.pregunta.EN;
				} else if (typeof preguntaExacta.pregunta === 'string') {
					textoFinal = preguntaExacta.pregunta;
				}
			}
		}

		// 2. Si no encontró texto, usar tabla de respaldo
		if (!textoFinal && textosRespaldo[preguntaId]) {
			textoFinal = textosRespaldo[preguntaId];
			debug(
				`USANDO TEXTO DE RESPALDO para pregunta ID ${preguntaId}:`,
				textoFinal
			);
		}

		// 3. Si aún no hay texto, usar un texto genérico
		if (!textoFinal) {
			textoFinal = `Pregunta ID: ${preguntaId}`;
			debug('USANDO TEXTO GENÉRICO por último recurso:', textoFinal);
		}

		return textoFinal;
	};

	/**
	 * Maneja la redirección de una pregunta
	 * @param {string|number} preguntaId - ID de la pregunta
	 * @param {string|number} formularioId - ID del formulario
	 * @param {number} anyo - Año del formulario
	 * @returns {Promise<boolean>} - true si se completó correctamente
	 */
	const manejarRedireccion = async (preguntaId, formularioId, anyo) => {
		// Información completa de depuración
		debug('REDIRECCIÓN - Información completa:', {
			preguntaId,
			formularioId,
			empresaId,
			procesoId,
			area: selectedArea[preguntaId],
			departamento: selectedDepartamento[preguntaId],
		});

		// Crear respuesta inicial
		await crearRespuestaInicial(preguntaId, formularioId, anyo);

		// Buscar texto de la pregunta
		const textoFinal = buscarTextoPregunta(preguntaId, formularioId);
		debug('TEXTO FINAL que se enviará al correo:', textoFinal);

		// Enviar correo de redirección
		await enviarCorreo(
			formularios[0]?.nombreEmpresa,
			selectedArea[preguntaId],
			selectedDepartamento[preguntaId],
			textoFinal
		);

		return true;
	};

	const handleSubmit = async (
		preguntaId,
		formularioId,
		selectedAreaParam = {},
		selectedDepartamentoParam = {},
		respuestaOverride = null
	) => {
		// Obtener el año del formulario
		const anyo = obtenerAnioFormulario(formularioId);
		debug(`Guardando respuesta para formulario ${formularioId}, año: ${anyo}`);

		// Verificar si es una redirección
		if (selectedArea[preguntaId] && selectedDepartamento[preguntaId]) {
			return manejarRedireccion(preguntaId, formularioId, anyo);
		}

		// Para respuestas normales, también pasamos el año
		return originalHandleSubmit(
			preguntaId,
			formularioId,
			selectedAreaParam,
			selectedDepartamentoParam,
			respuestaOverride,
			anyo // Pasar el año como parámetro adicional
		);
	};

	const handleFinalizarProceso = useFinalizarProceso(
		formularios,
		procesoId,
		respuestas
	);

	const handleGuardarTodo = useGuardarTodo(
		formularios,
		empresaId,
		procesoId,
		respuestas,
		respuestasEnviadas,
		setRespuestasEnviadas,
		esRespuestaValida,
		selectedArea,
		selectedDepartamento,
		userId
	);

	// Creamos un objeto labels con las traducciones para mantener compatibilidad con los componentes hijos
	const labels = {
		vistaFormulario: t('vistaFormulario.titulo'),
		nombreProceso: t('vistaFormulario.nombreProceso'),
		nombreFormulario: t('vistaFormulario.nombreFormulario'),
		estadoFormulario: t('vistaFormulario.estadoFormulario'),
		mostrarPreguntas: t('vistaFormulario.mostrarPreguntas'),
		esconderPreguntas: t('vistaFormulario.esconderPreguntas'),
		guardarRespuesta: t('vistaFormulario.guardarRespuesta'),
		editarRespuesta: t('vistaFormulario.editarRespuesta'),
		redirigirPregunta: t('vistaFormulario.redirigirPregunta'),
		quedaRedirigida: t('vistaFormulario.quedaRedirigida'),
		soloVista: t('vistaFormulario.soloVista'),
		seleccionarArea: t('vistaFormulario.seleccionarArea'),
		seleccionarDepartamento: t('vistaFormulario.seleccionarDepartamento'),
		finalizarProceso: t('vistaFormulario.finalizarProceso'),
		cargandoFormulario: t('vistaFormulario.cargandoFormulario'),
		noPreguntasDisponibles: t('vistaFormulario.noPreguntasDisponibles'),
		preguntaTexto: t('vistaFormulario.preguntaTexto'),
		guardarTodo: t('vistaFormulario.guardarTodo'),
		anio: t('vistaFormulario.anio'),
		preguntasContestadas: t('vistaFormulario.preguntasContestadas'),
		acabarRequisito: t('vistaFormulario.acabarRequisito'),
		revisarRequisito: t('vistaFormulario.revisarRequisito'),
		estados: {
			Pendiente: t('vistaFormulario.estados.Pendiente'),
			Acabada: t('vistaFormulario.estados.Acabada'),
			Completada: t('vistaFormulario.estados.Completada'),
			EnProceso: t('vistaFormulario.estados.EnProceso'),
			Enviada: t('vistaFormulario.estados.Enviada'),
			Revisada: t('vistaFormulario.estados.Revisada'),
		},
	};

	return (
		<div className={styles.container}>
			{showMensajeRevision && mensajerevision && (
				<div className={styles.containerClasses}>
					<div className='flex justify-between items-start'>
						<div>
							<h3 className={styles.headerClasses}>
								{t('vistaFormulario.reviewReason')}
							</h3>
							<p className={styles.bodyClasses}>{mensajerevision}</p>
						</div>
						<button
							onClick={() => setShowMensajeRevision(false)}
							className={styles.closeButtonClasses}>
							×
						</button>
					</div>
				</div>
			)}

			<h1 className={styles.title}>{t('vistaFormulario.vistaFormulario')}</h1>
			{error && <p className={styles.errorText}>{error}</p>}

			{formularios.length > 0 ? (
				<div className='w-full'>
					{sortFormularios(formularios).map((formulario) => (
						<FormularioCard
							key={formulario.FormularioId}
							formulario={formulario}
							labels={labels} // Pasamos el objeto labels para compatibilidad
							mostrarPreguntas={mostrarPreguntas}
							togglePreguntas={togglePreguntas}
							empresaId={empresaId}
							procesoId={procesoId}
							language={language}
							respuestas={respuestas}
							respuestasEnviadas={respuestasEnviadas}
							redirigirPregunta={redirigirPregunta}
							userId={userId}
							quillRefs={quillRefs}
							handleQuillRef={handleQuillRef}
							handleEditorChange={handleEditorChange}
							handleFileChange={handleFileChange}
							handleSubmit={handleSubmit}
							toggleRedirigir={toggleRedirigir}
							selectedArea={selectedArea}
							selectedDepartamento={selectedDepartamento}
							handleAreaChange={handleAreaChange}
							handleDepartamentoChange={handleDepartamentoChange}
							esRespuestaValida={esRespuestaValida}
							tableState={tableState}
							setTableState={setTableState}
						/>
					))}

					<div className={styles.buttonContainer}>
						{/* Solo mostrar el botón si el usuario tiene permiso de enviar */}
						{canSendRequirements && (
							<button
								type='button'
								onClick={handleFinalizarProceso}
								className={`${styles.button.base} ${styles.button.primary}`}
								disabled={!todasPreguntasContestadas()}>
								{t('vistaFormulario.finalizarProceso')}
							</button>
						)}
					</div>
				</div>
			) : (
				<p className='text-gray-600'>
					{t('vistaFormulario.cargandoFormulario')}
				</p>
			)}
		</div>
	);
}

export default VistaFormulario;
