// src/utils/translationHelpers.js
export const translateDepartment = (deptName, labels) => {
	if (!deptName) return '';

	// Si tenemos una traducción específica para este departamento, usarla
	if (
		labels?.departamentos &&
		typeof labels.departamentos[deptName] === 'string'
	) {
		return labels.departamentos[deptName];
	}

	// Si no hay traducción o la traducción no es una cadena, devolver el nombre original
	return deptName;
};

export const translateArea = (areaName, labels) => {
	if (!areaName) return '';

	// Si tenemos una traducción específica para esta área, usarla
	if (
		labels?.departamentos &&
		typeof labels.departamentos[areaName] === 'string'
	) {
		return labels.departamentos[areaName];
	}

	// Si no hay traducción o la traducción no es una cadena, devolver el nombre original
	return areaName;
};
