import React, { useState, useEffect } from 'react';
import './DeleteUser.css';
import useUserCompany from '../../hooks/useUserCompany';
import useUserLanguage from '../../hooks/useUserLanguage'; // Importamos el hook para obtener el idioma

function DeleteUser() {
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [targetUserCompany, setTargetUserCompany] = useState(null);

  const { empresa: userCompany, error: companyError } = useUserCompany();
  const { language } = useUserLanguage(); // Obtenemos el idioma actual del usuario

  const labels = {
    title: language === 'EN' ? 'Delete User' : 'Eliminar Usuario',
    userIdLabel: language === 'EN' ? 'User ID' : 'ID del Usuario',
    placeholder: language === 'EN' ? 'Enter the user ID' : 'Ingresa el ID del usuario',
    deleteButton: language === 'EN' ? 'Delete User' : 'Eliminar Usuario',
    successMessage: language === 'EN' ? 'User deleted successfully.' : 'Usuario eliminado con éxito.',
    errorMessage: language === 'EN' ? 'Error deleting user.' : 'Error al eliminar el usuario.',
    notFoundMessage: language === 'EN' ? 'User not found.' : 'Usuario no encontrado.',
    invalidIdMessage: language === 'EN' ? 'Please enter a valid ID.' : 'Por favor, ingresa un ID válido.',
    companyErrorMessage: language === 'EN' 
      ? 'You cannot delete users outside your network.' 
      : 'No puedes eliminar usuarios fuera de tu red de trabajo',
    companyFetchError: language === 'EN' ? 'Error fetching user.' : 'Error al obtener el usuario.'
  };

  // Función para obtener la empresa del usuario que se quiere eliminar
  const fetchTargetUserCompany = async (id) => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/users/${id}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();

      if (response.ok) {
        setTargetUserCompany(data.empresa);
      } else {
        setError(labels.notFoundMessage);
        setTargetUserCompany(null);
      }
    } catch (error) {
      console.error(labels.companyFetchError, error);
      setError(labels.companyFetchError);
      setTargetUserCompany(null);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchTargetUserCompany(userId);
    } else {
      setTargetUserCompany(null);
    }
  }, [userId]);

  const handleDeleteUser = async (e) => {
    e.preventDefault();

    if (!userId) {
      setError(labels.invalidIdMessage);
      return;
    }

    // Verificar que la empresa del usuario a eliminar coincida con la empresa del usuario autenticado
    if (targetUserCompany !== userCompany) {
      setError(labels.companyErrorMessage);
      return;
    }

    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/users/${userId}`, {
        method: 'DELETE',
        credentials: 'include',
      });

      if (response.ok) {
        setSuccessMessage(labels.successMessage);
        setError('');
        setUserId('');
        setTargetUserCompany(null); // Limpia la empresa del usuario objetivo después de la eliminación
      } else {
        setError(labels.errorMessage);
        setSuccessMessage('');
      }
    } catch (error) {
      console.error(labels.errorMessage, error);
      setError(labels.errorMessage);
      setSuccessMessage('');
    }
  };

  return (
    <div>
      <div className="delete-user-container">
        <h2 className="delete-user-title">{labels.title}</h2>

        <form className="delete-user-form" onSubmit={handleDeleteUser}>
          <div className="form-group">
            <label htmlFor="userId">{labels.userIdLabel}:</label>
            <input
              type="text"
              id="userId"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder={labels.placeholder}
              required
            />
          </div>
          <button type="submit" className="delete-user-button">{labels.deleteButton}</button>
        </form>

        {error && <p className="error">{error}</p>}
        {successMessage && <p className="success">{successMessage}</p>}
        {companyError && <p className="error">{companyError}</p>}
      </div>
    </div>
  );
}

export default DeleteUser;
