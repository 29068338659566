// frontend/src/i18n/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Importamos las traducciones directamente
import translationEN from './locales/EN/translation.json';
import translationES from './locales/ES/translation.json';

// Recursos de traducción
const resources = {
	EN: {
		translation: translationEN,
	},
	ES: {
		translation: translationES,
	},
};

// Inicializar i18n
i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		resources,
		lng: 'ES', // Idioma por defecto
		fallbackLng: 'ES',
		debug: process.env.NODE_ENV === 'development',
		returnObjects: true, // Esta es la línea clave que necesitas añadir

		interpolation: {
			escapeValue: false, // React ya evita los ataques XSS
		},

		react: {
			useSuspense: false, // Para evitar problemas con componentes suspendidos
		},
	});

// Función para cambiar el idioma
export const changeLanguage = (language) => {
	i18n.changeLanguage(language);
};

export default i18n;
