// src/services/metricsService.js

import { ROUTE_METRICS } from '../config/routeConfig';
import { debug, debugError } from '../utils/debugHelper';

class MetricsService {
	constructor() {
		this.metrics = {
			performance: new Map(),
			pageViews: new Map(),
			errors: new Map(),
			apiCalls: new Map(),
		};

		this.metricsInterval = 10000; // 10 segundos
		this.retryTimeout = 5000; // 5 segundos de espera entre reintentos
		this.maxRetries = 3;
		this.currentRetries = 0;

		// Base URL para métricas
		this.metricsUrl = ROUTE_METRICS;

		this.startMetricsCollection();
	}

	startMetricsCollection() {
		if (!this.intervalId) {
			this.intervalId = setInterval(() => {
				this.sendMetrics();
			}, this.metricsInterval);
		}
	}

	stopMetricsCollection() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}

	recordMetric(type, key, value) {
		if (!this.metrics[type]) return;

		switch (type) {
			case 'performance':
				this.metrics.performance.set(key, value);
				break;
			case 'pageViews':
				const viewCount = this.metrics.pageViews.get(key) || 0;
				this.metrics.pageViews.set(key, viewCount + 1);
				break;
			case 'errors':
				const errorCount = this.metrics.errors.get(key) || 0;
				this.metrics.errors.set(key, errorCount + 1);
				break;
			case 'apiCalls':
				const calls = this.metrics.apiCalls.get(key) || {
					count: 0,
					totalDuration: 0,
				};
				this.metrics.apiCalls.set(key, {
					count: calls.count + 1,
					totalDuration: calls.totalDuration + (value || 0),
				});
				break;
		}
	}

	formatMetricsForPrometheus() {
		const timestamp = Math.floor(Date.now() / 1000);
		const lines = [];

		// Definiciones de tipos
		lines.push(
			'# HELP frontend_performance_metrics Frontend performance metrics'
		);
		lines.push('# TYPE frontend_performance_metrics gauge');

		lines.push('# HELP frontend_page_views_total Total number of page views');
		lines.push('# TYPE frontend_page_views_total counter');

		lines.push('# HELP frontend_errors_total Total number of frontend errors');
		lines.push('# TYPE frontend_errors_total counter');

		lines.push('# HELP frontend_api_calls_total Total number of API calls');
		lines.push('# TYPE frontend_api_calls_total counter');

		lines.push('# HELP frontend_api_duration_seconds Duration of API calls');
		lines.push('# TYPE frontend_api_duration_seconds gauge');

		// Performance metrics
		this.metrics.performance.forEach((value, metric) => {
			lines.push(
				`frontend_performance_metrics{metric="${metric}"} ${value} ${timestamp}`
			);
		});

		// Page views
		this.metrics.pageViews.forEach((count, page) => {
			lines.push(
				`frontend_page_views_total{page="${page}"} ${count} ${timestamp}`
			);
		});

		// Errors
		this.metrics.errors.forEach((count, error) => {
			const [errorType, context] = error.split('_');
			lines.push(
				`frontend_errors_total{type="${errorType}",context="${context}"} ${count} ${timestamp}`
			);
		});

		// API calls
		this.metrics.apiCalls.forEach((data, key) => {
			const [method, endpoint, status] = key.split('_');
			lines.push(
				`frontend_api_calls_total{method="${method}",endpoint="${endpoint}",status="${status}"} ${data.count} ${timestamp}`
			);
			lines.push(
				`frontend_api_duration_seconds{method="${method}",endpoint="${endpoint}",status="${status}"} ${
					data.totalDuration / 1000
				} ${timestamp}`
			);
		});

		return lines.join('\n');
	}

	async sendMetrics(retry = false) {
		if (
			this.metrics.performance.size === 0 &&
			this.metrics.pageViews.size === 0 &&
			this.metrics.errors.size === 0 &&
			this.metrics.apiCalls.size === 0
		) {
			return;
		}

		try {
			const metricsData = this.formatMetricsForPrometheus();
			debug('Enviando métricas:', metricsData); // Siempre mostrar las métricas que se envían

			const response = await fetch(this.metricsUrl, {
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
					Accept: 'text/plain',
				},
				body: metricsData,
				credentials: 'include',
			});

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}

			this.clearMetrics();
			this.currentRetries = 0;
			debug('✅ Métricas enviadas correctamente'); // Siempre mostrar confirmación
		} catch (error) {
			debugError('❌ Error al enviar métricas:', error);

			if (!retry && this.currentRetries < this.maxRetries) {
				this.currentRetries++;
				debug(
					`🔄 Reintentando envío (intento ${this.currentRetries}/${this.maxRetries})...`
				);
				setTimeout(() => {
					this.sendMetrics(true);
				}, this.retryTimeout);
			} else if (this.currentRetries >= this.maxRetries) {
				debugError(
					`❌ Se alcanzó el máximo de reintentos (${this.maxRetries})`
				);
			}
		}
	}

	clearMetrics() {
		Object.values(this.metrics).forEach((metric) => metric.clear());
	}

	// Métodos de conveniencia
	recordPerformanceMetric(metric, value) {
		this.recordMetric('performance', metric, value);
	}

	recordPageView(page) {
		this.recordMetric('pageViews', page, 1);
	}

	recordError(error, context) {
		this.recordMetric('errors', `${error.name}_${context}`, 1);
	}

	recordApiCall(endpoint, method, status, duration) {
		this.recordMetric('apiCalls', `${method}_${endpoint}_${status}`, duration);
	}
}

export const metricsService = new MetricsService();
