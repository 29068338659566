// frontend/src/views/System/DeleteUser.js
import React, { useState } from 'react';
import useUserCompany from '../../hooks/user/useUserCompany';
import useI18n from '../../hooks/i18n/useI18n';
import { deleteUserStyles } from '../../styles/deleteUserStyles.js';
import { deleteUserHandlers } from '../../utils/handlers/userHandlers';
import { debug, debugError } from '../../utils/debugHelper';

function DeleteUser() {
	const [userId, setUserId] = useState('');
	const [error, setError] = useState('');
	const [successMessage, setSuccessMessage] = useState('');
	const [targetUserCompany, setTargetUserCompany] = useState(null);

	const { empresa: userCompany, error: companyError } = useUserCompany();
	const { t, language, languageError } = useI18n();

	if (languageError || companyError) {
		return (
			<div className={deleteUserStyles.errorContainer}>
				<p>{languageError || companyError}</p>
			</div>
		);
	}

	if (!language) {
		return (
			<div className={deleteUserStyles.loader}>
				<div className='text-center'>
					<div className={deleteUserStyles.loaderSpinner}></div>
					<p className={deleteUserStyles.loaderText}>{t('common.loading')}</p>
				</div>
			</div>
		);
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError(''); // Limpiar errores previos

		try {
			// Ahora necesitamos modificar las funciones de los handlers para que acepten t en lugar de labels
			// O podemos crear un objeto labels temporal para compatibilidad
			const tempLabels = {
				companyErrorMessage: t('deleteUser.companyErrorMessage'),
				errorMessage: t('deleteUser.errorMessage'),
				successMessage: t('deleteUser.successMessage'),
				notFoundMessage: t('deleteUser.notFoundMessage'),
			};

			// Verificar y obtener la empresa del usuario
			const { found, company } =
				await deleteUserHandlers.fetchTargetUserCompany(
					userId,
					tempLabels,
					setError,
					setTargetUserCompany
				);
			debug('Resultado de búsqueda:', { found, company, userCompany });

			// Si el usuario fue encontrado y pertenece a la empresa correcta
			if (found && company === userCompany) {
				debug('Intentando eliminar usuario...');
				const deleted = await deleteUserHandlers.handleDeleteUser(
					userId,
					company, // Usar company en lugar de targetUserCompany
					userCompany,
					tempLabels,
					setError,
					setSuccessMessage,
					setUserId,
					setTargetUserCompany
				);
				debug('Resultado de eliminación:', deleted);
			} else {
				debug('No se cumplieron las condiciones:', {
					found,
					company,
					userCompany,
				});
				if (found && company !== userCompany) {
					setError(t('deleteUser.companyErrorMessage'));
				}
			}
		} catch (error) {
			debugError('Error en handleSubmit:', error);
			setError(t('deleteUser.errorMessage'));
		}
	};

	return (
		<div className={deleteUserStyles.container}>
			<div className='max-w-2xl mx-auto'>
				<div className={deleteUserStyles.titleContainer}>
					<h2 className={deleteUserStyles.titleText}>
						{t('deleteUser.title')}
					</h2>
					<div className={deleteUserStyles.titleUnderline}></div>
				</div>

				<div className={deleteUserStyles.card}>
					<div className={deleteUserStyles.header}>
						<form
							onSubmit={handleSubmit}
							className={deleteUserStyles.form}>
							<div className={deleteUserStyles.formGroup}>
								<div>
									<label className={deleteUserStyles.label}>
										{t('deleteUser.userIdLabel')}
									</label>
									<div className={deleteUserStyles.inputGroup}>
										<input
											type='text'
											value={userId}
											onChange={(e) => setUserId(e.target.value)}
											className={deleteUserStyles.input}
											placeholder={t('deleteUser.placeholder')}
											required
										/>
										<button
											type='submit'
											className={deleteUserStyles.button}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-5 w-5 mr-2'
												viewBox='0 0 20 20'
												fill='currentColor'>
												<path
													fillRule='evenodd'
													d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
													clipRule='evenodd'
												/>
											</svg>
											{t('deleteUser.deleteButton')}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>

					{error && (
						<div className={deleteUserStyles.errorMessage}>
							<p className={deleteUserStyles.errorText}>{error}</p>
						</div>
					)}

					{successMessage && (
						<div className={deleteUserStyles.successMessage}>
							<p className={deleteUserStyles.successText}>{successMessage}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default DeleteUser;
