// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/componentes/PreguntasTable.module.css */
.PreguntasTable_preguntasTable__kAEVk {
    width: 100%;
    border-collapse: collapse;
  }
  
  .PreguntasTable_preguntasTable__kAEVk th, .PreguntasTable_preguntasTable__kAEVk td {
    padding: 10px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .PreguntasTable_preguntasTable__kAEVk th {
    background-color: #f4f4f4;
  }
  
  .PreguntasTable_preguntasTable__kAEVk tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  `, "",{"version":3,"sources":["webpack://./src/componentes/PreguntasTable.module.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/componentes/PreguntasTable.module.css */\n.preguntasTable {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .preguntasTable th, .preguntasTable td {\n    padding: 10px;\n    text-align: left;\n    border: 1px solid #ddd;\n  }\n  \n  .preguntasTable th {\n    background-color: #f4f4f4;\n  }\n  \n  .preguntasTable tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preguntasTable": `PreguntasTable_preguntasTable__kAEVk`
};
export default ___CSS_LOADER_EXPORT___;
