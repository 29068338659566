// frontend/src/utils/questionUtils.js
import {
	questionOptions,
	conditionalQuestionOptions,
	tableConfigs,
} from '../config/questionConfig';
import i18n from '../i18n/i18n';
import { debug } from './debugHelper';

const getTranslation = (key, defaultValue) => {
	try {
		return i18n.t(key, { defaultValue });
	} catch (error) {
		debug(`Error al obtener traducción para ${key}:`, error);
		return defaultValue;
	}
};

export const getQuestionOptions = (formularioId, procesoId, preguntaId) => {
	const directKey = `${formularioId}-${preguntaId}`;
	if (questionOptions[directKey]) {
		return questionOptions[directKey];
	}

	const fullKey = `${formularioId}-${procesoId}-${preguntaId}`;
	if (conditionalQuestionOptions[fullKey]) {
		return conditionalQuestionOptions[fullKey];
	}

	return {
		positiveOption: 'Si he cumplido con todo correcto',
		negativeOption: 'No he cumplido como toca',
	};
};

export const getCheckedBoxes = (response) => {
	if (
		!response ||
		response ===
			getTranslation(
				'tableQuestion.autoMessages.Va a informar de todo',
				'Va a informar de todo'
			)
	)
		return [];
	if (!response.includes(' - ')) return [];

	return response
		.split(', ')
		.map((item) => {
			const [indexStr] = item.split(' - ');
			return parseInt(indexStr);
		})
		.filter((index) => !isNaN(index));
};

export const shouldHideQuestion = (pregunta, respuestas, formularioId) => {
	if (!pregunta.conditional?.controlledBy) return false;

	const parentResponse =
		respuestas[`${formularioId}-${pregunta.conditional.controlledBy}`];
	if (!parentResponse) return true;

	if (pregunta.conditional.showOnOption === 'checkbox') {
		const checkedBoxes = getCheckedBoxes(parentResponse.text);
		return !checkedBoxes.includes(pregunta.conditional.checkboxIndex);
	}

	return false;
};

export function getTextFromHtml(html) {
	if (!html) return '';
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = html;
	return tempDiv.textContent || tempDiv.innerText || '';
}

/**
 * Verifica si el texto es igual o comienza con la opción (considerando normalización)
 * @param {string} normalizedText - Texto normalizado
 * @param {string} normalizedOption - Opción normalizada
 * @returns {boolean} - true si coincide, false si no
 */
function matchesDirectly(normalizedText, normalizedOption) {
	if (normalizedText === normalizedOption) return true;
	if (normalizedText.startsWith(normalizedOption)) return true;
	if (normalizedOption.length > 15 && normalizedText.includes(normalizedOption))
		return true;
	return false;
}

/**
 * Verifica variantes de "sí"/"yes"
 * @param {string} normalizedText - Texto normalizado
 * @param {string} normalizedOption - Opción normalizada
 * @returns {boolean} - true si coincide, false si no
 */
function matchesYesVariants(normalizedText, normalizedOption) {
	if (
		(normalizedText === 'yes' &&
			(normalizedOption === 'si' || normalizedOption === 'sí')) ||
		(normalizedOption === 'yes' &&
			(normalizedText === 'si' || normalizedText === 'sí'))
	) {
		return true;
	}
	return false;
}

/**
 * Verifica coincidencias con traducciones
 * @param {string} normalizedText - Texto normalizado
 * @param {string} normalizedOption - Opción normalizada
 * @param {Object} optionsTranslations - Diccionario de traducciones
 * @returns {boolean} - true si coincide con alguna traducción, false si no
 */
function matchesTranslations(
	normalizedText,
	normalizedOption,
	optionsTranslations
) {
	// Buscar en traducciones normales
	const optionTranslated = optionsTranslations[normalizedOption];
	if (optionTranslated) {
		const normalizedTranslation = optionTranslated.toLowerCase().trim();
		if (
			normalizedText === normalizedTranslation ||
			normalizedText.startsWith(normalizedTranslation)
		) {
			return true;
		}
	}

	// Buscar coincidencias entre traducciones
	for (const [originalText, translatedText] of Object.entries(
		optionsTranslations
	)) {
		const normalizedOriginal = originalText.toLowerCase().trim();
		const normalizedTranslation = translatedText.toLowerCase().trim();

		if (
			(normalizedText === normalizedTranslation &&
				normalizedOption === normalizedOriginal) ||
			(normalizedText === normalizedOriginal &&
				normalizedOption === normalizedTranslation)
		) {
			return true;
		}
	}

	return false;
}

/**
 * Verifica coincidencias con frases especiales
 * @param {string} normalizedText - Texto normalizado
 * @param {string} normalizedOption - Opción normalizada
 * @returns {boolean} - true si coincide, false si no
 */
function matchesSpecialPhrases(normalizedText, normalizedOption) {
	const specialPhrases = {
		[getTranslation(
			'questionConditional.options.No se han identificado errores materiales de ejercicios anteriores',
			'No se han identificado errores materiales de ejercicios anteriores'
		)]: getTranslation(
			'questionConditional.options.No se han identificado errores materiales de ejercicios anteriores',
			'No material errors from previous fiscal years have been identified'
		),
		[getTranslation(
			'questionConditional.options.No se han realizado cambios',
			'No se han realizado cambios'
		)]: getTranslation(
			'questionConditional.options.No se han realizado cambios',
			'No changes have been made'
		),
		[getTranslation(
			'questionConditional.options.Si ha identificado errores materiales de ejercicios anteriores debe revelar la naturaleza de los mismos',
			'Si ha identificado errores materiales de ejercicios anteriores debe revelar la naturaleza de los mismos'
		)]: getTranslation(
			'questionConditional.options.Si ha identificado errores materiales de ejercicios anteriores debe revelar la naturaleza de los mismos',
			'If you have identified material errors from previous fiscal years, you must disclose their nature'
		),
	};

	for (const [spanish, english] of Object.entries(specialPhrases)) {
		if (
			(normalizedText.includes(spanish.toLowerCase()) &&
				normalizedOption.includes(english.toLowerCase())) ||
			(normalizedText.includes(english.toLowerCase()) &&
				normalizedOption.includes(spanish.toLowerCase()))
		) {
			return true;
		}
	}

	return false;
}

export function checkIfStartsWithOption(text, option, language = 'ES') {
	if (!text || !option) return false;

	const normalizedText = text.trim().toLowerCase();
	const normalizedOption = option.trim().toLowerCase();

	// Obtener traducciones disponibles
	const optionsTranslations = {};
	try {
		const translationsOptions = i18n.t('questionConditional.options', {});
		Object.assign(optionsTranslations, translationsOptions);
	} catch (error) {}

	// Intentar coincidencias directas
	if (matchesDirectly(normalizedText, normalizedOption)) return true;

	// Verificar variantes de "sí"/"yes"
	if (matchesYesVariants(normalizedText, normalizedOption)) return true;

	// Verificar coincidencias con traducciones
	if (
		matchesTranslations(normalizedText, normalizedOption, optionsTranslations)
	)
		return true;

	// Verificar coincidencias con frases especiales
	if (matchesSpecialPhrases(normalizedText, normalizedOption)) return true;

	return false;
}

function normalizeText(text) {
	if (!text) return '';
	const maxLength = 10000;
	const textToProcess =
		text.length > maxLength ? text.substring(0, maxLength) : text;
	let result = '';
	let inTag = false;

	for (let i = 0; i < textToProcess.length; i++) {
		const char = textToProcess[i];
		if (char === '<') inTag = true;
		else if (char === '>') inTag = false;
		else if (!inTag) result += char;
	}

	return result.replace(/\s+/g, ' ').trim();
}

function robustTextIncludes(fullText, searchText) {
	if (!fullText || !searchText) return false;

	const normalizedFull = normalizeText(fullText);
	const normalizedSearch = normalizeText(searchText);

	if (normalizedFull === normalizedSearch) return true;
	if (normalizedFull.includes(normalizedSearch)) return true;

	if (normalizedSearch.length > 30) {
		const searchStart = normalizedSearch.substring(0, 30);
		if (normalizedFull.includes(searchStart)) return true;
	}

	return false;
}

const visibilityCache = new Map();

/**
 * Verifica condiciones básicas para mostrar una pregunta
 * @param {Object} pregunta - Objeto de pregunta
 * @param {string} formularioId - ID del formulario
 * @param {string} procesoId - ID del proceso
 * @param {string} empresaId - ID de la empresa
 * @param {string} cacheKey - Clave para caché
 * @returns {boolean|null} - true/false si se puede determinar, null si se necesita más validación
 */
function checkBasicConditions(
	pregunta,
	formularioId,
	procesoId,
	empresaId,
	cacheKey
) {
	if (!pregunta || !formularioId || !procesoId || !empresaId) {
		visibilityCache.set(cacheKey, true);
		return true;
	}

	if (pregunta.type === 'standard' || pregunta.type === 'boolean') {
		visibilityCache.set(cacheKey, true);
		return true;
	}

	return null; // Se necesita más validación
}

/**
 * Procesa preguntas de tipo 'dependent'
 * @param {Object} pregunta - Objeto de pregunta
 * @param {Object} respuestas - Respuestas actuales
 * @param {Object} respuestasEnviadas - Respuestas guardadas
 * @param {string} formularioId - ID del formulario
 * @param {string} procesoId - ID del proceso
 * @param {string} empresaId - ID de la empresa
 * @param {string} language - Idioma
 * @param {string} cacheKey - Clave para caché
 * @returns {boolean|null} - true/false si se puede determinar, null si se necesita más validación
 */
function processDependentQuestion(
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	procesoId,
	empresaId,
	language,
	cacheKey
) {
	if (pregunta.type !== 'dependent' || !pregunta.conditional?.dependsOn) {
		return null; // No es una pregunta dependiente
	}

	const parentId = pregunta.conditional.dependsOn;
	const parentKey = `${formularioId}-${parentId}`;

	const parentResponse = respuestas[parentKey]?.text || '';
	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${parentId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];
	const respuestaGuardadaText = respuestaGuardada?.respuesta?.text || '';

	const effectiveResponse = parentResponse || respuestaGuardadaText;
	const effectiveResponseText = getTextFromHtml(effectiveResponse);

	// Manejar caso 'negative'
	if (pregunta.conditional.showOnOption === 'negative') {
		if (
			effectiveResponseText === 'negative' ||
			effectiveResponseText.includes('No es material')
		) {
			visibilityCache.set(cacheKey, true);
			return true;
		} else {
			visibilityCache.set(cacheKey, false);
			return false;
		}
	}

	// Procesar configuración específica
	const parentConfig =
		questionOptions[`${formularioId}-${procesoId}-${parentId}`];
	if (parentConfig) {
		if (!respuestaGuardada) {
			visibilityCache.set(cacheKey, false);
			return false;
		}

		if (parentConfig.controlBehavior === 'positive') {
			const startsWithPositive = checkIfStartsWithOption(
				effectiveResponseText,
				parentConfig.positiveOption,
				language
			);

			const result = !startsWithPositive;
			visibilityCache.set(cacheKey, result);
			return result;
		}

		if (parentConfig.controlBehavior === 'negative') {
			const startsWithNegative = checkIfStartsWithOption(
				effectiveResponseText,
				parentConfig.negativeOption,
				language
			);

			const result = !startsWithNegative;
			visibilityCache.set(cacheKey, result);
			return result;
		}
	}

	// Caso por defecto
	const result = !!effectiveResponse;
	visibilityCache.set(cacheKey, result);
	return result;
}

/**
 * Procesa preguntas de tipo controladas por checkbox
 * @param {Object} pregunta - Objeto de pregunta
 * @param {Object} respuestas - Respuestas actuales
 * @param {Object} respuestasEnviadas - Respuestas guardadas
 * @param {string} formularioId - ID del formulario
 * @param {string} procesoId - ID del proceso
 * @param {string} empresaId - ID de la empresa
 * @param {string} cacheKey - Clave para caché
 * @returns {boolean|null} - true/false si se puede determinar, null si se necesita más validación
 */
function processCheckboxControlledQuestion(
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	procesoId,
	empresaId,
	cacheKey
) {
	if (
		!pregunta.conditional?.controlledBy ||
		pregunta.conditional?.showOnOption !== 'checkbox'
	) {
		return null; // No es controlada por checkbox
	}

	const controllingId = pregunta.conditional.controlledBy;
	const checkboxIndex = pregunta.conditional.checkboxIndex;

	const tableResponse = respuestas[`${formularioId}-${controllingId}`]?.text;
	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];

	// Si es negativa, no mostrar
	if (
		tableResponse === 'negative' ||
		respuestaGuardada?.respuesta?.text === 'negative'
	) {
		visibilityCache.set(cacheKey, false);
		return false;
	}

	// Si no hay respuesta, no mostrar
	if (!tableResponse && !respuestaGuardada?.respuesta?.text) {
		visibilityCache.set(cacheKey, false);
		return false;
	}

	// Verificar si el checkbox específico está marcado
	const effectiveResponse = tableResponse || respuestaGuardada?.respuesta?.text;
	const checkedBoxes = getCheckedBoxes(effectiveResponse);
	const isCheckboxChecked = checkedBoxes.includes(checkboxIndex);

	visibilityCache.set(cacheKey, isCheckboxChecked);
	return isCheckboxChecked;
}

/**
 * Procesa preguntas de tipo 'negative'
 * @param {Object} pregunta - Objeto de pregunta
 * @param {Object} respuestas - Respuestas actuales
 * @param {Object} respuestasEnviadas - Respuestas guardadas
 * @param {string} formularioId - ID del formulario
 * @param {string} procesoId - ID del proceso
 * @param {string} empresaId - ID de la empresa
 * @param {string} cacheKey - Clave para caché
 * @returns {boolean|null} - true/false si se puede determinar, null si se necesita más validación
 */
function processNegativeControlledQuestion(
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	procesoId,
	empresaId,
	cacheKey
) {
	if (
		!pregunta.conditional?.controlledBy ||
		pregunta.conditional?.showOnOption !== 'negative'
	) {
		return null; // No es controlada por negative
	}

	const controllingId = pregunta.conditional.controlledBy;
	const tableResponse = respuestas[`${formularioId}-${controllingId}`]?.text;

	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];
	const isResponsaGuardadaNegative =
		respuestaGuardada?.respuesta?.text === 'negative';

	if (isResponsaGuardadaNegative) {
		visibilityCache.set(cacheKey, true);
		return true;
	}

	// Verificar respuesta negativa en el texto
	const result =
		tableResponse === 'negative' || tableResponse?.includes('No es material');
	visibilityCache.set(cacheKey, result);
	return result;
}

/**
 * Procesa preguntas de tipo 'hideable'
 * @param {Object} pregunta - Objeto de pregunta
 * @param {Object} respuestas - Respuestas actuales
 * @param {Object} respuestasEnviadas - Respuestas guardadas
 * @param {string} formularioId - ID del formulario
 * @param {string} procesoId - ID del proceso
 * @param {string} empresaId - ID de la empresa
 * @param {string} cacheKey - Clave para caché
 * @returns {boolean|null} - true/false si se puede determinar, null si se necesita más validación
 */
function processHideableQuestion(
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	procesoId,
	empresaId,
	cacheKey
) {
	if (pregunta.type !== 'hideable' || !pregunta.conditional?.controlledBy) {
		return null; // No es una pregunta hideable
	}

	const controllingId = pregunta.conditional.controlledBy;
	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];

	// Si es 'negative', manejarlo
	if (pregunta.conditional.showOnOption === 'negative') {
		const result = processNegativeControlledQuestion(
			pregunta,
			respuestas,
			respuestasEnviadas,
			formularioId,
			procesoId,
			empresaId,
			cacheKey
		);
		if (result !== null) return result;
	}

	// Si es 'checkbox', manejarlo
	if (pregunta.conditional.showOnOption === 'checkbox') {
		const result = processCheckboxControlledQuestion(
			pregunta,
			respuestas,
			respuestasEnviadas,
			formularioId,
			procesoId,
			empresaId,
			cacheKey
		);
		if (result !== null) return result;
	}

	// Caso por defecto
	if (
		!respuestaGuardada &&
		!(pregunta.conditional.showOnOption === 'negative')
	) {
		visibilityCache.set(cacheKey, false);
		return false;
	}

	return null; // Continuar validación
}

export const shouldShowQuestion = (
	pregunta,
	respuestas,
	formularioId,
	procesoId,
	respuestasEnviadas = {},
	empresaId,
	language = 'ES',
	estadoFormulario = null // Añadir este parámetro opcional
) => {
	// Si el formulario está en un estado final, siempre mostrar la pregunta
	if (
		estadoFormulario === 'Acabada' ||
		estadoFormulario === 'Completada' ||
		estadoFormulario === 'Revision'
	) {
		return true;
	}

	// El resto de la función queda igual
	// Manejar caso específico de negative checkboxes
	if (
		pregunta.conditional?.controlledBy &&
		pregunta.conditional?.showOnOption === 'negative'
	) {
		const controllingId = pregunta.conditional.controlledBy;
		const tableResponse = respuestas[`${formularioId}-${controllingId}`]?.text;

		const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingId}`;
		const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];
		const isResponsaGuardadaNegative =
			respuestaGuardada?.respuesta?.text === 'negative';

		if (isResponsaGuardadaNegative) {
			return true;
		}
	}

	// Verificar caché
	const cacheKey = `${pregunta.id}-${formularioId}-${procesoId}-${empresaId}-${language}`;
	if (visibilityCache.has(cacheKey)) {
		return visibilityCache.get(cacheKey);
	}

	// Verificar condiciones básicas
	const basicResult = checkBasicConditions(
		pregunta,
		formularioId,
		procesoId,
		empresaId,
		cacheKey
	);
	if (basicResult !== null) return basicResult;

	// Procesar preguntas dependientes
	const dependentResult = processDependentQuestion(
		pregunta,
		respuestas,
		respuestasEnviadas,
		formularioId,
		procesoId,
		empresaId,
		language,
		cacheKey
	);
	if (dependentResult !== null) return dependentResult;

	// Procesar preguntas ocultas
	const hideableResult = processHideableQuestion(
		pregunta,
		respuestas,
		respuestasEnviadas,
		formularioId,
		procesoId,
		empresaId,
		cacheKey
	);
	if (hideableResult !== null) return hideableResult;

	// Valor por defecto si nada ha devuelto un resultado concreto
	visibilityCache.set(cacheKey, true);
	return true;
};

export const clearVisibilityCache = () => {
	visibilityCache.clear();
};

/**
 * Valida un valor numérico para un campo de tipo entero
 * @param {string} value - Valor a validar
 * @returns {boolean} - true si es válido, false si no
 */
function isValidIntegerValue(value) {
	for (let i = 0; i < value.length; i++) {
		const char = value.charAt(i);
		if (char < '0' || char > '9') {
			return false;
		}
	}
	return true;
}

/**
 * Valida un valor numérico para un campo de tipo decimal
 * @param {string} value - Valor a validar
 * @returns {boolean} - true si es válido, false si no
 */
function isValidDecimalValue(value) {
	let hasDecimalPoint = false;

	for (let i = 0; i < value.length; i++) {
		const char = value.charAt(i);

		if (char === '.') {
			if (hasDecimalPoint) {
				return false;
			}
			hasDecimalPoint = true;
		} else if (char < '0' || char > '9') {
			return false;
		}
	}
	return true;
}

/**
 * Actualiza el valor si es válido
 * @param {string} value - Valor a actualizar
 * @param {boolean} isValid - Si el valor es válido
 * @param {Function} setInputValue - Función para actualizar el valor de entrada
 * @param {Function} handleEditorChange - Función para manejar cambios en el editor
 * @param {Object} pregunta - Objeto de pregunta
 * @param {string} formularioId - ID del formulario
 */
function updateIfValid(
	value,
	isValid,
	setInputValue,
	handleEditorChange,
	pregunta,
	formularioId
) {
	if (isValid) {
		setInputValue(value);
		handleEditorChange(pregunta.id, formularioId, value);
	}
}

export const handleNumericalChange = (
	value,
	pregunta,
	setInputValue,
	handleEditorChange,
	formularioId
) => {
	// Manejar caso de entrada vacía
	if (value === '') {
		setInputValue(value);
		handleEditorChange(pregunta.id, formularioId, value);
		return;
	}

	// Validar longitud máxima
	const maxLength = 50;
	if (value.length > maxLength) return;

	// Procesar según tipo de pregunta
	if (pregunta.questionType === 'integer') {
		const isValid = isValidIntegerValue(value);
		updateIfValid(
			value,
			isValid,
			setInputValue,
			handleEditorChange,
			pregunta,
			formularioId
		);
	} else {
		const isValid = isValidDecimalValue(value);
		updateIfValid(
			value,
			isValid,
			setInputValue,
			handleEditorChange,
			pregunta,
			formularioId
		);
	}
};
