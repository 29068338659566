import React from 'react';
import { useTranslation } from 'react-i18next';

function UserFilters({
	searchName,
	setSearchName,
	searchEmail,
	setSearchEmail,
	searchUsername,
	setSearchUsername,
	selectedDepartment,
	setSelectedDepartment,
	selectedArea,
	setSelectedArea,
	allDepartments,
	allAreas,
	// Ya no necesitamos el objeto labels, usaremos i18n
}) {
	// Usamos el hook de traducción de i18n
	const { t } = useTranslation();

	return (
		<div className='flex flex-wrap gap-4 mb-6'>
			{/* Filtro por nombre */}
			<div className='flex items-center space-x-2'>
				<label
					htmlFor='searchName'
					className='text-sm font-medium text-gray-700'>
					{t('dashboard.filtros.buscarPorNombre')}:
				</label>
				<input
					type='text'
					id='searchName'
					value={searchName}
					onChange={(e) => setSearchName(e.target.value)}
					className='px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500'
					placeholder={t('dashboard.filtros.buscarPorNombre')}
				/>
			</div>

			{/* Filtro por email */}
			<div className='flex items-center space-x-2'>
				<label
					htmlFor='searchEmail'
					className='text-sm font-medium text-gray-700'>
					{t('dashboard.filtros.buscarPorEmail')}:
				</label>
				<input
					type='text'
					id='searchEmail'
					value={searchEmail}
					onChange={(e) => setSearchEmail(e.target.value)}
					className='px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500'
					placeholder={t('dashboard.filtros.buscarPorEmail')}
				/>
			</div>

			{/* Filtro por username */}
			<div className='flex items-center space-x-2'>
				<label
					htmlFor='searchUsername'
					className='text-sm font-medium text-gray-700'>
					{t('dashboard.filtros.buscarPorUsername')}:
				</label>
				<input
					type='text'
					id='searchUsername'
					value={searchUsername}
					onChange={(e) => setSearchUsername(e.target.value)}
					className='px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500'
					placeholder={t('dashboard.filtros.buscarPorUsername')}
				/>
			</div>

			{/* Filtro por departamento */}
			<div className='flex items-center space-x-2'>
				<label
					htmlFor='departmentFilter'
					className='text-sm font-medium text-gray-700'>
					{t('dashboard.filtros.filtrarPorDepartamento')}:
				</label>
				<select
					id='departmentFilter'
					value={selectedDepartment || ''}
					onChange={(e) => setSelectedDepartment(e.target.value || null)}
					className='px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500'>
					<option value=''>
						{t('dashboard.filtros.seleccionarDepartamento')}
					</option>
					{allDepartments.map((department) => (
						<option
							key={department}
							value={department}>
							{t(`departamentos.${department}`, { defaultValue: department })}
						</option>
					))}
				</select>
			</div>

			{/* Filtro por área */}
			<div className='flex items-center space-x-2'>
				<label
					htmlFor='areaFilter'
					className='text-sm font-medium text-gray-700'>
					{t('dashboard.filtros.filtrarPorArea')}:
				</label>
				<select
					id='areaFilter'
					value={selectedArea || ''}
					onChange={(e) => setSelectedArea(e.target.value || null)}
					className='px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-500'>
					<option value=''>{t('dashboard.filtros.seleccionarArea')}</option>
					{allAreas.map((area) => (
						<option
							key={area}
							value={area}>
							{t(`departamentos.${area}`, { defaultValue: area })}
						</option>
					))}
				</select>
			</div>

			{/* Botón para limpiar filtros */}
			{(searchName ||
				searchEmail ||
				searchUsername ||
				selectedDepartment ||
				selectedArea) && (
				<button
					onClick={() => {
						setSearchName('');
						setSearchEmail('');
						setSearchUsername('');
						setSelectedDepartment(null);
						setSelectedArea(null);
					}}
					className='px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors'>
					{t('dashboard.filtros.limpiarFiltros')}
				</button>
			)}
		</div>
	);
}

export default UserFilters;
