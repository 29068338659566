// frontend/src/utils/handlers/userHandlers/updateUserHandlers.js
import { ROUTE_USERS } from '../../../config/routeConfig';
import { commonUserHandlers } from './commonUserHandlers';
import { debugError } from '../../../utils/debugHelper';
import { fetchWithAuth } from '../../../utils/fetchUtils';

export const updateUserHandlers = {
	...commonUserHandlers,

	/**
	 * Valida los parámetros iniciales para la obtención del usuario
	 * @param {string} userId - ID del usuario
	 * @param {string} userCompany - Empresa del usuario actual
	 * @param {Object} labels - Etiquetas para mensajes
	 * @param {Function} setError - Función para establecer errores
	 * @returns {boolean} - true si los datos son válidos
	 */
	validateUserParams: (userId, userCompany, labels, setError) => {
		if (!userId) {
			setError(labels.errorMessage);
			return false;
		}

		if (!userCompany) {
			setError(labels.companyErrorMessage);
			return false;
		}

		return true;
	},

	/**
	 * Obtiene las áreas asociadas a los departamentos del usuario
	 * @param {Object} fetchedDepartamentos - Departamentos del usuario
	 * @param {Object} areaOptions - Opciones de áreas disponibles
	 * @returns {Array} - Lista de áreas con permisos
	 */
	getAreasFromDepartamentos: (fetchedDepartamentos, areaOptions) => {
		return Object.keys(fetchedDepartamentos).reduce((acc, deptName) => {
			for (const area in areaOptions) {
				if (areaOptions[area].includes(deptName)) {
					if (!acc.includes(area)) acc.push(area);
					break;
				}
			}
			return acc;
		}, []);
	},

	/**
	 * Genera estructura de departamentos con valores por defecto
	 * @param {Object} areaOptions - Opciones de áreas disponibles
	 * @param {Object} fetchedDepartamentos - Departamentos del usuario
	 * @returns {Object} - Estructura de departamentos con valores por defecto
	 */
	generateDepartamentosConValores: (areaOptions, fetchedDepartamentos) => {
		return Object.keys(areaOptions).reduce((acc, area) => {
			acc[area] = areaOptions[area].reduce((deptAcc, dept) => {
				deptAcc[dept] = fetchedDepartamentos[dept] || {
					ver: false,
					editar: false,
					enviar: false,
				};
				return deptAcc;
			}, {});
			return acc;
		}, {});
	},

	/**
	 * Procesa los datos del usuario obtenidos
	 * @param {Object} data - Datos del usuario
	 * @param {string} userCompany - Empresa del usuario actual
	 * @param {Object} areaOptions - Opciones de áreas disponibles
	 * @param {Function} setUserData - Función para establecer datos del usuario
	 * @param {Function} setSelectedAreas - Función para establecer áreas seleccionadas
	 * @param {Function} setDepartamentos - Función para establecer departamentos
	 * @param {Function} setError - Función para establecer errores
	 * @param {Object} labels - Etiquetas para mensajes
	 * @returns {boolean} - true si el procesamiento fue exitoso
	 */
	processUserData: (
		data,
		userCompany,
		areaOptions,
		setUserData,
		setSelectedAreas,
		setDepartamentos,
		setError,
		labels
	) => {
		if (data.empresa !== userCompany) {
			setError(labels.companyErrorMessage);
			setUserData(null);
			return false;
		}

		setUserData(data);
		const fetchedDepartamentos = data.departamentos || {};
		const userAreas = data.areas
		setSelectedAreas(userAreas);

		// Generar estructura de departamentos con valores
		const departamentosConValores =
			updateUserHandlers.generateDepartamentosConValores(
				areaOptions,
				fetchedDepartamentos
			);
		setDepartamentos(departamentosConValores);

		setError('');
		return true;
	},

	/**
	 * Obtiene datos de usuario mediante una solicitud HTTP
	 * @param {string} userId - ID del usuario a obtener
	 * @param {string} userCompany - Empresa del usuario actual
	 * @param {string} language - Idioma (EN/ES)
	 * @param {Function} setError - Función para establecer errores
	 * @param {Function} setUserData - Función para establecer datos del usuario
	 * @param {Function} setSelectedAreas - Función para establecer áreas seleccionadas
	 * @param {Function} setDepartamentos - Función para establecer departamentos
	 * @param {Object} areaOptions - Opciones de áreas disponibles
	 * @param {Object} labels - Etiquetas para mensajes
	 */
	handleFetchUser: async (
		userId,
		userCompany,
		language,
		setError,
		setUserData,
		setSelectedAreas,
		setDepartamentos,
		areaOptions,
		labels
	) => {
		// Validar parámetros
		if (
			!updateUserHandlers.validateUserParams(
				userId,
				userCompany,
				labels,
				setError
			)
		) {
			return;
		}

		try {
			const response = await fetchWithAuth(`${ROUTE_USERS}/${userId}`, {
				method: 'GET',
			});
			const data = await response.json();

			if (response.ok) {
				// Procesar datos del usuario
				updateUserHandlers.processUserData(
					data,
					userCompany,
					areaOptions,
					setUserData,
					setSelectedAreas,
					setDepartamentos,
					setError,
					labels
				);
			} else {
				setError(labels.errorMessage);
			}
		} catch (error) {
			debugError('Error al obtener usuario:', error);
			setError(labels.errorMessage);
		}
	},

	/**
	 * Filtra y limpia los departamentos que tienen algún permiso
	 * @param {Array} selectedAreas - Áreas seleccionadas
	 * @param {Object} departamentos - Objeto con todos los departamentos
	 * @returns {Object} - Departamentos filtrados con al menos un permiso
	 */
	filterDepartamentosConPermisos: (selectedAreas, departamentos) => {
		return selectedAreas.reduce((acc, area) => {
			const filteredDepartments = Object.entries(
				departamentos[area] || {}
			).reduce((deptAcc, [dept, permisos]) => {
				if (permisos.ver || permisos.editar || permisos.enviar) {
					deptAcc[dept] = permisos;
				}
				return deptAcc;
			}, {});
			return { ...acc, ...filteredDepartments };
		}, {});
	},

	/**
	 * Muestra un mensaje de alerta según el idioma
	 * @param {string} language - Idioma (EN/ES)
	 * @param {string} messageKey - Clave del mensaje (success/error/genericError)
	 * @param {string} customError - Mensaje de error personalizado (opcional)
	 */
	showAlertMessage: (language, messageKey, customError = null) => {
		let message;

		switch (messageKey) {
			case 'success':
				message =
					language === 'EN'
						? 'User updated successfully'
						: 'Usuario actualizado con éxito';
				break;
			case 'error':
				message =
					customError ||
					(language === 'EN'
						? 'Error updating user'
						: 'Error al actualizar el usuario');
				break;
			case 'genericError':
				message =
					language === 'EN'
						? 'There was an error, please try again.'
						: 'Hubo un error, por favor intenta nuevamente.';
				break;
			default:
				message = customError || 'Error';
		}

		alert(message);
	},

	/**
	 * Intenta extraer un mensaje de error de la respuesta
	 * @param {Response} response - Respuesta del servidor
	 * @returns {Promise<string>} - Mensaje de error o null
	 */
	async extractErrorMessage(response) {
		try {
			const errorData = await response.json();
			return errorData.error;
		} catch (e) {
			// Si no se puede parsear como JSON, usar la respuesta como texto
			return await response.text();
		}
	},

	/**
	 * Actualiza un usuario mediante una solicitud HTTP
	 * @param {string} userId - ID del usuario a actualizar
	 * @param {Object} userData - Datos del usuario
	 * @param {Array} selectedAreas - Áreas seleccionadas
	 * @param {Object} departamentos - Objeto con departamentos y permisos
	 * @param {string} language - Idioma (EN/ES)
	 * @param {Object} labels - Etiquetas para mensajes
	 * @returns {Promise<boolean>} - true si la actualización fue exitosa
	 */
	handleUpdateUser: async (
		userId,
		userData,
		selectedAreas,
		departamentos,
		language,
		labels
	) => {
		// Filtrar departamentos con permisos
		const departamentosLimpiados =
			updateUserHandlers.filterDepartamentosConPermisos(
				selectedAreas,
				departamentos
			);

		// Crear objeto de usuario actualizado
		const updatedUser = {
			...userData,
			areas: selectedAreas,
			departamentos: departamentosLimpiados,
		};

		try {
			const response = await fetchWithAuth(`${ROUTE_USERS}/${userId}`, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(updatedUser),
			});

			if (response.ok) {
				updateUserHandlers.showAlertMessage(language, 'success');
				return true;
			} else {
				// Extraer mensaje de error
				const errorMessage = await updateUserHandlers.extractErrorMessage(
					response
				);
				debugError('Error en la respuesta:', errorMessage);

				// Mostrar alerta de error
				updateUserHandlers.showAlertMessage(language, 'error', errorMessage);
				return false;
			}
		} catch (error) {
			debugError('Error al actualizar usuario:', error);
			updateUserHandlers.showAlertMessage(language, 'genericError');
			return false;
		}
	},
};
