// frontend/src/hooks/user/useUserId.js
import { useState, useEffect } from 'react';
import { ROUTE_AUTH_ME_ID } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useUserId() {
	const [userId, setUserId] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchUserId = async () => {
			try {
				const response = await fetchWithAuth(ROUTE_AUTH_ME_ID, {
					method: 'GET',
				});

				if (response.ok) {
					const data = await response.json();
					debug('ID obtenido en Hook useUserId:', data.id); // Mostrar el ID obtenido
					setUserId(data.id); // Almacenar el ID en el estado
				} else {
					debugError('Error al obtener el id del usuario');
					setError('Error al obtener el id del usuario');
				}
			} catch (error) {
				debugError('Error en la solicitud de id:', error);
				setError('Error en la solicitud de id');
			}
		};

		fetchUserId();
	}, []);

	return { userId, error };
}

export default useUserId;
