import React from 'react';
import useI18n from '../hooks/i18n/useI18n';
import useUserPermissions from '../hooks/user/useUserPermissions';
import useUserArea from '../hooks/user/useUserArea';
import { getAvailableSidebarOptions } from '../utils/permissionsUtils';
import { sidebarStyles as styles } from '../styles/sidebarStyles';

function Sidebar({ onFilterChange, selectedOption }) {
	const { t, languageError } = useI18n();
	const { permissions } = useUserPermissions();
	const { area } = useUserArea();

	// Obtener solo las opciones que este usuario debería ver según sus permisos
	const availableSidebarOptions = getAvailableSidebarOptions(permissions, area);

	// Mapeo de valores a claves para la traducción
	const optionKeyMap = {
		Pendiente: 'openCases',
		Completada: 'completedCases',
		Acabada: 'finishedCases',
		Revision: 'revisionCases',
		Redirigida: 'redirectedQuestions',
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div className={styles.errorContainer}>
				<p className={styles.errorText}>{languageError}</p>
			</div>
		);
	}

	// Si no hay permisos o no hay opciones disponibles, mostrar mensaje
	if (!permissions || availableSidebarOptions.length === 0) {
		return (
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<h2 className={styles.title}>{t('sidebar.title')}</h2>
					<p className={styles.noPermissionsText}>
						{t('sidebar.noPermissions', 'No tiene permisos para ver casos')}
					</p>
				</div>
			</div>
		);
	}

	return (
		<aside className={styles.container}>
			<div className={styles.wrapper}>
				{/* Título del Sidebar */}
				<h2 className={styles.title}>{t('sidebar.title')}</h2>

				{/* Lista de opciones */}
				<nav className={styles.nav}>
					{availableSidebarOptions.map((option) => (
						<button
							key={option}
							onClick={() => onFilterChange(option)}
							className={styles.button(selectedOption === option)}>
							{/* Indicador de selección */}
							<div className={styles.indicator(selectedOption === option)} />
							<span className={styles.buttonText}>
								{t(`sidebar.${optionKeyMap[option] || option}`)}
							</span>
						</button>
					))}
				</nav>
			</div>
		</aside>
	);
}

export default Sidebar;
