import React from 'react';
import { questionStyles } from '../../styles/questionStyles';
import { t } from 'i18next';

const GYearQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const handleChange = (e) => {
		const value = e.target.value;

		// Solo permitimos hasta 4 dígitos numéricos
		if (value === '' || /^\d{0,4}$/.test(value)) {
			handleEditorChange(pregunta.id, formulario.FormularioId, value);
		}
	};

	// Determinar si el valor actual es válido (exactamente 4 dígitos)
	const respuestaActual =
		respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || '';
	const esValido = /^\d{4}$/.test(respuestaActual);

	const inputClasses = `
		${questionStyles.gYear.input}
		${
			pregunta.required
				? questionStyles.gYear.required
				: questionStyles.gYear.optional
		}
		${questionStyles.gYear.text}
		${!canEdit || isRedirigida ? questionStyles.gYear.disabled : ''}
	`;

	return (
		<div className={questionStyles.gYear.container}>
			<input
				type='text'
				value={respuestaActual}
				onChange={handleChange}
				className={inputClasses}
				placeholder={t('gYearQuestionPlaceholder')}
				readOnly={!canEdit || isRedirigida}
				maxLength={4}
			/>
			{canEdit && !isRedirigida && !esValido && respuestaActual.length > 0 && (
				<p className={questionStyles.gYear.errorMessage}>
					Debe ingresar exactamente 4 dígitos numéricos
				</p>
			)}
		</div>
	);
};

export default GYearQuestion;
