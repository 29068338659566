import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavBar } from '../NavBarContext';
import useUserArea from '../hooks/user/useUserArea';
import useUserPermissions from '../hooks/user/useUserPermissions';
import useI18n from '../hooks/i18n/useI18n';
import { pathToOption, optionToPath, areaOptions } from '../config/routeConfig';
import { navBarStyles as styles } from '../styles/navBarStyles';
import {
	handleLogout,
	getHasESGPermissions,
	getFilteredOptions,
} from '../utils/navBarHelpers';

function NavBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const { selectedOption, setSelectedOption } = useNavBar();
	const { area } = useUserArea();
	const { permissions } = useUserPermissions();
	const [showMenu, setShowMenu] = useState(false);
	const { t, language, languageError } = useI18n();

	useEffect(() => {
		const activeOption = pathToOption[location.pathname];
		if (activeOption) setSelectedOption(activeOption);
	}, [location.pathname, setSelectedOption]);

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		const path = optionToPath[option];
		if (path) navigate(path);
	};

	const hasESGPermissions = getHasESGPermissions(area, permissions);
	const filteredOptions = getFilteredOptions(
		area,
		hasESGPermissions,
		areaOptions
	);

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	return (
		<nav className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.mainDiv}>
					{/* Logo */}
					<div className={styles.logoContainer}>
						<img
							src='/logo-app.png'
							alt='MineESG Logo'
							className={styles.logo}
						/>
					</div>

					{/* Navegación Central */}
					<div className={styles.navContainer}>
						<div className={styles.buttonContainer}>
							{filteredOptions.map((option) => (
								<button
									key={option}
									onClick={() => handleOptionClick(option)}
									className={styles.navButton(selectedOption === option)}>
									{t(`navBar.${option}`)}
								</button>
							))}
						</div>
					</div>

					{/* Perfil */}
					<div className={styles.profileContainer}>
						<div className='relative'>
							<button
								onClick={() => setShowMenu(!showMenu)}
								className={styles.profileButton}>
								<img
									src='/account_circle.svg'
									alt={t('navBar.profile')}
									className={styles.profileImage}
								/>
								<span className={styles.profileName}>
									{t('navBar.profile')}
								</span>
							</button>

							{showMenu && (
								<div className={styles.dropdownMenu}>
									<button
										onClick={() => handleLogout(setSelectedOption, navigate)}
										className={styles.logoutButton}>
										<span className='flex-1'>{t('navBar.logout')}</span>
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}

export default NavBar;
