// frontend/src/utils/permissionsUtils.js
import { debug, debugWarn, debugError } from './debugHelper';

/**
 * Comprueba si el usuario tiene algún permiso
 * @param {Object} permissions - Objeto de permisos por departamento
 * @returns {boolean} - True si tiene algún permiso
 */
export const hasAnyPermission = (permissions) => {
	if (!permissions) {
		debug('PermissionsUtils: No hay objeto de permisos');
		return false;
	}

	const result = Object.values(permissions).some(
		(perm) => perm.ver || perm.editar || perm.enviar
	);

	debug(`PermissionsUtils: hasAnyPermission = ${result}`, {
		permissions,
	});
	return result;
};

/**
 * Comprueba si el usuario tiene permiso para ver en algún departamento
 * @param {Object} permissions - Objeto de permisos por departamento
 * @returns {boolean} - True si tiene permiso para ver
 */
export const hasViewPermission = (permissions) => {
	if (!permissions) {
		debug('PermissionsUtils: No hay objeto de permisos para verificar ver');
		return false;
	}

	const result = Object.values(permissions).some((perm) => perm.ver);
	debug(`PermissionsUtils: hasViewPermission = ${result}`);

	// Log detallado de permisos de ver por departamento
	if (result) {
		const deptsWithView = Object.entries(permissions)
			.filter(([, perm]) => perm.ver)
			.map(([dept]) => dept);
		debug('PermissionsUtils: Departamentos con permiso ver:', deptsWithView);
	}

	return result;
};

/**
 * Comprueba si el usuario tiene permiso para editar en algún departamento
 * @param {Object} permissions - Objeto de permisos por departamento
 * @returns {boolean} - True si tiene permiso para editar
 */
export const hasEditPermission = (permissions) => {
	if (!permissions) {
		debug('PermissionsUtils: No hay objeto de permisos para verificar editar');
		return false;
	}

	const result = Object.values(permissions).some((perm) => perm.editar);
	debug(`PermissionsUtils: hasEditPermission = ${result}`);

	// Log detallado de permisos de editar por departamento
	if (result) {
		const deptsWithEdit = Object.entries(permissions)
			.filter(([, perm]) => perm.editar)
			.map(([dept]) => dept);
		debug('PermissionsUtils: Departamentos con permiso editar:', deptsWithEdit);
	}

	return result;
};

/**
 * Comprueba si el usuario tiene permiso para enviar en algún departamento
 * @param {Object} permissions - Objeto de permisos por departamento
 * @returns {boolean} - True si tiene permiso para enviar
 */
export const hasSendPermission = (permissions) => {
	if (!permissions) {
		debug('PermissionsUtils: No hay objeto de permisos para verificar enviar');
		return false;
	}

	const result = Object.values(permissions).some((perm) => perm.enviar);
	debug(`PermissionsUtils: hasSendPermission = ${result}`);

	// Log detallado de permisos de enviar por departamento
	if (result) {
		const deptsWithSend = Object.entries(permissions)
			.filter(([, perm]) => perm.enviar)
			.map(([dept]) => dept);
		debug('PermissionsUtils: Departamentos con permiso enviar:', deptsWithSend);
	}

	return result;
};

/**
 * Comprueba si el usuario es de área ESG
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si el usuario es de ESG
 */
export const isUserESG = (area) => {
	debug('PermissionsUtils: Verificando si el usuario es de área ESG:', area);

	// Manejar caso donde area podría ser un string o un array
	if (typeof area === 'string') {
		const result = area === 'ESG';
		debug(`PermissionsUtils: isUserESG (string) = ${result}`);
		return result;
	}

	if (Array.isArray(area)) {
		const result = area.includes('ESG');
		debug(`PermissionsUtils: isUserESG (array) = ${result}`);
		return result;
	}

	debug('PermissionsUtils: área no válida para verificar ESG');
	return false;
};

/**
 * Comprueba si el usuario es ESG con permiso de enviar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si el usuario es ESG con permiso de enviar
 */
export const isESGWithSendPermission = (permissions, area) => {
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);
	const result = isESG && canSend;

	debug(`PermissionsUtils: Usuario ESG con permiso enviar = ${result}`);
	return result;
};

/**
 * Determina las opciones disponibles para la barra lateral
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {Array} - Opciones disponibles para la barra lateral
 */
export const getAvailableSidebarOptions = (permissions, area) => {
	debug('PermissionsUtils: Calculando opciones del sidebar:', {
		permissions,
		area,
	});

	if (!permissions) {
		debug(
			'PermissionsUtils: No hay permisos para calcular opciones del sidebar'
		);
		return [];
	}

	// Si es usuario ESG con permiso de enviar, mostrar todas las opciones
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend) {
		debug(
			'PermissionsUtils: Usuario ESG con permiso enviar tiene acceso a todas las opciones del sidebar'
		);
		return ['Pendiente', 'Revision', 'Completada', 'Acabada', 'Redirigida'];
	}

	const options = [];
	const canView = hasViewPermission(permissions);
	const canEdit = hasEditPermission(permissions);

	debug('PermissionsUtils: Permisos básicos para el sidebar:', {
		canView,
		canEdit,
		canSend,
	});

	// Si tiene permisos de editar o enviar puede ver casos pendientes y revisión
	if (canEdit || canSend) {
		options.push('Pendiente');
		options.push('Revision');
		debug(
			'PermissionsUtils: Añadiendo Pendiente y Revision al sidebar (canEdit || canSend)'
		);
	}

	// Todos los usuarios con permiso de ver pueden ver casos completados y acabados
	if (canView) {
		options.push('Completada');
		options.push('Acabada');
		debug(
			'PermissionsUtils: Añadiendo Completada y Acabada al sidebar (canView)'
		);
	}

	// Solo los usuarios con permisos de editar pueden ver preguntas redirigidas
	if (canEdit) {
		options.push('Redirigida');
		debug('PermissionsUtils: Añadiendo Redirigida al sidebar (canEdit)');
	}

	debug('PermissionsUtils: Opciones finales del sidebar:', options);
	return options;
};

/**
 * Comprueba si un usuario puede ver un caso específico según su departamento
 * @param {Object} caso - Caso a comprobar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si puede ver el caso
 */
export const canViewCase = (caso, permissions, area) => {
	if (!caso || !permissions) {
		debug('PermissionsUtils: canViewCase - Caso o permisos no disponibles');
		return false;
	}

	debug('PermissionsUtils: Verificando acceso para ver caso:', {
		casoId: caso.id,
		departamento: caso.departamento || caso.proceso?.departamento,
		estado: caso.estadoEmpresas,
	});

	// Si es usuario ESG con permiso de enviar, puede ver TODOS los casos de su empresa
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend) {
		debug(
			'PermissionsUtils: Usuario ESG con permiso enviar puede ver todos los casos'
		);
		return true; // Retornar true inmediatamente sin importar el departamento
	}

	const departamentoCaso = caso.departamento || caso.proceso?.departamento;
	const permisoDept = permissions[departamentoCaso] || {};

	debug('PermissionsUtils: Datos para verificar acceso:', {
		isESG,
		permisosDepartamento: permisoDept,
		estadoCaso: caso.estadoEmpresas,
	});

	// Usuario ESG puede ver casos completados o acabados
	if (
		isESG &&
		(caso.estadoEmpresas === 'Completada' || caso.estadoEmpresas === 'Acabada')
	) {
		debug('PermissionsUtils: Usuario ESG puede ver caso completado/acabado');
		return true;
	}

	// Usuario con permiso 'ver' puede ver casos completados o acabados
	if (
		permisoDept.ver &&
		(caso.estadoEmpresas === 'Completada' || caso.estadoEmpresas === 'Acabada')
	) {
		debug(
			'PermissionsUtils: Usuario con permiso ver puede ver caso completado/acabado'
		);
		return true;
	}

	// Usuario con permiso 'editar' puede ver todos los casos
	if (permisoDept.editar) {
		debug(
			'PermissionsUtils: Usuario con permiso editar puede ver todos los casos'
		);
		return true;
	}

	// Usuario con permiso 'enviar' puede ver casos pendientes y en revisión
	if (
		permisoDept.enviar &&
		(caso.estadoEmpresas === 'Pendiente' || caso.estadoEmpresas === 'Revision')
	) {
		debug(
			'PermissionsUtils: Usuario con permiso enviar puede ver caso pendiente/revisión'
		);
		return true;
	}

	debug('PermissionsUtils: Usuario NO puede ver este caso');
	return false;
};

/**
 * Comprueba si un usuario puede editar un caso específico
 * @param {Object} caso - Caso a comprobar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si puede editar el caso
 */
export const canEditCase = (caso, permissions, area) => {
	if (!caso || !permissions) {
		debug('PermissionsUtils: canEditCase - Caso o permisos no disponibles');
		return false;
	}

	debug('PermissionsUtils: Verificando si puede editar caso:', {
		casoId: caso.id,
		departamento: caso.departamento || caso.proceso?.departamento,
		estado: caso.estadoEmpresas,
	});

	// Si es usuario ESG con permiso de enviar, puede editar cualquier caso no acabado
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend && caso.estadoEmpresas !== 'Acabada') {
		debug(
			'PermissionsUtils: Usuario ESG con permiso enviar puede editar caso no acabado'
		);
		return true;
	}

	const departamentoCaso = caso.departamento || caso.proceso?.departamento;
	const permisoDept = permissions[departamentoCaso] || {};

	// Solo se puede editar si tiene permiso de editar y el caso no está acabado
	const canEdit = permisoDept.editar && caso.estadoEmpresas !== 'Acabada';
	debug(`PermissionsUtils: canEditCase = ${canEdit}`, {
		tienePermisoEditar: permisoDept.editar,
		noEstaAcabado: caso.estadoEmpresas !== 'Acabada',
	});

	return canEdit;
};

/**
 * Comprueba si un usuario puede enviar un caso específico
 * @param {Object} caso - Caso a comprobar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si puede enviar el caso
 */
export const canSendCase = (caso, permissions, area) => {
	if (!caso || !permissions) {
		debug('PermissionsUtils: canSendCase - Caso o permisos no disponibles');
		return false;
	}

	debug('PermissionsUtils: Verificando si puede enviar caso:', {
		casoId: caso.id,
		departamento: caso.departamento || caso.proceso?.departamento,
		estado: caso.estadoEmpresas,
	});

	// Si es usuario ESG con permiso de enviar, puede enviar cualquier caso no acabado
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend && caso.estadoEmpresas !== 'Acabada') {
		debug(
			'PermissionsUtils: Usuario ESG con permiso enviar puede enviar caso no acabado'
		);
		return true;
	}

	const departamentoCaso = caso.departamento || caso.proceso?.departamento;
	const permisoDept = permissions[departamentoCaso] || {};

	// Solo se puede enviar si tiene permiso de enviar y el caso no está acabado
	const canSendResult = permisoDept.enviar && caso.estadoEmpresas !== 'Acabada';
	debug(`PermissionsUtils: canSendCase = ${canSendResult}`, {
		tienePermisoEnviar: permisoDept.enviar,
		noEstaAcabado: caso.estadoEmpresas !== 'Acabada',
	});

	return canSendResult;
};

/**
 * Determina las acciones disponibles para un caso según el estado seleccionado
 * @param {Object} caso - Caso a comprobar
 * @param {string} selectedOption - Opción seleccionada en la barra lateral
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {Array} - Acciones disponibles para el caso
 */
export const getAvailableActions = (
	caso,
	selectedOption,
	permissions,
	area
) => {
	if (!caso || !permissions) {
		debug(
			'PermissionsUtils: getAvailableActions - Caso o permisos no disponibles'
		);
		return [];
	}

	debug('PermissionsUtils: Calculando acciones disponibles:', {
		casoId: caso.id,
		departamento: caso.departamento || caso.proceso?.departamento,
		estado: caso.estadoEmpresas,
		selectedOption,
	});

	const departamentoCaso = caso.departamento || caso.proceso?.departamento;
	const permisoDept = permissions[departamentoCaso] || {};
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);
	const isESGWithSend = isESG && canSend;
	const actions = [];

	debug('PermissionsUtils: Datos para calcular acciones:', {
		isESG,
		isESGWithSend,
		permisosDepartamento: permisoDept,
	});

	// Usuario ESG con permiso de enviar puede realizar todas las acciones en cualquier caso
	if (isESGWithSend) {
		switch (selectedOption) {
			case 'Pendiente':
			case 'Revision':
				actions.push('editar');
				break;
			case 'Completada':
				actions.push('confirmar');
				break;
			case 'Acabada':
				actions.push('visualizar');
				actions.push('exportarPDF_ES');
				actions.push('exportarPDF_EN');
				actions.push('exportarWord_ES');
				actions.push('exportarWord_EN');
				break;
			default:
				actions.push('visualizar');
		}

		debug('PermissionsUtils: Acciones para usuario ESG+enviar:', actions);
		return actions;
	}

	// Usuario con solo permiso de ver solo puede visualizar
	if (permisoDept.ver && !permisoDept.editar && !permisoDept.enviar) {
		debug(
			'PermissionsUtils: Usuario solo con permiso ver - solo puede visualizar'
		);
		actions.push('visualizar');
		return actions;
	}

	// Lógica para cada estado
	switch (selectedOption) {
		case 'Pendiente':
			if (caso.estadoEmpresas !== 'Acabada' && permisoDept.editar) {
				debug('PermissionsUtils: Añadiendo acción editar para caso pendiente');
				actions.push('editar');
			} else {
				debug(
					'PermissionsUtils: Añadiendo acción visualizar para caso pendiente'
				);
				actions.push('visualizar');
			}
			break;

		case 'Revision':
			if (permisoDept.editar) {
				debug(
					'PermissionsUtils: Añadiendo acción editar para caso en revisión'
				);
				actions.push('editar');
			} else {
				debug(
					'PermissionsUtils: Añadiendo acción visualizar para caso en revisión'
				);
				actions.push('visualizar');
			}
			break;

		case 'Completada':
			if (isESG && permisoDept.editar) {
				debug(
					'PermissionsUtils: Añadiendo acción confirmar para caso completado (usuario ESG)'
				);
				actions.push('confirmar');
			} else {
				debug(
					'PermissionsUtils: Añadiendo acción visualizar para caso completado'
				);
				actions.push('visualizar');
			}
			break;

		case 'Acabada':
			debug('PermissionsUtils: Añadiendo acción visualizar para caso acabado');
			actions.push('visualizar');
			if (isESG && permisoDept.editar) {
				debug(
					'PermissionsUtils: Añadiendo acciones de exportación para caso acabado (usuario ESG)'
				);
				actions.push('exportarPDF_ES');
				actions.push('exportarPDF_EN');
				actions.push('exportarWord_ES');
				actions.push('exportarWord_EN');
			}
			break;

		default:
			debug(
				`PermissionsUtils: Opción ${selectedOption} no reconocida, añadiendo acción visualizar por defecto`
			);
			actions.push('visualizar');
	}

	debug('PermissionsUtils: Acciones finales disponibles:', actions);
	return actions;
};

/**
 * Filtra los casos según los permisos del usuario
 * @param {Array} casos - Lista de casos a filtrar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @param {string} userCompany - Empresa del usuario
 * @returns {Array} - Casos filtrados según permisos
 */
export const filterCasosByPermissions = (
	casos,
	permissions,
	area,
	userCompany
) => {
	if (!casos || !permissions) {
		debug(
			'PermissionsUtils: filterCasosByPermissions - Casos o permisos no disponibles'
		);
		return [];
	}

	debug('PermissionsUtils: Filtrando casos por permisos:', {
		totalCasos: casos.length,
		empresa: userCompany,
	});

	// Determinar si el usuario es ESG con permiso de enviar
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);
	const isESGWithSend = isESG && canSend;

	debug('PermissionsUtils: Usuario ESG con permiso enviar:', isESGWithSend);

	// Si es usuario ESG con permiso de enviar, solo filtramos por empresa
	if (isESGWithSend) {
		const casosFiltrados = casos.filter((caso) => {
			// Filtrar solo por empresa si es necesario
			return !userCompany || caso.nombreEmpresa === userCompany;
		});

		debug(
			`PermissionsUtils: Usuario ESG + Enviar - Mostrando ${casosFiltrados.length} casos (todos los departamentos)`
		);
		return casosFiltrados;
	}

	// Determinar si el usuario solo tiene permisos de visualización
	const isViewOnly = isViewOnlyUser(permissions);
	debug(`PermissionsUtils: Usuario es solo visualización = ${isViewOnly}`);

	// Obtener la lista de departamentos con permiso de ver
	const departamentosConPermiso = Object.entries(permissions)
		.filter(([dept, perm]) => perm.ver)
		.map(([dept]) => dept);

	debug(
		'PermissionsUtils: Departamentos con permiso de ver:',
		departamentosConPermiso
	);

	const casosFiltrados = casos.filter((caso) => {
		// Verificar que es de la misma empresa
		if (caso.nombreEmpresa !== userCompany) {
			return false;
		}

		// Verificar que el departamento del caso está en la lista de departamentos permitidos
		const departamentoCaso = caso.departamento || caso.proceso?.departamento;
		const tienePermisoEnDepartamento =
			departamentoCaso && departamentosConPermiso.includes(departamentoCaso);

		if (!tienePermisoEnDepartamento) {
			debug(
				`PermissionsUtils: Caso ${caso.id} filtrado por departamento no permitido: ${departamentoCaso}`
			);
			return false;
		}

		// Si es un usuario de solo visualización, solo mostrar casos Completados o Acabados
		if (isViewOnly) {
			const estadoPermitido =
				caso.estadoEmpresas === 'Completada' ||
				caso.estadoEmpresas === 'Acabada';
			if (!estadoPermitido) {
				debug(
					`PermissionsUtils: Caso ${caso.id} filtrado por estado no permitido: ${caso.estadoEmpresas}`
				);
				return false;
			}
			return true;
		}

		// Para otros usuarios, verificar según su tipo de permiso
		return canViewCase(caso, permissions, area);
	});

	debug(
		`PermissionsUtils: Casos filtrados: ${casosFiltrados.length} de ${casos.length}`
	);

	// Log detallado de los casos filtrados
	if (casosFiltrados.length > 0) {
		debug(
			'PermissionsUtils: Resumen de casos filtrados:',
			casosFiltrados.map((c) => ({
				id: c.id,
				departamento: c.departamento || c.proceso?.departamento,
				estado: c.estadoEmpresas,
			}))
		);
	}

	return casosFiltrados;
};

/**
 * Verifica si un usuario tiene acceso a una opción específica del sidebar
 * @param {string} option - Opción a verificar ('Pendiente', 'Completada', etc.)
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si tiene acceso a la opción
 */
export const hasAccessToSidebarOption = (option, permissions, area) => {
	if (!permissions) {
		debug(
			`PermissionsUtils: hasAccessToSidebarOption - No hay permisos para verificar acceso a ${option}`
		);
		return false;
	}

	debug(`PermissionsUtils: Verificando acceso a opción ${option}`, {
		permissions,
		area,
	});

	// Si es usuario ESG con permiso de enviar, darle acceso a todas las opciones
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend) {
		debug(
			`PermissionsUtils: Usuario ESG con permiso enviar tiene acceso a ${option}`
		);
		return true;
	}

	const options = getAvailableSidebarOptions(permissions, area);
	const hasAccess = options.includes(option);

	debug(`PermissionsUtils: Acceso a ${option} = ${hasAccess}`);
	return hasAccess;
};

/**
 * Determina la primera opción disponible del sidebar para redireccionar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {string|null} - Primera opción disponible o null si no hay opciones
 */
export const getFirstAvailableSidebarOption = (permissions, area) => {
	const options = getAvailableSidebarOptions(permissions, area);
	const firstOption = options.length > 0 ? options[0] : null;

	debug(
		`PermissionsUtils: Primera opción disponible del sidebar: ${firstOption}`
	);
	return firstOption;
};

/**
 * Determina si un usuario tiene permisos suficientes para una sección específica
 * @param {string} section - Sección a verificar ('cases', 'admin', etc.)
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {boolean} - True si tiene acceso a la sección
 */
export const hasAccessToSection = (section, permissions, area) => {
	if (!permissions) {
		debug(
			`PermissionsUtils: hasAccessToSection - No hay permisos para verificar acceso a ${section}`
		);
		return false;
	}

	debug(`PermissionsUtils: Verificando acceso a sección ${section}`, {
		permissions,
		area,
	});

	// Si es usuario ESG con permiso de enviar, darle acceso a todas las secciones
	const isESG = isUserESG(area);
	const canSend = hasSendPermission(permissions);

	if (isESG && canSend) {
		debug(
			`PermissionsUtils: Usuario ESG con permiso enviar tiene acceso a la sección ${section}`
		);
		return true;
	}

	const canEdit = hasEditPermission(permissions);
	const canView = hasViewPermission(permissions);

	let hasAccess = false;

	switch (section) {
		case 'casos':
			// Todos los usuarios con algún permiso pueden acceder a casos
			hasAccess = hasAnyPermission(permissions);
			break;

		case 'admin':
			// Solo usuarios ESG pueden acceder a admin
			hasAccess = isESG;
			break;

		case 'processes':
			// Solo usuarios ESG con permiso de editar pueden acceder a procesos
			hasAccess = isESG && canEdit;
			break;

		case 'reports':
			// Usuarios con permiso de ver pueden acceder a reportes
			hasAccess = canView;
			break;

		default:
			debug(`PermissionsUtils: Sección ${section} no reconocida`);
			hasAccess = false;
	}

	debug(`PermissionsUtils: Acceso a sección ${section} = ${hasAccess}`);
	return hasAccess;
};

/**
 * Determina los botones a mostrar para un caso específico
 * @param {Object} caso - Caso a evaluar
 * @param {string} selectedOption - Opción seleccionada del sidebar
 * @param {Object} permissions - Objeto de permisos por departamento
 * @param {Array|string} area - Array de áreas o string de área del usuario
 * @returns {Object} - Objeto con propiedades booleanas para cada botón
 */
export const getButtonsToShow = (caso, selectedOption, permissions, area) => {
	if (!caso || !permissions) {
		debug(
			'PermissionsUtils: getButtonsToShow - Caso o permisos no disponibles'
		);
		return {
			showView: false,
			showEdit: false,
			showConfirm: false,
			showExport: false,
		};
	}

	debug('PermissionsUtils: Determinando botones a mostrar:', {
		casoId: caso.id,
		estado: caso.estadoEmpresas,
		selectedOption,
	});

	const actions = getAvailableActions(caso, selectedOption, permissions, area);

	const buttons = {
		showView: actions.includes('visualizar'),
		showEdit: actions.includes('editar'),
		showConfirm: actions.includes('confirmar'),
		showExport: actions.some((a) => a.startsWith('exportar')),
	};

	debug('PermissionsUtils: Botones a mostrar:', buttons);
	return buttons;
};

/**
 * Verifica si el usuario actual solo tiene permisos de visualización
 * @param {Object} permissions - Objeto de permisos por departamento
 * @returns {boolean} - True si el usuario solo tiene permisos de visualización
 */
export const isViewOnlyUser = (permissions) => {
	if (!permissions) {
		debug('PermissionsUtils: isViewOnlyUser - No hay permisos disponibles');
		return false;
	}

	const canView = hasViewPermission(permissions);
	const canEdit = hasEditPermission(permissions);
	const canSend = hasSendPermission(permissions);

	const isViewOnly = canView && !canEdit && !canSend;
	debug(`PermissionsUtils: Usuario es solo visualización = ${isViewOnly}`, {
		canView,
		canEdit,
		canSend,
	});

	return isViewOnly;
};
