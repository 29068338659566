import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useUserId from '../hooks/user/useUserId';
import useI18n from '../hooks/i18n/useI18n';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EDITOR_MODULES, EDITOR_FORMATS } from '../config/editorConfig';
import { vistaPreguntaStyles as styles } from '../styles/vistaPreguntaStyles';
import { ROUTE_FORMS, ROUTE_RESPONSES } from '../config/routeConfig';
import { debug, debugError } from '../utils/debugHelper';
import { fetchWithAuth } from '../utils/fetchUtils';

function VistaPregunta() {
	const { empresaId, procesoId, preguntaId } = useParams();
	const { search } = useLocation();
	const navigate = useNavigate();
	const { userId } = useUserId();
	const { t, language, languageError } = useI18n();
	const [pregunta, setPregunta] = useState(null);
	const [respuesta, setRespuesta] = useState('');
	const [imagen, setImagen] = useState(null);
	const [respuestaId, setRespuestaId] = useState(null);
	const [error, setError] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);
	const [isRequired, setIsRequired] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(search);
		const idFromURL = params.get('respuestaId');
		setRespuestaId(idFromURL);
	}, [search]);

	useEffect(() => {
		setError(null);
		fetchPregunta();
		fetchExistingResponse();
	}, [preguntaId, respuestaId]);

	const fetchPregunta = async () => {
		try {
			const response = await fetchWithAuth(
				`${ROUTE_FORMS}/${procesoId}/preguntas/${preguntaId}`
			);
			if (!response.ok) throw new Error('Error al obtener la pregunta');
			const data = await response.json();
			setPregunta(data);
			setIsRequired(data.required || false);
		} catch (error) {
			debugError(error);
		}
	};

	const fetchExistingResponse = async () => {
		try {
			if (!respuestaId) {
				throw new Error('No se encontró una respuesta previa para actualizar.');
			}

			const response = await fetchWithAuth(`${ROUTE_RESPONSES}/${respuestaId}`);
			if (response.ok) {
				const data = await response.json();
				debug('Respuesta redirigida encontrada:', data);
				setRespuesta(data.respuesta?.text || '');
				setImagen(data.respuesta?.image || '');
			} else {
				throw new Error('Error al obtener la respuesta redirigida existente.');
			}
		} catch (error) {
			debugError('Error al buscar respuesta existente:', error);
		}
	};

	const handleSaveResponse = async () => {
		try {
			if (!respuestaId) {
				debugError(
					'Error: No se encontró una respuesta previa para actualizar.'
				);
				return;
			}

			const respuestaObj = {
				preguntasid: preguntaId,
				empresasid: parseInt(empresaId),
				procesosid: parseInt(procesoId),
				respuesta: { text: respuesta, image: imagen || '' },
				estadopregunta: 'Contestada',
				respondidoporuserid: userId,
				contestadafecha: new Date().toISOString(),
			};

			debug('Datos enviados a la base de datos:', respuestaObj);

			const url = `${ROUTE_RESPONSES}/${respuestaId}`;

			const response = await fetchWithAuth(url, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(respuestaObj),
			});

			if (!response.ok)
				throw new Error(t('vistaPregunta.errorGuardarRespuesta'));

			setSuccessMessage(t('vistaPregunta.preguntaContestadaExito'));

			setTimeout(() => {
				navigate('/casos?tab=preguntasRedirigidas');
			}, 2000);
		} catch (error) {
			setError(t('vistaPregunta.errorGuardarRespuesta'));
			debugError(error);
		}
	};

	const handleImageUpload = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			setImagen(e.target.result);
		};
		reader.readAsDataURL(file);
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.card}>
				<h2 className={styles.questionTitle}>
					{t('vistaPregunta.responderPregunta')}
				</h2>

				{pregunta && (
					<div className={styles.questionText}>
						<strong>{t('vistaPregunta.pregunta')}: </strong>
						<a
							href={pregunta.link}
							target='_blank'
							rel='noopener noreferrer'
							className={styles.questionLink}>
							{pregunta.text[language] || t('vistaPregunta.textoNoDisponible')}
						</a>
						{isRequired && <span className={styles.requiredTitle}>*</span>}
					</div>
				)}

				{error && <div className={styles.statusMessage.error}>{error}</div>}
				{successMessage && (
					<div className={styles.statusMessage.success}>{successMessage}</div>
				)}

				<div
					className={`${styles.editorContainer} ${
						isRequired ? styles.editorRequired : styles.editorOptional
					}`}>
					<ReactQuill
						value={respuesta}
						onChange={setRespuesta}
						modules={EDITOR_MODULES}
						formats={EDITOR_FORMATS}
						style={{ height: '250px' }}
					/>
				</div>

				<div className={styles.fileInputContainer}>
					<input
						type='file'
						accept='image/*'
						onChange={(e) => handleImageUpload(e.target.files[0])}
						className={styles.fileInput}
					/>
					<button
						onClick={handleSaveResponse}
						className={styles.button}>
						{t('vistaPregunta.guardarRespuesta')}
					</button>
				</div>
			</div>
		</div>
	);
}

export default VistaPregunta;
