//frontend/src/hooks/questions/useQuestionType/useDefaultQuestion.js
export const useDefaultQuestion = (quillRefs, handleQuillRef) => {
	const setupQuillRef = (ref, preguntaId) => {
		if (ref && !quillRefs[preguntaId]) {
			handleQuillRef(preguntaId, ref);
		}
	};

	return { setupQuillRef };
};
