// frontend/src/hooks/user/useUserPermissions.js
import { useState, useEffect } from 'react';
import { ROUTE_AUTH_ME_PERMISSIONS } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useUserPermissions() {
	const [permissions, setPermissions] = useState(null);
	const [loading, setLoading] = useState(true); // Añadido estado de carga
	const [error, setError] = useState(null);

	const fetchPermissions = async () => {
		setLoading(true);
		try {
			const response = await fetchWithAuth(ROUTE_AUTH_ME_PERMISSIONS, {
				method: 'GET',
			});

			if (response.ok) {
				const data = await response.json();
				setPermissions(data.permissions);

				// Agrega el debug aquí para ver los permisos obtenidos
				debug('Permisos obtenidos en useUserPermissions:', data.permissions);
			} else {
				debugError('Error al obtener permisos del usuario');
				setError('Error al obtener permisos del usuario');
				setPermissions({}); // Establecer un objeto vacío en lugar de null
			}
		} catch (error) {
			debugError('Error en la solicitud de permisos:', error);
			setError('Error en la solicitud de permisos');
			setPermissions({}); // Establecer un objeto vacío en lugar de null
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchPermissions();
	}, []);

	return { permissions, error, loading, refetch: fetchPermissions };
}

export default useUserPermissions;
