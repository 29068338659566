// frontend/src/App.js
import React, { useState, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from 'react-router-dom';
import Login from './views/Login/Login';
import DashboardSystem from './views/System/DashboardSystem';
import CreateUser from './views/System/CreateUser';
import UpdateUser from './views/System/UpdateUser';
import DeleteUser from './views/System/DeleteUser';
import Casos from './views/Responsables/Casos';
import ListadeProcesos from './views/Sostenibilidad/ListadeProcesos';
import ExtraccionDashboard from './views/Extraccion/ExtraccionDashboard';
import NavBar from './componentes/NavBar';
import { NavBarProvider } from './NavBarContext';
import VistaFormulario from './views/Responsables/VistaFormulario';
import VistaPregunta from './componentes/VistaPregunta';
import VistaProcesoAcabado from './componentes/VistaProcesoAcabado';
import useUserArea from './hooks/user/useUserArea';
import ProtectedRoute from './componentes/ProtectedRoute';
import './App.css';
import { ROUTE_AUTH_LOGOUT, ROUTE_AUTH_ME_ID } from './config/routeConfig';
import { debug, debugError } from './utils/debugHelper';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import useI18n from './hooks/i18n/useI18n';
import { fetchWithAuth } from './utils/fetchUtils';

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [authChecked, setAuthChecked] = useState(false);
	const { area } = useUserArea();
	const { t } = useI18n();

	useEffect(() => {
		const checkAuth = async () => {
			try {
				// Cambiar fetch por fetchWithAuth
				const response = await fetchWithAuth(ROUTE_AUTH_ME_ID);
				setIsLoggedIn(response.ok);
				debug('Autenticación:', response.ok);
			} catch (error) {
				debugError('Error verificando autenticación:', error);
				setIsLoggedIn(false);
			} finally {
				setAuthChecked(true);
			}
		};
		checkAuth();
	}, []);

	const handleLogout = async () => {
		try {
			await fetchWithAuth(ROUTE_AUTH_LOGOUT, {
				method: 'POST',
			});
			setIsLoggedIn(false);
		} catch (error) {
			debugError('Error al cerrar sesión:', error);
		}
	};

	// Función para determinar la redirección basada en el área del usuario
	const getAreaBasedRedirect = () => {
		if (area && area.includes('Extraccion')) {
			return <Navigate to='/extraccion' />;
		}
		return <Navigate to='/casos' />;
	};

	// Función para manejar la redirección según el estado de autenticación y área
	const getAuthBasedRedirect = () => {
		if (!isLoggedIn) {
			return <Navigate to='/' />;
		}
		return getAreaBasedRedirect();
	};

	if (!authChecked) {
		return <p>{t('common.verifyAuth')}</p>;
	}

	return (
		<I18nextProvider i18n={i18n}>
			<Router>
				<NavBarProvider>
					<div className='App'>
						{isLoggedIn && <NavBar onLogout={handleLogout} />}
						<main>
							<Routes>
								<Route
									path='/'
									element={
										isLoggedIn ? (
											getAreaBasedRedirect()
										) : (
											<Login onLogin={() => setIsLoggedIn(true)} />
										)
									}
								/>

								<Route
									path='/system-dashboard'
									element={
										<ProtectedRoute
											element={<DashboardSystem />}
											allowedAreas={['System']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/create-user'
									element={
										<ProtectedRoute
											element={<CreateUser />}
											allowedAreas={['System']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/update-user'
									element={
										<ProtectedRoute
											element={<UpdateUser />}
											allowedAreas={['System']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/delete-user'
									element={
										<ProtectedRoute
											element={<DeleteUser />}
											allowedAreas={['System']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/casos'
									element={
										<ProtectedRoute
											element={<Casos />}
											allowedAreas={[
												'ESG',
												'MedioAmbiental',
												'EstandaresSociales',
												'GobiernoCorporativo',
												'Financiero',
											]}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/process/:FormularioId/forms/:empresaId'
									element={
										<ProtectedRoute
											element={<VistaFormulario />}
											allowedAreas={[
												'ESG',
												'MedioAmbiental',
												'EstandaresSociales',
												'GobiernoCorporativo',
												'Financiero',
											]}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/dashboard/forms/:empresaId/answer/:respuestaId'
									element={
										<ProtectedRoute
											element={<VistaFormulario />}
											allowedAreas={[
												'ESG',
												'MedioAmbiental',
												'EstandaresSociales',
												'GobiernoCorporativo',
												'Financiero',
											]}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/process/:empresaId/form/:procesoId/question/:preguntaId'
									element={
										<ProtectedRoute
											element={<VistaPregunta />}
											allowedAreas={[
												'ESG',
												'MedioAmbiental',
												'EstandaresSociales',
												'GobiernoCorporativo',
												'Financiero',
											]}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/procesos'
									element={
										<ProtectedRoute
											element={<ListadeProcesos />}
											allowedAreas={['ESG']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/empresas/:empresaId/procesos/:procesoId/vista-proceso-acabado'
									element={
										<ProtectedRoute
											element={<VistaProcesoAcabado />}
											allowedAreas={[
												'MedioAmbiental',
												'ESG',
												'EstandaresSociales',
												'GobiernoCorporativo',
											]}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='/extraccion'
									element={
										<ProtectedRoute
											element={<ExtraccionDashboard />}
											allowedAreas={['Extraccion']}
											authChecked={authChecked}
											isLoggedIn={isLoggedIn}
										/>
									}
								/>
								<Route
									path='*'
									element={getAuthBasedRedirect()}
								/>
							</Routes>
						</main>
						<footer className='app-footer'>{t('footer')}</footer>
					</div>
				</NavBarProvider>
			</Router>
		</I18nextProvider>
	);
}

export default App;
