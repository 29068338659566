//frontend/src/styles/formularioCardStyles.js
export const formularioCardStyles = {
	formularioCard: `
    bg-secundario/10       
    rounded-lg
    shadow-sm
    p-6
    mb-16               
    w-full
    max-w-[900px]
    mx-auto
    hover:shadow-md
    transition-all
    duration-300
  `,
	headerGrid: `
  grid 
  grid-cols-3 
  gap-4
`,
	headerItem: `
bg-fondo
p-4
rounded-lg
transition-all
duration-200
group
hover:bg-primario hover:bg-opacity-30
`,
	infoLabel:
		'text-primario text-md font-medium  transition-colors duration-200',
	infoValue:
		'text-secundario text-base font-semibold mt-1 ',
	questionCounter: `
	  flex items-center 
	  gap-1 
	  text-secundario
	  font-medium
	`,
	yearBadge: `
	  bg-secundario/30 
	  text-secundario
	  px-2 
	  py-0.5 
	  rounded-full 
	  text-sm 
	  font-medium
	`,
	statusBadge: {
		pendiente:
			'inline-flex px-2 py-1 rounded-full bg-warn/15 text-warn text-sm font-medium',
		completado:
			'inline-flex px-2 py-1 rounded-full bg-green-50 text-green-700 text-sm font-medium',
	},
	toggleButton: `
  self-end
	  w-full
	  bg-secundario
	  text-white
	  py-3
	  px-4
	  rounded-lg
	  font-medium
	  transition-all
	  duration-300
	  hover:bg-primario
	  hover:shadow-md
	  flex
	  items-center
	  justify-center
	  gap-2
	`,
  toggleContainer:`flex
	  items-center
	  justify-center
    
    `,
	preguntasList: 'mt-6 space-y-4',
	preguntaItem: `
  bg-fondo p-6 rounded-lg border 
  flex flex-col gap-4 mt-4
`,
	noQuestionsText: 'text-secundario text-center my-4',
	buttonContainer: `
    flex 
    items-center
    gap-3
    mt-2
  `,
	submitButton: `
    bg-secundario
    text-white 
    h-9
    px-4
    rounded-md
    font-medium
    hover:bg-primario
    transition-colors
    flex
    items-center
    disabled:bg-opacity-60
    disabled:cursor-not-allowed
  `,
	redirigirButton: `
    bg-secundario
    text-white 
    h-9
    px-4
    rounded-md
    font-medium
    hover:bg-primario
    transition-colors
    flex
    items-center
  `,
	redirigidaButton: `
  bg-warn
  text-fondo 
  h-9
  px-4
  rounded-md
  font-medium
  hover:bg-opacity-80
  transition-colors
  flex
  items-center
  cursor-not-allowed
  
`,
//TODO Remove if file inputs get removed
	fileInput: '' /*`
bg-blue-500 
text-white 
px-4 py-2 
rounded 
font-medium
hover:bg-blue-600 
transition-colors
cursor-pointer
`*/,

	redirigirContainer: `
    mt-4 
    flex 
    flex-col 
    gap-4
    p-4
    bg-fondo
    rounded-lg
    border
    
  `,

	selectInput: `
    w-full
    p-2
    bg-fondo
    border
    border-gray-300
    rounded-lg
    shadow-sm
    focus:border-secundario
    focus:ring-1
    focus:ring-secundario
    outline-none
  `,

	conditionalQuestion: `
  flex
  flex-col
  gap-4
`,

	labelAboveSelect: `
  mb-2
  text-gray-600
  text-sm
  leading-relaxed
`,
	questionLabel: `
  p-3
  rounded-lg
  border-l-4
  border-l-blue-500

  text-sm
  leading-relaxed
`,
IRO2ESRS2:`
w-full bg-fondo rounded-lg border border-gray-300 p-6 my-4 text-secundario font-medium
`,


	requiredField: `
    border-red-300
    bg-error/50
  `,
  requiredTitle:`
  text-error
  text-2xl
  `
};
