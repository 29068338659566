import React from 'react';
import ReactQuill from 'react-quill';
import { EDITOR_MODULES, EDITOR_FORMATS } from '../../config/editorConfig';
import {
	getQuestionOptions,
	shouldShowQuestion,
	clearVisibilityCache,
	getCheckedBoxes,
} from '../../utils/questionUtils';
import { useQuestionState } from '../../hooks/questions/useQuestionState';
import { useDefaultQuestion } from '../../hooks/questions/useQuestionType/useDefaultQuestion';
import { useConditionalQuestion } from '../../hooks/questions/useQuestionType/useConditionalQuestion';
import { formularioCardStyles as styles } from '../../styles/formularioCardStyles';
import { quillStyles } from '../../styles/quillStyles';
import TableQuestion from '../../componentes/Questions/TableQuestion';
import ConditionalQuestion from '../../componentes/Questions/ConditionalQuestion';
import BooleanQuestion from '../../componentes/Questions/BooleanQuestion';
import NumericalQuestion from '../../componentes/Questions/NumericalQuestion';
import PercentQuestion from '../../componentes/Questions/PercentQuestion';
import MonetaryQuestion from '../../componentes/Questions/MonetaryQuestion';
import GYearQuestion from '../../componentes/Questions/GYearQuestion';
import GhgEmissionsQuestion from '../../componentes/Questions/GhgEmissionsQuestion';
import DecimalQuestion from '../../componentes/Questions/DecimalQuestion';
import EnergyQuestion from '../../componentes/Questions/EnergyQuestion';
import IntensityQuestion from '../../componentes/Questions/IntensityQuestion';
import MassQuestion from '../../componentes/Questions/MassQuestion';
import VolumeQuestion from '../../componentes/Questions/VolumeQuestion';
import AreaQuestion from '../../componentes/Questions/AreaQuestion';
import DatePicker from '../DataPicker';
import { questionOptions } from '../../config/questionConfig';
import { debug } from '../../utils/debugHelper';

import QuestionTitle from './componentes/QuestionTitle';
import FileUploadButton from './componentes/FileUploadButton';
import ActionButtons from './componentes/ActionButtons';
import RedirectSection from './componentes/RedirectSection';

// Función que verifica si una pregunta es controlada por otra
const isControlledBy = (questionId, controllingId, formularioId, procesoId) => {
	const configKey = `${formularioId}-${procesoId}-${controllingId}`;
	const config = questionOptions[configKey];

	if (config && config.controlledQuestions) {
		return config.controlledQuestions.includes(questionId.toString());
	}

	return false;
};

// Función que verifica si una pregunta controla a otra(s)
const controlsOtherQuestions = (questionId, formularioId, procesoId) => {
	const configKey = `${formularioId}-${procesoId}-${questionId}`;
	const config = questionOptions[configKey];

	return (
		config &&
		config.controlledQuestions &&
		config.controlledQuestions.length > 0
	);
};

// Función auxiliar para verificar la condición negativa de la tabla
const checkNegativeTableCondition = (
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	empresaId,
	procesoId
) => {
	const controllingQuestionId = pregunta.conditional.controlledBy;

	// Verificar si la respuesta está guardada
	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingQuestionId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];
	const isResponsaGuardadaNegative =
		respuestaGuardada?.respuesta?.text === 'negative';

	debug(
		`🔍 DIAGNÓSTICO PREGUNTA ${pregunta.id} controlada por tabla ${controllingQuestionId}:`,
		{
			preguntaId: pregunta.id,
			preguntaType: pregunta.type,
			conditional: pregunta.conditional,
			isResponsaGuardadaNegative,
			respuestaGuardada: !!respuestaGuardada,
		}
	);

	const shouldShow = shouldShowQuestion(
		pregunta,
		respuestas,
		formularioId,
		procesoId,
		respuestasEnviadas,
		empresaId
	);

	// Solo forzar visibilidad si la respuesta negative está guardada
	if (isResponsaGuardadaNegative) {
		debug(
			`⚠️ FORZANDO visibilidad de pregunta ${pregunta.id} controlada por tabla ${controllingQuestionId}`
		);
		return true;
	} else if (!shouldShow) {
		debug(
			`DEBUG PreguntaItem - Pregunta ${pregunta.id} oculta por !shouldShow`
		);
		return false;
	}

	return true;
};

// Función auxiliar para verificar la condición del checkbox
const checkCheckboxCondition = (
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	empresaId,
	procesoId
) => {
	const controllingId = pregunta.conditional.controlledBy;
	const checkboxIndex = pregunta.conditional.checkboxIndex;

	debug(
		`🔍 [PreguntaItem] INICIO DIAGNÓSTICO - Pregunta ${pregunta.id} controlada por CHECKBOX ${checkboxIndex}`
	);
	debug(`  - ID de la pregunta controladora (tabla): ${controllingId}`);
	debug(`  - Índice del checkbox: ${checkboxIndex}`);

	// Verificar si la respuesta está guardada
	const respuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${controllingId}`;
	const respuestaGuardada = respuestasEnviadas[respuestaGuardadaKey];

	debug(`  - ¿Tiene respuesta guardada?: ${!!respuestaGuardada}`);

	// Buscar si esta pregunta ya tiene respuesta auto-rellenada
	const thisRespuestaKey = `${formularioId}-${pregunta.id}`;
	const currentRespuesta = respuestas[thisRespuestaKey]?.text;
	debug(`  - Respuesta actual para esta pregunta: "${currentRespuesta}"`);

	// Verificar si esta pregunta tiene respuesta guardada
	const thisRespuestaGuardadaKey = `${formularioId}-${empresaId}-${procesoId}-${pregunta.id}`;
	const thisRespuestaGuardada = respuestasEnviadas[thisRespuestaGuardadaKey];
	debug(
		`  - ¿Esta pregunta tiene respuesta guardada?: ${!!thisRespuestaGuardada}`
	);

	if (thisRespuestaGuardada) {
		debug(
			`  - Respuesta guardada para esta pregunta: "${thisRespuestaGuardada?.respuesta?.text}"`
		);
	}

	// Solo proceder si hay respuesta guardada
	if (respuestaGuardada?.respuesta?.text) {
		// Verificar si el checkbox está marcado en la respuesta guardada
		debug(`  - Respuesta guardada: "${respuestaGuardada?.respuesta?.text}"`);
		const checkedBoxes = getCheckedBoxes(respuestaGuardada.respuesta.text);
		debug(
			`  - Checkboxes marcados en respuesta guardada: [${checkedBoxes.join(
				', '
			)}]`
		);

		const isCheckboxChecked = checkedBoxes.includes(checkboxIndex);
		debug(`  - ¿Checkbox ${checkboxIndex} marcado?: ${isCheckboxChecked}`);

		debug(
			`🔍 DIAGNÓSTICO PREGUNTA ${pregunta.id} controlada por CHECKBOX ${checkboxIndex}:`,
			{
				preguntaId: pregunta.id,
				respuestaGuardada: respuestaGuardada.respuesta.text,
				isCheckboxChecked,
				checkedBoxes,
			}
		);

		// Determinar si se debe mostrar según shouldShowQuestion
		const shouldShow = shouldShowQuestion(
			pregunta,
			respuestas,
			formularioId,
			procesoId,
			respuestasEnviadas,
			empresaId
		);

		if (!shouldShow) {
			debug(`❌ PREGUNTA ${pregunta.id} OCULTA POR REGLAS DE VISIBILIDAD`);
			return false;
		}

		debug(
			`✅ PREGUNTA ${pregunta.id} VISIBLE CON CHECKBOX ${checkboxIndex} MARCADO`
		);
		return true;
	} else {
		debug(`❌ PREGUNTA ${pregunta.id} OCULTA - NO HAY RESPUESTA GUARDADA`);
		return false;
	}
};

// Función auxiliar para verificar si la pregunta debe ser renderizada
const shouldRenderPregunta = (
	pregunta,
	respuestas,
	respuestasEnviadas,
	formularioId,
	empresaId,
	procesoId,
	estadoFormulario // Añadir este parámetro
) => {
	// Si el formulario está en estado acabado, completado o revisión, siempre mostrar la pregunta
	if (
		estadoFormulario === 'Acabada' ||
		estadoFormulario === 'Completada' ||
		estadoFormulario === 'Revision'
	) {
		return true;
	}
	// Caso 1: Controlada por tabla con showOnOption = 'negative'
	if (
		pregunta.conditional?.controlledBy &&
		pregunta.conditional?.showOnOption === 'negative'
	) {
		return checkNegativeTableCondition(
			pregunta,
			respuestas,
			respuestasEnviadas,
			formularioId,
			empresaId,
			procesoId
		);
	}

	// Caso 2: Controlada por checkbox
	else if (
		pregunta.conditional?.controlledBy &&
		pregunta.conditional?.showOnOption === 'checkbox'
	) {
		return checkCheckboxCondition(
			pregunta,
			respuestas,
			respuestasEnviadas,
			formularioId,
			empresaId,
			procesoId
		);
	}

	// Caso 3: Verificación estándar
	else {
		return shouldShowQuestion(
			pregunta,
			respuestas,
			formularioId,
			procesoId,
			respuestasEnviadas,
			empresaId
		);
	}
};

// Componente para renderizar el input adecuado según el tipo de pregunta
const renderQuestionInput = (props) => {
	const {
		pregunta,
		formulario,
		procesoId,
		respuestas,
		respuestasEnviadas,
		handleEditorChange,
		canEdit,
		isRedirigida,
		options,
		selectRef,
		handleOptionChange,
		handleSubmitWithConditional,
		selectedOption,
		language,
		handleSubmit,
		setupQuillRef,
	} = props;

	debug('DEBUG renderInput:', {
		type: pregunta.type,
		questionType: pregunta.questionType,
		preguntaId: pregunta.id,
		options,
	});

	const commonProps = {
		pregunta,
		formulario,
		procesoId,
		respuestas,
		respuestasEnviadas,
		handleEditorChange,
		canEdit,
		isRedirigida,
		options,
		styles,
		selectRef,
		handleOptionChange,
		handleSubmitWithConditional,
		selectedOption,
		language,
	};

	// Primero evaluamos el type para conditional
	if (pregunta.type === 'conditional') {
		debug('DEBUG renderInput - Rendering ConditionalQuestion:', {
			commonProps,
			hasQuestionConfig:
				!!questionOptions[
					`${formulario.FormularioId}-${procesoId}-${pregunta.id}`
				],
		});
		return (
			<ConditionalQuestion
				{...commonProps}
				language={language}
			/>
		);
	}

	// Luego el switch para los demás tipos
	switch (pregunta.questionType) {
		case 'table':
			return (
				<TableQuestion
					{...commonProps}
					handleSubmit={handleSubmit}
				/>
			);
		case 'boolean':
			return <BooleanQuestion {...commonProps} />;
		case 'numerical':
		case 'integer':
			return <NumericalQuestion {...commonProps} />;
		case 'percent':
			return <PercentQuestion {...commonProps} />;
		case 'Monetary':
			return <MonetaryQuestion {...commonProps} />;
		case 'gYear':
			return <GYearQuestion {...commonProps} />;
		case 'ghgEmissions':
			return <GhgEmissionsQuestion {...commonProps} />;
		case 'decimal':
			return <DecimalQuestion {...commonProps} />;
		case 'energy':
			return <EnergyQuestion {...commonProps} />;
		case 'intensity':
			return <IntensityQuestion {...commonProps} />;
		case 'mass':
			return <MassQuestion {...commonProps} />;
		case 'volume':
			return <VolumeQuestion {...commonProps} />;
		case 'area':
			return <AreaQuestion {...commonProps} />;
		case 'date':
			return (
				<DatePicker
					value={
						respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
					}
					onChange={(value) =>
						handleEditorChange(pregunta.id, formulario.FormularioId, value)
					}
					isDisabled={!canEdit || isRedirigida}
				/>
			);
		default:
			return (
				<div className={quillStyles.wrapper}>
					<div
						className={`${quillStyles.editor.container} ${
							pregunta.required
								? quillStyles.editor.required
								: quillStyles.editor.default
						}`}>
						<ReactQuill
							ref={(ref) => setupQuillRef(ref, pregunta.id)}
							value={
								respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text ||
								''
							}
							onChange={(value) =>
								handleEditorChange(pregunta.id, formulario.FormularioId, value)
							}
							modules={EDITOR_MODULES}
							formats={EDITOR_FORMATS}
							className={quillStyles.editor.base}
							readOnly={!canEdit || isRedirigida}
							theme='snow'
						/>
					</div>
				</div>
			);
	}
};

const PreguntaItem = ({
	pregunta,
	formulario,
	empresaId,
	procesoId,
	language,
	labels,
	respuestas,
	respuestasEnviadas,
	redirigirPregunta,
	userId,
	quillRefs,
	handleQuillRef,
	handleEditorChange,
	handleFileChange,
	handleSubmit,
	toggleRedirigir,
	selectedArea,
	selectedDepartamento,
	handleAreaChange,
	handleDepartamentoChange,
	esRespuestaValida,
}) => {
	// Limpia el caché cuando cambian las respuestas
	React.useEffect(() => {
		clearVisibilityCache();
	}, [respuestas, respuestasEnviadas]);

	debug('DEBUG PreguntaItem - Render:', {
		preguntaId: pregunta.id,
		type: pregunta.type,
		formularioId: formulario.FormularioId,
		language: language,
		estadoEmpresas: formulario.estadoEmpresas,
	});

	const { getQuestionState } = useQuestionState(pregunta);
	let { isRedirigida, hasRespuesta, canEdit } = getQuestionState(
		formulario.FormularioId,
		empresaId,
		procesoId,
		pregunta.id,
		respuestasEnviadas,
		userId
	);

	// CAMBIO IMPORTANTE: Forzar canEdit = true si el formulario está en estado Revision
	if (formulario.estadoEmpresas === 'Revision') {
		debug(
			`Formulario en estado Revision - Forzando canEdit=true para pregunta ${pregunta.id}`
		);
		canEdit = true;
	}

	const { setupQuillRef } = useDefaultQuestion(quillRefs, handleQuillRef);
	const {
		selectedOption,
		selectRef,
		handleOptionChange,
		handleSubmitWithConditional,
	} = useConditionalQuestion(
		respuestasEnviadas,
		formulario.FormularioId,
		procesoId,
		pregunta.id
	);

	const options = getQuestionOptions(
		formulario.FormularioId,
		procesoId,
		pregunta.id
	);

	// Verificar si la pregunta debe mostrarse según las condiciones
	if (
		!shouldRenderPregunta(
			pregunta,
			respuestas,
			respuestasEnviadas,
			formulario.FormularioId,
			empresaId,
			procesoId,
			formulario.estadoEmpresas
		)
	) {
		return null;
	}

	// Log final - si llega hasta aquí la pregunta será renderizada
	if (
		pregunta.conditional?.controlledBy &&
		(pregunta.conditional?.showOnOption === 'negative' ||
			pregunta.conditional?.showOnOption === 'checkbox')
	) {
		debug(
			`✅ PREGUNTA ${pregunta.id} HA PASADO TODAS LAS VERIFICACIONES Y SE RENDERIZARÁ`
		);
	}

	return (
		<li className={styles.preguntaItem}>
			<QuestionTitle
				pregunta={pregunta}
				language={language}
				labels={labels}
			/>

			{renderQuestionInput({
				pregunta,
				formulario,
				procesoId,
				respuestas,
				respuestasEnviadas,
				handleEditorChange,
				canEdit,
				isRedirigida,
				options,
				selectRef,
				handleOptionChange,
				handleSubmitWithConditional,
				selectedOption,
				language,
				handleSubmit,
				setupQuillRef,
			})}

			<div className={styles.buttonContainer}>
				<FileUploadButton
					pregunta={pregunta}
					formulario={formulario}
					handleFileChange={handleFileChange}
					canEdit={canEdit}
					isRedirigida={isRedirigida}
				/>

				{isRedirigida ? (
					<button
						className={`${styles.submitButton} ${styles.redirigidaButton}`}
						disabled>
						{labels.quedaRedirigida}
					</button>
				) : !canEdit ? (
					<span className={styles.soloVista}>{labels.soloVista}</span>
				) : (
					<ActionButtons
						pregunta={pregunta}
						formulario={formulario}
						handleSubmit={handleSubmit}
						handleSubmitWithConditional={handleSubmitWithConditional}
						isRedirigida={isRedirigida}
						canEdit={canEdit}
						hasRespuesta={hasRespuesta}
						labels={labels}
						options={options}
						respuestas={respuestas}
						handleEditorChange={handleEditorChange}
						procesoId={procesoId}
						esRespuestaValida={esRespuestaValida}
						selectRef={selectRef}
						toggleRedirigir={toggleRedirigir}
						selectedArea={selectedArea}
						selectedDepartamento={selectedDepartamento}
						language={language}
					/>
				)}
			</div>

			{redirigirPregunta[pregunta.id] && (
				<RedirectSection
					preguntaId={pregunta.id}
					redirigirPregunta={redirigirPregunta}
					isRedirigida={isRedirigida}
					canEdit={canEdit}
					selectedArea={selectedArea}
					selectedDepartamento={selectedDepartamento}
					handleAreaChange={handleAreaChange}
					handleDepartamentoChange={handleDepartamentoChange}
					labels={labels}
					language={language}
				/>
			)}
		</li>
	);
};

// Función para determinar si las props han cambiado de manera relevante
const checkNegativeTablesChanges = (prevProps, nextProps) => {
	if (
		prevProps.pregunta.conditional?.controlledBy &&
		prevProps.pregunta.conditional?.showOnOption === 'negative'
	) {
		const controllingId = prevProps.pregunta.conditional.controlledBy;
		const respuestaGuardadaKey = `${prevProps.formulario.FormularioId}-${prevProps.empresaId}-${prevProps.procesoId}-${controllingId}`;

		const prevSavedResponse =
			prevProps.respuestasEnviadas[respuestaGuardadaKey]?.respuesta?.text;
		const nextSavedResponse =
			nextProps.respuestasEnviadas[respuestaGuardadaKey]?.respuesta?.text;

		if (
			prevSavedResponse !== nextSavedResponse &&
			(prevSavedResponse === 'negative' || nextSavedResponse === 'negative')
		) {
			debug(
				`🔄 FORZANDO RE-RENDER DE PREGUNTA ${prevProps.pregunta.id} POR CAMBIO EN RESPUESTA GUARDADA`
			);
			return true; // Hay cambios
		}
	}
	return false; // No hay cambios
};

// Comprueba cambios en checkboxes
const checkCheckboxChanges = (prevProps, nextProps) => {
	if (prevProps.pregunta.conditional?.showOnOption === 'checkbox') {
		const controllingId = prevProps.pregunta.conditional.controlledBy;
		const checkboxIndex = prevProps.pregunta.conditional.checkboxIndex;

		// Verificar cambios en la respuesta actual
		const prevResponse =
			prevProps.respuestas[
				`${prevProps.formulario.FormularioId}-${controllingId}`
			]?.text;
		const nextResponse =
			nextProps.respuestas[
				`${nextProps.formulario.FormularioId}-${controllingId}`
			]?.text;

		if (prevResponse !== nextResponse) {
			const prevChecked = getCheckedBoxes(prevResponse || '').includes(
				checkboxIndex
			);
			const nextChecked = getCheckedBoxes(nextResponse || '').includes(
				checkboxIndex
			);

			if (prevChecked !== nextChecked) {
				debug(
					`🔄 FORZANDO RE-RENDER DE PREGUNTA ${prevProps.pregunta.id} POR CAMBIO EN CHECKBOX`
				);
				return true; // Hay cambios
			}
		}

		// Verificar cambios en respuestas guardadas
		const respuestaGuardadaKey = `${prevProps.formulario.FormularioId}-${prevProps.empresaId}-${prevProps.procesoId}-${controllingId}`;
		const prevSavedResponse =
			prevProps.respuestasEnviadas[respuestaGuardadaKey]?.respuesta?.text;
		const nextSavedResponse =
			nextProps.respuestasEnviadas[respuestaGuardadaKey]?.respuesta?.text;

		if (prevSavedResponse !== nextSavedResponse) {
			const prevChecked = getCheckedBoxes(prevSavedResponse || '').includes(
				checkboxIndex
			);
			const nextChecked = getCheckedBoxes(nextSavedResponse || '').includes(
				checkboxIndex
			);

			if (prevChecked !== nextChecked) {
				debug(
					`🔄 FORZANDO RE-RENDER DE PREGUNTA ${prevProps.pregunta.id} POR CAMBIO EN CHECKBOX GUARDADO`
				);
				return true; // Hay cambios
			}
		}
	}
	return false; // No hay cambios
};

// Comprueba cambios en la respuesta específica
const checkSpecificResponseChanges = (prevProps, nextProps) => {
	const prevRespuesta =
		prevProps.respuestas[
			`${prevProps.formulario.FormularioId}-${prevProps.pregunta.id}`
		]?.text;
	const nextRespuesta =
		nextProps.respuestas[
			`${nextProps.formulario.FormularioId}-${nextProps.pregunta.id}`
		]?.text;

	return prevRespuesta !== nextRespuesta;
};

// Comprueba cambios en respuestas guardadas
const checkSavedResponsesChanges = (prevProps, nextProps) => {
	if (prevProps.respuestasEnviadas !== nextProps.respuestasEnviadas) {
		// Verificar si alguna respuesta relevante para esta pregunta ha cambiado
		const respuestaGuardadaKey = `${prevProps.formulario.FormularioId}-${prevProps.empresaId}-${prevProps.procesoId}-${prevProps.pregunta.id}`;
		if (
			prevProps.respuestasEnviadas[respuestaGuardadaKey] !==
			nextProps.respuestasEnviadas[respuestaGuardadaKey]
		) {
			return true; // Hay cambios
		}

		// Si esta pregunta es dependiente, verificar si su controlador ha cambiado
		if (prevProps.pregunta.conditional?.dependsOn) {
			const parentId = prevProps.pregunta.conditional.dependsOn;
			const parentKey = `${prevProps.formulario.FormularioId}-${prevProps.empresaId}-${prevProps.procesoId}-${parentId}`;
			if (
				prevProps.respuestasEnviadas[parentKey] !==
				nextProps.respuestasEnviadas[parentKey]
			) {
				return true; // Hay cambios
			}
		}
	}
	return false; // No hay cambios
};

// Comprueba cambios en preguntas controladoras
const checkControllingQuestionsChanges = (prevProps, nextProps) => {
	for (let keyPrev in prevProps.respuestas) {
		const keyNext = keyPrev; // Misma clave en el objeto siguiente
		if (
			prevProps.respuestas[keyPrev]?.text !==
			nextProps.respuestas[keyNext]?.text
		) {
			// Extraer ID de la pregunta que cambió
			const parts = keyPrev.split('-');
			const changedId = parseInt(parts[parts.length - 1]);

			// Verificar si controla a nuestra pregunta
			if (
				isControlledBy(
					prevProps.pregunta.id,
					changedId,
					prevProps.formulario.FormularioId,
					prevProps.procesoId
				)
			) {
				return true; // Hay cambios
			}

			// Verificar traducción especial Yes/Si
			if (
				prevProps.pregunta.conditional?.dependsOn === changedId &&
				((prevProps.respuestas[keyPrev]?.text?.includes('Yes') &&
					nextProps.respuestas[keyNext]?.text?.includes('Si')) ||
					(prevProps.respuestas[keyPrev]?.text?.includes('Si') &&
						nextProps.respuestas[keyNext]?.text?.includes('Yes')))
			) {
				return true; // Hay cambios
			}
		}
	}
	return false; // No hay cambios
};

// Comprueba cambios en preguntas controladas por esta
const checkControlledQuestionsChanges = (prevProps, nextProps) => {
	if (
		controlsOtherQuestions(
			prevProps.pregunta.id,
			prevProps.formulario.FormularioId,
			prevProps.procesoId
		)
	) {
		// Comprobamos si hay cambios relevantes en las respuestas
		const thisKey = `${prevProps.formulario.FormularioId}-${prevProps.pregunta.id}`;
		if (prevProps.respuestas[thisKey] !== nextProps.respuestas[thisKey]) {
			return true; // Hay cambios
		}
	}
	return false; // No hay cambios
};

// Comprueba cambios en props críticas
const checkCriticalPropsChanges = (prevProps, nextProps) => {
	return (
		prevProps.isRedirigida !== nextProps.isRedirigida ||
		prevProps.canEdit !== nextProps.canEdit ||
		prevProps.language !== nextProps.language ||
		prevProps.redirigirPregunta[prevProps.pregunta.id] !==
			nextProps.redirigirPregunta[nextProps.pregunta.id] ||
		prevProps.formulario.estadoEmpresas !== nextProps.formulario.estadoEmpresas // AÑADIDO: detectar cambios en estado del formulario
	);
};

// Comprueba cambios en área y departamento
const checkAreaDepartmentChanges = (prevProps, nextProps) => {
	if (
		prevProps.selectedArea[prevProps.pregunta.id] !==
			nextProps.selectedArea[nextProps.pregunta.id] ||
		prevProps.selectedDepartamento[prevProps.pregunta.id] !==
			nextProps.selectedDepartamento[nextProps.pregunta.id]
	) {
		debug(
			`FORZANDO RE-RENDER DE PREGUNTA ${prevProps.pregunta.id} POR CAMBIO EN ÁREA/DEPARTAMENTO`
		);
		return true; // Hay cambios
	}
	return false; // No hay cambios
};

// Función principal refactorizada para reducir complejidad cognitiva
const areEqual = (prevProps, nextProps) => {
	// Comprobamos cada tipo de cambio posible en orden
	if (checkNegativeTablesChanges(prevProps, nextProps)) return false;
	if (checkCheckboxChanges(prevProps, nextProps)) return false;
	if (checkSpecificResponseChanges(prevProps, nextProps)) return false;
	if (checkSavedResponsesChanges(prevProps, nextProps)) return false;
	if (checkControllingQuestionsChanges(prevProps, nextProps)) return false;
	if (checkControlledQuestionsChanges(prevProps, nextProps)) return false;
	if (checkCriticalPropsChanges(prevProps, nextProps)) return false;
	if (checkAreaDepartmentChanges(prevProps, nextProps)) return false;

	// Si no hay cambios, los props son iguales
	return true;
};

// Exportar con React.memo para optimizar renderizados
export default React.memo(PreguntaItem, areEqual);
