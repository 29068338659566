import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUserArea from '../hooks/user/useUserArea';
import useUserPermissions from '../hooks/user/useUserPermissions'; // Añadido
import useI18n from '../hooks/i18n/useI18n';
import FormularioCard from './FormularioCard';
import { vistaFormularioStyles as styles } from '../styles/vistaFormularioStyles';
import RevisionModal from './RevisionModal';
import { getProcessesToCreate } from '../config/questionConfig';
import { fetchWithAuth } from '../utils/fetchUtils';
import {
	ROUTE_COMPANY_COMPLETE,
	ROUTE_COMPANY_LIST,
	ROUTE_FORMS,
	ROUTE_RESPONSES,
	ROUTE_COMPANY_CREATE_WITH_FORMS,
} from '../config/routeConfig';
import { debug, debugError, debugWarn } from '../utils/debugHelper';
import { hasSendPermission } from '../utils/permissionsUtils'; // Añadido

function VistaProcesoAcabado() {
	const { empresaId, procesoId } = useParams();
	const { area } = useUserArea();
	const { permissions } = useUserPermissions(); // Añadido
	const { t, language, languageError } = useI18n();
	const navigate = useNavigate();
	const [formularios, setFormularios] = useState([]);
	const [nombreEmpresa, setNombreEmpresa] = useState('');
	const [nombreProceso, setNombreProceso] = useState('');
	const [error, setError] = useState(null);
	const [respuestasEnviadas, setRespuestasEnviadas] = useState({});
	const [mostrarPreguntas, setMostrarPreguntas] = useState({});
	const [respuestas, setRespuestas] = useState({});
	const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);
	const [anyoActual, setAnyoActual] = useState(null);

	// Log de parámetros iniciales
	debug('VistaProcesoAcabado - Parámetros:', {
		empresaId,
		procesoId,
		query: window.location.search,
		area,
	});

	useEffect(() => {
		debug('VistaProcesoAcabado - useEffect inicial ejecutándose');

		// Obtener el año de los parámetros de URL
		const searchParams = new URLSearchParams(window.location.search);
		const anyoParam = searchParams.get('anyo');

		debug('VistaProcesoAcabado - Año obtenido de URL:', anyoParam);

		if (anyoParam) {
			setAnyoActual(parseInt(anyoParam));
		}

		fetchFormularios();
	}, [empresaId, procesoId, window.location.search]);

	const fetchFormularioPreguntas = async (formulario) => {
		const preguntas = await fetchPreguntas(formulario.FormularioId);
		debug(
			`VistaProcesoAcabado - Preguntas obtenidas para formulario ${formulario.FormularioId}: ${preguntas.length}`
		);
		return { ...formulario, preguntas };
	};

	// Función auxiliar para procesamiento de formularios encontrados
	const procesarFormulariosEncontrados = async (formulariosRelacionados) => {
		setNombreProceso(
			formulariosRelacionados[0]?.proceso?.nombre || 'Proceso sin nombre'
		);
		debug(
			`VistaProcesoAcabado - Nombre del proceso: ${
				formulariosRelacionados[0]?.proceso?.nombre || 'Proceso sin nombre'
			}`
		);

		const formulariosConPreguntas = await Promise.all(
			formulariosRelacionados.map(fetchFormularioPreguntas)
		);

		formulariosConPreguntas.sort((a, b) => a.FormularioId - b.FormularioId);

		// Obtener el año de URL o de los formularios directamente
		const searchParams = new URLSearchParams(window.location.search);
		const anyoParam = searchParams.get('anyo');
		const anyo = anyoParam
			? parseInt(anyoParam)
			: formulariosRelacionados[0]?.anyo ||
			  new Date(formulariosRelacionados[0]?.fechaCreacion).getFullYear();

		// CAMBIO IMPORTANTE: Pasamos los formularios directamente y el año
		await fetchRespuestasEnviadas(formulariosConPreguntas, anyo);

		// Actualizar el estado después de cargar las respuestas
		setFormularios(formulariosConPreguntas);
	};

	// Función auxiliar para manejar el caso de no encontrar formularios
	const manejarFormulariosNoEncontrados = (data, empresaData, anyo) => {
		debugWarn(
			`VistaProcesoAcabado - No se encontraron formularios para el año ${anyo}`
		);

		// Si no hay formularios para el año específico, intentar buscar para otro año
		const anyosDisponibles = Array.from(
			new Set(
				data
					.filter(
						(item) =>
							item.procesosId === parseInt(procesoId) &&
							item.nombreEmpresa === empresaData.nombreEmpresa
					)
					.map((item) => new Date(item.fechaCreacion).getFullYear())
			)
		).sort((a, b) => b - a); // Ordenar descendente (más reciente primero)

		debug(
			`VistaProcesoAcabado - Años disponibles para este proceso:`,
			anyosDisponibles
		);

		if (anyosDisponibles.length > 0) {
			const anyoAlternativo = anyosDisponibles[0]; // Tomar el año más reciente
			debug(
				`VistaProcesoAcabado - Intentando con año alternativo: ${anyoAlternativo}`
			);

			// Redireccionar a la misma página pero con el año encontrado
			navigate(
				`/empresas/${empresaId}/procesos/${procesoId}/vista-proceso-acabado?anyo=${anyoAlternativo}`,
				{ replace: true }
			);
		} else {
			setError(`No se encontraron formularios para el proceso en ningún año.`);
		}
	};

	// Función auxiliar para encontrar formularios relacionados por año
	const encontrarFormulariosPorAnyo = (data, empresaData, anyo) => {
		return data.filter((item) => {
			// Usar directamente el campo anyo si existe
			const itemAnyo = item.anyo || 0; // Usar 0 como fallback

			const coincide =
				item.procesosId === parseInt(procesoId) &&
				item.nombreEmpresa === empresaData.nombreEmpresa &&
				itemAnyo === anyo;

			// Depuración detallada
			if (
				item.procesosId === parseInt(procesoId) &&
				item.nombreEmpresa === empresaData.nombreEmpresa
			) {
				debug(
					`VistaProcesoAcabado - Evaluando item: id=${item.id}, procesosId=${item.procesosId}, empresa=${item.nombreEmpresa}, itemAnyo=${itemAnyo}, anyo=${anyo}, coincide=${coincide}, fechaCreacion=${item.fechaCreacion}`
				);
			}

			return coincide;
		});
	};

	// Función auxiliar para procesar la empresa encontrada
	const procesarEmpresaEncontrada = async (empresaData, data) => {
		setNombreEmpresa(empresaData.nombreEmpresa);
		debug(
			`VistaProcesoAcabado - Empresa encontrada: ${empresaData.nombreEmpresa}`
		);

		// Obtener el año de los parámetros de URL o usar el año de la fecha de creación
		const searchParams = new URLSearchParams(window.location.search);
		const anyoParam = searchParams.get('anyo');

		// Convertir explícitamente a número para evitar problemas de comparación
		const anyo = anyoParam
			? parseInt(anyoParam)
			: new Date(empresaData.fechaCreacion).getFullYear();
		debug(
			`VistaProcesoAcabado - Usando año: ${anyo}, (parametro URL: ${anyoParam})`
		);

		if (!anyoActual) {
			setAnyoActual(anyo);
		}

		// Mostrar todos los items relacionados con esta empresa y proceso para depurar
		const itemsEmpresaProceso = data.filter(
			(item) =>
				item.procesosId === parseInt(procesoId) &&
				item.nombreEmpresa === empresaData.nombreEmpresa
		);

		debug(
			`VistaProcesoAcabado - Todos los items para empresa=${empresaData.nombreEmpresa} y procesoId=${procesoId}:`,
			itemsEmpresaProceso.map((item) => {
				const itemAnyo = new Date(item.fechaCreacion).getFullYear();
				return {
					id: item.id,
					FormularioId: item.FormularioId,
					anyo: itemAnyo,
					fechaCreacion: item.fechaCreacion,
				};
			})
		);

		// Buscar ahora por el año específico
		const formulariosRelacionados = encontrarFormulariosPorAnyo(
			data,
			empresaData,
			anyo
		);

		debug(
			`VistaProcesoAcabado - Formularios relacionados encontrados: ${formulariosRelacionados.length}`
		);
		debug(
			'VistaProcesoAcabado - Formularios relacionados:',
			formulariosRelacionados
		);

		if (formulariosRelacionados.length > 0) {
			await procesarFormulariosEncontrados(formulariosRelacionados);
		} else {
			manejarFormulariosNoEncontrados(data, empresaData, anyo);
		}
	};

	// Función principal - MODIFICADA para usar fetchWithAuth
	const fetchFormularios = () => {
		debug('VistaProcesoAcabado - Iniciando fetchFormularios');

		fetchWithAuth(ROUTE_COMPANY_LIST)
			.then((response) => {
				if (!response.ok) throw new Error('Error al obtener la empresa');
				return response.json();
			})
			.then(async (data) => {
				debug(`VistaProcesoAcabado - Datos obtenidos: ${data.length} empresas`);
				// Log para ver todos los datos y depurar
				debug('VistaProcesoAcabado - Todos los datos:', data);

				const empresaData = data.find(
					(item) => item.id === parseInt(empresaId)
				);

				if (empresaData) {
					await procesarEmpresaEncontrada(empresaData, data);
				} else {
					debugError(
						`VistaProcesoAcabado - Empresa con ID ${empresaId} no encontrada`
					);
					setError('Empresa no encontrada');
				}
			})
			.catch((error) => {
				debugError('VistaProcesoAcabado - Fetch Error:', error);
				setError('No se pudo cargar los formularios de la empresa.');
			});
	};

	// MODIFICADA para usar fetchWithAuth
	const fetchPreguntas = async (formularioId) => {
		try {
			const response = await fetchWithAuth(
				`${ROUTE_FORMS}/${formularioId}/preguntas`
			);
			if (!response.ok)
				throw new Error('Error al obtener preguntas del formulario');
			const data = await response.json();
			return data.preguntas || [];
		} catch (error) {
			debugError(
				`Error al obtener preguntas para Formulario ID ${formularioId}:`,
				error
			);
			return [];
		}
	};

	// FUNCIÓN MODIFICADA: Ahora acepta formularios y año directamente
	const determinarAnyo = (
		anyoDirecto,
		formulariosList,
		formularios,
		anyoActual
	) => {
		const searchParams = new URLSearchParams(window.location.search);
		const anyoParam = searchParams.get('anyo');

		// Calcular año del formulario (si existe)
		const formularioAnyo =
			formulariosList && formulariosList.length > 0
				? formulariosList[0].anyo ||
				  new Date(formulariosList[0].fechaCreacion).getFullYear()
				: formularios[0]?.anyo ||
				  (formularios[0]
						? new Date(formularios[0].fechaCreacion).getFullYear()
						: new Date().getFullYear());

		// Priorizar: 1) anyoDirecto, 2) parámetro URL, 3) anyoActual, 4) año del formulario
		const anyo =
			anyoDirecto ||
			(anyoParam ? parseInt(anyoParam) : anyoActual || formularioAnyo);

		return anyo;
	};

	// Obtiene las respuestas de la API - MODIFICADA para usar fetchWithAuth
	const obtenerRespuestas = async (empresaId, procesoId, anyo) => {
		const url = `${ROUTE_RESPONSES}?empresaId=${empresaId}&procesoId=${procesoId}&anyo=${anyo}`;

		const response = await fetchWithAuth(url);

		if (!response.ok) {
			console.error(
				'❌ Error HTTP al obtener respuestas:',
				response.status,
				response.statusText
			);
			// Intentar mostrar el cuerpo de error
			const errorText = await response.text();
			console.error('❌ Contenido de error:', errorText);
			throw new Error('Error al obtener respuestas enviadas');
		}

		return await response.json();
	};

	// Procesa los datos de las respuestas obtenidas
	const procesarRespuestas = (data, setRespuestas) => {
		if (data.length > 0) {
			// Mostrar todos los años presentes en las respuestas
			const anyosEnRespuestas = [...new Set(data.map((r) => r.anyo))].filter(
				Boolean
			);
		}

		const respuestasMap = {};
		data.forEach((respuesta) => {
			const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;
			respuestasMap[respuestaKey] = respuesta;

			// Log para cada respuesta procesada
			debug(`Procesando respuesta: ${respuestaKey}`, {
				id: respuesta.id,
				formularioid: respuesta.formularioid,
				empresasid: respuesta.empresasid,
				procesosid: respuesta.procesosid,
				preguntasid: respuesta.preguntasid,
				estadopregunta: respuesta.estadopregunta,
				anyo: respuesta.anyo, // Añadido para diagnóstico
			});

			// También actualizar el estado de respuestas para mostrar el contenido
			if (respuesta.respuesta && respuesta.respuesta.text) {
				setRespuestas((prev) => ({
					...prev,
					[`${respuesta.formularioid}-${respuesta.preguntasid}`]: {
						text: respuesta.respuesta.text,
						image: respuesta.respuesta.image,
					},
				}));
			}
		});

		if (Object.keys(respuestasMap).length > 0) return respuestasMap;
	};

	// Realiza diagnóstico adicional cuando no se encuentran respuestas - MODIFICADA para usar fetchWithAuth
	const realizarDiagnostico = async (empresaId, procesoId, anyo) => {
		const diagUrl = `${ROUTE_RESPONSES}?empresaId=${empresaId}&procesoId=${procesoId}`;
		try {
			const diagResponse = await fetchWithAuth(diagUrl);
			if (diagResponse.ok) {
				const diagData = await diagResponse.json();
				if (diagData.length > 0) {
					const anyosDisponibles = [
						...new Set(diagData.map((r) => r.anyo)),
					].filter(Boolean);

					if (anyosDisponibles.length > 0 && !anyosDisponibles.includes(anyo)) {
						console.warn(
							`⚠️ El año ${anyo} no está entre los años disponibles en la BD:`,
							anyosDisponibles
						);
					}
				}
			}
		} catch (diagError) {
			console.error('❌ Error en diagnóstico:', diagError);
		}
	};

	// FUNCIÓN PRINCIPAL REFACTORIZADA: Ahora acepta formularios y año directamente
	const fetchRespuestasEnviadas = async (
		formulariosList = null,
		anyoDirecto = null
	) => {
		try {
			// 1. Determinar el año a utilizar
			const anyo = determinarAnyo(
				anyoDirecto,
				formulariosList,
				formularios,
				anyoActual
			);

			// 2. Obtener las respuestas de la API
			const data = await obtenerRespuestas(empresaId, procesoId, anyo);

			// 3. Procesar las respuestas obtenidas
			const respuestasMap = procesarRespuestas(data, setRespuestas);
			setRespuestasEnviadas(respuestasMap);

			// 4. Si no hay respuestas, realizar diagnóstico adicional
			if (data.length === 0) {
				await realizarDiagnostico(empresaId, procesoId, anyo);
			}
		} catch (error) {
			console.error('❌ Error en fetchRespuestasEnviadas:', error);
		}
	};

	const togglePreguntas = (formularioId) => {
		setMostrarPreguntas((prev) => ({
			...prev,
			[formularioId]: !prev[formularioId],
		}));
	};

	const isESG = area && area.includes('ESG');

	// Verificar si el usuario tiene permisos de enviar (necesario para acciones de acabar/revisar)
	const canSendRequirements = hasSendPermission(permissions);

	debug('VistaProcesoAcabado - Usuario es ESG:', isESG);
	debug(
		'VistaProcesoAcabado - Usuario puede enviar requisitos:',
		canSendRequirements
	);

	// Función auxiliar para formatear respuestas
	const formatearRespuestas = (respuestas) => {
		const respuestasFormateadas = {};

		// Convertir las respuestas ya cargadas al formato que necesita getProcessesToCreate
		Object.entries(respuestas).forEach(([key, value]) => {
			respuestasFormateadas[key] = value;
		});

		debug('Respuestas formateadas para crear procesos:', respuestasFormateadas);
		return respuestasFormateadas;
	};

	// Función auxiliar para crear un proceso específico
	const crearProceso = async (
		procesosId,
		formularioIds,
		formularios,
		anyoActual
	) => {
		if (!formularioIds || formularioIds.length === 0) return;

		// Priorizar el año explícito (anyoActual) sobre el año del formulario
		const anyo = anyoActual || formularios[0]?.anyo || new Date().getFullYear();

		debug(`crearProceso - Año utilizado para la creación: ${anyo}`);

		const payload = {
			nombreEmpresa: formularios[0]?.nombreEmpresa,
			procesosId,
			userId: formularios[0]?.UserId,
			anyo: anyo, // Usar el año priorizado
			formularioIds,
		};

		debug(`Creando proceso ${procesosId} con payload:`, payload);

		const response = await fetchWithAuth(ROUTE_COMPANY_CREATE_WITH_FORMS, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		});

		if (!response.ok) {
			const errorData = await response.text();
			debugError(`Error al crear proceso ${procesosId}:`, errorData);
			throw new Error(`Error al crear el proceso ${procesosId}`);
		}

		const data = await response.json();
		debug(`Proceso ${procesosId} creado exitosamente:`, data);
	};

	// Función principal refactorizada
	const handleAcabarRequisito = async () => {
		try {
			// Solo continuar con la creación de procesos si es el ESRS2 (ID: 1)
			if (parseInt(procesoId) !== 1) {
				// Si no es ESRS2, simplemente cambiar el estado y salir
				updateProcesoEstado('Acabada');
				return;
			}

			debug('Procesando ESRS2 para crear procesos adicionales...');
			debug(`Año actual del proceso ESRS2: ${anyoActual}`);

			// 1. Obtener todas las respuestas formateadas
			const respuestasFormateadas = formatearRespuestas(respuestas);

			// 2. Determinar qué procesos y formularios crear
			const formWithTable = formularios.find((f) => f.FormularioId === 1);
			if (!formWithTable) {
				// Si no se encuentra el formulario, cambiar el estado y salir
				updateProcesoEstado('Acabada');
				return;
			}

			// Pasar explícitamente el año actual a getProcessesToCreate
			const {
				processes,
				formularios: formulariosMap,
				anyo,
			} = getProcessesToCreate(
				respuestasFormateadas,
				formWithTable.FormularioId,
				procesoId,
				anyoActual
			);

			// Loguear información de procesos a crear
			debug(
				'==================== PROCESOS A CREAR AL ACABAR REQUISITO ===================='
			);
			debug('Procesos que se van a crear:', processes);
			debug('Año que se usará para los nuevos procesos:', anyoActual);
			processes.forEach((processId) => {
				debug(`Proceso ${processId}:`, {
					formularios: Array.from(formulariosMap.get(processId) || []),
				});
			});
			debug(
				'================================================================================'
			);

			// 3. Crear cada proceso con sus formularios específicos
			for (const procesosId of processes) {
				const formularioIds = formulariosMap.get(procesosId);
				// Pasar explícitamente el año actual a crearProceso
				await crearProceso(procesosId, formularioIds, formularios, anyoActual);
			}

			// 4. Finalmente, cambiar el estado a Acabada
			updateProcesoEstado('Acabada');
		} catch (error) {
			debugError('Error en el proceso de acabar requisito:', error);
			setError('Error al acabar el requisito: ' + error.message);
		}
	};

	const handleRevisarRequisito = () => {
		setIsRevisionModalOpen(true);
	};

	// MODIFICADA para usar fetchWithAuth
	const updateProcesoEstado = (nuevoEstado) => {
		const nombreEmpresa = formularios[0]?.nombreEmpresa;
		const formulariosParaCompletar =
			filterFormulariosByDateAndName(formularios);
		const fechaCreacion = new Date(
			formulariosParaCompletar[0]?.fechaCreacion
		).toLocaleString('sv-SE');

		// Extraer el año del formulario o usar anyoActual
		const anyo = formulariosParaCompletar[0]?.anyo || anyoActual;

		const payload = {
			nombreEmpresa,
			procesoId: parseInt(procesoId),
			estadoEmpresas: nuevoEstado,
			fechaCreacion,
			anyo,
		};

		fetchWithAuth(ROUTE_COMPANY_COMPLETE, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		})
			.then((response) => {
				if (!response.ok)
					throw new Error(`Error al cambiar el estado a ${nuevoEstado}`);
				return response.json();
			})
			.then(() => {
				navigate('/casos');
			})
			.catch((error) => {
				debugError(`Error al cambiar el estado a ${nuevoEstado}:`, error);
			});
	};

	const filterFormulariosByDateAndName = (formularios) => {
		if (!formularios || formularios.length === 0) {
			debugWarn('No hay formularios para filtrar');
			return [];
		}

		// Log para depuración
		debug('Formularios antes de filtrar:', formularios);

		// Obtener el año actual de la URL o del primer formulario
		const searchParams = new URLSearchParams(window.location.search);
		const anyo =
			searchParams.get('anyo') ||
			(formularios.length > 0
				? formularios[0].anyo ||
				  new Date(formularios[0].fechaCreacion).getFullYear()
				: new Date().getFullYear());

		debug(`Filtrando formularios para año: ${anyo}`);

		// Usar un filtro más permisivo
		const filtrados = formularios.filter((formulario) => {
			// Usar directamente el campo anyo si existe
			const formularioAnyo =
				formulario.anyo || new Date(formulario.fechaCreacion).getFullYear();

			const coincidencia =
				formulario.nombreEmpresa === formularios[0]?.nombreEmpresa &&
				parseInt(formularioAnyo) === parseInt(anyo);

			debug(
				`Evaluando formulario ${formulario.FormularioId}: año ${formularioAnyo}, coincide: ${coincidencia}`
			);

			return coincidencia;
		});

		debug(`Formularios filtrados: ${filtrados.length}`, filtrados);
		return filtrados;
	};

	// Props vacías para el modo lectura
	const emptyProps = {
		handleEditorChange: () => {},
		handleFileChange: () => {},
		handleSubmit: () => {},
		toggleRedirigir: () => {},
		handleAreaChange: () => {},
		handleDepartamentoChange: () => {},
		selectedArea: {},
		selectedDepartamento: {},
		redirigirPregunta: {},
		quillRefs: {},
		handleQuillRef: () => {},
		// Añadir esta función para solucionar el error:
		esRespuestaValida: (formularioId, pregunta) => {
			// En modo lectura, simplemente devolver true es seguro
			return true;
		},
	};

	// Añadir función para manejar el envío de la revisión - MODIFICADA para usar fetchWithAuth
	const handleSubmitRevision = (mensaje) => {
		const nombreEmpresa = formularios[0]?.nombreEmpresa;

		// Si filterFormulariosByDateAndName no funciona, usar directamente el primer formulario
		let formulariosParaCompletar = filterFormulariosByDateAndName(formularios);
		if (!formulariosParaCompletar || formulariosParaCompletar.length === 0) {
			debugWarn(
				'No se obtuvieron formularios mediante filterFormulariosByDateAndName, usando formularios directamente'
			);
			formulariosParaCompletar = formularios;
		}

		if (!formulariosParaCompletar || formulariosParaCompletar.length === 0) {
			debugError('No hay formularios disponibles para completar');
			setError('No hay formularios disponibles para completar');
			return;
		}

		// Asegurarnos de tener una fecha válida
		let fechaCreacion;
		try {
			fechaCreacion = new Date(
				formulariosParaCompletar[0]?.fechaCreacion
			).toLocaleString('sv-SE');
		} catch (e) {
			debugWarn('Error al formatear la fecha, usando fecha actual');
			fechaCreacion = new Date().toLocaleString('sv-SE');
		}

		const payload = {
			nombreEmpresa,
			procesoId: parseInt(procesoId),
			estadoEmpresas: 'Revision',
			fechaCreacion,
			mensajerevision: mensaje,
			anyo: anyoActual,
		};
		debug('Payload para revisión:', JSON.stringify(payload, null, 2));

		fetchWithAuth(ROUTE_COMPANY_COMPLETE, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(payload),
		})
			.then((response) => {
				if (!response.ok) {
					// Intentar obtener el mensaje de error del servidor
					return response.text().then((errorText) => {
						debugError('Error del servidor:', errorText);
						throw new Error('Error al cambiar el estado a Revision');
					});
				}
				return response.json();
			})
			.then(() => {
				setIsRevisionModalOpen(false);
				navigate('/casos');
			})
			.catch((error) => {
				debugError('Error al cambiar el estado a Revision:', error);
			});
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>
				{t('vistaFormulario.vistaFormulario', 'Vista de')} {nombreProceso}{' '}
				{t('vistaProcesoAcabado.deEmpresa', 'de la empresa')} {nombreEmpresa}
			</h1>

			{error && <p className={styles.errorText}>{error}</p>}

			{formularios.length > 0 ? (
				<div className='w-full'>
					{debug('🧩 Renderizando formularios con:', {
						formularios: formularios.length,
						respuestasKeys: Object.keys(respuestas).length,
						respuestasEnviadasKeys: Object.keys(respuestasEnviadas).length,
					})}
					{formularios.map((formulario) => (
						<FormularioCard
							key={formulario.FormularioId}
							formulario={formulario}
							t={t}
							mostrarPreguntas={mostrarPreguntas}
							togglePreguntas={togglePreguntas}
							empresaId={empresaId}
							procesoId={procesoId}
							respuestasEnviadas={respuestasEnviadas}
							respuestas={respuestas}
							language={language}
							{...emptyProps}
						/>
					))}

					{/* Modificado: Solo mostrar los botones si el usuario es ESG Y puede enviar requisitos */}
					{isESG && canSendRequirements && (
						<div className={styles.buttonContainer}>
							{formularios.length > 0 &&
								formularios[0].estadoEmpresas !== 'Acabada' && (
									<>
										<button
											onClick={handleAcabarRequisito}
											className={`${styles.button.base} ${styles.button.success}`}>
											{t('vistaFormulario.acabarRequisito', 'Acabar Requisito')}
										</button>
										<button
											onClick={handleRevisarRequisito}
											className={`${styles.button.base} ${styles.button.primary}`}>
											{t(
												'vistaFormulario.revisarRequisito',
												'Revisar Requisito'
											)}
										</button>
									</>
								)}
						</div>
					)}
					<RevisionModal
						isOpen={isRevisionModalOpen}
						onClose={() => setIsRevisionModalOpen(false)}
						onSubmit={handleSubmitRevision}
					/>
				</div>
			) : (
				<p className='text-gray-600'>
					{t(
						'vistaFormulario.cargandoFormulario',
						'Cargando formularios de la empresa...'
					)}
				</p>
			)}
		</div>
	);
}

export default VistaProcesoAcabado;
