//frontend/src/config/questionConfig.js
import { debug } from '../utils/debugHelper';
import i18next from 'i18next';

// Función auxiliar para buscar traducciones en el objeto de opciones
const findTranslationInOptions = (options, normalizedOption) => {
	// Intentar búsqueda directa
	if (options && typeof options === 'object' && options[normalizedOption]) {
		return options[normalizedOption];
	}

	return null;
};

// Función auxiliar para casos con dos puntos
const handleColonCase = (options, normalizedOption) => {
	if (!normalizedOption.endsWith(':')) {
		return null;
	}

	// Probar con doble punto al final (a veces aparece así)
	const withDoubleColon = normalizedOption + ':';
	if (options[withDoubleColon]) {
		return options[withDoubleColon];
	}

	// Probar sin punto al final
	const withoutColon = normalizedOption.slice(0, -1);
	if (options[withoutColon]) {
		return options[withoutColon];
	}

	return null;
};

// Función principal refactorizada
export const getTranslatedOption = (option, language = 'ES') => {
	// Si no es inglés, devolvemos la opción original
	if (language !== 'EN') {
		return option;
	}

	// Normalizar la opción antes de buscarla (remover posibles espacios extras)
	const normalizedOption = option.trim();

	// Obtener todas las opciones traducidas
	const options = i18next.t('questionConditional.options', {});

	// Intentar búsqueda directa
	const directTranslation = findTranslationInOptions(options, normalizedOption);
	if (directTranslation) {
		return directTranslation;
	}

	// Intentar alternativas con dos puntos
	const colonTranslation = handleColonCase(options, normalizedOption);
	if (colonTranslation) {
		return colonTranslation;
	}

	// Casos especiales para Si/No
	if (option === 'Si') {
		return i18next.t('questionConditional.yes', 'Yes');
	}

	// Si todo falla, devolver la opción original
	return option;
};

// Función para obtener opciones de pregunta con traducciones
export const getTranslatedQuestionOptions = (key, language = 'ES') => {
	const config = questionOptions[key];
	if (!config) return null;

	// Crear una copia del objeto con las opciones traducidas
	return {
		...config,
		positiveOption: getTranslatedOption(config.positiveOption, language),
		negativeOption: getTranslatedOption(config.negativeOption, language),
	};
};
export const conditionalQuestionOptions = {
	// Opciones para pregunta 13 del formulario IRO-1
};

export const questionOptions = {
	'2-1-1': {
		positiveOption: 'Individual',
		negativeOption: 'Consolidada',
		controlledQuestions: ['2', '3'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'3-1-1': {
		positiveOption: 'Si',
		negativeOption: 'No',
		controlledQuestions: ['2'],
		controlBehavior: 'negative', // Las preguntas se autorrellanan/ocultan cuando la respuesta es negativa
		positiveLabel:
			'Ha seleccionado Si. Además, por favor, utilice el editor de texto para especificar el horizonte temporal al que se refiere, corto plazo 1 año, medio plazo 3 años, largo plazo mas de 5 años.',
		showLabelOnPositive: true,
	},
	'3-1-3': {
		positiveOption: 'Si',
		negativeOption: 'No',
		controlledQuestions: ['4', '5', '6'],
		controlBehavior: 'negative', // Las preguntas se autorrellanan/ocultan cuando la respuesta es negativa
		positiveLabel:
			'Por favor, proporcione una lista detallada de estas métricas...',
		showLabelOnPositive: true,
	},
	'3-1-10': {
		positiveOption: 'No se han realizado cambios',
		negativeOption: 'Se han producido los siguientes cambios:',
		controlledQuestions: ['11', '12'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'3-1-13': {
		positiveOption:
			'No se han identificado errores materiales de ejercicios anteriores',
		negativeOption:
			'Si ha identificado errores materiales de ejercicios anteriores debe revelar la naturaleza de los mismos:',
		controlledQuestions: ['14', '15'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'3-1-16': {
		positiveOption: 'No se ha incluido este tipo de información',
		negativeOption:
			'Si, se ha incluido este tipo de información. Indique qué otras legislaciones, normas o marcos generalmente aceptados han sido utilizados:',
		controlledQuestions: ['17'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'3-1-20': {
		positiveOption: 'No se ha incorporado información por referencia',
		negativeOption:
			'Si, se ha incorporado, indique a qué sección o documento específico se ha hecho referencia. Proporcione una lista detallada de los requisitos de divulgación de ESRS (o puntos de datos específicos) que se han incorporado por referencia:',
	},
	'3-1-21': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['22', '23', '24', '25', '26', '27'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'4-1-13': {
		positiveOption: 'Si, es aplicable',
		negativeOption: 'No, es aplicable',
		positiveLabel:
			'Describa el grado y la forma en que el proceso de identificación, evaluación y gestión de oportunidades está integrado en el proceso general de gestión de la empresa',
		showLabelOnPositive: true,
	},
	'10-1-9': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['10', '11', '12', '13', '14'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'10-1-15': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['16'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'10-1-17': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['18'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'10-1-19': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['20'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando se selecciona negativeOption
	},
	'11-1-8': {
		positiveOption:
			'Si se han producido cambios en la estrategia o modelo de negocio',
		negativeOption: 'No se han producido cambios',
		positiveLabel: 'Descríbalos:',
		showLabelOnPositive: true,
	},
	'11-1-9': {
		positiveOption:
			'Si se han modificado o se prevé modificar la estrategia y (o) el modelo de negocio para tener en cuenta los intereses y opiniones de las partes interesadas',
		negativeOption:
			'No se han modificado ni se prevé modificar la estrategia y (o) el modelo de negocio para tener en cuenta los intereses y opiniones de las partes interesadas',
		positiveLabel: 'Descríbalos:',
		showLabelOnPositive: true,
	},
	'11-1-10': {
		positiveOption:
			'Se mantienen los procesos vigentes para la integración de los intereses y opiniones de las partes interesadas',
		negativeOption:
			'Se han definido nuevos procesos para la integración de los intereses y opiniones de las partes interesadas',
		positiveLabel: 'Descríbalos:',
		showLabelOnPositive: true,
	},
	'11-1-11': {
		positiveOption:
			'Si es probable que los nuevos pasos previstos modifiquen la relación con las partes interesadas',
		negativeOption:
			'No es probable que los nuevos procesos previstos modifiquen la relación con las partes interesadas',
		positiveLabel: 'Descríbalos:',
		showLabelOnPositive: true,
	},

	'28-4-6': {
		positiveOption: 'No tenemos divulgacion',
		negativeOption: 'Si esta es nuestra divulgacion:',
		controlledQuestions: ['7', '8'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},

	'42-6-2': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['3', '4', '5', '6', '7', '8'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},

	'43-6-1': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['2', '3', '4', '5', '6', '7', '8', '9'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},

	'54-8-1': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['2', '3', '4', '5', '6', '7'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'72-9-1': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['2', '3', '4', '5', '6', '7', '8', '9'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'78-10-1': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['2', '3', '4', '5', '6', '7', '8', '9'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'84-11-1': {
		positiveOption: 'No',
		negativeOption: 'Si',
		controlledQuestions: ['2', '3', '4', '5', '6', '7', '8'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'89-12-3': {
		positiveOption: 'Si',
		negativeOption: 'No',
		controlledQuestions: ['4', '5'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
	'89-12-6': {
		positiveOption: 'Si',
		negativeOption: 'No',
		controlledQuestions: ['7', '8'],
		controlBehavior: 'positive', // Las preguntas se autorrellanan/ocultan cuando la respuesta es positiva
	},
};

export const tableConfigs = {
	'1-1-1': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 3,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 2,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS E1 Cambio Climatico',
		},
		rows: [
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación',
				PilarDeDivulgacion: 'DR related to ESRS 2 GOV-3',
				RequisitoDeDivulgacion:
					'Integración del desempeño relacionado con la sostenibilidad en los esquemas de incentivos rendimiento vinculado a los objetivos de reducción de emisiones de GEI',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 13, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'DR related to ESRS 2 SBM-3',
				RequisitoDeDivulgacion:
					'Impactos, riesgos y oportunidades materiales y su interacción con la estrategia y el modelo de negocio, aspectos materiales (IRO), incluida la resiliencia, en relación con la mitigación del cambio climático',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 15, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'DR related to ESRS 2 IRO-1',
				RequisitoDeDivulgacion:
					'Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con el clima. Procesos para identificar y evaluar impactos materiales relacionados con la mitigación del cambio climático (como las emisiones de GEI), así como riesgos y oportunidades de transición',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 16, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación',
				PilarDeDivulgacion: 'E1-1',
				RequisitoDeDivulgacion:
					'Plan de transición para la mitigación del cambio climático',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 14, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'E1-2',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con la mitigación del cambio climático y la adaptación al mismo',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 17, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'E1-3',
				RequisitoDeDivulgacion:
					'Actuaciones y recursos en relación con las políticas en materia de cambio climático',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 18, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'E1-4',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la mitigación del cambio climático y la adaptación al mismo',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 19, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Energía',
				PilarDeDivulgacion: 'E1-5',
				RequisitoDeDivulgacion: 'Consumo y combinación energéticos',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 20, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación',
				PilarDeDivulgacion: 'E1-6',
				RequisitoDeDivulgacion:
					'Emisiones de GEI brutas de alcance 1, 2 y 3 y emisiones de GEI totales',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 21, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación',
				PilarDeDivulgacion: 'E1-7',
				RequisitoDeDivulgacion:
					'Absorciones de GEI y proyectos de mitigación de GEI financiados mediante créditos de carbono',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 22, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación',
				PilarDeDivulgacion: 'E1-8',
				RequisitoDeDivulgacion:
					'Sistema de fijación del precio interno del carbono',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 23, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E1',
				Subtopic: 'Mitigación - Adaptación - Energía',
				PilarDeDivulgacion: 'E1-9',
				RequisitoDeDivulgacion:
					'Efectos financieros previstos de los riesgos físicos y de transición de importancia relativa y oportunidades potenciales relacionadas con el cambio climático',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 3,
						disableFormulario: 24, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
		],
	},
	'1-1-9': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 4,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 10,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS E2 Contaminación',
		},
		rows: [
			{
				Topic: 'ESRS E2',
				Subtopic:
					'Contaminación del aire, agua y suelo - Contaminación de organismos vivos y recursos alimenticios - Sustancias de alta preocupación - Microplásticos',
				PilarDeDivulgacion: 'DR related to ESRS 2 IRO-1',
				RequisitoDeDivulgacion:
					'Descripción de los procesos para identificar y evaluar materiales relacionados con la contaminación del aire, agua o suelo',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 25, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic:
					'Contaminación del aire, agua y suelo - Contaminación de organismos vivos y recursos alimenticios - Sustancias de alta preocupación - Microplásticos',
				PilarDeDivulgacion: 'E2-1',
				RequisitoDeDivulgacion:
					'Divulgación de cómo la política aborda la mitigación de los impactos negativos relacionados con la contaminación',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 26, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic:
					'Contaminación del aire, agua y suelo - Contaminación de organismos vivos y recursos alimenticios - Sustancias de alta preocupación - Microplásticos',
				PilarDeDivulgacion: 'E2-2',
				RequisitoDeDivulgacion:
					'Información sobre los planes de acción que se han implementado a nivel de sitio (contaminación).',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 27, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic:
					'Contaminación del aire, agua y suelo - Contaminación de organismos vivos y recursos alimenticios - Sustancias de alta preocupación - Microplásticos',
				PilarDeDivulgacion: 'E2-3',
				RequisitoDeDivulgacion:
					'Información sobre los objetivos que se han implementado a nivel de sitio (contaminación).',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 28, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic: 'Contaminación del aire, agua y suelo - Microplásticos',
				PilarDeDivulgacion: 'E2-4',
				RequisitoDeDivulgacion: 'Cantidades de emisiones de contaminantes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 29, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic: 'Sustancias de alta preocupación',
				PilarDeDivulgacion: 'E2-5',
				RequisitoDeDivulgacion:
					'Producción, uso, distribución, comercialización e importación/exportación de sustancias preocupantes y sustancias extremadamente preocupantes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 30, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E2',
				Subtopic:
					'Contaminación del aire, agua y suelo - Contaminación de organismos vivos y recursos alimenticios - Sustancias de alta preocupación - Microplásticos',
				PilarDeDivulgacion: 'E2-6',
				RequisitoDeDivulgacion:
					'Efectos financieros anticipados derivados de riesgos y oportunidades materiales relacionados con la contaminación (relacionados con la materialidad financiera)',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 4,
						disableFormulario: 31, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-17': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 5,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 18,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS E3 Recursos hídricos y marinos',
		},
		rows: [
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua - Recursos Marinos',
				PilarDeDivulgacion: 'DR related to ESRS 2 IRO-1',
				RequisitoDeDivulgacion:
					'Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con los recursos hídricos y marinos',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 32, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua - Recursos Marinos',
				PilarDeDivulgacion: 'E3-1',
				RequisitoDeDivulgacion:
					'Divulgación de cómo la política aborda la mitigación de los impactos negativos relacionados con la contaminación',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 33, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua - Recursos Marinos',
				PilarDeDivulgacion: 'E3-2',
				RequisitoDeDivulgacion:
					'Información sobre los planes de acción que se han implementado a nivel de sitio (contaminación).',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 34, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua - Recursos Marinos',
				PilarDeDivulgacion: 'E3-3',
				RequisitoDeDivulgacion:
					'Información sobre los objetivos que se han implementado a nivel de sitio (contaminación).',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 35, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua',
				PilarDeDivulgacion: 'E3-4',
				RequisitoDeDivulgacion: 'Cantidades de emisiones de contaminantes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 36, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E3',
				Subtopic: 'Agua - Recursos Marinos',
				PilarDeDivulgacion: 'E3-5',
				RequisitoDeDivulgacion:
					'Producción, uso, distribución, comercialización e importación/exportación de sustancias preocupantes y sustancias extremadamente preocupantes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 5,
						disableFormulario: 37, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-25': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 6,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 26,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS E4 Biodiversidad y ecosistemas',
		},
		rows: [
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'DR related to ESRS 2 IRO-1',
				RequisitoDeDivulgacion:
					'Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con la biodiversidad y los ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 38, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'DR related to ESRS 2 SBM-3',
				RequisitoDeDivulgacion:
					'Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 39, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-1',
				RequisitoDeDivulgacion:
					'Plan de transición y consideración de la biodiversidad y los ecosistemas en la estrategia y el modelo de negocio',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 40, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-2',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con la biodiversidad y los ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 41, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-3',
				RequisitoDeDivulgacion:
					'Acciones y recursos relacionados con la biodiversidad y los ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 42, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-4',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la biodiversidad y los ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 43, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-5',
				RequisitoDeDivulgacion:
					'Métricas de impacto relacionadas con los cambios en biodiversidad y ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 95, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E4',
				Subtopic: 'Biodiversidad y ecosistemas',
				PilarDeDivulgacion: 'E4-6',
				RequisitoDeDivulgacion:
					'Efectos financieros anticipados de los impactos, riesgos y oportunidades relacionados con la biodiversidad y los ecosistemas',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 6,
						disableFormulario: 44, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-33': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 7,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 34,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS E5 Uso de recursos y economía circular',
		},
		rows: [
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Entradas de recursos, incluido el uso de recursos - Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'DR related to ESRS 2 IRO-1',
				RequisitoDeDivulgacion:
					'Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con el uso de recursos y la economía circular',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 45, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Entradas de recursos, incluido el uso de recursos - Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'E5-1',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con el uso de recursos y la economía circular',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 46, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Entradas de recursos, incluido el uso de recursos - Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'E5-2',
				RequisitoDeDivulgacion:
					'Acciones y recursos relacionados con el uso de recursos y la economía circulars',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 47, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Entradas de recursos, incluido el uso de recursos - Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'E5-3',
				RequisitoDeDivulgacion:
					'Metas relacionadas con el uso de recursos y la economía circular',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 48, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic: 'Entradas de recursos, incluido el uso de recursos',
				PilarDeDivulgacion: 'E5-4',
				RequisitoDeDivulgacion: 'Entradas de recursoss',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 49, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'E5-5',
				RequisitoDeDivulgacion: 'Entradas de recursos de productos y servicios',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 50, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS E5',
				Subtopic:
					'Entradas de recursos, incluido el uso de recursos - Salidas de recursos relacionadas con productos y servicios - Residuos',
				PilarDeDivulgacion: 'E5-6',
				RequisitoDeDivulgacion:
					'Efectos financieros anticipados de los impactos, riesgos y oportunidades relacionados con el uso de recursos y la economía circular',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 7,
						disableFormulario: 51, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-41': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 8,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 42,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS S1 Fuerza laboral propia',
		},
		rows: [
			{
				Topic: 'ESRS S1',
				Subtopic: 'Estrategia',
				PilarDeDivulgacion: 'DR related to ESRS 2 SMB-3',
				RequisitoDeDivulgacion:
					'Los intereses de los trabajadores y los impactos materiales en su modelo comercial y estrategia',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 52, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S1-1',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con el personal propio ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 53, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S1-2',
				RequisitoDeDivulgacion:
					'Procesos para colaborar con los trabajadores propios y los representantes de los trabajadores en materia de incidencias ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 54, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S1-3',
				RequisitoDeDivulgacion:
					' Procesos para reparar las incidencias negativas y canales para que los trabajadores propios ex presen sus inquietudes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 55, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S1-4',
				RequisitoDeDivulgacion:
					'Adopción de medidas relacionadas con las incidencias de importancia relativa sobre el personal propio, enfoques para mitigar los riesgos de importancia relativa y aprovechar las oportunidades de importancia relativa relacionados con el p ersonal propio y eficacia de dichas actuaciones ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 56, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-5',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la gestión de incidencias negativas de importancia relativa, el impulso de incidencias positivas y la gestión de riesgos y oportunidades de importancia relativa ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 57, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-6',
				RequisitoDeDivulgacion:
					'Características de los asalariados de la empresa',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 58, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-7',
				RequisitoDeDivulgacion:
					'Características de los trabajadores no asalariados en el personal propio de la empresa',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 59, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-8',
				RequisitoDeDivulgacion:
					'Cobertura de la negociación colectiva y diálogo social',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 60, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-9',
				RequisitoDeDivulgacion: 'Parámetros de diversidad',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 61, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-10',
				RequisitoDeDivulgacion: 'Salarios adecuados',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 62, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-11',
				RequisitoDeDivulgacion: 'Protección social',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 63, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-12',
				RequisitoDeDivulgacion: 'Personas con discapacidad',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 64, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-13',
				RequisitoDeDivulgacion:
					'Parámetros de formación y desarrollo de capacidades',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 65, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-14',
				RequisitoDeDivulgacion: 'Parámetros de salud y seguridad',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 66, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-15',
				RequisitoDeDivulgacion: 'Parámetros de conciliación laboral',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 67, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-16',
				RequisitoDeDivulgacion:
					'Parámetros de retribución (brecha salarial y retribución total)',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 68, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S1',
				Subtopic: 'Parametros y metas',
				PilarDeDivulgacion: 'S1-17',
				RequisitoDeDivulgacion:
					'Incidentes, reclamaciones e incidencias graves relacionados con los derechos humanos',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 8,
						disableFormulario: 69, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},

			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-49': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 9,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 50,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS S2 Trabajadores en la Cadena de Valor',
		},
		rows: [
			{
				Topic: 'ESRS S2',
				Subtopic: 'Estrategia',
				PilarDeDivulgacion: 'DR related to ESRS 2 SMB-3',
				RequisitoDeDivulgacion:
					'Los intereses de los trabajadores de la cadena de valor y los impactos materiales en su modelo comercial y estrategia',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 70, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S2',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S2-1',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con los trabajadores de la cadena de valor',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 71, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S2',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S2-2',
				RequisitoDeDivulgacion:
					'Procesos para colaborar con los trabajadores de la cadena de valor en materia de incidencias',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 72, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S2',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S2-3',
				RequisitoDeDivulgacion:
					'Procesos para reparar las incidencias negativas y canales para que los trabajadores de la cadena de valor expresen sus inquietudes ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 73, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S2',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S2-4',
				RequisitoDeDivulgacion:
					' Adopción de medidas relacionadas con las incidencias de importancia relativa sobre los trabajadores de la cadena de valor, enfoques para gestionar los riesgos de importancia relativa y aprovechar las oportunidades de importancia relativa relacionados con los trabajadores de la cadena de valor y la eficacia de dichas actuaciones',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 74, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S2',
				Subtopic: 'Parámetros y metas',
				PilarDeDivulgacion: 'S2-5',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la gestión de incidencias negativas de importancia relativa, el impulso de incidencias positivas y la gestión de riesgos y oportunidades de importancia relativa',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 9,
						disableFormulario: 75, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},

			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-57': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 10,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 58,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS S3 Colectivos afectados',
		},
		rows: [
			{
				Topic: 'ESRS S3',
				Subtopic: 'Estrategia',
				PilarDeDivulgacion: 'DR related to ESRS 2 SMB-3',
				RequisitoDeDivulgacion:
					'Los intereses de los colectivos afectados y los impactos materiales en su modelo comercial y estrategia',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 76, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S3',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S3-1',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con los colectivos afectados',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 77, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S3',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S3-2',
				RequisitoDeDivulgacion:
					'Procesos para colaborar con los colectivos afectados en materia de incidencia',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 78, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S3',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S3-3',
				RequisitoDeDivulgacion:
					'Procesos para reparar las incidencias negativas y canales para que los colectivos afectados expresen sus inquietudes',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 79, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S3',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S3-4',
				RequisitoDeDivulgacion:
					' Adopción de medidas relacionadas con las incidencias de importancia relativa sobre los colectivos afectados, enfoques para gestionar los riesgos de importancia relativa y aprovechar las oportunidades de importancia relativa relacionados ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 80, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S3',
				Subtopic: 'Parámetros y metas',
				PilarDeDivulgacion: 'S3-5',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la gestión de incidencias negativas de importancia relativa, el impulso de incidencias positivas y la gestión de riesgos y oportunidades de importancia relativa',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 10,
						disableFormulario: 81, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},

			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-65': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 11,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 66,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS S4 Consumidores y usuarios finales',
		},
		rows: [
			{
				Topic: 'ESRS S4',
				Subtopic: 'Estrategia',
				PilarDeDivulgacion: 'DR related to ESRS 2 SMB-3',
				RequisitoDeDivulgacion:
					'Los intereses de los consumidores y los impactos materiales en su modelo comercial y estrategia',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 82, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S4',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S4-1',
				RequisitoDeDivulgacion:
					'Políticas relacionadas con los consumidores y usuariosfinales',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 83, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S4',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S4-2',
				RequisitoDeDivulgacion:
					'Procesos para colaborar con los consumidores y usuarios finales en materia de incidencias ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 84, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S4',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S4-3',
				RequisitoDeDivulgacion:
					'Procesos para reparar las incidencias negativas y canales para que los consumidores y usuarios finales expresen sus inquietudes ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 85, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S4',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'S4-4',
				RequisitoDeDivulgacion:
					'Adopción de medidas relacionadas con las incidencias de importancia relativa sobre los consumidores y usuarios finales, enfoques para mitigar los riesgos de importancia relativa y aprovechar las oportunidades de importancia relativa relacionados con los consumidores y usuarios finales y la eficacia de dichas actuaciones ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 86, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS S4',
				Subtopic: 'Parámetros y metas',
				PilarDeDivulgacion: 'S4-5',
				RequisitoDeDivulgacion:
					'Metas relacionadas con la gestión de incidencias negativas de importancia relativa, el impulso de incidencias positivas y la gestión de riesgos y oportunidades de importancia relativa',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 11,
						disableFormulario: 87, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},

			// ... (continúan todas las mismas filas)
		],
	},
	'1-1-73': {
		isHideable: true,
		showConditions: {
			positiveOption: 'Sí es material',
			negativeOption: 'No es material',
			onPositive: {
				type: 'table',
			},
			onNegative: {
				disableProcess: 12,
				showQuestion: {
					processId: 1,
					formularioId: 1,
					questionId: 74,
				},
			},
		},
		headers: {
			leftHeader: 'AR 16',
			rightHeader: 'ESRS G1 Conducta empresarial',
		},
		rows: [
			{
				Topic: 'ESRS G1',
				Subtopic: 'Gobernanza',
				PilarDeDivulgacion: 'GOV-1',
				RequisitoDeDivulgacion:
					'El papel de los órganos de administración, supervisión y gestión',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 88, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'G1-1',
				RequisitoDeDivulgacion:
					'Cultura corporativa y políticas de conducta empresarial ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 89, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'G1-2',
				RequisitoDeDivulgacion: 'Gestión de las relaciones con los proveedores',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 90, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Gestion de incidencias',
				PilarDeDivulgacion: 'G1-3',
				RequisitoDeDivulgacion:
					'Prevención y detección de la corrupción y el soborno',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 91, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Parámetros y metas ',
				PilarDeDivulgacion: 'G1-4',
				RequisitoDeDivulgacion: ' Casos confirmados de corrupción o soborno ',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 92, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Parámetros y metas',
				PilarDeDivulgacion: 'G1-5',
				RequisitoDeDivulgacion:
					'Influencia política y actividades de los grupos de presión',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 93, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
			{
				Topic: 'ESRS G1',
				Subtopic: 'Parámetros y metas',
				PilarDeDivulgacion: 'G1-6',
				RequisitoDeDivulgacion:
					'Influencia política y actividades de los grupos de presión',
				defaultChecked: false,
				processConfig: {
					whenTrue: {
						processId: 12,
						disableFormulario: 94, // Este checkbox desactiva el formulario 1 del proceso 1
					},
				},
			},
		],
	},
};

export const getProcessesToCreate = (
	respuestas,
	formularioId,
	procesoId,
	anyo
) => {
	const processesToCreate = new Set();
	const formulariosMap = new Map();
	const tablesToProcess = [1, 9, 17, 25, 33, 41, 49, 57, 65, 73];

	// Función para obtener formularios por proceso desde la configuración
	const getFormulariosForProcess = (processId) => {
		const formulariosSet = new Set();
		tablesToProcess.forEach((tableId) => {
			const config = tableConfigs[`${formularioId}-${procesoId}-${tableId}`];
			if (config) {
				config.rows.forEach((row) => {
					if (row.processConfig?.whenTrue?.processId === processId) {
						if (row.processConfig.whenTrue.disableFormulario) {
							formulariosSet.add(row.processConfig.whenTrue.disableFormulario);
						}
					}
				});
			}
		});
		return Array.from(formulariosSet);
	};

	// Función que procesa cada fila cuando hay un checkbox marcado
	const procesarFilaConCheckbox = (
		processId,
		disableFormulario,
		checkboxMarcado,
		formulariosMap
	) => {
		if (!processId) return;

		processesToCreate.add(processId);

		// Inicializar array con formularios reales del proceso
		if (!formulariosMap.has(processId)) {
			formulariosMap.set(processId, getFormulariosForProcess(processId));
		}

		// Si el checkbox está marcado, quitar ese formulario
		if (checkboxMarcado && disableFormulario) {
			const formularios = formulariosMap.get(processId);
			const index = formularios.indexOf(disableFormulario);
			if (index > -1) {
				formularios.splice(index, 1);
			}
		}
	};

	// Función que procesa la configuración de una tabla
	const procesarTabla = (
		tableId,
		respuestas,
		tableConfigs,
		formularioId,
		procesoId,
		formulariosMap
	) => {
		const tableConfig = tableConfigs[`${formularioId}-${procesoId}-${tableId}`];
		const response = respuestas[`${formularioId}-${tableId}`]?.text;

		// Si no hay configuración o respuesta, o la respuesta es "negative", saltamos
		if (!tableConfig || !response || response === 'negative') {
			return;
		}

		const checkboxStates = response.split(', ').map((item) => {
			const [, value] = item.split(' - ');
			return value === 'True';
		});

		tableConfig.rows.forEach((row, index) => {
			if (row.processConfig?.whenTrue) {
				const { processId, disableFormulario } = row.processConfig.whenTrue;
				procesarFilaConCheckbox(
					processId,
					disableFormulario,
					checkboxStates[index],
					formulariosMap
				);
			}
		});
	};

	// Función principal refactorizada
	tablesToProcess.forEach((tableId) => {
		procesarTabla(
			tableId,
			respuestas,
			tableConfigs,
			formularioId,
			procesoId,
			formulariosMap
		);
	});

	const result = {
		processes: Array.from(processesToCreate),
		formularios: formulariosMap,
		anyo: anyo, // Añadimos el año aquí para pasarlo a los procesos a crear
	};

	debug(
		'==================== PROCESOS Y FORMULARIOS A CREAR ===================='
	);
	debug('Procesos a crear:', result.processes);
	debug('Formularios por proceso:');
	result.formularios.forEach((formularios, processId) => {
		debug(`Proceso ${processId}:`, formularios);
	});
	debug('Año que se usará para los nuevos procesos:', result.anyo);
	debug('====================================================================');

	return result;
};
