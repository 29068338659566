// frontend/src/hooks/questions/useQuestionState.js
import { useState } from 'react';

export const useQuestionState = (pregunta) => {
	const [tableState, setTableState] = useState(
		pregunta.tableConfig?.rows
			? pregunta.tableConfig.rows.map(() => ({ checked: false }))
			: []
	);

	const getQuestionState = (
		formularioId,
		empresaId,
		procesoId,
		preguntaId,
		respuestasEnviadas,
		userId
	) => {
		const respuestaKey = `${formularioId}-${empresaId}-${procesoId}-${preguntaId}`;
		const respuestaGuardada = respuestasEnviadas[respuestaKey];
		const isRedirigida = respuestaGuardada?.estadopregunta === 'Redirigida';
		const hasRespuesta = !!respuestaGuardada;

		// La lógica normal: si no hay respuesta o el usuario actual es quien respondió
		let canEdit =
			!hasRespuesta || respuestaGuardada?.respondidoporuserid === userId;

		return {
			respuestaKey,
			respuestaGuardada,
			isRedirigida,
			hasRespuesta,
			canEdit,
		};
	};

	return {
		tableState,
		setTableState,
		getQuestionState,
	};
};
