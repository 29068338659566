import React, { useState } from 'react';
import './CreateUser.css';
import useUserCompany from '../../hooks/useUserCompany';
import useUserLanguage from '../../hooks/useUserLanguage'; // Importar el hook de idioma
import { areaOptions } from '../../config/areaOptions';
import { areaLabels } from '../../config/areaLabels';

function CreateUser() {
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [idioma, setIdioma] = useState('ES');
  const { empresa, error } = useUserCompany();
  const { language } = useUserLanguage(); // Obtener el idioma del usuario
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [departamentos, setDepartamentos] = useState({});

  const handleAreaChange = (area) => {
    setSelectedAreas(prevSelectedAreas =>
      prevSelectedAreas.includes(area)
        ? prevSelectedAreas.filter(a => a !== area)
        : [...prevSelectedAreas, area]
    );

    if (!departamentos[area]) {
      setDepartamentos(prev => ({
        ...prev,
        [area]: areaOptions[area].reduce((acc, dept) => {
          acc[dept] = { ver: area === "ESG" ? true : false, editar: false, enviar: false };
          return acc;
        }, {})
      }));
    }
  };

  const handlePermissionChange = (area, dept, perm) => {
    setDepartamentos(prev => ({
      ...prev,
      [area]: {
        ...prev[area],
        [dept]: {
          ...prev[area][dept],
          [perm]: !prev[area][dept]?.[perm]
        }
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const departamentosLimpiados = selectedAreas.reduce((acc, area) => {
      const filteredDepartments = Object.entries(departamentos[area] || {}).reduce((deptAcc, [dept, permisos]) => {
        if (permisos.ver || permisos.editar || permisos.enviar) {
          deptAcc[dept] = permisos;
        }
        return deptAcc;
      }, {});
      return { ...acc, ...filteredDepartments };
    }, {});

    const newUser = {
      nombre,
      apellidos,
      email,
      username,
      password,
      idioma,
      empresa,
      areas: selectedAreas,
      departamentos: departamentosLimpiados
    };

    console.log("Datos enviados al controlador para crear usuario:", JSON.stringify(newUser, null, 2));

    try {
      const response = await fetch('https://risincome.aicrumit.com/backend/api/users', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newUser),
        credentials: 'include'
      });

      if (response.ok) {
        alert(language === 'EN' ? 'User created successfully' : 'Usuario creado con éxito');
        setNombre('');
        setApellidos('');
        setEmail('');
        setUsername('');
        setPassword('');
        setIdioma('ES');
        setSelectedAreas([]);
        setDepartamentos({});
      } else {
        alert(language === 'EN' ? 'Error creating user' : 'Error al crear el usuario');
      }
    } catch (error) {
      console.error('Error al crear usuario:', error);
      alert(language === 'EN' ? 'There was an error, please try again.' : 'Hubo un error, por favor intenta nuevamente.');
    }
  };

  // Objeto de traducción para etiquetas
  const labels = {
    title: language === 'EN' ? 'Create New User' : 'Crear Nuevo Usuario',
    nombre: language === 'EN' ? 'First Name' : 'Nombre',
    apellidos: language === 'EN' ? 'Last Name' : 'Apellidos',
    email: 'Email',
    username: language === 'EN' ? 'Username' : 'Username',
    password: language === 'EN' ? 'Password' : 'Contraseña',
    idioma: language === 'EN' ? 'Language' : 'Idioma',
    empresa: language === 'EN' ? 'Current Company' : 'Empresa actual',
    areas: language === 'EN' ? 'Areas' : 'Áreas',
    createUser: language === 'EN' ? 'Create User' : 'Crear Usuario',
    sinDepartamentos: language === 'EN' ? 'No departments assigned' : 'Sin departamentos asignados',
    permisos: {
      ver: language === 'EN' ? 'View' : 'Ver',
      editar: language === 'EN' ? 'Edit' : 'Editar',
      enviar: language === 'EN' ? 'Send' : 'Enviar'
    }
  };

  return (
    <div>
      <h2 className="create-user-title">{labels.title}</h2>
      <p><strong>{labels.empresa}:</strong> {empresa || (language === 'EN' ? 'Loading company...' : 'Cargando empresa...')}</p>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form className="create-user-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{labels.nombre}:</label>
          <input type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>{labels.apellidos}:</label>
          <input type="text" value={apellidos} onChange={(e) => setApellidos(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>{labels.email}:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>{labels.username}:</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>{labels.password}:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>{labels.idioma}:</label>
          <select value={idioma} onChange={(e) => setIdioma(e.target.value)}>
            <option value="ES">ES (Español)</option>
            <option value="EN">EN (English)</option>
          </select>
        </div>

        <div className="form-group">
          <label>{labels.areas}:</label>
          {Object.keys(areaOptions).map(area => (
            <div key={area} className="checkbox-item">
              <label>{areaLabels[area][language]}</label> {/* Acceso correcto a la traducción */}
              <input
                type="checkbox"
                checked={selectedAreas.includes(area)}
                onChange={() => handleAreaChange(area)}
              />
            </div>
          ))}
        </div>

        {selectedAreas.map(area => (
          <div key={area} className="form-group">
            <h3>{areaLabels[area][language] || area} - {labels.areas}</h3> {/* Usa el nombre traducido */}
            {areaOptions[area].map(dept => (
              <div key={dept} className="checkbox-item">
                <strong>{dept}</strong>
                <ul>
                  {['ver', 'editar', 'enviar'].map(perm => (
                    <li key={perm}>
                      <label>{labels.permisos[perm]}:</label>
                      <input
                        type="checkbox"
                        checked={departamentos[area]?.[dept]?.[perm] || false}
                        onChange={() => handlePermissionChange(area, dept, perm)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}

        <button type="submit" className="create-user-button">{labels.createUser}</button>
      </form>
    </div>
  );
}

export default CreateUser;
