//frontend/src/config/editorConfig.js
export const EDITOR_MODULES = {
	toolbar: [
		[{ header: [1, 2, 3, false] }],
		['bold', 'italic', 'underline'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['link', 'image'],
		['clean'],
	],
	clipboard: {
		matchVisual: false,
	},
};

export const EDITOR_FORMATS = [
	'header',
	'bold',
	'italic',
	'underline',
	'list',
	'bullet',
	'link',
	'image',
];
