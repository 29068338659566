import React, { useState } from 'react';
import useUserCompany from '../../hooks/user/useUserCompany';
import useI18n from '../../hooks/i18n/useI18n';
import { areaOptions } from '../../config/areaOptions';
import { updateUserStyles } from '../../styles/updateUserStyles.js';
import { updateUserHandlers } from '../../utils/handlers/userHandlers';
import { debugError } from '../../utils/debugHelper.js';

function UpdateUser() {
	const [userId, setUserId] = useState('');
	const [userData, setUserData] = useState(null);
	const [error, setError] = useState('');
	const [selectedAreas, setSelectedAreas] = useState([]);
	const [departamentos, setDepartamentos] = useState({});

	const { empresa: userCompany, error: companyError } = useUserCompany();
	const { t, language, languageError } = useI18n();

	// Si hay error en la carga del idioma o la empresa, mostrar mensaje
	if (languageError || companyError) {
		return (
			<div className={updateUserStyles.errorContainer}>
				<p>{languageError || companyError}</p>
			</div>
		);
	}

	// Si el idioma aún no está disponible, mostramos un loader
	if (!language) {
		return (
			<div className={updateUserStyles.loader}>
				<div className='text-center'>
					<div className={updateUserStyles.loaderSpinner}></div>
					<p className={updateUserStyles.loaderText}>{t('common.loading')}</p>
				</div>
			</div>
		);
	}

	const handleSearch = async (e) => {
		e.preventDefault();

		// Creamos un objeto labels temporal para compatibilidad con los handlers existentes
		const tempLabels = {
			errorMessage: t('updateUser.errorMessage'),
			companyErrorMessage: t('updateUser.companyErrorMessage'),
			userIdLabel: t('updateUser.userIdLabel'),
			selectLanguage: t('updateUser.selectLanguage'),
			departamentos: {},
		};

		await updateUserHandlers.handleFetchUser(
			userId,
			userCompany,
			language,
			setError,
			setUserData,
			setSelectedAreas,
			setDepartamentos,
			areaOptions,
			tempLabels
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!userData) return;

		// Creamos un objeto labels temporal para compatibilidad
		const tempLabels = {
			successMessage: t('updateUser.successMessage'),
			errorMessage: t('updateUser.errorMessage'),
		};

		await updateUserHandlers.handleUpdateUser(
			userId,
			userData,
			selectedAreas,
			departamentos,
			language,
			tempLabels
		);
	};

	const handleInputChange = (e) => {
		setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div className={updateUserStyles.container}>
			<div className='max-w-4xl mx-auto'>
				{/* Header */}
				<div className={updateUserStyles.titleContainer}>
					<h2 className={updateUserStyles.titleText}>
						{t('updateUser.title')}
					</h2>
					<div className={updateUserStyles.titleUnderline}></div>
				</div>

				<div className={updateUserStyles.card}>
					{/* Formulario de búsqueda con mejor diseño */}
					<div className={updateUserStyles.header}>
						<form
							onSubmit={handleSearch}
							className={updateUserStyles.searchForm}>
							<div className={updateUserStyles.formGroup}>
								<div>
									<label className={updateUserStyles.searchLabel}>
										{t('updateUser.userIdLabel')}
									</label>
									<div className={updateUserStyles.searchContainer}>
										<input
											type='text'
											value={userId}
											onChange={(e) => setUserId(e.target.value)}
											className={updateUserStyles.searchInput}
											placeholder={t('updateUser.userIdLabel')}
											required
										/>
										<button
											type='submit'
											className={updateUserStyles.searchButton}>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												className='h-5 w-5 mr-2'
												viewBox='0 0 20 20'
												fill='currentColor'>
												<path
													fillRule='evenodd'
													d='M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z'
													clipRule='evenodd'
												/>
											</svg>
											{t('updateUser.searchButton')}
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>

					{error && (
						<div className={updateUserStyles.errorMessage}>
							<p className={updateUserStyles.errorText}>{error}</p>
						</div>
					)}

					{userData && (
						<form
							onSubmit={handleSubmit}
							className={updateUserStyles.form}>
							{/* Grid de campos principales con mejor espaciado */}
							<div className={updateUserStyles.grid}>
								{['nombre', 'apellidos', 'email', 'username'].map((field) => (
									<div
										key={field}
										className='space-y-2'>
										<label className={updateUserStyles.label}>
											{t(`updateUser.${field}`)}
										</label>
										<input
											type={field === 'email' ? 'email' : 'text'}
											name={field}
											value={userData[field]}
											onChange={handleInputChange}
											required
											className={updateUserStyles.input}
										/>
									</div>
								))}

								{/* Select de idioma */}
								<div className='space-y-2'>
									<label className={updateUserStyles.label}>
										{t('updateUser.idioma')}
									</label>
									<select
										name='idioma'
										value={userData.idioma || ''}
										onChange={handleInputChange}
										className={updateUserStyles.input}>
										<option value=''>{t('updateUser.selectLanguage')}</option>
										<option value='ES'>ES (Español)</option>
										<option value='EN'>EN (English)</option>
									</select>
								</div>
							</div>

							{/* Sección de Áreas con mejor diseño */}
							<div className={updateUserStyles.areaSection}>
								<div className={updateUserStyles.areaHeader}>
									<h3 className={updateUserStyles.areaTitle}>
										{t('updateUser.areas')}
									</h3>
									<div className={updateUserStyles.areaLine}></div>
								</div>

								<div className={updateUserStyles.areaGrid}>
									{Object.keys(areaOptions)
										.filter((area) => area !== 'System') // Filtrar el área "System" exactamente como en CreateUser
										.map((area) => (
											<div
												key={area}
												className={updateUserStyles.areaItem}>
												<label className={updateUserStyles.areaLabel}>
													<input
														type='checkbox'
														checked={selectedAreas.includes(area)}
														onChange={() =>
															updateUserHandlers.handleAreaChange(
																area,
																selectedAreas,
																setSelectedAreas,
																departamentos,
																setDepartamentos,
																areaOptions
															)
														}
														className={updateUserStyles.checkbox}
													/>
													<span className={updateUserStyles.areaText}>
														{t(`departamentos.${area}`, { defaultValue: area })}
													</span>
												</label>
											</div>
										))}
								</div>
							</div>

							{/* Departamentos y Permisos con mejor diseño */}
							{selectedAreas.map((area) => (
								<div
									key={area}
									className={updateUserStyles.departmentSection}>
									<div className={updateUserStyles.departmentHeader}>
										<h3 className={updateUserStyles.areaTitle}>
											{t(`departamentos.${area}`, { defaultValue: area })} -{' '}
											{t('updateUser.departments')}
										</h3>
										<div className={updateUserStyles.areaLine}></div>
									</div>

									<div className='space-y-6'>
										{areaOptions[area].map((dept) => (
											<div
												key={dept}
												className={updateUserStyles.departmentItem}>
												<strong className={updateUserStyles.departmentName}>
													{t(`departamentos.${dept}`, { defaultValue: dept })}
												</strong>
												<div className={updateUserStyles.permissionsContainer}>
													{['ver', 'editar', 'enviar'].map((perm) => (
														<label
															key={perm}
															className={updateUserStyles.permissionLabel}>
															<input
																type='checkbox'
																checked={
																	departamentos[area]?.[dept]?.[perm] || false
																}
																onChange={() =>
																	updateUserHandlers.handlePermissionChange(
																		area,
																		dept,
																		perm,
																		setDepartamentos
																	)
																}
																className={updateUserStyles.checkbox}
															/>
															<span className={updateUserStyles.permissionText}>
																{t(`updateUser.permisos.${perm}`)}
															</span>
														</label>
													))}
												</div>
											</div>
										))}
									</div>
								</div>
							))}

							{/* Botón de actualizar */}
							<div className={updateUserStyles.buttonContainer}>
								<button
									type='submit'
									className={updateUserStyles.button}>
									{t('updateUser.updateButton')}
								</button>
							</div>
						</form>
					)}
				</div>
			</div>
		</div>
	);
}

export default UpdateUser;
