// Importamos el sistema de traducciones
import i18next from 'i18next'; // Importamos i18next directamente

// Configuración para los reportes PDF
export const pdfReportConfig = {
	// Configuración para cada proceso
	processes: {
		// ESRS 2 - Proceso 1 (con todas las secciones completas)
		1: {
			titleKey: 'casosTable.procesos.ESRS 2',
			descriptionKey: 'listaProcesos.esrs2Title',
			// Indicamos que este proceso tiene una pregunta consolidada especial
			hasConsolidatedDepartmentsQuestion: true,
			// Definimos en qué posición/sección debe aparecer la pregunta consolidada
			consolidatedQuestionSectionOrder: -1, // -1 indica que se inserta en questionIds
			sections: [
				{
					titleKey:
						'[BP-1] General basis for preparation of sustainability statements',
					title_es:
						'[BP-1] Base general para la preparación de estados de sostenibilidad',
					title_en:
						'[BP-1] General basis for preparation of sustainability statements',
					formId: 2,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
				{
					titleKey: '[BP-2] Disclosures in relation to specific circumstances',
					title_es:
						'[BP-2] Divulgaciones en relación con circunstancias específicas',
					title_en: '[BP-2] Disclosures in relation to specific circumstances',
					formId: 3,
					sortOrder: 2,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25, 26, 27,
					],
				},
				{
					titleKey:
						'[GOV-1] Role of the administrative, management and supervisory bodies',
					title_es:
						'[GOV-1] Función de los órganos administrativos, de gestión y de supervisión',
					title_en:
						'[GOV-1] Role of the administrative, management and supervisory bodies',
					formId: 5,
					sortOrder: 3,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
					],
				},
				{
					titleKey:
						'[GOV-2] Information provided to and sustainability matters addressed by administrative, management and supervisory bodies',
					title_es:
						'[GOV-2] Información proporcionada a los órganos administrativos, de gestión y de supervisión y cuestiones de sostenibilidad tratadas por ellos',
					title_en:
						'[GOV-2] Information provided to and sustainability matters addressed by administrative, management and supervisory bodies',
					formId: 6,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey:
						'[GOV-3] Integration of sustainability-related performance in incentive schemes',
					title_es:
						'[GOV-3] Integración del desempeño relacionado con la sostenibilidad en los esquemas de incentivos',
					title_en:
						'[GOV-3] Integration of sustainability-related performance in incentive schemes',
					formId: 7,
					sortOrder: 5,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
				{
					titleKey: '[GOV-4] Statement on due diligence',
					title_es: '[GOV-4] Declaración sobre la debida diligencia',
					title_en: '[GOV-4] Statement on due diligence',
					formId: 8,
					sortOrder: 6,
					optional: false,
					questionIds: [1],
				},
				{
					titleKey:
						'[GOV-5] Risk management and internal controls over sustainability reporting',
					title_es:
						'[GOV-5] Gestión de riesgos y controles internos sobre la información de sostenibilidad',
					title_en:
						'[GOV-5] Risk management and internal controls over sustainability reporting',
					formId: 9,
					sortOrder: 7,
					optional: false,
					questionIds: [1, 2, 3, 4, 5],
				},
				{
					titleKey: '[SBM-1] Strategy, business model and value chain',
					title_es: '[SBM-1] Estrategia, modelo de negocio y cadena de valor',
					title_en: '[SBM-1] Strategy, business model and value chain',
					formId: 10,
					sortOrder: 8,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25, 26, 27, 28,
					],
				},
				{
					titleKey: '[SBM-2] Interests and views of stakeholders - general',
					title_es:
						'[SBM-2] Intereses y opiniones de las partes interesadas - general',
					title_en: '[SBM-2] Interests and views of stakeholders - general',
					formId: 11,
					sortOrder: 9,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[SBM-3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 12,
					sortOrder: 10,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[IRO-1] Description of process to identify and assess material impacts, risks and opportunities',
					title_es:
						'[IRO-1] Descripción del proceso para identificar y evaluar impactos materiales, riesgos y oportunidades',
					title_en:
						'[IRO-1] Description of process to identify and assess material impacts, risks and opportunities',
					formId: 4,
					sortOrder: 11,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
				},
				{
					titleKey:
						'[IRO-2] Disclosure Requirements in ESRS covered by sustainability statements Policies and, actions (or) targets not adopted',
					title_es:
						'[IRO-2] Requisitos de divulgación en ESRS cubiertos por declaraciones de sostenibilidad. Políticas y acciones (o) objetivos no adoptados',
					title_en:
						'[IRO-2] Disclosure Requirements in ESRS covered by sustainability statements Policies and, actions (or) targets not adopted',
					formId: 1,
					sortOrder: 12,
					optional: false,
					questionIds: [
						'DEPARTMENTS',
						81,
						2,
						10,
						18,
						26,
						34,
						42,
						50,
						58,
						66,
						74,
					], // Aquí es donde ponemos el marcador DEPARTMENTS
				},
			],
		},

		// E1 Climate Change - Proceso 3
		3: {
			titleKey: 'casosTable.procesos.ESRS E1',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS E1',
			sections: [
				{
					titleKey:
						'[GOV-3] Integration of sustainability-related performance in incentive schemes',
					title_es:
						'[GOV-3] Integración del desempeño relacionado con la sostenibilidad en los esquemas de incentivos',
					title_en:
						'[GOV-3] Integration of sustainability-related performance in incentive schemes',
					formId: 13,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3],
				},
				{
					titleKey:
						'[SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[SBM-3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 15,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7],
				},
				{
					titleKey:
						'[IRO-1] Description of processes to identify and assess material climate-related impacts, risks and opportunities',
					title_es:
						'[IRO-1] Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con el clima',
					title_en:
						'[IRO-1] Description of processes to identify and assess material climate-related impacts, risks and opportunities',
					formId: 16,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
				},
				{
					titleKey: '[E1-1] Transition plan for climate change mitigation',
					title_es:
						'[E1-1] Plan de transición para la mitigación del cambio climático',
					title_en: '[E1-1] Transition plan for climate change mitigation',
					formId: 14,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
				},
				{
					titleKey:
						'[E1-2] Policies related to climate change mitigation and adaptation',
					title_es:
						'[E1-2] Políticas relacionadas con la mitigación y adaptación al cambio climático',
					title_en:
						'[E1-2] Policies related to climate change mitigation and adaptation',
					formId: 17,
					sortOrder: 5,
					optional: false,
					questionIds: [1],
				},
				{
					titleKey:
						'[E1-3] Actions and resources in relation to climate change policies',
					title_es:
						'[E1-3] Acciones y recursos en relación con las políticas de cambio climático',
					title_en:
						'[E1-3] Actions and resources in relation to climate change policies',
					formId: 18,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey:
						'[E1-4] Targets related to climate change mitigation and adaptation',
					title_es:
						'[E1-4] Objetivos relacionados con la mitigación y adaptación al cambio climático',
					title_en:
						'[E1-4] Targets related to climate change mitigation and adaptation',
					formId: 19,
					sortOrder: 7,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24,
					],
				},
				{
					titleKey: '[E1-5] Energy consumption and mix',
					title_es: '[E1-5] Consumo y mix energético',
					title_en: '[E1-5] Energy consumption and mix',
					formId: 20,
					sortOrder: 8,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23,
					],
				},
				{
					titleKey: '[E1-6] Gross Scopes 1, 2, 3 and Total GHG emissions',
					title_es:
						'[E1-6] Emisiones brutas de GEI de alcances 1, 2, 3 y totales',
					title_en: '[E1-6] Gross Scopes 1, 2, 3 and Total GHG emissions',
					formId: 21,
					sortOrder: 9,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
					],
				},
				{
					titleKey:
						'[E1-7] GHG removals and GHG mitigation projects financed through carbon credits',
					title_es:
						'[E1-7] Eliminaciones de GEI y proyectos de mitigación de GEI financiados mediante créditos de carbono',
					title_en:
						'[E1-7] GHG removals and GHG mitigation projects financed through carbon credits',
					formId: 22,
					sortOrder: 10,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25,
					],
				},
				{
					titleKey: '[E1-8] Internal carbon pricing',
					title_es: '[E1-8] Fijación interna de precios del carbono',
					title_en: '[E1-8] Internal carbon pricing',
					formId: 23,
					sortOrder: 11,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[E1-9] Anticipated financial effects from material physical and transition risks and potential climate-related opportunities',
					title_es:
						'[E1-9] Efectos financieros anticipados de riesgos físicos y de transición materiales y oportunidades potenciales relacionadas con el clima',
					title_en:
						'[E1-9] Anticipated financial effects from material physical and transition risks and potential climate-related opportunities',
					formId: 24,
					sortOrder: 12,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
						37, 38, 39, 40, 41, 42, 43, 44,
					],
				},
			],
		},

		// Proceso 4 - E2 Pollution
		4: {
			titleKey: 'casosTable.procesos.ESRS E2',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS E2',
			sections: [
				{
					titleKey:
						'[IRO-1] Description of processes to identify and assess material pollution-related impacts, risks and opportunities',
					title_es:
						'[IRO-1] Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con la polución',
					title_en:
						'[IRO-1] Description of processes to identify and assess material pollution-related impacts, risks and opportunities',
					formId: 25,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3],
				},
				{
					titleKey: '[E2-1] Policies related to pollution',
					title_es: '[E2-1] Políticas relacionadas con la polución',
					title_en: '[E2-1] Policies related to pollution',
					formId: 26,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey: '[E2-2] Actions and resources related to pollution',
					title_es: '[E2-2] Acciones y recursos relacionados con la polución',
					title_en: '[E2-2] Actions and resources related to pollution',
					formId: 27,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey: '[E2-3] Targets related to pollution',
					title_es: '[E2-3] Objetivos relacionados con la polución',
					title_en: '[E2-3] Targets related to pollution',
					formId: 28,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				},
				{
					titleKey: '[E2-4] Pollution of air, water and soil – general',
					title_es: '[E2-4] Polución del aire, agua y suelo – general',
					title_en: '[E2-4] Pollution of air, water and soil – general',
					formId: 29,
					sortOrder: 5,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20,
					],
				},
				{
					titleKey:
						'[E2-5] Substances of concern and substances of very high concern',
					title_es:
						'[E2-5] Sustancias preocupantes y sustancias de muy alta preocupación',
					title_en:
						'[E2-5] Substances of concern and substances of very high concern',
					formId: 30,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
				},
				{
					titleKey:
						'[E2-6] Anticipated financial effects from material pollution-related risks and opportunities',
					title_es:
						'[E2-6] Efectos financieros anticipados de riesgos y oportunidades materiales relacionados con la polución',
					title_en:
						'[E2-6] Anticipated financial effects from material pollution-related risks and opportunities',
					formId: 31,
					sortOrder: 7,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				},
			],
		},

		// Proceso 5 - E3 Water & marine resources
		5: {
			titleKey: 'casosTable.procesos.ESRS E3',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS E3',
			sections: [
				{
					titleKey:
						'[IRO-1] Description of processes to identify and assess material water and marine resources-related impacts, risks and opportunities',
					title_es:
						'[IRO-1] Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con el agua y los recursos marinos',
					title_en:
						'[IRO-1] Description of processes to identify and assess material water and marine resources-related impacts, risks and opportunities',
					formId: 32,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2],
				},
				{
					titleKey: '[E3-1] Policies related to water and marine resources',
					title_es:
						'[E3-1] Políticas relacionadas con el agua y los recursos marinos',
					title_en: '[E3-1] Policies related to water and marine resources',
					formId: 33,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[E3-2] Actions and resources related to water and marine resources',
					title_es:
						'[E3-2] Acciones y recursos relacionados con el agua y los recursos marinos',
					title_en:
						'[E3-2] Actions and resources related to water and marine resources',
					formId: 34,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3],
				},
				{
					titleKey: '[E3-3] Targets related to water and marine resources',
					title_es:
						'[E3-3] Objetivos relacionados con el agua y los recursos marinos',
					title_en: '[E3-3] Targets related to water and marine resources',
					formId: 35,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
				},
				{
					titleKey: '[E3-4] Water consumption',
					title_es: '[E3-4] Consumo de agua',
					title_en: '[E3-4] Water consumption',
					formId: 36,
					sortOrder: 5,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[E3-5] Anticipated financial effects from material water and marine resources-related risks and opportunities',
					title_es:
						'[E3-5] Efectos financieros anticipados de riesgos y oportunidades materiales relacionados con el agua y los recursos marinos',
					title_en:
						'[E3-5] Anticipated financial effects from material water and marine resources-related risks and opportunities',
					formId: 37,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},
		// Proceso 6 - E4 Biodiversity & Ecosystems
		6: {
			titleKey: 'casosTable.procesos.ESRS E4',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS E4',
			sections: [
				{
					titleKey:
						'[E4 SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[E4 SBM-3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[E4 SBM-3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 38,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
				{
					titleKey:
						'[E4 IRO-1] Description of processes to identify and assess material biodiversity and ecosystem-related impacts, risks, dependencies and opportunities',
					title_es:
						'[E4 IRO-1] Descripción de los procesos para identificar y evaluar impactos, riesgos, dependencias y oportunidades materiales relacionados con la biodiversidad y los ecosistemas',
					title_en:
						'[E4 IRO-1] Description of processes to identify and assess material biodiversity and ecosystem-related impacts, risks, dependencies and opportunities',
					formId: 39,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
				},
				{
					titleKey:
						'[E4-1] Transition plan on biodiversity and ecosystems in strategy and business model',
					title_es:
						'[E4-1] Plan de transición sobre biodiversidad y ecosistemas en la estrategia y modelo de negocio',
					title_en:
						'[E4-1] Transition plan on biodiversity and ecosystems in strategy and business model',
					formId: 40,
					sortOrder: 3,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
					],
				},
				{
					titleKey: '[E4-2] Policies related to biodiversity and ecosystems',
					title_es:
						'[E4-2] Políticas relacionadas con la biodiversidad y los ecosistemas',
					title_en: '[E4-2] Policies related to biodiversity and ecosystems',
					formId: 41,
					sortOrder: 4,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20,
					],
				},
				{
					titleKey:
						'[E4-3] Actions and resources related to biodiversity and ecosystems',
					title_es:
						'[E4-3] Acciones y recursos relacionados con la biodiversidad y los ecosistemas',
					title_en:
						'[E4-3] Actions and resources related to biodiversity and ecosystems',
					formId: 42,
					sortOrder: 5,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
				},
				{
					titleKey: '[E4-4] Targets related to biodiversity and ecosystems',
					title_es:
						'[E4-4] Objetivos relacionados con la biodiversidad y los ecosistemas',
					title_en: '[E4-4] Targets related to biodiversity and ecosystems',
					formId: 43,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
				},
				{
					titleKey:
						'[E4-5] Impact metrics related to biodiversity and ecosystems change',
					title_es:
						'[E4-5] Métricas de impacto relacionadas con el cambio en la biodiversidad y los ecosistemas',
					title_en:
						'[E4-5] Impact metrics related to biodiversity and ecosystems change',
					formId: 95,
					sortOrder: 7,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22, 23, 24, 25, 26, 27,
					],
				},
				{
					titleKey:
						'[E4-6] Anticipated financial effects from material biodiversity and ecosystem-related risks and opportunities',
					title_es:
						'[E4-6] Efectos financieros anticipados de riesgos y oportunidades materiales relacionados con la biodiversidad y los ecosistemas',
					title_en:
						'[E4-6] Anticipated financial effects from material biodiversity and ecosystem-related risks and opportunities',
					formId: 44,
					sortOrder: 8,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},
		// Proceso 7 - E5 Resource use & circular economy
		7: {
			titleKey: 'casosTable.procesos.ESRS E5',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS E5',
			sections: [
				{
					titleKey:
						'[IRO-1] Description of processes to identify and assess material resource use and circular economy-related impacts, risks and opportunities',
					title_es:
						'[IRO-1] Descripción de los procesos para identificar y evaluar impactos, riesgos y oportunidades materiales relacionados con el uso de recursos y la economía circular',
					title_en:
						'[IRO-1] Description of processes to identify and assess material resource use and circular economy-related impacts, risks and opportunities',
					formId: 45,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2],
				},
				{
					titleKey:
						'[E5-1] Policies related to resource use and circular economy',
					title_es:
						'[E5-1] Políticas relacionadas con el uso de recursos y la economía circular',
					title_en:
						'[E5-1] Policies related to resource use and circular economy',
					formId: 46,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey:
						'[E5-2] Actions and resources related to resource use and circular economy',
					title_es:
						'[E5-2] Acciones y recursos relacionados con el uso de recursos y la economía circular',
					title_en:
						'[E5-2] Actions and resources related to resource use and circular economy',
					formId: 47,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
				},
				{
					titleKey:
						'[E5-3] Targets related to resource use and circular economy',
					title_es:
						'[E5-3] Objetivos relacionados con el uso de recursos y la economía circular',
					title_en:
						'[E5-3] Targets related to resource use and circular economy',
					formId: 48,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
				},
				{
					titleKey: '[E5-4] Resource inflows',
					title_es: '[E5-4] Entradas de recursos',
					title_en: '[E5-4] Resource inflows',
					formId: 49,
					sortOrder: 5,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey: '[E5-5] Resource outflows',
					title_es: '[E5-5] Salidas de recursos',
					title_en: '[E5-5] Resource outflows',
					formId: 50,
					sortOrder: 6,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
					],
				},
				{
					titleKey:
						'[E5-6] Potential financial effects from resource use and circular economy-related impacts, risks and opportunities',
					title_es:
						'[E5-6] Efectos financieros potenciales de impactos, riesgos y oportunidades relacionados con el uso de recursos y la economía circular',
					title_en:
						'[E5-6] Potential financial effects from resource use and circular economy-related impacts, risks and opportunities',
					formId: 51,
					sortOrder: 7,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},
		// Proceso 8 - S1 Human Rights
		8: {
			titleKey: 'casosTable.procesos.ESRS S1',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS S1',
			sections: [
				{
					titleKey:
						'[S1-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[S1-SBM3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[S1-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 52,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey: '[S1-1] Policies related to own workforce',
					title_es: '[S1-1] Políticas relacionadas con la propia plantilla',
					title_en: '[S1-1] Policies related to own workforce',
					formId: 53,
					sortOrder: 2,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20, 21, 22,
					],
				},
				{
					titleKey:
						'[S1-2] Processes for engaging with own workers and workers representatives about impacts',
					title_es:
						'[S1-2] Procesos para la participación de los propios trabajadores y representantes de los trabajadores sobre los impactos',
					title_en:
						'[S1-2] Processes for engaging with own workers and workers representatives about impacts',
					formId: 54,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
				},
				{
					titleKey:
						'[S1-3] Processes to remediate negative impacts and channels for own workforce to raise concerns',
					title_es:
						'[S1-3] Procesos para remediar impactos negativos y canales para que la propia plantilla plantee inquietudes',
					title_en:
						'[S1-3] Processes to remediate negative impacts and channels for own workforce to raise concerns',
					formId: 55,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				},
				{
					titleKey:
						'[S1-4] Taking action on material impacts on own workforce, and approaches to managing material risks and pursuing material opportunities related to own workforce, and effectiveness of those actions',
					title_es:
						'[S1-4] Tomar medidas sobre los impactos materiales en la propia plantilla, y enfoques para gestionar riesgos materiales y perseguir oportunidades materiales relacionadas con la propia plantilla, y la efectividad de esas acciones',
					title_en:
						'[S1-4] Taking action on material impacts on own workforce, and approaches to managing material risks and pursuing material opportunities related to own workforce, and effectiveness of those actions',
					formId: 56,
					sortOrder: 5,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20,
					],
				},
				{
					titleKey:
						'[S1-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					title_es:
						'[S1-5] Objetivos relacionados con la gestión de impactos negativos materiales, el avance de impactos positivos y la gestión de riesgos y oportunidades materiales',
					title_en:
						'[S1-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					formId: 57,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
				{
					titleKey: '[S1-6] Characteristics of undertakings employees',
					title_es: '[S1-6] Características de los empleados de la empresa',
					title_en: '[S1-6] Characteristics of undertakings employees',
					formId: 58,
					sortOrder: 7,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
						20,
					],
				},
				{
					titleKey:
						'[S1-7] Characteristics of non-employees in undertakings own workforce',
					title_es:
						'[S1-7] Características de los no empleados en la propia plantilla de la empresa',
					title_en:
						'[S1-7] Characteristics of non-employees in undertakings own workforce',
					formId: 59,
					sortOrder: 8,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
				},
				{
					titleKey: '[S1-8] Collective bargaining coverage and social dialogue',
					title_es:
						'[S1-8] Cobertura de negociación colectiva y diálogo social',
					title_en: '[S1-8] Collective bargaining coverage and social dialogue',
					formId: 60,
					sortOrder: 9,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey: '[S1-9] Diversity metrics',
					title_es: '[S1-9] Métricas de diversidad',
					title_en: '[S1-9] Diversity metrics',
					formId: 61,
					sortOrder: 10,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
				{
					titleKey: '[S1-10] Adequate Wages',
					title_es: '[S1-10] Salarios adecuados',
					title_en: '[S1-10] Adequate Wages',
					formId: 62,
					sortOrder: 11,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey: '[S1-11] Social protection',
					title_es: '[S1-11] Protección social',
					title_en: '[S1-11] Social protection',
					formId: 63,
					sortOrder: 12,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				},
				{
					titleKey: '[S1-12] Persons with disabilities',
					title_es: '[S1-12] Personas con discapacidades',
					title_en: '[S1-12] Persons with disabilities',
					formId: 64,
					sortOrder: 13,
					optional: false,
					questionIds: [1, 2, 3],
				},
				{
					titleKey: '[S1-13] Training and skills development metrics',
					title_es: '[S1-13] Métricas de formación y desarrollo de habilidades',
					title_en: '[S1-13] Training and skills development metrics',
					formId: 65,
					sortOrder: 14,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7],
				},
				{
					titleKey: '[S1-14] Health and safety metrics',
					title_es: '[S1-14] Métricas de salud y seguridad',
					title_en: '[S1-14] Health and safety metrics',
					formId: 65,
					sortOrder: 15,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey: '[S1-15] Work-life balance metrics',
					title_es:
						'[S1-15] Métricas de equilibrio entre vida laboral y personal',
					title_en: '[S1-15] Work-life balance metrics',
					formId: 66,
					sortOrder: 16,
					optional: false,
					questionIds: [1, 2, 3, 4],
				},
				{
					titleKey:
						'[S1-16] Remuneration metrics (pay gap and total remuneration) - general',
					title_es:
						'[S1-16] Métricas de remuneración (brecha salarial y remuneración total) - general',
					title_en:
						'[S1-16] Remuneration metrics (pay gap and total remuneration) - general',
					formId: 67,
					sortOrder: 17,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7],
				},
				{
					titleKey:
						'[S1-17] Incidents, complaints and severe human rights impacts -general',
					title_es:
						'[S1-17] Incidentes, reclamaciones e impactos graves sobre los derechos humanos - general',
					title_en:
						'[S1-17] Incidents, complaints and severe human rights impacts -general',
					formId: 68,
					sortOrder: 18,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
				},
			],
		},
		// Proceso 9 - S2 Labor Practices
		9: {
			titleKey: 'casosTable.procesos.ESRS S2',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS S2',
			sections: [
				{
					titleKey:
						'[S2-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[S2-SBM3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[S2-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 70,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey: '[S2-1] Policies related to value chain workers',
					title_es:
						'[S2-1] Políticas relacionadas con los trabajadores de la cadena de valor',
					title_en: '[S2-1] Policies related to value chain workers',
					formId: 71,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				},
				{
					titleKey:
						'[S2-2] Processes for engaging with value chain workers about impacts',
					title_es:
						'[S2-2] Procesos para la participación de los trabajadores de la cadena de valor sobre los impactos',
					title_en:
						'[S2-2] Processes for engaging with value chain workers about impacts',
					formId: 72,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[S2-3] Processes to remediate negative impacts and channels for value chain workers to raise concerns',
					title_es:
						'[S2-3] Procesos para remediar impactos negativos y canales para que los trabajadores de la cadena de valor planteen inquietudes',
					title_en:
						'[S2-3] Processes to remediate negative impacts and channels for value chain workers to raise concerns',
					formId: 73,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[S2-4] Taking action on material impacts on value chain workers, and approaches to managing material risks and pursuing material opportunities related to value chain workers, and effectiveness of those actions',
					title_es:
						'[S2-4] Tomar medidas sobre los impactos materiales en los trabajadores de la cadena de valor, y enfoques para gestionar riesgos materiales y perseguir oportunidades materiales relacionadas con los trabajadores de la cadena de valor, y la efectividad de esas acciones',
					title_en:
						'[S2-4] Taking action on material impacts on value chain workers, and approaches to managing material risks and pursuing material opportunities related to value chain workers, and effectiveness of those actions',
					formId: 74,
					sortOrder: 5,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
					],
				},
				{
					titleKey:
						'[S2-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					title_es:
						'[S2-5] Objetivos relacionados con la gestión de impactos negativos materiales, el avance de impactos positivos y la gestión de riesgos y oportunidades materiales',
					title_en:
						'[S2-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					formId: 75,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},
		// Proceso 10 - S3 Health & Safety
		10: {
			titleKey: 'casosTable.procesos.ESRS S3',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS S3',
			sections: [
				{
					titleKey:
						'[S3-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[S3-SBM3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[S3-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 76,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey: '[S3-1] Policies related to affected communities',
					title_es:
						'[S3-1] Políticas relacionadas con las comunidades afectadas',
					title_en: '[S3-1] Policies related to affected communities',
					formId: 77,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[S3-2] Processes for engaging with affected communities about impacts',
					title_es:
						'[S3-2] Procesos para la participación de las comunidades afectadas sobre los impactos',
					title_en:
						'[S3-2] Processes for engaging with affected communities about impacts',
					formId: 78,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[S3-3] Processes to remediate negative impacts and channels for affected communities to raise concerns',
					title_es:
						'[S3-3] Procesos para remediar impactos negativos y canales para que las comunidades afectadas planteen inquietudes',
					title_en:
						'[S3-3] Processes to remediate negative impacts and channels for affected communities to raise concerns',
					formId: 79,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey:
						'[S3-4] Taking action on material impacts on affected communities, and approaches to managing material risks and pursuing material opportunities related to affected communities, and effectiveness of those actions',
					title_es:
						'[S3-4] Tomar medidas sobre los impactos materiales en las comunidades afectadas, y enfoques para gestionar riesgos materiales y perseguir oportunidades materiales relacionadas con las comunidades afectadas, y la efectividad de esas acciones',
					title_en:
						'[S3-4] Taking action on material impacts on affected communities, and approaches to managing material risks and pursuing material opportunities related to affected communities, and effectiveness of those actions',
					formId: 80,
					sortOrder: 5,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
					],
				},
				{
					titleKey:
						'[S3-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					title_es:
						'[S3-5] Objetivos relacionados con la gestión de impactos negativos materiales, el avance de impactos positivos y la gestión de riesgos y oportunidades materiales',
					title_en:
						'[S3-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities',
					formId: 81,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},

		// Proceso 11 - S4 Training & Education
		11: {
			titleKey: 'casosTable.procesos.ESRS S4',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS S4',
			sections: [
				{
					titleKey:
						'[S4-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					title_es:
						'[S4-SBM3] Impactos materiales, riesgos y oportunidades y su interacción con la estrategia y el modelo de negocio',
					title_en:
						'[S4-SBM3] Material impacts, risks and opportunities and their interaction with strategy and business model',
					formId: 82,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey: '[S4-1] Policies related to consumers and end-users',
					title_es:
						'[S4-1] Políticas relacionadas con los consumidores y usuarios finales',
					title_en: '[S4-1] Policies related to consumers and end-users',
					formId: 83,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[S4-2] Processes for engaging with consumers and end-users about impacts',
					title_es:
						'[S4-2] Procesos para la participación de los consumidores y usuarios finales sobre los impactos',
					title_en:
						'[S4-2] Processes for engaging with consumers and end-users about impacts',
					formId: 84,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey:
						'[S4-3] Processes to remediate negative impacts and channels for consumers and end-users to raise concerns',
					title_es:
						'[S4-3] Procesos para remediar impactos negativos y canales para que los consumidores y usuarios finales planteen inquietudes',
					title_en:
						'[S4-3] Processes to remediate negative impacts and channels for consumers and end-users to raise concerns',
					formId: 85,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
				},
				{
					titleKey:
						'[S4-4] Taking action on material impacts on consumers and end-users, and approaches to managing material risks and pursuing material opportunities related to consumers and end-users, and effectiveness of those actions',
					title_es:
						'[S4-4] Tomar medidas sobre los impactos materiales en los consumidores y usuarios finales, y enfoques para gestionar riesgos materiales y perseguir oportunidades materiales relacionadas con los consumidores y usuarios finales, y la efectividad de esas acciones',
					title_en:
						'[S4-4] Taking action on material impacts on consumers and end-users, and approaches to managing material risks and pursuing material opportunities related to consumers and end-users, and effectiveness of those actions',
					formId: 86,
					sortOrder: 5,
					optional: false,
					questionIds: [
						1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
					],
				},
				{
					titleKey:
						'[S4-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities (consumers and end-users)',
					title_es:
						'[S4-5] Objetivos relacionados con la gestión de impactos negativos materiales, el avance de impactos positivos y la gestión de riesgos y oportunidades materiales (consumidores y usuarios finales)',
					title_en:
						'[S4-5] Targets related to managing material negative impacts, advancing positive impacts, and managing material risks and opportunities (consumers and end-users)',
					formId: 87,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6],
				},
			],
		},

		// Proceso 12 - G1 Governance
		12: {
			titleKey: 'casosTable.procesos.ESRS G1',
			descriptionKey: 'extraccionDashboard.procesosNombres.ESRS G1',
			sections: [
				{
					titleKey:
						'[GOV-1] Role of administrative, supervisory and management bodies',
					title_es:
						'[GOV-1] Función de los órganos administrativos, de supervisión y de gestión',
					title_en:
						'[GOV-1] Role of administrative, supervisory and management bodies',
					formId: 88,
					sortOrder: 1,
					optional: false,
					questionIds: [1, 2],
				},
				{
					titleKey: '[G1-1] Business conduct policies and corporate culture',
					title_es:
						'[G1-1] Políticas de conducta empresarial y cultura corporativa',
					title_en: '[G1-1] Business conduct policies and corporate culture',
					formId: 89,
					sortOrder: 2,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey: '[G1-2] Management of relationships with suppliers',
					title_es: '[G1-2] Gestión de las relaciones con los proveedores',
					title_en: '[G1-2] Management of relationships with suppliers',
					formId: 90,
					sortOrder: 3,
					optional: false,
					questionIds: [1, 2, 3],
				},
				{
					titleKey: '[G1-3] Prevention and detection of corruption or bribery',
					title_es: '[G1-3] Prevención y detección de corrupción o soborno',
					title_en: '[G1-3] Prevention and detection of corruption or bribery',
					formId: 91,
					sortOrder: 4,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
				},
				{
					titleKey: '[G1-4] Incidents of corruption or bribery',
					title_es: '[G1-4] Incidentes de corrupción o soborno',
					title_en: '[G1-4] Incidents of corruption or bribery',
					formId: 92,
					sortOrder: 5,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8],
				},
				{
					titleKey: '[G1-5] Political influence and lobbying activities',
					title_es: '[G1-5] Influencia política y actividades de lobby',
					title_en: '[G1-5] Political influence and lobbying activities',
					formId: 93,
					sortOrder: 6,
					optional: false,
					questionIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
				},
				{
					titleKey: '[G1-6] Payment practices',
					title_es: '[G1-6] Prácticas de pago',
					title_en: '[G1-6] Payment practices',
					formId: 94,
					sortOrder: 7,
					optional: false,
					questionIds: [1, 2, 3, 4, 5],
				},
			],
		},
	},
	// Configuración global
	global: {
		header: {
			titleKey: 'pdfReport.title',
		},
		fonts: {
			titleSize: 16,
			subtitleSize: 14,
			normalSize: 12,
			smallSize: 10,
		},
		colors: {
			title: [0, 0, 1], // RGB para azul
			subtitle: [0, 0, 0.8],
			normal: [0, 0, 0],
			highlight: [0.2, 0.4, 0.8],
		},
	},
};

/**
 * Obtiene el texto localizado para un campo específico
 * @param {Object} field - Campo que contiene los textos traducidos
 * @param {string} language - Código de idioma (es/en/EN/ES)
 */
export const getFieldText = (field, language) => {
	const normalizedLang = language?.toUpperCase() === 'ES' ? 'es' : 'en';

	// Si es una clave de traducción, intentamos obtenerla usando i18next
	if (field?.titleKey) {
		const translation = i18next.t(field.titleKey);

		// Si la traducción existe y no es igual a la clave (es decir, no hay fallback),
		// usamos la traducción
		if (translation && translation !== field.titleKey) {
			return translation;
		}

		// Si no hay traducción, usamos los campos directos
		return (
			field[`title_${normalizedLang}`] || field[`title_en`] || field.titleKey
		);
	}

	// Si no hay clave, buscamos el campo directo
	return (
		field[`title_${normalizedLang}`] || field[`title_en`] || field.title || ''
	);
};

/**
 * Obtiene un valor desde un path usando i18next
 * @param {string} path - Path de la traducción
 * @param {string} defaultValue - Valor por defecto
 * @param {string} language - Idioma a usar (es/en)
 */
const getTranslation = (path, defaultValue = '', language = 'en') => {
	const normalizedLang = language?.toUpperCase() === 'ES' ? 'es' : 'en';

	// Traducciones directas para ambos idiomas
	if (
		path.startsWith('pdfReport.labels.') ||
		path === 'pdfReport.title' ||
		path === 'pdfReport.subtitle'
	) {
		// Para español
		if (normalizedLang === 'es') {
			switch (path) {
				case 'pdfReport.title':
					return 'Compañía ABC 2026';
				case 'pdfReport.subtitle':
					return 'Informe de Gestión y Sostenibilidad';
				case 'pdfReport.labels.company':
					return 'Empresa';
				case 'pdfReport.labels.process':
					return 'Proceso';
				case 'pdfReport.labels.year':
					return 'Año';
				case 'pdfReport.labels.creationDate':
					return 'Fecha de creación';
				case 'pdfReport.labels.reportDate':
					return 'Fecha de generación del informe';
				case 'pdfReport.labels.noAnswer':
					return 'Sin respuesta';
				case 'pdfReport.labels.departmentsQuestion':
					return 'Departamentos sobre los que se informará';
				default:
					break;
			}
		}
		// Para inglés
		else {
			switch (path) {
				case 'pdfReport.title':
					return 'Company ABC 2026';
				case 'pdfReport.subtitle':
					return 'Management and Sustainability Report';
				case 'pdfReport.labels.company':
					return 'Company';
				case 'pdfReport.labels.process':
					return 'Process';
				case 'pdfReport.labels.year':
					return 'Year';
				case 'pdfReport.labels.creationDate':
					return 'Creation date';
				case 'pdfReport.labels.reportDate':
					return 'Report generation date';
				case 'pdfReport.labels.noAnswer':
					return 'No answer';
				case 'pdfReport.labels.departmentsQuestion':
					return 'Departments that will be reported';
				default:
					break;
			}
		}
	}

	// Intenta la traducción vía i18next como respaldo
	const translation = i18next.t(path);
	return translation && translation !== path ? translation : defaultValue;
};

// Función auxiliar para obtener las etiquetas localizadas
const getLocalizedLabels = (normalizedLang) => {
	return {
		company: getTranslation(
			'pdfReport.labels.company',
			normalizedLang === 'es' ? 'Empresa' : 'Company',
			normalizedLang
		),
		process: getTranslation(
			'pdfReport.labels.process',
			normalizedLang === 'es' ? 'Proceso' : 'Process',
			normalizedLang
		),
		year: getTranslation(
			'pdfReport.labels.year',
			normalizedLang === 'es' ? 'Año' : 'Year',
			normalizedLang
		),
		creationDate: getTranslation(
			'pdfReport.labels.creationDate',
			normalizedLang === 'es' ? 'Fecha de creación' : 'Creation date',
			normalizedLang
		),
		reportDate: getTranslation(
			'pdfReport.labels.reportDate',
			normalizedLang === 'es'
				? 'Fecha de generación del informe'
				: 'Report generation date',
			normalizedLang
		),
		noAnswer: getTranslation(
			'pdfReport.labels.noAnswer',
			normalizedLang === 'es' ? 'Sin respuesta' : 'No answer',
			normalizedLang
		),
		departmentsQuestion: getTranslation(
			'pdfReport.labels.departmentsQuestion',
			normalizedLang === 'es'
				? 'Departamentos sobre los que se informará'
				: 'Departments that will be reported',
			normalizedLang
		),
	};
};

// Función auxiliar para obtener el encabezado localizado
const getLocalizedHeader = (normalizedLang) => {
	return {
		title: getTranslation(
			'pdfReport.title',
			normalizedLang === 'es' ? 'Compañía ABC 2026' : 'Company ABC 2026',
			normalizedLang
		),
		subtitle: getTranslation(
			'pdfReport.subtitle',
			normalizedLang === 'es'
				? 'Informe de Gestión y Sostenibilidad'
				: 'Management and Sustainability Report',
			normalizedLang
		),
	};
};

// Función auxiliar para transformar las secciones
const transformSections = (sections, normalizedLang) => {
	return sections.map((section) => ({
		title: getFieldText(section, normalizedLang),
		formId: section.formId,
		sortOrder: section.sortOrder,
		optional: section.optional !== undefined ? section.optional : true,
		questionIds: section.questionIds || [],
	}));
};

// Función auxiliar para obtener la descripción del proceso
const getProcessDescription = (processConfig, normalizedLang) => {
	if (!processConfig.descriptionKey) {
		return '';
	}

	return getTranslation(
		processConfig.descriptionKey,
		processConfig[`description_${normalizedLang}`] ||
			processConfig.description ||
			'',
		normalizedLang
	);
};

// Función principal refactorizada
export const getLocalizedConfig = (procesosId, language = 'es') => {
	const normalizedLang = language?.toUpperCase() === 'ES' ? 'es' : 'en';

	// Obtenemos la configuración del proceso
	const processConfig = pdfReportConfig.processes[procesosId];

	if (!processConfig) {
		return getDefaultConfig(procesosId, normalizedLang);
	}

	// Creamos una copia localizada de la configuración
	const localizedConfig = {
		process: {
			title: getFieldText(processConfig, normalizedLang),
			description: getProcessDescription(processConfig, normalizedLang),
			sections: transformSections(processConfig.sections, normalizedLang),
			// Pasamos la configuración de la pregunta consolidada
			hasConsolidatedDepartmentsQuestion:
				processConfig.hasConsolidatedDepartmentsQuestion || false,
			consolidatedQuestionSectionOrder:
				processConfig.consolidatedQuestionSectionOrder || 0,
		},
		global: {
			header: getLocalizedHeader(normalizedLang),
			labels: getLocalizedLabels(normalizedLang),
			fonts: pdfReportConfig.global.fonts,
			colors: pdfReportConfig.global.colors,
		},
	};

	return localizedConfig;
};

/**
 * Configuración por defecto para casos donde no hay configuración específica
 * @param {number} procesosId - ID del proceso
 * @param {string} language - Código de idioma (es/en)
 */
export const getDefaultConfig = (procesosId, language = 'es') => {
	const normalizedLang = language?.toUpperCase() === 'ES' ? 'es' : 'en';

	return {
		process: {
			title:
				normalizedLang === 'es'
					? `Proceso ${procesosId}`
					: `Process ${procesosId}`,
			description:
				normalizedLang === 'es'
					? `Informe del Proceso ${procesosId}`
					: `Process ${procesosId} Report`,
			sections: [
				{
					title:
						normalizedLang === 'es'
							? `Sección principal - Proceso ${procesosId}`
							: `Main Section - Process ${procesosId}`,
					formId: 1,
					sortOrder: 1,
					optional: false,
					questionIds: [],
				},
			],
			hasConsolidatedDepartmentsQuestion: false,
			consolidatedQuestionSectionOrder: 0,
		},
		global: {
			header: {
				title: getTranslation(
					'pdfReport.title',
					normalizedLang === 'es' ? 'Compañía ABC 2026' : 'Company ABC 2026',
					normalizedLang
				),
				subtitle: getTranslation(
					'pdfReport.subtitle',
					normalizedLang === 'es'
						? 'Informe de Gestión y Sostenibilidad'
						: 'Management and Sustainability Report',
					normalizedLang
				),
			},
			labels: getLocalizedLabels(normalizedLang),
			fonts: pdfReportConfig.global.fonts,
			colors: pdfReportConfig.global.colors,
		},
	};
};
