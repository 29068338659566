// frontend/src/styles/sidebarStyles.js
export const sidebarStyles = {
	container:
		'w-73 min-h-screen bg-fondosuavizado border-r border-[#d4a976]',
	wrapper: 'px-6 py-8',
	title: 'text-[#4b3621] text-lg font-semibold mb-8',
	nav: 'space-y-2',
	button: (isSelected) => `
      w-full text-left px-4 py-3 rounded-lg 
      transition-all duration-200
      flex items-center space-x-3 group
      ${
				isSelected
					? '!bg-primario text-white shadow-md transform translate-x-2'
					: '!bg-secundario text-white hover:!bg-primario '
			}
    `,
	indicator: (isSelected) =>
		'w-2 h-2 rounded-full transition-all duration-200 bg-white',
	buttonText: 'font-medium',
	errorContainer: 'mt-6 p-4 bg-warn/40  border-l-4 border-error rounded',
	errorText: 'text-sm text-error',
};
