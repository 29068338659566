//frontend/src/config/areaOptions.js
export const areaOptions = {
	System: [],
	ESG: ['General Strategy ESRS 2'],
	MedioAmbiental: [
		'Climate Change ESRS E1',
		'Pollution ESRS E2',
		'Water & marine resources ESRS E3',
		'Biodiversity & Ecosystems ESRS E4',
		'Resource use & circular economy ESRS E5',
	],
	EstandaresSociales: [
		'Own Workforce ESRS S1',
		'Worker in the value chain ESRS S2',
		'Affected communities ESRS S3',
		'Consumers & end-users ESRS S4',
	],
	GobiernoCorporativo: ['Business Conduct ESRS G1'],
	Financiero: ['Finances'],
};
