// config/procesosNombres.js
// Mapeo de códigos de procesos a textos descriptivos

export const obtenerMapeoProcesos = (language) => ({
	'ESRS 1': language === 'EN' ? 'General Principles' : 'Principios Generales',
	'ESRS 2': language === 'EN' ? 'General Information' : 'Información General',
	'ESRS E1': language === 'EN' ? 'Climate Change' : 'Cambio Climático',
	'ESRS E2': language === 'EN' ? 'Pollution' : 'Contaminación',
	'ESRS E3':
		language === 'EN' ? 'Water & Marine Resources' : 'Agua y Recursos Marinos',
	'ESRS E4':
		language === 'EN'
			? 'Biodiversity & Ecosystems'
			: 'Biodiversidad y Ecosistemas',
	'ESRS E5':
		language === 'EN'
			? 'Resource Use & Circular Economy'
			: 'Uso de Recursos y Economía Circular',
	'ESRS S1': language === 'EN' ? 'Own Workforce' : 'Plantilla Propia',
	'ESRS S2':
		language === 'EN'
			? 'Workers in the Value Chain'
			: 'Trabajadores en la Cadena de Valor',
	'ESRS S3':
		language === 'EN' ? 'Affected Communities' : 'Comunidades Afectadas',
	'ESRS S4':
		language === 'EN'
			? 'Consumers & End-users'
			: 'Consumidores y Usuarios Finales',
	'ESRS G1': language === 'EN' ? 'Business Conduct' : 'Conducta Empresarial',
});

/**
 * Función para obtener el nombre descriptivo de un proceso
 * @param {string} codigoProceso - Código del proceso (ej: "ESRS 2")
 * @param {string} language - Idioma ("EN" o "ES")
 * @returns {string} Nombre descriptivo del proceso
 */
export const obtenerNombreDescriptivoProceso = (
	codigoProceso,
	language = 'ES'
) => {
	const mapeo = obtenerMapeoProcesos(language);

	// Intentamos coincidencia exacta
	if (mapeo[codigoProceso]) {
		// Devolvemos el código del proceso junto con su descripción
		return `${codigoProceso} ${mapeo[codigoProceso]}`;
	}

	// Si no encontramos coincidencia, devolvemos el código original
	return codigoProceso;
};
