// frontend/src/styles/updateUserStyles.js
export const updateUserStyles = {
	container: 'max-w-6xl mx-auto py-8 px-4',
	card: 'bg-fondo rounded-xl shadow-lg overflow-hidden',
	header: 'bg-gradient-to-r from-secundario to-secundario/75 p-6 text-white',
	form: 'p-8',
	searchForm: 'w-full max-w-lg mx-auto p-8',
	formGroup: 'space-y-6',
	label: 'block text-sm font-medium text-secundario mb-1',
	searchLabel: 'block text-white text-lg font-medium mb-4 text-center',
	input:
		'w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-secundario focus:border-secundario transition-all duration-200',
	searchInput:
		'w-full px-4 py-2 border text-black rounded-lg focus:ring-2 focus:ring-secundario focus:border-secundario transition-all duration-200 text-lg',
	checkbox: 'w-5 h-5 rounded focus:ring-secundario accent-secundario',
	button:
		'w-full py-3 px-6 bg-secundario text-white font-medium rounded-lg shadow-md hover:from-blue-600 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200',
	searchButton:
		'bg-fondo hover:bg-opacity-80 text-secundario px-6 rounded-lg font-medium transition-all duration-200 shadow-lg hover:shadow-xl flex items-center justify-center min-w-[160px]',
	titleContainer: 'text-center mb-8',
	titleText: 'text-4xl font-bold text-secundario mb-2',
	titleUnderline:
		'h-1 w-24 bg-gradient-to-r from-secundario to-transparent mx-auto rounded-full',
	errorMessage:
		'bg-error/30 border-l-4 border-red-500 text-error p-4 mx-6 my-4 rounded',
	errorText: 'text-sm font-medium',
	grid: 'grid grid-cols-1 md:grid-cols-2 gap-8 mb-12',
	areaSection: 'bg-secundario/10 rounded-xl p-8 mb-12',
	areaHeader: 'flex items-center space-x-4 mb-8',
	areaTitle: 'text-xl font-semibold',
	areaLine: 'h-px flex-1 bg-gradient-to-r from-secundario to-transparent',
	areaGrid: 'grid grid-cols-1 md:grid-cols-2 gap-6',
	areaItem:
		'bg-fondo p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-100',
	areaLabel: 'flex items-center space-x-3 cursor-pointer',
	areaText: 'flex-1 text-sm font-medium text-secundario',
	departmentSection: 'bg-secundario/10 rounded-xl p-8 mb-8',
	departmentHeader: 'flex items-center space-x-4 mb-6',
	departmentItem:
		'bg-fondo p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-100',
	departmentName: 'block text-base font-medium  mb-4',
	permissionsContainer: 'flex flex-wrap gap-8',
	permissionLabel: 'flex items-center space-x-3 cursor-pointer',
	permissionText: 'text-sm font-medium ',
	buttonContainer: 'mt-10',
	searchContainer: 'flex space-x-4',
	loader: 'flex justify-center items-center min-h-screen',
	loaderSpinner:
		'animate-spin rounded-full h-12 w-12 border-b-2 border-secundario mx-auto',
	loaderText: 'mt-4 text-secundario',
	errorContainer: 'p-4 bg-error/30 border-l-4 border-red-500 text-error',
};
