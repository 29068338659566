import { ROUTE_USERS } from '../../../config/routeConfig';
import { debugError } from '../../debugHelper';
import { fetchWithAuth } from '../../fetchUtils';

const dashboardHandlers = {
	fetchUsers: async (page, usersPerPage, setUsers, setTotalPages) => {
		try {
			const response = await fetchWithAuth(
				`${ROUTE_USERS}?page=${page}&limit=${usersPerPage}`,
				{
					method: 'GET',
				}
			);
			const data = await response.json();

			if (Array.isArray(data.users)) {
				setUsers(data.users);
				setTotalPages(Math.ceil(data.total / usersPerPage));
				return true;
			} else {
				debugError(
					'La respuesta de la API no contiene un array de usuarios:',
					data
				);
				setUsers([]);
				return false;
			}
		} catch (error) {
			debugError('Error al obtener los usuarios:', error);
			setUsers([]);
			return false;
		}
	},

	// Nueva función para obtener todos los usuarios de una vez
	fetchAllUsers: async (setAllUsers) => {
		try {
			// Opción 1: Si tu API soporta obtener todos los usuarios sin límite de paginación
			const response = await fetchWithAuth(
				`${ROUTE_USERS}?limit=1000`, // Usar un límite grande para obtener todos
				{
					method: 'GET',
				}
			);
			const data = await response.json();

			if (Array.isArray(data.users)) {
				setAllUsers(data.users);
				return true;
			} else {
				debugError(
					'La respuesta de la API no contiene un array de usuarios:',
					data
				);
				setAllUsers([]);
				return false;
			}
		} catch (error) {
			debugError('Error al obtener todos los usuarios:', error);
			setAllUsers([]);
			return false;
		}
	},
};

export { dashboardHandlers };
