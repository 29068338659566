import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useFormularioData from '../../hooks/useFormularioData';
import useUserLanguage from '../../hooks/useUserLanguage';
import useUserId from '../../hooks/useUserId';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { areaOptions } from '../../config/areaOptions';
import styles from './VistaFormulario.module.css';
import useEnviarNotificacionRedireccion from '../../hooks/useEnviarNotificacionRedireccion';

function VistaFormulario() {
  const { empresaId } = useParams();
  const { formularios, error, procesoId } = useFormularioData(empresaId);
  const { language } = useUserLanguage();
  const { userId } = useUserId();
  const { enviarCorreo } = useEnviarNotificacionRedireccion();
  const navigate = useNavigate();

  const [respuestas, setRespuestas] = useState({});
  const [respuestasEnviadas, setRespuestasEnviadas] = useState({});
  const [redirigirPregunta, setRedirigirPregunta] = useState({});
  const [selectedArea, setSelectedArea] = useState({});
  const [selectedDepartamento, setSelectedDepartamento] = useState({});
  const [mostrarPreguntas, setMostrarPreguntas] = useState({});

  useEffect(() => {
    const updatedRespuestas = {};
    Object.keys(respuestasEnviadas).forEach((key) => {
      const { preguntasid, formularioid, respuesta } = respuestasEnviadas[key];
      updatedRespuestas[`${formularioid}-${preguntasid}`] = respuesta;
    });
    setRespuestas(updatedRespuestas);
  }, [respuestasEnviadas]);

  useEffect(() => {
    if (!procesoId) {
      console.error('procesoId no está definido.');
      return;
    }
    fetchRespuestasEnviadas();
  }, [empresaId, procesoId]);

  const fetchRespuestasEnviadas = async () => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/respuestas/empresa?empresaId=${empresaId}`);
      if (!response.ok) throw new Error('Error al obtener respuestas enviadas');
  
      const data = await response.json();
      console.log("Respuestas filtradas por empresaId desde la API:", data);
  
      const respuestasMap = {};
      data.forEach((respuesta) => {
        const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;
        respuestasMap[respuestaKey] = respuesta;
      });
  
      setRespuestasEnviadas(respuestasMap);
    } catch (error) {
      console.error('Error al obtener respuestas enviadas:', error);
    }
  };

  const handleEditorChange = (preguntaId, formularioId, value) => {
    const respuestaKey = `${formularioId}-${preguntaId}`;
    setRespuestas((prev) => ({
      ...prev,
      [respuestaKey]: { ...prev[respuestaKey], text: value },
    }));

    if (value.trim() === '') {
      setRedirigirPregunta((prev) => ({
        ...prev,
        [preguntaId]: true
      }));
    }
  };

  const handleFileChange = (preguntaId, formularioId, file) => {
    const respuestaKey = `${formularioId}-${preguntaId}`;
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Image = e.target.result;
      setRespuestas((prev) => ({
        ...prev,
        [respuestaKey]: { ...prev[respuestaKey], image: base64Image },
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = (preguntaId, formularioId) => {
    if (!formularioId || !procesoId || isNaN(procesoId) || !empresaId) {
      console.error('Error: formularioId, procesoId, o empresaId no definidos o inválidos');
      return;
    }
    const nombreEmpresa = formularios[0]?.nombreEmpresa;
    const respuestaKey = `${formularioId}-${empresaId}-${procesoId}-${preguntaId}`;
    const isRedirigida = selectedArea[preguntaId] && selectedDepartamento[preguntaId];

    const preguntaTexto = formularios.find(f => f.FormularioId === formularioId)?.preguntas.find(p => p.id === preguntaId)?.text || {};
    const preguntaTextoES = preguntaTexto.ES || 'Pregunta sin texto en ES';
    const preguntaTextoEN = preguntaTexto.EN || 'Question without text in EN';

    const respuestaObj = {
      preguntasid: preguntaId,
      empresasid: parseInt(empresaId),
      procesosid: parseInt(procesoId),
      formularioid: formularioId,
      respuesta: isRedirigida ? null : {
        text: respuestas[`${formularioId}-${preguntaId}`]?.text || '',
        image: respuestas[`${formularioId}-${preguntaId}`]?.image || '',
      },
      redirigira: isRedirigida ? `${selectedArea[preguntaId]} - ${selectedDepartamento[preguntaId]}` : null,
      estadopregunta: isRedirigida ? 'Redirigida' : 'Contestada',
      respondidoporuserid: isRedirigida ? null : userId,
      contestadafecha: isRedirigida ? null : new Date().toISOString(),
      redirigidafecha: isRedirigida ? new Date().toISOString() : null,
    };

    console.log("Formulario enviado:", { formularioId, preguntaId, respuestaObj }); // Log de formularios respondidos

    const requestMethod = respuestasEnviadas[respuestaKey] ? 'PUT' : 'POST';
    const requestUrl = respuestasEnviadas[respuestaKey]
      ? `https://risincome.aicrumit.com/backend/api/respuestas/${respuestasEnviadas[respuestaKey].id}`
      : 'https://risincome.aicrumit.com/backend/api/respuestas';

    fetch(requestUrl, {
      method: requestMethod,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(respuestaObj),
    })
      .then((response) => {
        if (!response.ok) throw new Error(`Error al ${requestMethod === 'POST' ? 'enviar' : 'actualizar'} la respuesta`);
        return response.json();
      })
      .then((data) => {
        setRespuestasEnviadas((prev) => ({ ...prev, [respuestaKey]: data }));
        
        if (isRedirigida) {
          enviarCorreo(nombreEmpresa, selectedArea[preguntaId], selectedDepartamento[preguntaId], { ES: preguntaTextoES, EN: preguntaTextoEN });
        }
      })
      .catch((error) => {
        console.error(`Error al ${requestMethod === 'POST' ? 'enviar' : 'actualizar'} la respuesta:`, error);
      });
  };

  const togglePreguntas = (formularioId) => {
    setMostrarPreguntas((prev) => ({
      ...prev,
      [formularioId]: !prev[formularioId],
    }));
  };

  const toggleRedirigir = (preguntaId) => {
    setRedirigirPregunta((prev) => ({
      ...prev,
      [preguntaId]: !prev[preguntaId],
    }));
  };

  const handleAreaChange = (preguntaId, area) => {
    setSelectedArea((prev) => ({ ...prev, [preguntaId]: area }));
    setSelectedDepartamento((prev) => ({ ...prev, [preguntaId]: '' }));
  };

  const handleDepartamentoChange = (preguntaId, departamento) => {
    setSelectedDepartamento((prev) => ({ ...prev, [preguntaId]: departamento }));
  };

  const todasPreguntasContestadas = () => {
    return formularios.every((formulario) =>
      formulario.preguntas.every((pregunta) =>
        respuestasEnviadas[`${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`]?.estadopregunta === 'Contestada'
      )
    );
  };

    // Filtrar formularios por fecha, nombre de empresa, proceso, y con intervalo de 4 segundos
    const filterFormulariosByDateAndName = (formularios) => {
      return formularios.filter((formulario, index, self) => {
        const formularioDate = new Date(formulario.fechaCreacion).getTime();
        
        return (
          index ===
          self.findIndex((t) => {
            const tDate = new Date(t.fechaCreacion).getTime();
            return (
              t.nombreEmpresa === formulario.nombreEmpresa &&
              t.proceso?.nombre === formulario.proceso?.nombre &&
              Math.abs(tDate - formularioDate) <= 4000 // Intervalo de 4 segundos
            );
          })
        );
      });
    };

    const handleGuardarTodo = () => {
      const savePromises = [];
    
      formularios.forEach((formulario) => {
        formulario.preguntas.forEach((pregunta) => {
          const respuestaKey = `${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`;
          const respuestaGuardada = respuestasEnviadas[respuestaKey];
    
          // Si la pregunta es requerida y no tiene una respuesta válida, saltarla
          if (pregunta.required && !esRespuestaValida(formulario.FormularioId, pregunta)) {
            console.log(`Pregunta ${pregunta.id} es requerida pero no tiene una respuesta válida, no se guardará.`);
            return; // Saltar esta pregunta
          }
    
          const isRedirigida = selectedArea[pregunta.id] && selectedDepartamento[pregunta.id];
          const respuestaObj = {
            preguntasid: pregunta.id,
            empresasid: parseInt(empresaId),
            procesosid: parseInt(procesoId),
            formularioid: formulario.FormularioId,
            respuesta: isRedirigida
              ? null
              : {
                  text: respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || '',
                  image: respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.image || '',
                },
            redirigira: isRedirigida ? `${selectedArea[pregunta.id]} - ${selectedDepartamento[pregunta.id]}` : null,
            estadopregunta: isRedirigida ? 'Redirigida' : 'Contestada',
            respondidoporuserid: isRedirigida ? null : userId,
            contestadafecha: isRedirigida ? null : new Date().toISOString(),
            redirigidafecha: isRedirigida ? new Date().toISOString() : null,
          };
    
          const requestMethod = respuestasEnviadas[respuestaKey] ? 'PUT' : 'POST';
          const requestUrl = respuestasEnviadas[respuestaKey]
            ? `https://risincome.aicrumit.com/backend/api/respuestas/${respuestasEnviadas[respuestaKey].id}`
            : 'https://risincome.aicrumit.com/backend/api/respuestas';
    
          const savePromise = fetch(requestUrl, {
            method: requestMethod,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(respuestaObj),
          })
            .then((response) => {
              if (!response.ok) throw new Error(`Error al ${requestMethod === 'POST' ? 'enviar' : 'actualizar'} la respuesta`);
              return response.json();
            })
            .then((data) => {
              setRespuestasEnviadas((prev) => ({ ...prev, [respuestaKey]: data }));
            })
            .catch((error) => {
              console.error(`Error al ${requestMethod === 'POST' ? 'enviar' : 'actualizar'} la respuesta:`, error);
            });
    
          savePromises.push(savePromise);
        });
      });
    
      Promise.all(savePromises)
        .then(() => {
          console.log('Respuestas válidas han sido guardadas');
          alert('Respuestas guardadas con éxito.');
        })
        .catch((error) => {
          console.error('Error al guardar todas las respuestas:', error);
        });
    };

    const handleFinalizarProceso = () => {
      const nuevoEstado = 'Completada';
      const nombreEmpresa = formularios[0]?.nombreEmpresa;
  
      // Filtrar los formularios que deben completarse aplicando el filtro de fecha
      const formulariosParaCompletar = filterFormulariosByDateAndName(formularios);
      // Ajustar la fechaCreacion a la zona horaria local
      const fechaCreacion = new Date(formulariosParaCompletar[0]?.fechaCreacion).toLocaleString("sv-SE"); 
  
      // Payload con la fecha ajustada
      const payload = {
        nombreEmpresa: nombreEmpresa,
        procesoId: parseInt(procesoId),
        estadoEmpresas: nuevoEstado,
        fechaCreacion, // Enviar fechaCreacion ajustada
      };
  
      console.log("Payload enviado al backend:", payload);
  
      fetch('https://risincome.aicrumit.com/backend/api/empresas/completar', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) throw new Error('Error al cambiar el estado del formulario');
          return response.json();
        })
        .then((data) => {
          console.log("Respuesta del servidor tras completar formularios:", data);
          navigate('/casos');
        })
        .catch((error) => {
          console.error('Error al cambiar el estado del formulario:', error);
        });
    };
  
    const esRespuestaValida = (formularioId, pregunta) => {
      if (!formularioId || !pregunta || !pregunta.id) return false;
      
      // Obtener y limpiar el texto de etiquetas HTML vacías
      const respuestaTexto = respuestas[`${formularioId}-${pregunta.id}`]?.text || '';
      const respuestaLimpia = respuestaTexto.replace(/<[^>]*>/g, '').trim(); // Elimina etiquetas HTML y espacios
    
      const isRedirigida = redirigirPregunta[pregunta.id];
      
      console.log(`Validación de respuesta - FormularioId: ${formularioId}, PreguntaId: ${pregunta.id}`);
      console.log(`Respuesta Texto Cruda: "${respuestaTexto}", Respuesta Limpia: "${respuestaLimpia}", Is Redirigida: ${isRedirigida}`);
    
      // Comprueba si hay contenido real en `respuestaLimpia` o si la pregunta está redirigida
      return (respuestaLimpia.length > 0) || isRedirigida;
    };

  const labels = {
    vistaFormulario: language === 'EN' ? 'Form View' : 'Vista de Formularios',
    nombreProceso: language === 'EN' ? 'Process Name' : 'Nombre del Proceso',
    nombreFormulario: language === 'EN' ? 'Form Name' : 'Nombre del Formulario',
    estadoFormulario: language === 'EN' ? 'Form Status' : 'Estado del Formulario',
    mostrarPreguntas: language === 'EN' ? 'Show Questions' : 'Mostrar Preguntas',
    esconderPreguntas: language === 'EN' ? 'Hide Questions' : 'Esconder Preguntas',
    guardarRespuesta: language === 'EN' ? 'Save Answer' : 'Guardar Respuesta',
    editarRespuesta: language === 'EN' ? 'Edit Answer' : 'Editar Respuesta',
    redirigirPregunta: language === 'EN' ? 'Redirect Question' : 'Redirigir Pregunta',
    quedaRedirigida: language === 'EN' ? 'Remains Redirected' : 'Queda Redirigida',
    soloVista: language === 'EN' ? 'View Only' : 'Solo vista',
    seleccionarArea: language === 'EN' ? 'Select an area' : 'Selecciona un área',
    seleccionarDepartamento: language === 'EN' ? 'Select a department' : 'Selecciona un departamento',
    finalizarProceso: language === 'EN' ? 'Finish Process' : 'Finalizar Proceso',
    cargandoFormulario: language === 'EN' ? 'Loading company forms...' : 'Cargando formularios de la empresa...',
    noPreguntasDisponibles: language === 'EN' ? 'No questions available for this form.' : 'No hay preguntas disponibles para este formulario.',
    preguntaTexto: language === 'EN' ? 'Question text not available' : 'Texto no disponible',
  };

  return (
    <div className={styles.formularioContainer}>
      <h1>{labels.vistaFormulario}</h1>
      {error && <p className={styles.errorText}>{error}</p>}
      {formularios.length > 0 ? (
        <div>
          {formularios
            .filter((formulario) => formulario.estadoEmpresas !== 'Acabada')
            .map((formulario, index) => (
              <div key={index} className={styles.formularioCard}>
                <p><strong>{labels.nombreProceso}:</strong> {formulario.proceso?.nombre || 'Sin nombre'}</p>
                <p><strong>{labels.nombreFormulario}:</strong> {formulario.formulario?.nombre || 'Sin nombre'}</p>             
                <p><strong>{labels.estadoFormulario}:</strong> {formulario.estadoEmpresas}</p>
                
                <button onClick={() => togglePreguntas(formulario.FormularioId)} className={styles.toggleButton}>
                  {mostrarPreguntas[formulario.FormularioId] ? labels.esconderPreguntas : labels.mostrarPreguntas}
                </button>
                
                {mostrarPreguntas[formulario.FormularioId] && formulario.preguntas && formulario.preguntas.length > 0 ? (
                  <ul className={styles.preguntasList}>
                    {formulario.preguntas.map((pregunta, idx) => {
                      const respuestaKey = `${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`;
                      const respuestaGuardada = respuestasEnviadas[respuestaKey];
                      const isRedirigida = respuestaGuardada?.estadopregunta === 'Redirigida';
                      const hasRespuesta = !!respuestaGuardada;
                      const canEdit = !hasRespuesta || respuestaGuardada?.respondidoporuserid === userId;

                      return (
                        <li key={idx} className={styles.preguntaItem}>
                          <strong>
        <a href={pregunta.link} target="_blank" rel="noopener noreferrer">
          {pregunta.id} - {pregunta.text[language] || labels.preguntaTexto}
        </a>
      </strong>
                          
      <ReactQuill
  value={respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''}
  onChange={(value) => handleEditorChange(pregunta.id, formulario.FormularioId, value)}
  modules={{
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
    ]
  }}
  formats={['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link', 'image']}
  className={`${styles.reactQuillEditor} ${pregunta.required ? styles.requiredField : ''}`} // Agregar clase condicional para `required`
  readOnly={!canEdit || isRedirigida}
/>
      
                          <div className={styles.buttonContainer}>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleFileChange(pregunta.id, formulario.FormularioId, e.target.files[0])}
                              className={styles.fileInput}
                              disabled={!canEdit || isRedirigida}
                            />
                            
                            {isRedirigida ? (
                              <button
                                className={`${styles.submitButton} ${styles.redirigidaButton}`}
                                style={{
                                  backgroundColor: 'orange',
                                  color: 'white',
                                  cursor: 'not-allowed',
                                  opacity: '0.5',
                                }}
                                disabled
                              >
                                {labels.quedaRedirigida}
                              </button>
                            ) : !canEdit ? (
                              <span className={styles.soloVista}>{labels.soloVista}</span>
                            ) : (
                              <>
                                <button
  onClick={() => handleSubmit(pregunta.id, formulario.FormularioId)}
  className={styles.submitButton}
  disabled={pregunta.required && !esRespuestaValida(formulario.FormularioId, pregunta)}
>
  {hasRespuesta ? labels.editarRespuesta : labels.guardarRespuesta}
</button>

                                <button
                                  onClick={() => toggleRedirigir(pregunta.id)}
                                  className={styles.redirigirButton}
                                >
                                  {labels.redirigirPregunta}
                                </button>
                              </>
                            )}
                          </div>

                          {redirigirPregunta[pregunta.id] && !isRedirigida && canEdit && (
                            <div className={styles.redirigirContainer}>
                              <select
                                value={selectedArea[pregunta.id] || ''}
                                onChange={(e) => handleAreaChange(pregunta.id, e.target.value)}
                                className={styles.selectInput}
                              >
                                <option value="">{labels.seleccionarArea}</option>
                                {Object.keys(areaOptions)
                                  .filter((area) => area !== 'System')
                                  .map((area) => (
                                    <option key={area} value={area}>{area}</option>
                                  ))}
                              </select>

                              {selectedArea[pregunta.id] && (
                                <select
                                  value={selectedDepartamento[pregunta.id] || ''}
                                  onChange={(e) => handleDepartamentoChange(pregunta.id, e.target.value)}
                                  className={styles.selectInput}
                                >
                                  <option value="">{labels.seleccionarDepartamento}</option>
                                  {areaOptions[selectedArea[pregunta.id]].map((departamento) => (
                                    <option key={departamento} value={departamento}>{departamento}</option>
                                  ))}
                                </select>
                              )}
                            </div>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  mostrarPreguntas[formulario.FormularioId] && <p>{labels.noPreguntasDisponibles}</p>
                )}
              </div>
            ))}
          <button
            onClick={handleFinalizarProceso}
            className={styles.submitButton}
            style={{
              backgroundColor: todasPreguntasContestadas() ? 'blue' : 'gray',
              cursor: todasPreguntasContestadas() ? 'pointer' : 'not-allowed',
              color: 'white',
            }}
            disabled={!todasPreguntasContestadas()}
          >
            {labels.finalizarProceso}
          </button>
          <button
  onClick={handleGuardarTodo}
  className={styles.submitButton}
  style={{
    backgroundColor: 'green',
    color: 'white',
    marginRight: '10px',
  }}
>
  {language === 'EN' ? 'Save All' : 'Guardar Todo'}
</button>
        </div>
      ) : (
        <p>{labels.cargandoFormulario}</p>
      )}
    </div>
  );
}

export default VistaFormulario;
