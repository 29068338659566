// VistaProcesoAcabado.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUserLanguage from '../hooks/useUserLanguage';
import useUserArea from '../hooks/useUserArea';
import styles from './VistaProcesoAcabado.module.css';

function VistaProcesoAcabado() {
  const { empresaId, procesoId } = useParams();
  const { language } = useUserLanguage();
  const { area } = useUserArea();
  const navigate = useNavigate();
  const [formularios, setFormularios] = useState([]);
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [nombreProceso, setNombreProceso] = useState('');
  const [error, setError] = useState(null);
  const [respuestasEnviadas, setRespuestasEnviadas] = useState({});
  const [mostrarPreguntas, setMostrarPreguntas] = useState({});

  useEffect(() => {
    fetchFormularios();
  }, [empresaId, procesoId]);

  const fetchFormularios = () => {
    fetch('https://risincome.aicrumit.com/backend/api/empresas/list')
      .then((response) => {
        if (!response.ok) throw new Error('Error al obtener la empresa');
        return response.json();
      })
      .then(async (data) => {
        const empresaData = data.find((item) => item.id === parseInt(empresaId));
        console.log("Datos de empresa obtenidos:", empresaData);

        if (empresaData) {
          setNombreEmpresa(empresaData.nombreEmpresa);
          
          const fechaCreacionReferencia = new Date(empresaData.fechaCreacion).getTime();

          const formulariosRelacionados = data.filter((item) => {
            const itemFechaCreacion = new Date(item.fechaCreacion).getTime();
            const diferenciaTiempo = Math.abs(itemFechaCreacion - fechaCreacionReferencia);

            return (
              item.procesosId === parseInt(procesoId) &&
              item.nombreEmpresa === empresaData.nombreEmpresa &&
              diferenciaTiempo <= 5000
            );
          });

          console.log("Formularios relacionados tras filtrado:", formulariosRelacionados);

          setNombreProceso(formulariosRelacionados[0]?.proceso?.nombre || 'Proceso sin nombre');

          const formulariosConPreguntas = await Promise.all(
            formulariosRelacionados.map(async (formulario) => {
              const preguntas = await fetchPreguntas(formulario.FormularioId);
              console.log(`Preguntas obtenidas para Formulario ID ${formulario.FormularioId}:`, preguntas);
              return { ...formulario, preguntas };
            })
          );

          // Ordenar formularios por FormularioId
          formulariosConPreguntas.sort((a, b) => a.FormularioId - b.FormularioId);

          setFormularios(formulariosConPreguntas);
          fetchRespuestasEnviadas();
        } else {
          setError('Empresa no encontrada');
        }
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
        setError('No se pudo cargar los formularios de la empresa.');
      });
  };

  const fetchPreguntas = async (formularioId) => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/formularios/${formularioId}/preguntas`);
      if (!response.ok) throw new Error('Error al obtener preguntas del formulario');
      const data = await response.json();
      return data.preguntas || [];
    } catch (error) {
      console.error(`Error al obtener preguntas para Formulario ID ${formularioId}:`, error);
      return [];
    }
  };

  const fetchRespuestasEnviadas = async () => {
    try {
      const response = await fetch('https://risincome.aicrumit.com/backend/api/respuestas');
      if (!response.ok) throw new Error('Error al obtener respuestas enviadas');
      const data = await response.json();

      const respuestasMap = {};
      data.forEach((respuesta) => {
        const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;
        respuestasMap[respuestaKey] = respuesta;
      });
      console.log("Respuestas obtenidas y mapeadas:", respuestasMap);
      setRespuestasEnviadas(respuestasMap);
    } catch (error) {
      console.error('Error al obtener respuestas enviadas:', error);
    }
  };

  const togglePreguntas = (formularioId) => {
    console.log(`Alternando visibilidad de preguntas para Formulario ID ${formularioId}`);
    setMostrarPreguntas((prev) => ({
      ...prev,
      [formularioId]: !prev[formularioId],
    }));
  };

  const isESG = area && area.includes('ESG');

  // Filtrar formularios por fecha, nombre de empresa, proceso y con intervalo de 4 segundos
const filterFormulariosByDateAndName = (formularios) => {
  return formularios.filter((formulario, index, self) => {
    const formularioDate = new Date(formulario.fechaCreacion).getTime();

    return (
      index ===
      self.findIndex((t) => {
        const tDate = new Date(t.fechaCreacion).getTime();
        return (
          t.nombreEmpresa === formulario.nombreEmpresa &&
          t.proceso?.nombre === formulario.proceso?.nombre &&
          Math.abs(tDate - formularioDate) <= 4000 // Intervalo de 4 segundos
        );
      })
    );
  });
};

const updateProcesoEstado = (nuevoEstado) => {
  // Asegurarse de que `formularios` esté disponible
  const nombreEmpresa = formularios[0]?.nombreEmpresa;

  // Filtrar formularios que deben completarse aplicando el filtro de fecha
  const formulariosParaCompletar = filterFormulariosByDateAndName(formularios);
  
  // Ajustar la fechaCreacion a la zona horaria local
  const fechaCreacion = new Date(formulariosParaCompletar[0]?.fechaCreacion).toLocaleString("sv-SE");

  // Payload con la fecha ajustada
  const payload = {
    nombreEmpresa,
    procesoId: parseInt(procesoId),
    estadoEmpresas: nuevoEstado,
    fechaCreacion, // Enviar fechaCreacion ajustada
  };

  console.log("Datos enviados al endpoint /api/empresas/completar:", payload);

  fetch('https://risincome.aicrumit.com/backend/api/empresas/completar', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (!response.ok) throw new Error(`Error al cambiar el estado a ${nuevoEstado}`);
      return response.json();
    })
    .then((data) => {
      console.log(`Proceso marcado como ${nuevoEstado} con éxito:`, data);
      navigate('/casos'); // Redirige al usuario a la URL especificada
    })
    .catch((error) => {
      console.error(`Error al cambiar el estado a ${nuevoEstado}:`, error);
    });
};

  const handleAcabarRequisito = () => {
    console.log("Iniciando proceso para acabar requisito...");
    updateProcesoEstado('Acabada');
  };

  const handleRevisarRequisito = () => {
    console.log("Iniciando proceso para revisar requisito...");
    updateProcesoEstado('Revision');
  };

  return (
    <div className={styles.formularioContainer}>
      <h1>
        Vista de {nombreProceso} Acabado de la empresa {nombreEmpresa}
      </h1>
      {error && <p className={styles.errorText}>{error}</p>}
      {formularios.length > 0 ? (
        <div>
          {formularios.map((formulario, index) => (
            <div key={index} className={styles.formularioCard}>
              <h2 className={styles.tituloFormulario}>{formulario.formulario?.nombre || 'Formulario sin nombre'}</h2>
              <p><strong>Estado del Formulario:</strong> {formulario.estadoEmpresas}</p>
              
              <button onClick={() => togglePreguntas(formulario.FormularioId)} className={styles.toggleButton}>
                {mostrarPreguntas[formulario.FormularioId] ? 'Esconder Preguntas' : 'Mostrar Preguntas'}
              </button>
              
              {mostrarPreguntas[formulario.FormularioId] && (
                <ul className={styles.preguntasList}>
                  {formulario.preguntas.map((pregunta, idx) => {
                    const respuestaKey = `${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`;
                    const respuesta = respuestasEnviadas[respuestaKey];
                    console.log(`Respuesta para Pregunta ID ${pregunta.id} y Formulario ID ${formulario.FormularioId}:`, respuesta);

                    return (
                      <li key={idx} className={styles.preguntaItem}>
                        <strong>Pregunta:</strong> {pregunta.text[language] || 'Texto no disponible'}
                        <p><strong>Respuesta:</strong> <span dangerouslySetInnerHTML={{ __html: respuesta?.respuesta?.text || 'Sin respuesta' }} /></p>
                        {respuesta?.respuesta?.image && (
                          <div>
                            <p><strong>Respuesta visual:</strong></p>
                            <img src={respuesta.respuesta.image} alt="Respuesta visual" className={styles.respuestaImagen} />
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>Cargando formularios de la empresa...</p>
      )}

      {isESG && (
        <div className={styles.buttonsContainer}>
          <button onClick={handleAcabarRequisito} className={`${styles.actionButton} ${styles.acabarButton}`}>
            Acabar Requisito
          </button>
          <button onClick={handleRevisarRequisito} className={`${styles.actionButton} ${styles.revisarButton}`}>
            Revisar Requisito
          </button>
        </div>
      )}
    </div>
  );
}

export default VistaProcesoAcabado;
