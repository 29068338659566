//frontend/src/hooks/useRespuestas.js
import { useState, useEffect, useCallback, useRef } from 'react';
import { shouldShowQuestion } from '../utils/questionUtils';
import {
	ROUTE_RESPONSES,
	ROUTE_RESPONSES_COMPANY,
} from '../config/routeConfig';
import { debug, debugError } from '../utils/debugHelper';
import { cleanQuillHtml } from '../utils/htmlUtils'; // Importamos la utilidad corregida
import { fetchWithAuth } from '../utils/fetchUtils';

function useRespuestas(empresaId, procesoId, formularios) {
	const [respuestas, setRespuestas] = useState({});
	const [respuestasEnviadas, setRespuestasEnviadas] = useState({});
	const [redirigirPregunta, setRedirigirPregunta] = useState({});
	const [selectedArea, setSelectedArea] = useState({});
	const [selectedDepartamento, setSelectedDepartamento] = useState({});

	// Usamos useRef para controlar si ya procesamos las respuestas
	// Esto evita bucles infinitos en el efecto
	const respuestasProcessedRef = useRef(false);

	useEffect(() => {
		debug('Debug - Estado redirigirPregunta actualizado:', redirigirPregunta);
	}, [redirigirPregunta]);

	useEffect(() => {
		// Si no hay respuestas enviadas, no hacemos nada
		if (Object.keys(respuestasEnviadas).length === 0) {
			return;
		}

		// Si ya procesamos las respuestas para este conjunto de respuestasEnviadas, no lo hacemos de nuevo
		// Esto evita bucles infinitos
		if (respuestasProcessedRef.current) {
			return;
		}

		respuestasProcessedRef.current = true;

		const updatedRespuestas = { ...respuestas }; // Creamos una copia para no mutar el estado directamente

		Object.values(respuestasEnviadas).forEach((respuesta) => {
			const editorKey = `${respuesta.formularioid}-${respuesta.preguntasid}`;
			const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;

			// Solo actualizamos si el valor ha cambiado o no existe
			// Esto reduce actualizaciones innecesarias
			if (
				!updatedRespuestas[editorKey] ||
				updatedRespuestas[editorKey].text !== respuesta.respuesta.text
			) {
				// No aplicamos limpieza aquí, solo guardamos tal cual
				updatedRespuestas[editorKey] = {
					text: respuesta.respuesta.text,
					image: respuesta.respuesta.image,
				};
			}

			// Solo guardamos la referencia, no mutamos el objeto
			updatedRespuestas[respuestaKey] = respuesta;
		});

		setRespuestas(updatedRespuestas);
	}, [respuestasEnviadas]);

	// Cuando cambia empresaId o procesoId, reseteamos el flag
	// para permitir procesar las nuevas respuestas
	useEffect(() => {
		respuestasProcessedRef.current = false;
	}, [empresaId, procesoId]);

	useEffect(() => {
		if (!procesoId) {
			debugError('procesoId no está definido.');
			return;
		}
		fetchRespuestasEnviadas();
	}, [empresaId, procesoId]);

	useEffect(() => {
		debug('Estado selectedArea actualizado:', selectedArea);
	}, [selectedArea]);

	useEffect(() => {
		debug('Estado selectedDepartamento actualizado:', selectedDepartamento);
	}, [selectedDepartamento]);

	const fetchRespuestasEnviadas = async () => {
		try {
			const response = await fetchWithAuth(
				`${ROUTE_RESPONSES_COMPANY}?empresaId=${empresaId}`
			);
			if (!response.ok) throw new Error('Error al obtener respuestas enviadas');

			const data = await response.json();
			debug('Respuestas filtradas por empresaId desde la API:', data);

			const respuestasMap = {};
			data.forEach((respuesta) => {
				const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;
				respuestasMap[respuestaKey] = respuesta;
			});

			setRespuestasEnviadas(respuestasMap);
		} catch (error) {
			debugError('Error al obtener respuestas enviadas:', error);
		}
	};

	// Solo limpiamos cuando el usuario edita, no cuando cargamos desde el servidor
	const handleEditorChange = (preguntaId, formularioId, value) => {
		const respuestaKey = `${formularioId}-${preguntaId}`;

		// Limpiamos el HTML solo si el usuario ha escrito algo simple
		// Para contenido más complejo, lo dejamos tal cual
		const cleanedValue = value;

		debug('handleEditorChange - Valor original:', value);
		debug('handleEditorChange - Valor que guardamos:', cleanedValue);

		setRespuestas((prev) => {
			const newRespuestas = {
				...prev,
				[respuestaKey]: { ...prev[respuestaKey], text: cleanedValue },
			};

			if (
				cleanedValue === 'negative' ||
				(typeof cleanedValue === 'string' &&
					cleanedValue.includes('No es material'))
			) {
				setRedirigirPregunta((prev) => {
					const newState = { ...prev };
					delete newState[preguntaId];

					formularios.forEach((formulario) => {
						formulario.preguntas.forEach((pregunta) => {
							if (pregunta.conditional?.controlledBy === preguntaId) {
								delete newState[pregunta.id];
							}
						});
					});

					return newState;
				});

				setSelectedArea((prev) => {
					const newState = { ...prev };
					delete newState[preguntaId];
					return newState;
				});

				setSelectedDepartamento((prev) => {
					const newState = { ...prev };
					delete newState[preguntaId];
					return newState;
				});
			}

			return newRespuestas;
		});
	};

	const handleFileChange = (preguntaId, formularioId, file) => {
		const respuestaKey = `${formularioId}-${preguntaId}`;
		const reader = new FileReader();
		reader.onload = (e) => {
			const base64Image = e.target.result;
			setRespuestas((prev) => ({
				...prev,
				[respuestaKey]: { ...prev[respuestaKey], image: base64Image },
			}));
		};
		reader.readAsDataURL(file);
	};

	const handleAreaChange = useCallback((preguntaId, area) => {
		debug('handleAreaChange called:', { preguntaId, area });
		setSelectedArea((prev) => ({
			...prev,
			[preguntaId]: area,
		}));
		setSelectedDepartamento((prev) => ({
			...prev,
			[preguntaId]: '',
		}));
	}, []);

	const handleDepartamentoChange = useCallback((preguntaId, departamento) => {
		debug('handleDepartamentoChange called:', {
			preguntaId,
			departamento,
		});
		setSelectedDepartamento((prev) => ({
			...prev,
			[preguntaId]: departamento,
		}));
	}, []);

	const toggleRedirigir = useCallback(
		(preguntaId, formularioId, closeAfterRedirect = false) => {
			debug('toggleRedirigir called:', {
				preguntaId,
				formularioId,
				closeAfterRedirect,
			});

			if (closeAfterRedirect) {
				// Si se está cerrando después de una redirección exitosa, simplemente cerramos
				// el panel y limpiamos los datos
				setRedirigirPregunta((prevState) => {
					const newState = { ...prevState };
					delete newState[preguntaId];
					return newState;
				});

				setSelectedArea((prev) => {
					const newAreaState = { ...prev };
					delete newAreaState[preguntaId];
					return newAreaState;
				});

				setSelectedDepartamento((prev) => {
					const newDepartamentoState = { ...prev };
					delete newDepartamentoState[preguntaId];
					return newDepartamentoState;
				});

				return;
			}

			// Comportamiento normal de toggle
			setRedirigirPregunta((prevState) => {
				const currentValue = prevState[preguntaId] || false;
				const newState = {
					...prevState,
					[preguntaId]: !currentValue,
				};

				// Si estamos desactivando la redirección
				if (currentValue) {
					// Limpiar las selecciones de área y departamento
					setSelectedArea((prev) => {
						const newAreaState = { ...prev };
						delete newAreaState[preguntaId];
						return newAreaState;
					});

					setSelectedDepartamento((prev) => {
						const newDepartamentoState = { ...prev };
						delete newDepartamentoState[preguntaId];
						return newDepartamentoState;
					});
				}

				return newState;
			});
		},
		[]
	);

	const updateRedirectStatus = async (
		preguntaId,
		formularioId,
		area,
		departamento
	) => {
		try {
			const respuestaKey = `${formularioId}-${empresaId}-${procesoId}-${preguntaId}`;
			const respuestaId = respuestasEnviadas[respuestaKey]?.id;

			if (!respuestaId) {
				debugError('No se encontró ID de respuesta para:', respuestaKey);
				return false;
			}

			const response = await fetchWithAuth(
				`${ROUTE_RESPONSES}/${respuestaId}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						redirigira: `${area} - ${departamento}`,
						estadopregunta: 'Redirigida',
						redirigidafecha: new Date().toISOString(),
					}),
				}
			);

			if (!response.ok) {
				throw new Error('Error al actualizar el estado de redirección');
			}

			await fetchRespuestasEnviadas();
			return true;
		} catch (error) {
			debugError('Error al actualizar el estado de redirección:', error);
			return false;
		}
	};

	return {
		respuestas,
		respuestasEnviadas,
		redirigirPregunta,
		selectedArea,
		selectedDepartamento,
		setRespuestasEnviadas,
		handleEditorChange,
		handleFileChange,
		toggleRedirigir,
		handleAreaChange,
		handleDepartamentoChange,
		updateRedirectStatus,
	};
}

export default useRespuestas;
