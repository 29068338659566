//frontend/src/hooks/user/useUserArea.js
import { useState, useEffect } from 'react';
import { ROUTE_AUTH_ME_AREA } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useUserArea() {
	const [area, setArea] = useState(null);
	const [error, setError] = useState(null);

	const fetchArea = async () => {
		try {
			const response = await fetchWithAuth(ROUTE_AUTH_ME_AREA, {
				method: 'GET',
			});

			if (response.ok) {
				const data = await response.json();
				debug('Área obtenida en Hook useUserArea:', data.area);
				setArea(data.area);
			} else {
				debugError('Error al obtener el área del usuario');
				setError('Error al obtener el área del usuario');
			}
		} catch (error) {
			debugError('Error en la solicitud de área:', error);
			setError('Error en la solicitud de área');
		}
	};

	// Llamamos a `fetchArea` en el primer renderizado del hook
	useEffect(() => {
		fetchArea();
	}, []);

	// Devuelve `fetchArea` como `refetchArea` para llamar después del login
	return { area, error, refetchArea: fetchArea };
}

export default useUserArea;
