import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserLanguage from '../hooks/useUserLanguage';
import useUserCompany from '../hooks/useUserCompany';
import useUserPermissions from '../hooks/useUserPermissions';
import useUserArea from '../hooks/useUserArea';
import useExportarWord from '../hooks/useExportarWord';
import styles from './CasosTable.module.css';

function CasosTable({ casos, handleFillForm, selectedOption }) {
  const { language: userLanguage } = useUserLanguage();
  const { empresa: userCompany } = useUserCompany();
  const { permissions = {} } = useUserPermissions();
  const { area } = useUserArea();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const { exportarWord } = useExportarWord();
  const [selectedYear, setSelectedYear] = useState(''); // Estado para el filtro de año

  const handleViewAcabadoProcess = (empresaId, procesoId) => {
    navigate(`/empresas/${empresaId}/procesos/${procesoId}/vista-proceso-acabado`);
  };

  const fetchPreguntas = async (formularioId) => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/formularios/${formularioId}/preguntas`);
      if (!response.ok) throw new Error('Error al obtener preguntas del formulario');
      const data = await response.json();
      return data.preguntas || [];
    } catch (error) {
      console.error(`Error al obtener preguntas para Formulario ID ${formularioId}:`, error);
      return [];
    }
  };

  const handleExportToWord = async (caso, language) => {
    console.log(`Caso seleccionado para exportación en ${language}:`, caso);
    try {
      // Paso 1: Obtener todos los formularios completados en esa fecha para ese proceso y empresa
      const urlFormularios = `https://risincome.aicrumit.com/backend/api/empresas/exportar-detalles/${caso.id}`;
      console.log("Enviando solicitud GET a:", urlFormularios);
  
      const responseFormularios = await fetch(urlFormularios);
      if (!responseFormularios.ok) throw new Error("Error al obtener los formularios completados");
  
      const empresasDetalles = await responseFormularios.json();
      console.log("Formularios obtenidos para exportación (empresasDetalles):", empresasDetalles);
  
      const preguntasPorFormulario = await Promise.all(
        empresasDetalles.map(async (formulario) => {
          console.log("Obteniendo preguntas para FormularioId:", formulario.FormularioId);
  
          const preguntas = await fetchPreguntas(formulario.FormularioId);
          const preguntasFiltradas = preguntas.map((p) => ({
            id: p.id,
            text: p.text[language] || p.text['EN']
          }));
  
          console.log(`Preguntas filtradas para FormularioId ${formulario.FormularioId}:`, preguntasFiltradas);
  
          return {
            FormularioId: formulario.FormularioId,
            preguntas: preguntasFiltradas,
            empresaId: caso.id,
            procesosId: formulario.procesosId 
          };
        })
      );
  
      console.log("Preguntas por formulario (preguntasPorFormulario):", preguntasPorFormulario);
  
      const fetchRespuestas = async () => {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/respuestas');
        if (!response.ok) throw new Error('Error al obtener respuestas enviadas');
        const data = await response.json();
  
        const respuestasMap = {};
        data.forEach((respuesta) => {
          const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;
          respuestasMap[respuestaKey] = respuesta.respuesta;
        });
        console.log("Mapa de respuestas (respuestasMap):", respuestasMap);
        return respuestasMap;
      };
  
      const respuestasMap = await fetchRespuestas();
  
      const dataParaExportar = empresasDetalles.map((detalle) => {
        
        const preguntasConRespuestas = preguntasPorFormulario
          .find((p) => p.FormularioId === detalle.FormularioId)
          ?.preguntas.map((pregunta) => {
            const respuestaKey = `${detalle.FormularioId}-${detalle.empresaId || caso.id}-${detalle.procesosId}-${pregunta.id}`;
            const respuesta = respuestasMap[respuestaKey] || { text: "Sin respuesta", image: "" };
            
            console.log(`Construyendo clave de respuesta con: FormularioId=${detalle.FormularioId}, EmpresaId=${detalle.empresaId || caso.id}, ProcesosId=${detalle.procesosId}, PreguntaId=${pregunta.id}`);
            console.log(`Clave de respuesta buscada: ${respuestaKey}`);
            console.log(`Respuesta encontrada para Pregunta ID ${pregunta.id}:`, respuesta);
      
            return {
              ...pregunta,
              respuestaText: respuesta.text || "Sin respuesta",
              respuestaImage: respuesta.image || null,
            };
          }) || [];
      
        console.log(`Preguntas y respuestas para FormularioId ${detalle.FormularioId}:`, preguntasConRespuestas);
      
        return { ...detalle, preguntas: preguntasConRespuestas };
      });
      
      console.log("Datos completos para exportación (dataParaExportar) que se enviarán al controlador:", dataParaExportar);
      
      exportarWord(dataParaExportar);
  
    } catch (error) {
      console.error("Error en el proceso de exportación:", error);
      setError("Error al obtener los detalles para exportación.");
    }
  };
  

  const translations = {
    idEmpresa: { ES: 'ID Empresa', EN: 'Company ID' },
    nombreProceso: { ES: 'Nombre del Proceso', EN: 'Process Name' },
    nombreEmpresa: { ES: 'Nombre de la Empresa', EN: 'Company Name' },
    fechaCreacion: { ES: 'Fecha de Creación', EN: 'Creation Date' },
    ano: { ES: 'Año', EN: 'Year' },
    estado: { ES: 'Estado', EN: 'Status' },
    accion: { ES: 'Acción', EN: 'Action' },
    visualizarRequisito: { ES: 'Visualizar Requisito', EN: 'View Requirement' },
    rellenarRequisito: { ES: 'Rellenar Requisito', EN: 'Fill Requirement' },
    confirmarRequisito: { ES: 'Confirmar Requisito', EN: 'Confirm Requirement' },
    exportarWordES: { ES: 'Exportar a PDF (ES)', EN: 'Export to PDF (ES)' },
    exportarWordEN: { ES: 'Exportar a PDF (EN)', EN: 'Export to PDF (EN)' },
    sinNombre: { ES: 'Sin nombre', EN: 'No name' },
    sinEstado: { ES: 'Sin estado', EN: 'No status' },
    noHayCasos: { ES: 'No hay casos disponibles', EN: 'No cases available' },
    estadoValores: {
      Pendiente: { ES: 'Pendiente', EN: 'Pending' },
      Acabada: { ES: 'Acabada', EN: 'Finished' },
    },
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value); // Actualizar el año seleccionado
  };

  const filteredCasos = casos
    .filter((caso) => (selectedYear ? caso.anyo === parseInt(selectedYear) : true)) // Filtrar por año
    .map((caso) => ({
      ...caso,
      departamento: caso.proceso?.departamento || null,
    }))
    .filter((caso) => {
      const permisoDept = permissions?.[caso.departamento] || {};
      const isSameCompany = caso.nombreEmpresa === userCompany;
      const isESG = area && area.includes('ESG');

      if (isESG && (caso.estadoEmpresas === 'Completada' || caso.estadoEmpresas === 'Acabada')) {
        return true;
      }

      const hasPermission = permisoDept.ver || permisoDept.editar || permisoDept.enviar;
      return isSameCompany && hasPermission;
    });

console.log("Casos filtrados para visualizar:", filteredCasos);

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Select para filtrar por año */}
      <div className={styles.filterContainer}>
        <label htmlFor="yearFilter">{translations.ano[userLanguage]}:</label>
        <select id="yearFilter" value={selectedYear} onChange={handleYearChange}>
          <option value="">{userLanguage === 'ES' ? 'Todos los años' : 'All years'}</option>
          {Array.from(new Set(casos.map((caso) => caso.anyo))) // Generar opciones únicas de año
            .sort((a, b) => a - b) // Ordenar los años
            .map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
        </select>
      </div>

      <table className={styles.casosTable}>
        <thead>
          <tr>
            <th>{translations.idEmpresa[userLanguage]}</th>
            <th>{translations.nombreProceso[userLanguage]}</th>
            <th>{translations.nombreEmpresa[userLanguage]}</th>
            <th>{translations.fechaCreacion[userLanguage]}</th>
            <th>{translations.ano[userLanguage]}</th>
            <th>{translations.estado[userLanguage]}</th>
            <th>{translations.accion[userLanguage]}</th>
          </tr>
        </thead>
        <tbody>
          {filteredCasos.length > 0 ? (
            filteredCasos.map((caso) => {
              const permisoDept = permissions?.[caso.departamento] || {};
              const isESG = area && area.includes('ESG');
              const ano = caso.anyo;

              return (
                <tr key={caso.id}>
                  <td>{caso.id}</td>
                  <td>{caso.proceso?.nombre || translations.sinNombre[userLanguage]}</td>
                  <td>{caso.nombreEmpresa || translations.sinNombre[userLanguage]}</td>
                  <td>{new Date(caso.fechaCreacion).toLocaleDateString()}</td>
                  <td>{ano}</td>
                  <td>
                    {translations.estadoValores[caso.estadoEmpresas]?.[userLanguage] || caso.estadoEmpresas}
                  </td>
                  <td>
                  {selectedOption === 'Pendiente' && caso.estadoEmpresas !== 'Acabada' && permisoDept.editar ? (
  <button onClick={() => handleFillForm(caso.FormularioId, caso.id, caso.procesosId)}>
    {translations.rellenarRequisito[userLanguage]}
  </button>
) : (
  <button onClick={() => handleViewAcabadoProcess(caso.id, caso.procesosId)}>
    {translations.visualizarRequisito[userLanguage]}
  </button>
)}
                    {selectedOption === 'Completada' && (
                      <button onClick={() => handleViewAcabadoProcess(caso.id, caso.procesosId)}>
                        {isESG ? translations.confirmarRequisito[userLanguage] : translations.visualizarRequisito[userLanguage]}
                      </button>
                    )}
                    {selectedOption === 'Acabada' && (
                      isESG ? (
                        <>
                          <button className={styles.exportButton} onClick={() => handleExportToWord(caso, 'ES')}>
                            {translations.exportarWordES[userLanguage]}
                          </button>
                          <button className={styles.exportButton} onClick={() => handleExportToWord(caso, 'EN')}>
                            {translations.exportarWordEN[userLanguage]}
                          </button>
                        </>
                      ) : (
                        <button onClick={() => handleViewAcabadoProcess(caso.id, caso.procesosId)}>
                          {translations.visualizarRequisito[userLanguage]}
                        </button>
                      )
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="7">{translations.noHayCasos[userLanguage]}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CasosTable;
