// Separamos los mapeos por categorías para reducir la complejidad cognitiva

// ESRS 2 - Información general
const getMapeoESRS2 = (language) => ({
	'SBM-3':
		language === 'EN'
			? 'Material Impacts, Risks and Opportunities and Their Interaction with Strategy and Business Model'
			: 'Impactos, riesgos y oportunidades materiales y su interacción con la estrategia y el modelo de negocio. SBM-3',
	'IRO-1':
		language === 'EN'
			? 'Description of the Process to Identify and Assess Material Impacts, Risks and Opportunities'
			: 'Descripción del proceso para determinar y evaluar los impactos, riesgos y oportunidades materiales',
	'IRO-2':
		language === 'EN'
			? "Disclosure Requirements Covered by the Company's Sustainability Statement"
			: ' Requisitos de divulgación de las NEIS cubiertos por el estado de sostenibilidad de la empresa',
	'BP-1':
		language === 'EN'
			? 'General Basis for Sustainability Statement'
			: 'Base general para la elaboración del estado de sostenibilidad',
	'BP-2':
		language === 'EN'
			? 'Information on Specific Circumstances'
			: 'Información relativa a circunstancias específicas',
	'GOV-1':
		language === 'EN'
			? 'Role of Management and Supervisory Bodies'
			: 'El papel de los órganos de administración, dirección y supervisión',
	'GOV-2':
		language === 'EN'
			? 'Information Provided and Sustainability Issues'
			: 'Información facilitada y asuntos de sostenibilidad tratados por los órganos de administración, dirección y supervisión',
	'GOV-3':
		language === 'EN'
			? 'Integration of Sustainability Performance in Incentive Systems'
			: 'Integración del rendimiento en sostenibilidad en los sistemas de incentivos',
	'GOV-4':
		language === 'EN'
			? 'Declaration on Sustainability Due Diligence'
			: 'Declaración sobre la diligencia debida de sostenibilidad',
	'GOV-5':
		language === 'EN'
			? 'Risk Management and Internal Controls on Sustainability Statements'
			: 'Gestión de riesgos y controles internos sobre los estados de sostenibilidad',
	'SBM-1':
		language === 'EN'
			? 'Strategy, Business Model and Value Chain'
			: 'Estrategia, modelo de negocio y cadena de valor',
	'SBM-2':
		language === 'EN'
			? 'Overview of Strategy and Business Model'
			: 'Visión general de la estrategia y el modelo de negocio',
});

// ESRS E1 - Climate Change
const getMapeoESRSE1 = (language) => ({
	'E1.GOV-3':
		language === 'EN'
			? 'Integration of Sustainability Performance in Incentive Schemes'
			: 'Integración del rendimiento relacionado con la sostenibilidad en los esquemas de incentivos',
	'E1-1':
		language === 'EN'
			? 'Transition Plan for Climate Change Mitigation'
			: 'Plan de transición para la mitigación del cambio climático',
	'E1.SBM-3':
		language === 'EN'
			? 'Material Impacts, Risks and Opportunities and Strategy Interaction'
			: 'Impactos, riesgos y oportunidades materiales y su interacción con la estrategia y el modelo de negocio',
	'E1.IRO-1':
		language === 'EN'
			? 'Process Description for Climate-Related Impacts and Risks'
			: 'Descripción de los procesos para determinar y evaluar los impactos, los riesgos y las oportunidades materiales relacionados con el clima',
	'E1-2':
		language === 'EN'
			? 'Climate Change Mitigation and Adaptation Policies'
			: 'Políticas relacionadas con la mitigación y adaptación al cambio climático',
	'E1-3':
		language === 'EN'
			? 'Actions and Resources for Climate Policies'
			: 'Actuaciones y recursos en relación con las políticas en materia de cambio climático',
	'E1-4':
		language === 'EN'
			? 'Climate Change Mitigation and Adaptation Goals'
			: 'Metas relacionadas con la mitigación y adaptación al cambio climático',
	'E1-5':
		language === 'EN'
			? 'Energy Consumption and Mix'
			: 'Consumo y combinación energéticos',
	'E1-6':
		language === 'EN'
			? 'GHG Emissions Scope 1, 2, 3 and Total'
			: 'Emisiones de GEI brutas de alcance 1, 2, 3 y emisiones de GEI totales',
	'E1-7':
		language === 'EN'
			? 'GHG Absorption and Carbon Credits'
			: 'Absorciones de GEI y proyectos de mitigación de GEI financiados mediante créditos de carbono',
	'E1-8':
		language === 'EN'
			? 'Internal Carbon Pricing System'
			: 'Sistema de fijación del precio interno del carbono',
	'E1-9':
		language === 'EN'
			? 'Expected Financial Effects of Climate Risks and Opportunities'
			: 'Efectos financieros previstos de los riesgos físicos y de transición materiales y oportunidades potenciales relacionadas con el cambio climático',
});

// ESRS E2 - Pollution
const getMapeoESRSE2 = (language) => ({
	'E2-IRO1': language === 'EN' ? 'General Information' : 'Información general',
	'E2-1':
		language === 'EN'
			? 'Pollution-Related Policies'
			: 'Políticas relacionadas con la contaminación',
	'E2-2':
		language === 'EN'
			? 'Actions and Resources Related to Pollution'
			: 'Actuaciones y recursos relacionados con la contaminación',
	'E2-3':
		language === 'EN'
			? 'Pollution-Related Goals'
			: 'Metas relacionadas con la contaminación',
	'E2-4':
		language === 'EN'
			? 'Air, Water and Soil Pollution'
			: 'Contaminación del aire, del agua y del suelo',
	'E2-5':
		language === 'EN'
			? 'Substances of Concern and Extremely Concerning Substances'
			: 'Sustancias preocupantes (o peligrosas) y sustancias extremadamente preocupantes (o muy peligrosas)',
	'E2-6':
		language === 'EN'
			? 'Expected Financial Effects of Pollution-Related Risks and Opportunities'
			: 'Posibles efectos financieros previstos de los riesgos y oportunidades materiales relacionados con la contaminación',
});

// ESRS E3 - Water and Marine Resources
const getMapeoESRSE3 = (language) => ({
	'E3.IRO-1':
		language === 'EN'
			? 'Process Description for Water and Marine Resources Impacts and Risks'
			: 'Descripción de los procesos para determinar y evaluar los impactos, los riesgos y las oportunidades materiales relacionados con los recursos hídricos y marinos',
	'E3-1':
		language === 'EN'
			? 'Water and Marine Resources Policies'
			: 'Políticas relacionadas con los recursos hídricos y marinos',
	'E3-2':
		language === 'EN'
			? 'Actions and Resources for Water and Marine Resources'
			: 'Actuaciones y recursos asociados a los recursos hídricos y marinos',
	'E3-3':
		language === 'EN'
			? 'Water and Marine Resources Goals'
			: 'Metas relacionadas con los recursos hídricos y marinos',
	'E3-4': language === 'EN' ? 'Water Consumption' : 'Consumo de agua',
	'E3-5':
		language === 'EN'
			? 'Expected Financial Effects from Water and Marine Resources Risks'
			: 'Efectos financieros previstos derivados de riesgos y oportunidades asociados a los recursos hídricos y marinos',
});

// ESRS E4 - Biodiversity and Ecosystems
const getMapeoESRSE4 = (language) => ({
	'E4.SBM-3':
		language === 'EN'
			? 'Sites Located in Sensitive Areas'
			: 'Emplazamientos ubicados en zonas sensibles',
	'E4.IRO-1':
		language === 'EN'
			? 'Process Description for Biodiversity and Ecosystems Impacts and Risks'
			: 'Descripción de los procesos para identificar y evaluar los impactos, riesgos y oportunidades materiales relacionados con la biodiversidad y los ecosistemas',
	'E4-1':
		language === 'EN'
			? 'Transition Plan and Biodiversity Review in Strategy'
			: 'Plan de transición y examen de la biodiversidad y los ecosistemas en la estrategia y el modelo de negocio',
	'E4-2':
		language === 'EN'
			? 'Biodiversity and Ecosystems Policies'
			: 'Políticas relacionadas con la biodiversidad y los ecosistemas',
	'E4-3':
		language === 'EN'
			? 'Actions and Resources for Biodiversity and Ecosystems'
			: 'Actuaciones y recursos relacionados con la biodiversidad y los ecosistemas',
	'E4-4':
		language === 'EN'
			? 'Biodiversity and Ecosystems Goals'
			: 'Metas relacionadas con la biodiversidad y los ecosistemas',
	'E4-5':
		language === 'EN'
			? 'Impact Parameters Related to Biodiversity Changes'
			: 'Parámetros de impacto relacionados con los cambios de la biodiversidad y los ecosistemas',
	'E4-6':
		language === 'EN'
			? 'Expected Financial Effects of Biodiversity Risks and Opportunities'
			: 'Efectos financieros previstos de los riesgos y las oportunidades materiales relacionados con la biodiversidad y los ecosistemas',
});

// ESRS E5 - Resource Use and Circular Economy
const getMapeoESRSE5 = (language) => ({
	'E5.IRO-1':
		language === 'EN'
			? 'Process Description for Resource Use and Circular Economy Impacts and Risks'
			: 'Descripción de los procesos para determinar y evaluar los impactos, riesgos y oportunidades materiales relacionados con el uso de los recursos y la economía circular',
	'E5-1':
		language === 'EN'
			? 'Resource Use and Circular Economy Policies'
			: 'Políticas relacionadas con el uso de recursos y la economía circular',
	'E5-2':
		language === 'EN'
			? 'Actions and Resources for Resource Use and Circular Economy'
			: 'Actuaciones y recursos relacionados con el uso de los recursos y la economía circular',
	'E5-3':
		language === 'EN'
			? 'Resource Use and Circular Economy Goals'
			: 'Metas relacionadas con el uso de los recursos y la economía circular',
	'E5-4': language === 'EN' ? 'Resource Inputs' : 'Entradas de recursos',
	'E5-5': language === 'EN' ? 'Resource Outputs' : 'Salidas de recursos',
	'E5-6':
		language === 'EN'
			? 'Expected Financial Effects of Resource Use Risks and Opportunities'
			: 'Efectos financieros previstos de los riesgos y oportunidades materiales relacionados con el uso de los recursos y la economía circular',
});

// ESRS S1 - Own Personnel (Primera parte)
const getMapeoESRSS1Part1 = (language) => ({
	'S1.SBM-3':
		language === 'EN'
			? 'Material Impacts and Strategy Integration for Own Personnel'
			: 'Impactos, riesgos y oportunidades materiales y su integración con la estrategia y modelo de negocio, en el caso del personal propio',
	'S1-1':
		language === 'EN'
			? 'Own Personnel Policies'
			: 'Políticas relacionadas con el personal propio',
	'S1-2':
		language === 'EN'
			? 'Collaboration Processes with Personnel and Representatives'
			: 'Procesos para colaborar con el personal propio y los representantes de los trabajadores en materia de impactos',
	'S1-3':
		language === 'EN'
			? 'Redress Processes and Channels for Personnel'
			: 'Procesos para reparar impactos negativos y canales para que el personal propio exprese sus inquietudes',
	'S1-4':
		language === 'EN'
			? 'Measures for Material Impacts on Own Personnel'
			: 'Adopción de medidas relacionadas con los impactos materiales sobre el personal propio',
	'S1-5':
		language === 'EN'
			? 'Goals for Managing Material Negative Impacts'
			: 'Metas relacionadas con la gestión de impactos negativos materiales',
	'S1-6':
		language === 'EN'
			? 'Characteristics of Company Employees'
			: 'Características de los asalariados de la empresa',
	'S1-7':
		language === 'EN'
			? 'Characteristics of Non-Salaried Workers'
			: 'Características de los trabajadores no asalariados en el personal propio de la empresa',
	'S1-8':
		language === 'EN'
			? 'Collective Bargaining Coverage and Social Dialogue'
			: 'Cobertura de la negociación colectiva y diálogo social',
});

// ESRS S1 - Own Personnel (Segunda parte)
const getMapeoESRSS1Part2 = (language) => ({
	'S1-9':
		language === 'EN' ? 'Diversity Parameters' : 'Parámetros de diversidad',
	'S1-10': language === 'EN' ? 'Adequate Wages' : 'Salarios adecuados',
	'S1-11': language === 'EN' ? 'Social Protection' : 'Protección social',
	'S1-12':
		language === 'EN'
			? 'Persons with Disabilities'
			: 'Personas con discapacidad',
	'S1-13':
		language === 'EN'
			? 'Training and Skills Development Parameters'
			: 'Parámetros de formación y desarrollo de capacidades',
	'S1-14':
		language === 'EN'
			? 'Health and Safety Parameters'
			: 'Parámetros de salud y seguridad',
	'S1-15':
		language === 'EN'
			? 'Work-Life Balance Parameters'
			: 'Parámetros de conciliación laboral',
	'S1-16':
		language === 'EN'
			? 'Remuneration Parameters (Pay Gap and Total Compensation)'
			: 'Parámetros de remuneración (brecha salarial y remuneración total)',
	'S1-17':
		language === 'EN'
			? 'Human Rights-Related Impacts, Claims and Incidents'
			: 'Impactos, reclamaciones e incidencias graves relacionados con los derechos humanos',
});

// ESRS S2 - Workers in the Value Chain
const getMapeoESRSS2 = (language) => ({
	'S2.SBM-3':
		language === 'EN'
			? 'Material Impacts and Strategy Integration for Value Chain Workers'
			: 'Impactos, riesgos y oportunidades materiales y su interacción con la estrategia y modelo de negocio',
	'S2-1':
		language === 'EN'
			? 'Value Chain Workers Policies'
			: 'Políticas relacionadas con los trabajadores de la cadena de valor',
	'S2-2':
		language === 'EN'
			? 'Collaboration Processes with Value Chain Workers'
			: 'Procesos para colaborar con los trabajadores de la cadena de valor en materia de impactos',
	'S2-3':
		language === 'EN'
			? 'Redress Processes and Channels for Value Chain Workers'
			: 'Procesos para reparar los impactos negativos y canales para que los trabajadores de la cadena de valor expresen sus inquietudes',
	'S2-4':
		language === 'EN'
			? 'Measures for Material Impacts on Value Chain Workers'
			: 'Adopción de medidas relacionadas con los impactos materiales sobre los trabajadores de la cadena de valor',
	'S2-5':
		language === 'EN'
			? 'Goals for Managing Material Negative Impacts for Value Chain Workers'
			: 'Metas relacionadas con la gestión de impactos materiales negativos',
});

// ESRS S3 - Affected Communities
const getMapeoESRSS3 = (language) => ({
	'S3.SBM-3':
		language === 'EN'
			? 'Material Impacts and Strategy Integration for Affected Communities'
			: 'Interacción de los impactos, riesgos y oportunidades materiales con la estrategia y modelo de negocio',
	'S3-1':
		language === 'EN'
			? 'Affected Communities Policies'
			: 'Políticas relacionadas con los colectivos afectados',
	'S3-2':
		language === 'EN'
			? 'Collaboration Procedures with Affected Communities'
			: 'Procedimientos para colaborar con los colectivos afectados en materia de impactos',
	'S3-3':
		language === 'EN'
			? 'Redress Processes and Channels for Affected Communities'
			: 'Procesos para reparar los impactos negativos y canales para que los colectivos afectados expresen sus inquietudes',
	'S3-4':
		language === 'EN'
			? 'Measures for Material Impacts on Affected Communities'
			: 'Medidas relacionadas con los impactos materiales sobre los colectivos afectados',
	'S3-5':
		language === 'EN'
			? 'Goals for Managing Material Impacts on Affected Communities'
			: 'Metas relacionadas con la gestión de impactos materiales sobre colectivos afectados',
});

// ESRS S4 - Consumers and End Users
const getMapeoESRSS4 = (language) => ({
	'S4.SBM-3':
		language === 'EN'
			? 'Material Impacts and Strategy Integration for Consumers and End Users'
			: 'Impactos, riesgos y oportunidades materiales y su interacción con la estrategia y el modelo de negocio',
	'S4-1':
		language === 'EN'
			? 'Consumers and End Users Policies'
			: 'Políticas relacionadas con los consumidores y usuarios finales',
	'S4-2':
		language === 'EN'
			? 'Collaboration Processes with Consumers and End Users'
			: 'Procesos para colaborar con los consumidores y usuarios finales en materia de impactos',
	'S4-3':
		language === 'EN'
			? 'Redress Processes and Channels for Consumers and End Users'
			: 'Procesos para reparar los impactos negativos y los canales para que los consumidores y usuarios finales planteen sus inquietudes',
	'S4-4':
		language === 'EN'
			? 'Measures for Material Impacts and Opportunities'
			: 'Adopción de medidas relacionadas con los impactos materiales',
	'S4-5':
		language === 'EN'
			? 'Goals for Managing Material Impacts on Consumers and End Users'
			: 'Metas relacionadas con la gestión de impactos materiales sobre consumidores y usuarios finales',
});

// ESRS G1 - Governance
const getMapeoESRSG1 = (language) => ({
	'G1.GOV-1':
		language === 'EN'
			? 'Role of administrative, supervisory and management bodies'
			: 'Función de los órganos administrativos, de supervisión y de gestión',
	'G1-1':
		language === 'EN'
			? 'Business conduct policies and corporate culture'
			: 'Políticas de conducta empresarial y cultura corporativa',
	'G1-2':
		language === 'EN'
			? 'Management of relationships with suppliers'
			: 'Gestión de las relaciones con los proveedores',
	'G1-3':
		language === 'EN'
			? 'Prevention and detection of corruption or bribery'
			: 'Prevención y detección de corrupción o soborno',
	'G1-4':
		language === 'EN'
			? 'Incidents of corruption or bribery'
			: 'Incidentes de corrupción o soborno',
	'G1-5':
		language === 'EN'
			? 'Political influence and lobbying activities'
			: 'Influencia política y actividades de lobby',
	'G1-6': language === 'EN' ? 'Payment practices' : 'Prácticas de pago',
});

// Función principal refactorizada que combina todos los mapeos
export const obtenerMapeoFormularios = (language) => {
	return {
		...getMapeoESRS2(language),
		...getMapeoESRSE1(language),
		...getMapeoESRSE2(language),
		...getMapeoESRSE3(language),
		...getMapeoESRSE4(language),
		...getMapeoESRSE5(language),
		...getMapeoESRSS1Part1(language),
		...getMapeoESRSS1Part2(language),
		...getMapeoESRSS2(language),
		...getMapeoESRSS3(language),
		...getMapeoESRSS4(language),
		...getMapeoESRSG1(language),
	};
};

// Función auxiliar para normalizar códigos sin usar regex
const normalizarCodigo = (codigo) => {
	let resultado = '';
	for (let i = 0; i < codigo.length; i++) {
		const caracter = codigo[i];
		// Si no es guion, punto, guion bajo o espacio, añadirlo al resultado
		if (
			caracter !== '-' &&
			caracter !== '.' &&
			caracter !== '_' &&
			caracter !== ' '
		) {
			resultado += caracter.toLowerCase();
		}
	}
	return resultado;
};

/**
 * Función para obtener el nombre descriptivo de un formulario
 * @param {string} codigoFormulario - Código del formulario (ej: "E2-IRO1")
 * @param {string} language - Idioma ("EN" o "ES")
 * @returns {string} Nombre descriptivo del formulario
 */
export const obtenerNombreDescriptivo = (codigoFormulario, language = 'ES') => {
	const mapeo = obtenerMapeoFormularios(language);

	// Intentamos coincidencia exacta
	if (mapeo[codigoFormulario]) {
		return mapeo[codigoFormulario];
	}

	// Si no hay coincidencia exacta, normalizamos para buscar similitudes
	const codigoNormalizado = normalizarCodigo(codigoFormulario);

	for (const [codigo, descripcion] of Object.entries(mapeo)) {
		const mapCodigo = normalizarCodigo(codigo);
		if (mapCodigo === codigoNormalizado) {
			return descripcion;
		}
	}

	// Si no encontramos coincidencia, devolvemos el código original
	return codigoFormulario;
};
