import React, { useState, useMemo } from 'react';
import useUserCompany from '../../hooks/user/useUserCompany';
import useI18n from '../../hooks/i18n/useI18n'; // Cambiamos a useI18n
import { areaOptions } from '../../config/areaOptions';
import { areaLabels } from '../../config/areaLabels';
import {
	translateDepartment,
	translateArea,
} from '../../utils/translationHelpers';
import { createUserStyles } from '../../styles/createUserStyles.js';
import { createUserHandlers } from '../../utils/handlers/userHandlers';
import { debugError } from '../../utils/debugHelper.js';

function CreateUser() {
	// Custom Hooks
	const { empresa, error: companyError } = useUserCompany();
	const { t, language, languageError } = useI18n(); // Usamos useI18n en lugar de useUserLanguage

	// Estados del formulario
	const [formState, setFormState] = useState({
		nombre: '',
		apellidos: '',
		email: '',
		username: '',
		password: '',
		idioma: 'ES',
	});

	const [selectedAreas, setSelectedAreas] = useState([]);
	const [departamentos, setDepartamentos] = useState({});

	// Si hay error en la carga del idioma o la empresa, mostrar mensaje
	if (languageError || companyError) {
		return (
			<div className={createUserStyles.errorContainer}>
				<p>{languageError || companyError}</p>
			</div>
		);
	}

	// Si el idioma aún no está disponible, mostramos un loader
	if (!language) {
		return (
			<div className={createUserStyles.loader}>
				<div className='text-center'>
					<div className={createUserStyles.loaderSpinner}></div>
					<p className={createUserStyles.loaderText}>{t('common.loading')}</p>
				</div>
			</div>
		);
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const clearForm = () => {
		setFormState({
			nombre: '',
			apellidos: '',
			email: '',
			username: '',
			password: '',
			idioma: 'ES',
		});
		setSelectedAreas([]);
		setDepartamentos({});
	};

	const handleSubmit = (e) => {
		const formData = {
			...formState,
			empresa,
			areas: selectedAreas,
			departamentos,
		};

		createUserHandlers.handleSubmit(e, formData, clearForm, language);
	};

	return (
		<div className={createUserStyles.container}>
			<div className='max-w-4xl mx-auto'>
				{/* Header */}
				<div className={createUserStyles.titleContainer}>
					<h2 className={createUserStyles.titleText}>
						{t('createUser.title')}
					</h2>
					<div className={createUserStyles.titleUnderline}></div>
				</div>

				{/* Contenedor principal */}
				<div className={createUserStyles.card}>
					{/* Header con gradiente */}
					<div className={createUserStyles.header}>
						<div className='flex flex-col items-center justify-center space-y-2'>
							<span className='text-white/90 text-lg'>
								{t('createUser.empresa')}
							</span>
							<span className='text-white text-2xl font-bold'>
								{empresa ||
									(language === 'EN'
										? 'Loading company...'
										: 'Cargando empresa...')}
							</span>
						</div>
					</div>

					<form
						onSubmit={handleSubmit}
						className={createUserStyles.form}>
						{/* Grid de campos principales */}
						<div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
							{/* Campos de texto */}
							{['nombre', 'apellidos', 'email', 'username', 'password'].map(
								(field) => (
									<div
										key={field}
										className='space-y-2'>
										<label className={createUserStyles.label}>
											{t(`createUser.${field}`)}
										</label>
										<input
											type={field === 'password' ? 'password' : 'text'}
											name={field}
											value={formState[field]}
											onChange={handleInputChange}
											required
											className={createUserStyles.input}
										/>
									</div>
								)
							)}

							{/* Select de idioma */}
							<div className='space-y-2'>
								<label className={createUserStyles.label}>
									{t('createUser.idioma')}
								</label>
								<select
									name='idioma'
									value={formState.idioma}
									onChange={handleInputChange}
									className={createUserStyles.input}>
									<option value='ES'>ES (Español)</option>
									<option value='EN'>EN (English)</option>
								</select>
							</div>
						</div>

						{/* Sección de Áreas */}
						<div className={createUserStyles.areaSection}>
							<div className={createUserStyles.areaHeader}>
								<h3 className={createUserStyles.areaTitle}>
									{t('createUser.areas')}
								</h3>
								<div className={createUserStyles.areaLine}></div>
							</div>

							<div className={createUserStyles.areaContainer}>
								<div className={createUserStyles.areaGrid}>
									{Object.keys(areaOptions)
										.filter((area) => area !== 'System') // Filtrar el área "System"
										.map((area) => (
											<div
												key={area}
												className={createUserStyles.areaItem}>
												<input
													type='checkbox'
													checked={selectedAreas.includes(area)}
													onChange={() =>
														createUserHandlers.handleAreaChange(
															area,
															selectedAreas,
															setSelectedAreas,
															departamentos,
															setDepartamentos,
															areaOptions
														)
													}
													className={createUserStyles.checkbox}
												/>
												<label className='flex-1 text-sm font-medium text-gray-700'>
													{t(`departamentos.${area}`, { defaultValue: area })}
												</label>
											</div>
										))}
								</div>
							</div>
						</div>

						{/* Departamentos y Permisos */}
						{selectedAreas.map((area) => (
							<div
								key={area}
								className={createUserStyles.departmentContainer}>
								<div className={createUserStyles.areaHeader}>
									<h3 className={createUserStyles.areaTitle}>
										{t(`departamentos.${area}`, { defaultValue: area })} -{' '}
										{t('createUser.areas')}
									</h3>
									<div className={createUserStyles.areaLine}></div>
								</div>

								<div className='space-y-4'>
									{areaOptions[area].map((dept) => (
										<div
											key={dept}
											className={createUserStyles.departmentItem}>
											<strong className={createUserStyles.departmentName}>
												{t(`departamentos.${dept}`, { defaultValue: dept })}
											</strong>
											<div className={createUserStyles.permissionsContainer}>
												{['ver', 'editar', 'enviar'].map((perm) => (
													<label
														key={perm}
														className={createUserStyles.permissionLabel}>
														<input
															type='checkbox'
															checked={
																departamentos[area]?.[dept]?.[perm] || false
															}
															onChange={() =>
																createUserHandlers.handlePermissionChange(
																	area,
																	dept,
																	perm,
																	setDepartamentos
																)
															}
															className={createUserStyles.checkbox}
														/>
														<span className={createUserStyles.permissionText}>
															{t(`createUser.permisos.${perm}`)}
														</span>
													</label>
												))}
											</div>
										</div>
									))}
								</div>
							</div>
						))}

						{/* Botón de envío */}
						<div className='mt-10'>
							<button
								type='submit'
								className={createUserStyles.button}>
								{t('createUser.createUser')}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default CreateUser;
