import React, { createContext, useContext, useState, useMemo } from 'react';

// Crear el contexto
const NavBarContext = createContext();

// Proveedor del contexto
export const NavBarProvider = ({ children }) => {
	const [selectedOption, setSelectedOption] = useState('Tareas'); // Opción inicial

	// Memorizar el valor del contexto para evitar re-renderizaciones innecesarias
	const value = useMemo(
		() => ({ selectedOption, setSelectedOption }),
		[selectedOption]
	);

	return (
		<NavBarContext.Provider value={value}>{children}</NavBarContext.Provider>
	);
};

// Hook para usar el contexto
export const useNavBar = () => {
	return useContext(NavBarContext);
};
