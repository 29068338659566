import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './views/Login/Login';
import DashboardSystem from './views/System/DashboardSystem';
import CreateUser from './views/System/CreateUser';
import UpdateUser from './views/System/UpdateUser';
import DeleteUser from './views/System/DeleteUser';
import Casos from './views/Responsables/Casos';
import ListadeProcesos from './views/Sostenibilidad/ListadeProcesos';
import CrearEmpresa from './views/Sostenibilidad/CrearEmpresa';
import ExtraccionDashboard from './views/Extraccion/ExtraccionDashboard'; // Importa el componente ExtraccionDashboard
import NavBar from './componentes/NavBar';
import { NavBarProvider } from './NavBarContext';
import VistaFormulario from './views/Responsables/VistaFormulario';
import VistaPregunta from './componentes/VistaPregunta';
import VistaProcesoAcabado from './componentes/VistaProcesoAcabado';
import useUserArea from './hooks/useUserArea';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { area } = useUserArea(); // Trae `area` al nivel del componente `App`

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/user', { credentials: 'include' });
        setIsLoggedIn(response.ok);
        console.log("Autenticación:", response.ok);
      } catch (error) {
        console.error('Error verificando autenticación:', error);
        setIsLoggedIn(false);
      }
    };
    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await fetch('https://risincome.aicrumit.com/backend/api/auth/logout', { method: 'POST', credentials: 'include' });
      setIsLoggedIn(false);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  const ProtectedRoute = ({ element, allowedAreas }) => {
    const { area } = useUserArea(); 
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (area !== null) {
        setLoading(false);
        console.log("Área obtenida en ProtectedRoute:", area);
      }
    }, [area]);

    if (loading) {
      return <p>Cargando área...</p>;
    }

    if (!isLoggedIn) {
      return <Navigate to="/" />;
    }

    const hasAccess = allowedAreas && area.some(userArea => allowedAreas.includes(userArea));
    console.log("Tiene acceso:", hasAccess, "Área del usuario:", area, "Áreas permitidas:", allowedAreas);
      
    if (!hasAccess) {
      return <Navigate to="/casos" />;
    }

    return element;
  };

  return (
    <Router>
      <NavBarProvider>
        <div className="App">
          {isLoggedIn && <NavBar onLogout={handleLogout} />}
          <main>
            <Routes>
              <Route path="/" element={<Login onLogin={() => setIsLoggedIn(true)} />} />
              {isLoggedIn && (
                <>
                  <Route path="/system-dashboard" element={<ProtectedRoute element={<DashboardSystem />} allowedAreas={['System']} />} />
                  <Route path="/create-user" element={<ProtectedRoute element={<CreateUser />} allowedAreas={['System']} />} />
                  <Route path="/update-user" element={<ProtectedRoute element={<UpdateUser />} allowedAreas={['System']} />} />
                  <Route path="/delete-user" element={<ProtectedRoute element={<DeleteUser />} allowedAreas={['System']} />} />
                  <Route path="/casos" element={<ProtectedRoute element={<Casos />} allowedAreas={['ESG', 'MedioAmbiental', 'EstandaresSociales', 'GobiernoCorporativo', 'Financiero']} />} />
                  <Route path="/process/:FormularioId/forms/:empresaId" element={<ProtectedRoute element={<VistaFormulario />} allowedAreas={['ESG', 'MedioAmbiental', 'EstandaresSociales', 'GobiernoCorporativo', 'Financiero']} />} />
                  <Route path="/process/:empresaId/form/:procesoId/question/:preguntaId" element={<ProtectedRoute element={<VistaPregunta />} allowedAreas={['ESG', 'MedioAmbiental', 'EstandaresSociales', 'GobiernoCorporativo', 'Financiero' ]} />} />
                  <Route path="/procesos" element={<ProtectedRoute element={<ListadeProcesos />} allowedAreas={['ESG']} />} />
                  <Route path="/create-company/:procesoId" element={<ProtectedRoute element={<CrearEmpresa />} allowedAreas={['ESG']} />} />
                  <Route path="/empresas/:empresaId/procesos/:procesoId/vista-proceso-acabado" element={<ProtectedRoute element={<VistaProcesoAcabado />} allowedAreas={['MedioAmbiental', 'ESG', 'EstandaresSociales', 'GobiernoCorporativo']} />} />

                  {/* Ruta de Extraccion */}
                  <Route path="/extraccion" element={<ProtectedRoute element={<ExtraccionDashboard />} allowedAreas={['Extraccion']} />} />

                  {/* Ruta comodín que redirige según el área */}
                  <Route path="*" element={
                    area && area.includes("Extraccion")
                      ? <Navigate to="/extraccion" />
                      : <Navigate to="/dashboard" />
                  } />
                </>
              )}
            </Routes>
          </main>
          <footer className="app-footer">
            &copy; 2024 ProcesosApp. Todos los derechos reservados.
          </footer>
        </div>
      </NavBarProvider>
    </Router>
  );
}

export default App;
