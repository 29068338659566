// frontend/src/utils/numberValidation.js

/**
 * Verifica casos básicos para valores simples
 * @param {string} value - Valor a validar
 * @param {Object} options - Opciones de validación
 * @returns {boolean|null} - true si es válido, false si no es válido, null si se requiere más validación
 */
const checkSimpleValues = (value, options) => {
	const { allowNegative, allowDecimals, allowComma } = options;

	// Si es vacío, o solo un signo negativo o solo un decimal, es válido
	if (value === '') return true;
	if (allowNegative && value === '-') return true;
	if (allowDecimals && (value === '.' || (allowComma && value === ',')))
		return true;

	return null; // Continuar con más validaciones
};

/**
 * Genera la lista de caracteres válidos según las opciones
 * @param {Object} options - Opciones de validación
 * @returns {Array} - Lista de caracteres válidos
 */
const getValidChars = (options) => {
	const { allowNegative, allowDecimals, allowComma, allowedCurrencySymbols } =
		options;

	// Crear lista de caracteres válidos
	const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

	// Añadir el signo negativo si está permitido
	if (allowNegative) validChars.push('-');

	// Añadir separadores decimales si están permitidos
	if (allowDecimals) {
		validChars.push('.');
		if (allowComma) validChars.push(',');
	}

	// Añadir símbolos de moneda si están permitidos
	allowedCurrencySymbols.forEach((symbol) => validChars.push(symbol));

	return validChars;
};

/**
 * Valida la posición del signo negativo
 * @param {string} char - Carácter actual
 * @param {number} index - Posición del carácter
 * @param {number} minusCount - Contador de signos negativos
 * @returns {boolean} - true si es válido, false si no
 */
const isValidMinusPosition = (char, index, minusCount) => {
	if (char !== '-') return true;
	return index === 0 && minusCount === 0;
};

/**
 * Valida la posición de símbolos de moneda
 * @param {string} char - Carácter actual
 * @param {number} index - Posición del carácter
 * @param {number} length - Longitud total del valor
 * @param {Object} currencySymbolCounts - Contador de símbolos de moneda
 * @param {Array} allowedCurrencySymbols - Símbolos de moneda permitidos
 * @returns {boolean} - true si es válido, false si no
 */
const isValidCurrencyPosition = (
	char,
	index,
	length,
	currencySymbolCounts,
	allowedCurrencySymbols
) => {
	if (!allowedCurrencySymbols.includes(char)) return true;

	// Verificar que no haya más de una ocurrencia del mismo símbolo
	if (currencySymbolCounts[char] >= 1) return false;

	// Verificar que el símbolo esté al inicio o al final
	return index === 0 || index === length - 1;
};

/**
 * Verifica el valor caracter por caracter
 * @param {string} value - Valor a validar
 * @param {Array} validChars - Lista de caracteres válidos
 * @param {Object} options - Opciones de validación
 * @returns {boolean} - true si es válido, false si no
 */
const validateCharByChar = (value, validChars, options) => {
	const { allowDecimals, allowedCurrencySymbols } = options;

	let decimalCount = 0;
	let minusCount = 0;
	const currencySymbolCounts = {};

	// Inicializar contadores de símbolos de moneda
	allowedCurrencySymbols.forEach((symbol) => {
		currencySymbolCounts[symbol] = 0;
	});

	// Verificar cada carácter
	for (let i = 0; i < value.length; i++) {
		const char = value[i];

		// Verificar que el carácter es válido
		if (!validChars.includes(char)) return false;

		// Verificar signo negativo
		if (char === '-') {
			minusCount++;
			if (!isValidMinusPosition(char, i, minusCount - 1)) return false;
		}

		// Verificar punto o coma decimal
		if ((char === '.' || char === ',') && allowDecimals) {
			decimalCount++;
			if (decimalCount > 1) return false;
		}

		// Verificar símbolos de moneda
		if (allowedCurrencySymbols.includes(char)) {
			currencySymbolCounts[char]++;
			if (
				!isValidCurrencyPosition(
					char,
					i,
					value.length,
					currencySymbolCounts,
					allowedCurrencySymbols
				)
			) {
				return false;
			}
		}
	}

	// Verificar que no hay múltiples símbolos de moneda diferentes
	const totalCurrencySymbols = Object.values(currencySymbolCounts).reduce(
		(sum, count) => sum + count,
		0
	);

	return totalCurrencySymbols <= 1;
};

/**
 * Realiza una validación segura de números sin usar regex vulnerables
 *
 * @param {string} value - Valor a validar
 * @param {Object} options - Opciones de validación
 * @param {boolean} options.allowDecimals - Permitir decimales (default: true)
 * @param {boolean} options.allowNegative - Permitir números negativos (default: true)
 * @param {boolean} options.allowComma - Permitir coma como separador decimal (default: false)
 * @param {Array} options.allowedCurrencySymbols - Símbolos de moneda permitidos (default: [])
 * @returns {boolean} - Verdadero si es válido, falso si no
 */
export const isValidNumber = (value, options = {}) => {
	// Opciones por defecto
	const fullOptions = {
		allowDecimals: true,
		allowNegative: true,
		allowComma: false,
		allowedCurrencySymbols: [],
		...options,
	};

	// Verificar casos simples primero
	const simpleCheck = checkSimpleValues(value, fullOptions);
	if (simpleCheck !== null) return simpleCheck;

	// Obtener caracteres válidos según las opciones
	const validChars = getValidChars(fullOptions);

	// Validar el valor carácter por carácter
	return validateCharByChar(value, validChars, fullOptions);
};
