import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'; // Importa useNavigate
import useUserId from '../hooks/useUserId';
import useUserLanguage from '../hooks/useUserLanguage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './VistaPregunta.module.css';

function VistaPregunta() {
  const { empresaId, procesoId, preguntaId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate(); // Inicializa el hook de navegación
  const { userId } = useUserId();
  const { language } = useUserLanguage();
  const [pregunta, setPregunta] = useState(null);
  const [respuesta, setRespuesta] = useState('');
  const [imagen, setImagen] = useState(null);
  const [respuestaId, setRespuestaId] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // Mensaje de éxito

  // Traducciones para el texto de la UI
  const labels = {
    responderPregunta: language === 'EN' ? 'Answer Question' : 'Responder Pregunta',
    pregunta: language === 'EN' ? 'Question' : 'Pregunta',
    textoNoDisponible: language === 'EN' ? 'Text not available' : 'Texto no disponible',
    cargandoPregunta: language === 'EN' ? 'Loading question...' : 'Cargando la pregunta...',
    guardarRespuesta: language === 'EN' ? 'Save Answer' : 'Guardar Respuesta',
    errorGuardarRespuesta: language === 'EN' ? 'Error saving answer.' : 'Error al guardar la respuesta.',
    respuestaGuardada: language === 'EN' ? 'Answer saved successfully' : 'Respuesta actualizada con éxito',
    preguntaContestadaExito: language === 'EN' ? 'This question has been answered successfully.' : 'Esta pregunta ha sido contestada con éxito', // Mensaje de éxito
  };

  useEffect(() => {
    const params = new URLSearchParams(search);
    const idFromURL = params.get('respuestaId');
    setRespuestaId(idFromURL);
  }, [search]);

  useEffect(() => {
    setError(null); // Reiniciar el error antes de cada carga
    fetchPregunta();
    fetchExistingResponse();
  }, [preguntaId, respuestaId]);

  const fetchPregunta = async () => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/formularios/${procesoId}/preguntas/${preguntaId}`);
      if (!response.ok) throw new Error('Error al obtener la pregunta');
      const data = await response.json();
      setPregunta(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExistingResponse = async () => {
    try {
      if (!respuestaId) {
        throw new Error('No se encontró una respuesta previa para actualizar.');
      }

      const response = await fetch(`https://risincome.aicrumit.com/backend/api/respuestas/${respuestaId}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Respuesta redirigida encontrada:', data);
        setRespuesta(data.respuesta?.text || '');
        setImagen(data.respuesta?.image || '');
      } else {
        throw new Error('Error al obtener la respuesta redirigida existente.');
      }
    } catch (error) {
      console.error('Error al buscar respuesta existente:', error);
    }
  };

  const handleSaveResponse = async () => {
    try {
      if (!respuestaId) {
        console.error('Error: No se encontró una respuesta previa para actualizar.');
        return;
      }

      const respuestaObj = {
        preguntasid: preguntaId,
        empresasid: parseInt(empresaId),
        procesosid: parseInt(procesoId),
        respuesta: { text: respuesta, image: imagen || '' },
        estadopregunta: 'Contestada',
        respondidoporuserid: userId,
        contestadafecha: new Date().toISOString(),
      };

      console.log('Datos enviados a la base de datos:', respuestaObj);

      const url = `https://risincome.aicrumit.com/backend/api/respuestas/${respuestaId}`;

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(respuestaObj),
      });

      if (!response.ok) throw new Error(labels.errorGuardarRespuesta);

      setSuccessMessage(labels.preguntaContestadaExito); // Establece el mensaje de éxito

      // Redirige al usuario a la vista de preguntas redirigidas después de un breve retraso
      setTimeout(() => {
        navigate('/casos?tab=preguntasRedirigidas');
      }, 2000); // Redirige después de 2 segundos

    } catch (error) {
      setError(labels.errorGuardarRespuesta);
      console.error(error);
    }
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImagen(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={styles.formularioContainer}>
      <h2>{labels.responderPregunta}</h2>
      {error && <p className={styles.errorText}>{error}</p>}
      {successMessage && <p className={styles.successText}>{successMessage}</p>}
      {pregunta ? (
        <div>
          <p><strong>{labels.pregunta}:</strong> {pregunta.text[language] || labels.textoNoDisponible}</p>
          <div className={styles.inputContainer}>
            <ReactQuill
              value={respuesta}
              onChange={setRespuesta}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  ['link', 'image']
                ]
              }}
              formats={[
                'header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link', 'image'
              ]}
              className={styles.reactQuillEditor}
            />
            <div className={styles.fileAndButtonsContainer}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(e.target.files[0])}
                className={styles.fileInput}
              />
              <button onClick={handleSaveResponse} className={styles.saveButton}>
                {labels.guardarRespuesta}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>{labels.cargandoPregunta}</p>
      )}
    </div>
  );
}

export default VistaPregunta;
