//frontend/src/hooks/editor/useQuillManager.js
import { useCallback, useState } from 'react';

export const useQuillManager = () => {
	const [quillRefs, setQuillRefs] = useState({});

	const handleQuillRef = useCallback(
		(preguntaId, ref) => {
			if (ref && !quillRefs[preguntaId]) {
				setQuillRefs((prev) => ({
					...prev,
					[preguntaId]: ref.getEditor(),
				}));
			}
		},
		[quillRefs]
	);

	return { quillRefs, handleQuillRef };
};
