import React from 'react';
import ReactQuill from 'react-quill';
import { useBooleanQuestion } from '../../hooks/questions/useQuestionType/useBooleanQuestion';
import { EDITOR_MODULES, EDITOR_FORMATS } from '../../config/editorConfig';
import { questionStyles } from '../../styles/questionStyles';

const BooleanQuestion = ({
	pregunta,
	formulario,
	respuestas,
	options,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const { selectedOption, setSelectedOption } = useBooleanQuestion();

	return (
		<div className={questionStyles.boolean.container}>
			<div className={questionStyles.boolean.radioGroup}>
				<div className={questionStyles.boolean.radioOption}>
					<input
						type='radio'
						id={`positive-boolean-${pregunta.id}`}
						name={`boolean-option-${pregunta.id}`}
						value='positive'
						checked={selectedOption === 'positive'}
						onChange={(e) => {
							setSelectedOption(e.target.value);
							handleEditorChange(
								pregunta.id,
								formulario.FormularioId,
								`<p>${options.positiveOption}</p>`
							);
						}}
						disabled={!canEdit || isRedirigida}
						className={questionStyles.boolean.radioInput}
					/>
					<label
						htmlFor={`positive-boolean-${pregunta.id}`}
						className={questionStyles.boolean.radioLabel}>
						{options.positiveOption || 'Sí'}
					</label>
				</div>
				<div className={questionStyles.boolean.radioOption}>
					<input
						type='radio'
						id={`negative-boolean-${pregunta.id}`}
						name={`boolean-option-${pregunta.id}`}
						value='negative'
						checked={selectedOption === 'negative'}
						onChange={(e) => {
							setSelectedOption(e.target.value);
							handleEditorChange(
								pregunta.id,
								formulario.FormularioId,
								`<p>${options.negativeOption}</p>`
							);
						}}
						disabled={!canEdit || isRedirigida}
						className={questionStyles.boolean.radioInput}
					/>
					<label
						htmlFor={`negative-boolean-${pregunta.id}`}
						className={questionStyles.boolean.radioLabel}>
						{options.negativeOption || 'No'}
					</label>
				</div>
			</div>
			<ReactQuill
				value={
					respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
				}
				onChange={(value) =>
					handleEditorChange(pregunta.id, formulario.FormularioId, value)
				}
				modules={EDITOR_MODULES}
				formats={EDITOR_FORMATS}
				className={`${questionStyles.boolean.editor} ${
					pregunta.required ? questionStyles.boolean.requiredField : ''
				}`}
				readOnly={!canEdit || isRedirigida}
			/>
		</div>
	);
};

export default BooleanQuestion;
