//frontend/src/utils/preguntasTableHelpers.js
export const handleAnswerQuestion = (
	navigate,
	preguntaId,
	empresaId,
	procesoId,
	respuestaId
) => {
	navigate(
		`/process/${empresaId}/form/${procesoId}/question/${preguntaId}?respuestaId=${respuestaId}`
	);
};
