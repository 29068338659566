//frontend/src/hooks/user/useUserLanguage.js
import { useState, useEffect } from 'react';
import { ROUTE_AUTH_ME_LANGUAGE } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useUserLanguage() {
	const [language, setLanguage] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchLanguage = async () => {
			try {
				const response = await fetchWithAuth(ROUTE_AUTH_ME_LANGUAGE, {
					method: 'GET',
				});

				if (response.ok) {
					const data = await response.json();
					debug('Idioma obtenido en Hook useUserLanguage:', data.language);
					if (data.language) {
						setLanguage(data.language);
					} else {
						debugError('El servidor no devolvió un idioma válido');
						setError('Error al obtener el idioma del usuario');
					}
				} else {
					debugError('Error al obtener el idioma del usuario');
					setError('Error al obtener el idioma del usuario');
				}
			} catch (error) {
				debugError('Error en la solicitud de idioma:', error);
				setError('Error en la solicitud de idioma');
			}
		};

		fetchLanguage();
	}, []);

	debug('Estado actual del hook:', { language, error });
	return { language, error };
}

export default useUserLanguage;
