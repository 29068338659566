import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginStyles as styles } from '../../styles/loginStyles';
import { ROUTE_AUTH_LOGIN } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';

function Login({ onLogin }) {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setError('');
		setMessage('');

		try {
			const response = await fetch(ROUTE_AUTH_LOGIN, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ username, password }),
				credentials: 'include',
			});

			const data = await response.json();
			debug('Datos de respuesta del servidor:', data);

			if (response.ok) {
				setMessage('Login exitoso');
				onLogin();

				if (data.area) {
					debug(`Área obtenida en login: ${data.area}`);
				} else {
					debug('Área no presente en la respuesta');
				}

				if (Array.isArray(data.area)) {
					if (data.area.includes('Extraccion')) {
						debug('Redirigiendo a /extraccion');
						navigate('/extraccion');
					} else if (data.area.includes('System')) {
						debug('Redirigiendo a /system-dashboard');
						navigate('/system-dashboard');
					} else {
						debug('Redirigiendo a /casos');
						navigate('/casos');
					}
				} else {
					debug('Redirigiendo a /casos');
					navigate('/casos');
				}
			} else {
				setError(data.message || 'Error al iniciar sesión');
			}
		} catch (err) {
			debugError('Error en la conexión con el servidor:', err);
			setError('Error en la conexión con el servidor');
		}
	};

	return (
		<div className={styles.container}>
			<main className={styles.mainContent}>
				<div className={styles.loginBox}>
					<section className={styles.leftSection}>
						<div className={styles.leftContent}>
							<div className={styles.brandingTop}>
								<img
									src='/logo-app.png'
									alt='Mine ESG Logo'
									className={styles.logo}
								/>
								<h1 className={styles.brandTitle}>
									Bienvenido a Aicrumit Applications
								</h1>
								<p className={styles.brandText}>
									Plataforma integral para la gestión y análisis de datos ESG
								</p>
							</div>
							<div className={styles.brandingBottom}>
								© 2024 Aicrumit. Todos los derechos reservados.
							</div>
						</div>
					</section>

					<section className={styles.rightSection}>
						<div className={styles.loginForm}>
							<div className={styles.formHeader}>
								<h2 className={styles.formTitle}>Iniciar Sesión</h2>
								<p className={styles.formSubtitle}>
									Ingresa tus credenciales para continuar
								</p>
							</div>

							{error && <div className={styles.errorMessage}>{error}</div>}
							{message && (
								<div className={styles.successMessage}>{message}</div>
							)}

							<form
								onSubmit={handleSubmit}
								className='space-y-6'>
								<div className={styles.inputGroup}>
									<label
										htmlFor='username'
										className={styles.inputLabel}>
										Usuario
									</label>
									<input
										id='username'
										type='text'
										value={username}
										onChange={(e) => setUsername(e.target.value)}
										className={styles.input}
										placeholder='Ingresa tu usuario'
										required
									/>
								</div>

								<div className={styles.inputGroup}>
									<label
										htmlFor='password'
										className={styles.inputLabel}>
										Contraseña
									</label>
									<input
										id='password'
										type='password'
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className={styles.input}
										placeholder='Ingresa tu contraseña'
										required
									/>
								</div>

								<button
									type='submit'
									className={styles.loginButton}>
									Iniciar Sesión
								</button>

								<div className={styles.divider}>
									<span className={styles.dividerText}>Mine ESG Platform</span>
								</div>
							</form>
						</div>
					</section>
				</div>
			</main>
		</div>
	);
}

export default Login;
