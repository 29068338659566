import React, { useState, useRef } from 'react';

const FileUploadButton = ({
	pregunta,
	formulario,
	handleFileChange,
	canEdit,
	isRedirigida,
}) => {
	const [fileName, setFileName] = useState('');
	const fileInputRef = useRef(null);

	const handleChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			setFileName(file.name);
			handleFileChange(pregunta.id, formulario.FormularioId, file);
		}
	};

	const handleClick = () => {
		// Solo permite hacer clic en el input si está habilitado
		if (canEdit && !isRedirigida) {
			fileInputRef.current.click();
		}
	};

	return (
		<div className='flex h-9 items-center bg-gray-50 rounded-md border border-gray-300 px-3'>
			<span
				className={`text-gray-700 ${
					canEdit && !isRedirigida
						? 'cursor-pointer hover:text-blue-600'
						: 'cursor-not-allowed text-gray-400'
				}`}
				onClick={handleClick}>
				Seleccionar archivo
			</span>
			<input
				type='file'
				accept='image/*'
				onChange={handleChange}
				className='hidden'
				disabled={!canEdit || isRedirigida}
				ref={fileInputRef}
			/>
			<span className='ml-2 text-gray-600 truncate max-w-xs'>
				{fileName || 'Ningún archivo'}
			</span>
		</div>
	);
};

export default FileUploadButton;
