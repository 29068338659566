import React, { useEffect, useState } from 'react';
import { questionStyles } from '../../styles/questionStyles';
import BaseNumericInput from './BaseNumericInput';
import { t } from 'i18next';

const MonetaryQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const [localValue, setLocalValue] = useState('');
	const [currencySymbol, setCurrencySymbol] = useState('');

	useEffect(() => {
		const savedValue =
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || '';

		// Limpiar el valor guardado de símbolos monetarios manualmente sin regex
		let cleanValue = savedValue;
		cleanValue = cleanValue.replace('$', '').replace('€', '').trim();

		// Detectar símbolo de moneda
		let symbol = '';
		if (savedValue.includes('$')) symbol = '$';
		else if (savedValue.includes('€')) symbol = '€';

		setLocalValue(cleanValue);
		setCurrencySymbol(symbol);
	}, [respuestas, formulario.FormularioId, pregunta.id]);

	const handleChange = (value) => {
		// Extraer el símbolo de moneda manualmente
		let symbol = '';
		if (value.includes('$')) symbol = '$';
		else if (value.includes('€')) symbol = '€';

		// Limpiar el valor de símbolos de moneda manualmente
		let numericValue = value;
		numericValue = numericValue.replace('$', '').replace('€', '');

		setLocalValue(numericValue);
		setCurrencySymbol(symbol);

		// Convertir la coma a punto para estandarizar
		const standardizedValue = numericValue.replace(',', '.');
		// Añadir el símbolo de moneda si existe
		const finalValue = symbol
			? `${symbol}${standardizedValue}`
			: standardizedValue;

		handleEditorChange(pregunta.id, formulario.FormularioId, finalValue);
	};

	const handleSymbolClick = (symbol) => {
		if (currencySymbol === symbol) {
			// Si el símbolo ya está seleccionado, lo quitamos
			setCurrencySymbol('');
			const finalValue = localValue;
			handleEditorChange(pregunta.id, formulario.FormularioId, finalValue);
		} else {
			// Si es un símbolo diferente o no había símbolo, lo añadimos
			setCurrencySymbol(symbol);
			const finalValue = `${symbol}${localValue}`;
			handleEditorChange(pregunta.id, formulario.FormularioId, finalValue);
		}
	};

	const inputClasses = `
		${questionStyles.monetary.input}
		${
			pregunta.required
				? questionStyles.monetary.required
				: questionStyles.monetary.optional
		}
		${questionStyles.monetary.text}
		${!canEdit || isRedirigida ? questionStyles.monetary.disabled : ''}
	`;

	const getSymbolButtonClass = (symbol) => `
		${questionStyles.monetary.symbolButton.base}
		${
			currencySymbol === symbol
				? questionStyles.monetary.symbolButton.active
				: questionStyles.monetary.symbolButton.inactive
		}
		${
			!canEdit || isRedirigida
				? questionStyles.monetary.symbolButton.disabled
				: questionStyles.monetary.symbolButton.enabled
		}
	`;

	return (
		<div className={questionStyles.monetary.container}>
			<div className={questionStyles.monetary.inputContainer}>
				<BaseNumericInput
					value={`${currencySymbol}${localValue}`}
					onChange={handleChange}
					validationOptions={{
						allowDecimals: true,
						allowComma: true,
						allowedCurrencySymbols: ['$', '€'],
					}}
					className={inputClasses}
					placeholder={t('monetaryQuestionPlaceholder')}
					readOnly={!canEdit || isRedirigida}
				/>
				<button
					type='button'
					onClick={() => handleSymbolClick('$')}
					disabled={!canEdit || isRedirigida}
					className={getSymbolButtonClass('$')}>
					$
				</button>
				<button
					type='button'
					onClick={() => handleSymbolClick('€')}
					disabled={!canEdit || isRedirigida}
					className={getSymbolButtonClass('€')}>
					€
				</button>
			</div>
		</div>
	);
};

export default MonetaryQuestion;
