// frontend/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { metricsService } from './services/metricsService';
import { debug } from './utils/debugHelper';

// Importar i18n antes que cualquier otro componente
import './i18n/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// Configuración de métricas con visualización en consola durante desarrollo
reportWebVitals((metric) => {
	if (process.env.NODE_ENV === 'development') {
		debug('Web Vital:', metric);
	}
	// Las métricas se enviarán automáticamente a través del metricsService
});
