// frontend/src/componentes/FormularioCard/componentes/ActionButtons.js
import React from 'react';
import { formularioCardStyles as styles } from '../../../styles/formularioCardStyles';
import { debug, debugError } from '../../../utils/debugHelper';
import { getCheckedBoxes } from '../../../utils/questionUtils';
import { tableConfigs } from '../../../config/questionConfig';
import { t } from 'i18next';

// Función para procesar redirección de pregunta
const handleRedirectQuestion = async (
	pregunta,
	formulario,
	selectedArea,
	selectedDepartamento,
	handleSubmit,
	toggleRedirigir
) => {
	debug('Detectada redirección para pregunta:', pregunta.id);
	debug('Área seleccionada:', selectedArea[pregunta.id]);
	debug('Departamento seleccionado:', selectedDepartamento[pregunta.id]);

	// Crear objetos simples con los valores para esta pregunta específica
	const areaParam = { [pregunta.id]: selectedArea[pregunta.id] };
	const departamentoParam = {
		[pregunta.id]: selectedDepartamento[pregunta.id],
	};

	// Intentar ejecutar la redirección
	try {
		const resultado = await handleSubmit(
			pregunta.id,
			formulario.FormularioId,
			areaParam,
			departamentoParam
		);
		debug('Resultado de la redirección:', resultado);

		// Mostrar mensaje de éxito
		alert(
			`Pregunta redirigida con éxito a ${selectedArea[pregunta.id]} - ${
				selectedDepartamento[pregunta.id]
			}`
		);

		// Cerramos el panel de redirección después de éxito
		if (toggleRedirigir) {
			toggleRedirigir(pregunta.id, formulario.FormularioId, true);
		}
	} catch (error) {
		debugError('Error al redirigir la pregunta:', error);
		alert(
			'Error al redirigir la pregunta: ' +
				(error.message || 'Error desconocido')
		);
	}
};

// Función para procesar preguntas controladas cuando la respuesta es negativa
const handleNegativeResponse = async (
	pregunta,
	formulario,
	tableConfig,
	handleEditorChange,
	handleSubmit
) => {
	debug('onSubmitClick - Detectada respuesta "No es material"');

	// 1. Identificar la pregunta negativa mediante la configuración de la tabla
	let negativeQuestionId = null;
	if (tableConfig?.showConditions?.onNegative?.showQuestion) {
		negativeQuestionId =
			tableConfig.showConditions.onNegative.showQuestion.questionId;
		debug(
			'onSubmitClick - ID de pregunta negativa encontrado en configuración:',
			negativeQuestionId
		);
	}

	// 2. Buscar todas las preguntas controladas por esta tabla (excluyendo la negativa)
	const controlledQuestions = formulario.preguntas.filter(
		(p) =>
			p.conditional?.controlledBy === pregunta.id &&
			p.conditional?.showOnOption === 'checkbox' &&
			(!negativeQuestionId || p.id !== negativeQuestionId)
	);

	debug(
		'onSubmitClick - Preguntas controladas:',
		controlledQuestions.map((p) => p.id)
	);

	// 3. Para cada pregunta controlada, actualizar texto y guardar
	await autoFillControlledQuestions(
		controlledQuestions,
		formulario.FormularioId,
		`<p>${t('tableQuestion.noPolicy')}</p>`,
		handleEditorChange,
		handleSubmit
	);
};

// Función para manejar respuestas positivas en tablas
const handlePositiveResponse = async (
	pregunta,
	formulario,
	respuestaText,
	tableConfig,
	handleEditorChange,
	handleSubmit
) => {
	debug(
		'onSubmitClick - Respuesta "Sí es material", procesando preguntas relacionadas'
	);

	// 1. Identificar la pregunta negativa
	const negativeQuestionId = findNegativeQuestionId(
		pregunta.id,
		formulario,
		tableConfig
	);

	// 2. Si hay pregunta negativa, auto-rellenarla
	if (negativeQuestionId) {
		await autoFillSingleQuestion(
			negativeQuestionId,
			formulario.FormularioId,
			`<p>${t('tableQuestion.isInforming')}</p>`,
			handleEditorChange,
			handleSubmit
		);
	}

	// 3. Obtener todos los índices de checkbox marcados
	const checkedBoxes = getCheckedBoxes(respuestaText);
	debug('onSubmitClick - Checkboxes marcados:', checkedBoxes);

	// 4. Buscar todas las preguntas controladas por checkboxes
	await processCheckboxControlledQuestions(
		pregunta.id,
		formulario,
		checkedBoxes,
		handleEditorChange,
		handleSubmit
	);
};

// Función para encontrar el ID de la pregunta negativa
const findNegativeQuestionId = (preguntaId, formulario, tableConfig) => {
	// Primero buscar en la configuración
	let negativeQuestionId = null;
	if (tableConfig?.showConditions?.onNegative?.showQuestion) {
		negativeQuestionId =
			tableConfig.showConditions.onNegative.showQuestion.questionId;
		debug(
			'onSubmitClick - ID de pregunta negativa encontrado en configuración:',
			negativeQuestionId
		);
	}

	// Si no se encuentra en la configuración, intentar buscarlo por la condición
	if (!negativeQuestionId) {
		const potentialNegativeQuestion = formulario.preguntas.find(
			(p) =>
				p.conditional?.controlledBy === preguntaId &&
				p.conditional?.showOnOption === 'negative'
		);
		if (potentialNegativeQuestion) {
			negativeQuestionId = potentialNegativeQuestion.id;
			debug(
				'onSubmitClick - ID de pregunta negativa encontrado por condición:',
				negativeQuestionId
			);
		}
	}

	return negativeQuestionId;
};

// Función para auto-rellenar una sola pregunta
const autoFillSingleQuestion = async (
	questionId,
	formularioId,
	text,
	handleEditorChange,
	handleSubmit
) => {
	debug(`onSubmitClick - Auto-rellenando pregunta ${questionId} con "${text}"`);

	// Actualizar estado local primero
	handleEditorChange(questionId, formularioId, text);

	// Pequeña espera para asegurar que el estado se actualice
	await new Promise((resolve) => setTimeout(resolve, 50));

	// Crear respuesta auto-rellenada
	const respuestaAutoRelleno = {
		text: text,
		image: '',
	};

	// Guardar con respuesta auto-rellenada en la BD
	debug(
		`onSubmitClick - Guardando pregunta ${questionId} con texto auto-rellenado`
	);
	await handleSubmit(questionId, formularioId, {}, {}, respuestaAutoRelleno);
};

// Función para auto-rellenar múltiples preguntas controladas
const autoFillControlledQuestions = async (
	questions,
	formularioId,
	text,
	handleEditorChange,
	handleSubmit
) => {
	for (const question of questions) {
		await autoFillSingleQuestion(
			question.id,
			formularioId,
			text,
			handleEditorChange,
			handleSubmit
		);
	}
};

// Función para procesar preguntas controladas por checkboxes
const processCheckboxControlledQuestions = async (
	preguntaId,
	formulario,
	checkedBoxes,
	handleEditorChange,
	handleSubmit
) => {
	const checkboxControlledQuestions = formulario.preguntas.filter(
		(p) =>
			p.conditional?.controlledBy === preguntaId &&
			p.conditional?.showOnOption === 'checkbox'
	);

	debug(
		'onSubmitClick - Total preguntas controladas por checkboxes:',
		checkboxControlledQuestions.map((p) => p.id)
	);

	for (const question of checkboxControlledQuestions) {
		const checkboxIndex = question.conditional?.checkboxIndex;

		if (!checkboxIndex) {
			debug(
				`onSubmitClick - Pregunta ${question.id} no tiene checkboxIndex definido, saltando`
			);
			continue;
		}

		// Verificar si el checkbox que controla esta pregunta está marcado
		const isCheckboxChecked = checkedBoxes.includes(checkboxIndex);
		debug(
			`onSubmitClick - Pregunta ${question.id} controlada por checkbox ${checkboxIndex}, checkbox marcado: ${isCheckboxChecked}`
		);

		if (!isCheckboxChecked) {
			// Si el checkbox no está marcado, auto-rellenar
			await autoFillSingleQuestion(
				question.id,
				formulario.FormularioId,
				`<p>${t('tableQuestion.isInforming')}</p>`,
				handleEditorChange,
				handleSubmit
			);
		}
	}
};

// Función para manejar preguntas tipo tabla
const handleTableQuestion = async (
	pregunta,
	formulario,
	procesoId,
	respuestas,
	handleEditorChange,
	handleSubmit
) => {
	// Obtener la respuesta actual
	const respuestaText =
		respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text;
	const isNegativeResponse = respuestaText === 'negative';

	debug('onSubmitClick - pregunta tipo tabla:', {
		preguntaId: pregunta.id,
		respuestaText,
		isNegativeResponse,
	});

	// Guardar la pregunta principal (la tabla)
	await handleSubmit(pregunta.id, formulario.FormularioId);

	// Obtener la configuración de la tabla para identificar preguntas relacionadas
	const tableKey = `${formulario.FormularioId}-${procesoId}-${pregunta.id}`;
	const tableConfig = tableConfigs[tableKey];

	// Procesar según la respuesta sea negativa o positiva
	if (isNegativeResponse) {
		await handleNegativeResponse(
			pregunta,
			formulario,
			tableConfig,
			handleEditorChange,
			handleSubmit
		);
	} else {
		await handlePositiveResponse(
			pregunta,
			formulario,
			respuestaText,
			tableConfig,
			handleEditorChange,
			handleSubmit
		);
	}
};

const ActionButtons = ({
	pregunta,
	formulario,
	handleSubmit,
	handleSubmitWithConditional,
	isRedirigida,
	canEdit,
	hasRespuesta,
	labels,
	options,
	respuestas,
	handleEditorChange,
	procesoId,
	esRespuestaValida,
	selectRef,
	toggleRedirigir,
	selectedArea,
	selectedDepartamento,
	redirigirPregunta,
}) => {
	const onSubmitClick = async () => {
		console.log('onSubmitClick - Inicio para pregunta:', pregunta.id);
		console.log(
			'¿Es pregunta requerida?:',
			pregunta.required || pregunta.requerida
		);
		console.log(
			'Respuesta actual:',
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text
		);

		// Verificar si hay redirección
		if (selectedArea[pregunta.id] && selectedDepartamento[pregunta.id]) {
			await handleRedirectQuestion(
				pregunta,
				formulario,
				selectedArea,
				selectedDepartamento,
				handleSubmit,
				toggleRedirigir
			);
			return;
		}

		// Verificar si es una pregunta tipo tabla
		if (pregunta.questionType === 'table') {
			await handleTableQuestion(
				pregunta,
				formulario,
				procesoId,
				respuestas,
				handleEditorChange,
				handleSubmit
			);
			return;
		}

		// SOLUCIÓN HÍBRIDA:
		// Para preguntas no requeridas con campos vacíos, usar handleSubmit directo
		// Para el resto, usar handleSubmitWithConditional para mantener la lógica condicional

		const isRequired =
			pregunta.required === true || pregunta.requerida === true;
		const respuestaTexto =
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || '';
		const isEmpty = !respuestaTexto.trim().length;

		try {
			console.log('Análisis de la pregunta para decidir método de guardado:');
			console.log('- ¿Es requerida?:', isRequired);
			console.log('- ¿Está vacía?:', isEmpty);

			if (!isRequired && isEmpty) {
				// Para preguntas NO requeridas con campos vacíos: handleSubmit directo
				console.log(
					'Usando handleSubmit directo para pregunta no requerida vacía'
				);
				await handleSubmit(pregunta.id, formulario.FormularioId);
			} else {
				// Para el resto: handleSubmitWithConditional para mantener lógica condicional
				console.log(
					'Usando handleSubmitWithConditional para mantener lógica condicional'
				);
				handleSubmitWithConditional(
					pregunta,
					formulario,
					respuestas,
					handleEditorChange,
					handleSubmit,
					options,
					procesoId
				);
			}

			console.log('Guardado completado exitosamente');
		} catch (error) {
			console.error('Error al guardar pregunta:', error);
			alert(
				'Error al guardar la respuesta: ' +
					(error.message || 'Error desconocido')
			);
		}
	};

	const handleRedirigirClick = () => {
		debug('Clicking redirigir button for pregunta:', {
			preguntaId: pregunta.id,
			formularioId: formulario.FormularioId,
			currentState: {
				isRedirigida,
				canEdit,
				hasRespuesta,
			},
		});

		if (toggleRedirigir) {
			// Pasar formularioId como segundo parámetro
			toggleRedirigir(pregunta.id, formulario.FormularioId);
		}
	};

	if (!canEdit) {
		return null;
	}

	return (
		<div className={styles.buttonContainer}>
			{!isRedirigida && (
				<button
					onClick={onSubmitClick}
					className={styles.submitButton}
					// Corregir la validación para distinguir entre preguntas requeridas y no requeridas
					disabled={
						(pregunta.required === true || pregunta.requerida === true) &&
						pregunta.questionType !== 'table' &&
						!isRedirigida &&
						!esRespuestaValida(formulario.FormularioId, pregunta)
					}>
					{hasRespuesta ? labels.editarRespuesta : labels.guardarRespuesta}
				</button>
			)}

			<button
				onClick={handleRedirigirClick}
				className={`${styles.redirigirButton} ${
					isRedirigida ? styles.redirigidaButton : ''
				}`}
				disabled={isRedirigida}>
				{labels.redirigirPregunta}
			</button>
		</div>
	);
};

export default ActionButtons;
