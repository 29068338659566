import React from 'react';
import { Link } from 'react-router-dom';
import useUserLanguage from '../hooks/useUserLanguage';

// Traducciones de nombres y departamentos
const processTranslations = {
  'Climate Change': { ES: 'Cambio Climático' },
  'Pollution': { ES: 'Contaminación' },
  'Water & marine resources': { ES: 'Recursos Hídricos y Marinos' },
  'Biodiversity & Ecosystems': { ES: 'Biodiversidad y Ecosistemas' },
  'Resource use & circular economy': { ES: 'Uso de Recursos y Economía Circular' },
  'Own Workforce': { ES: 'Personal Propio' },
  'Worker in the value chain': { ES: 'Trabajadores en la Cadena de Valor' },
  'Affected communities': { ES: 'Comunidades Afectadas' },
  'Consumers & end-users': { ES: 'Consumidores y Usuarios Finales' },
  'Business Conduct': { ES: 'Conducta Empresarial' },
  'General Principles': { ES: 'Principios Generales' },
  'General Strategy': { ES: 'Estrategia General' },
};

function TablaProcesos({ procesos, handleProcessClick }) {
  const { language } = useUserLanguage();
  console.log("Idioma detectado:", language);

  // Traducciones de etiquetas
  const labels = {
    nombre: language === 'EN' ? 'Name' : 'Nombre',
    departamento: language === 'EN' ? 'Department' : 'Departamento',
    accion: language === 'EN' ? 'Action' : 'Acción',
    noProcesos: language === 'EN' ? 'No processes found.' : 'No se encontraron procesos.'
  };

  const translate = (text) => {
    const key = Object.keys(processTranslations).find((k) => text.startsWith(k));
    return language === 'ES' && key ? processTranslations[key].ES : text;
  };

  return (
    <table className="process-table">
      <thead>
        <tr>
          <th>{labels.nombre}</th>
          <th>{labels.departamento}</th>
          <th>{labels.accion}</th>
        </tr>
      </thead>
      <tbody>
        {procesos.length === 0 ? (
          <tr>
            <td colSpan="3">{labels.noProcesos}</td>
          </tr>
        ) : (
          procesos.map((proceso) => {
            console.log("Nombre original:", proceso.nombre);
            console.log("Departamento original:", proceso.departamento);

            const nombreTraducido = translate(proceso.nombre);
            const departamentoTraducido = translate(proceso.departamento);

            console.log("Nombre traducido:", nombreTraducido);
            console.log("Departamento traducido:", departamentoTraducido);

            return (
              <tr key={proceso.id}>
                <td>{nombreTraducido}</td>
                <td>{departamentoTraducido}</td>
                <td>
                  <Link to={`/create-company/${proceso.id}`}>
                    <button className="action-btn">
                      <span className="arrow">▶</span>
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default TablaProcesos;
