// frontend/src/components/Questions/AreaQuestion.js
import React from 'react';
import { questionStyles } from '../../styles/questionStyles';

const AreaQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const handleChange = (e) => {
		const value = e.target.value;

		// Validación manual
		if (value === '' || isValidNumber(value)) {
			handleEditorChange(pregunta.id, formulario.FormularioId, value);
		}
	};

	// Función auxiliar para validar el signo negativo
	const hasValidNegativeSign = (str) => {
		const firstNegativeIndex = str.indexOf('-');
		return (
			firstNegativeIndex === -1 ||
			(firstNegativeIndex === 0 && str.lastIndexOf('-') === 0)
		);
	};

	// Función auxiliar para validar el punto decimal
	const hasValidDecimalPoint = (str) => {
		return (str.match(/\./g) || []).length <= 1;
	};

	// Función auxiliar para validar que todos los demás caracteres sean dígitos
	const hasOnlyValidDigits = (str) => {
		// Eliminar el signo negativo y el punto decimal para simplificar la verificación
		const digitsOnly = str.replace(/[-\.]/g, '');
		for (let i = 0; i < digitsOnly.length; i++) {
			const char = digitsOnly[i];
			if (char < '0' || char > '9') {
				return false;
			}
		}
		return true;
	};

	// Función para validar número sin usar regex propensa a backtracking
	const isValidNumber = (str) => {
		// Permitir un string vacío o solo un signo negativo
		if (str === '' || str === '-') return true;

		// Validar cada aspecto del número por separado
		return (
			hasValidNegativeSign(str) &&
			hasValidDecimalPoint(str) &&
			hasOnlyValidDigits(str)
		);
	};

	// Determinar clases de estilo basadas en las props
	const inputClasses = `
		${questionStyles.input.base}
		${
			pregunta.required
				? questionStyles.input.required
				: questionStyles.input.optional
		}
		${questionStyles.input.text}
		${!canEdit || isRedirigida ? questionStyles.input.disabled : ''}
	`;

	return (
		<div className={questionStyles.container}>
			<input
				type='text'
				value={
					respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
				}
				onChange={handleChange}
				className={inputClasses}
				placeholder='Ingrese el valor de área'
				readOnly={!canEdit || isRedirigida}
			/>
		</div>
	);
};

export default AreaQuestion;
