export const processListStyles = {
	// Contenedor principal con un fondo suave y padding uniforme
	container: `
      min-h-screen 
      bg-gradient-to-b from-gray-50 to-gray-100 
      py-8 
      px-4 
      md:px-8
    `,

	// Selector de año con diseño mejorado
	yearSelect: {
		container: `
        max-w-xl 
        mx-auto 
        mb-12
        bg-white 
        rounded-xl 
        shadow-sm 
        p-6
        border border-gray-200
      `,
		wrapper: `
        flex 
        flex-col 
        sm:flex-row 
        items-center 
        justify-center 
        gap-4
      `,
		label: `
        font-semibold 
        text-gray-700 
        text-lg
      `,
		select: `
        w-full 
        sm:w-48 
        px-4 
        py-2 
        bg-white 
        border 
        border-gray-300 
        rounded-lg 
        shadow-sm 
        focus:ring-2 
        focus:ring-blue-500 
        focus:border-transparent 
        text-gray-700
        cursor-pointer
        hover:border-gray-400 
        transition-colors
      `,
	},

	// Contenedor ESRS2
	esrs2Container: `
      max-w-2xl 
      mx-auto 
      mb-12 
      bg-white 
      rounded-xl 
      shadow-md 
      p-8 
      text-center
      border border-gray-200
    `,

	// Títulos
	title: `
      text-2xl 
      font-bold 
      text-gray-800 
      mb-8 
      text-center
    `,

	esrs2Title: `
      text-xl 
      font-semibold 
      text-gray-700 
      mb-6
    `,

	// Botones
	actionButton: `
      inline-flex 
      items-center 
      justify-center 
      px-6 
      py-3 
      bg-secundario
      text-white 
      font-medium 
      rounded-lg 
      shadow-sm 
      enabled:hover:bg-primario
      focus:outline-none 
      focus:ring-2 
      focus:ring-blue-500 
      focus:ring-offset-2 
      transition-colors
      duration-200
      min-w-[200px]
      disabled:bg-opacity-60
      disabled:cursor-not-allowed
    `,

	startButton: `
      inline-flex 
      items-center 
      justify-center 
      px-6 
      py-3 
      bg-success 
      text-white 
      font-medium 
      rounded-lg 
      shadow-sm 
      hover:opacity-80
      focus:outline-none 
      focus:ring-2 
      focus:ring-green-500 
      focus:ring-offset-2 
      transition-colors
      duration-200
      min-w-[200px]
    `,

	// Contenedor principal de contenido
	content: `
      max-w-5xl 
      mx-auto 
      bg-white 
      rounded-xl 
      shadow-md 
      p-8 
      border border-gray-200
    `,

	// Manejo de errores
	error: `
      mt-4 
      p-4 
      bg-error/30 
      border-l-4 
      border-red-500 
      text-error
      rounded-r-lg
    `,
    successMessage:`
    flex
      mx-auto
      mt-2 
     
      bg-success/20
      text-success
      
      font-medium 
      rounded-lg 
      shadow-sm 
      hover:opacity-80
      focus:outline-none 
      focus:ring-2 
      focus:ring-green-500 
      focus:ring-offset-2 
      transition-colors
      duration-200
      max-w-fit
    `
		,
	successText: 'text-sm font-medium m-auto  px-6 py-2',

	// Tabla de procesos (estilos que se aplicarán en el componente TablaProcesos)
	table: {
		container: `
        w-full 
        overflow-hidden 
        rounded-lg 
        border border-gray-200
        mt-8
      `,
		header: `
        bg-gray-50 
        text-left 
        text-sm 
        font-medium 
        text-gray-500 
        uppercase 
        tracking-wider
      `,
		headerCell: `
        px-6 
        py-4
      `,
		row: `
        bg-white 
        border-t 
        border-gray-200 
        hover:bg-gray-50 
        transition-colors 
        duration-150
      `,
		cell: `
        px-6 
        py-4 
        text-sm 
        text-gray-700
      `,
      
	},
};
