//frontend/src/componentes/FormularioCard/componentes/QuestionTitle.js
import React from 'react';
import { formularioCardStyles as styles } from '../../../styles/formularioCardStyles';

const QuestionTitle = ({ pregunta, language, labels }) => (
	<strong>
		<a
			href={pregunta.link}
			target='_blank'
			rel='noopener noreferrer'>
			{pregunta.id} - {pregunta.text[language] || labels.preguntaTexto}
		</a>
		{pregunta.questionType && (
			<span className={styles.questionType}> [{pregunta.questionType}]</span>
		)}
		{pregunta.type && (
			<span className={styles.questionType}> (Type: {pregunta.type})</span>
		)}
		{pregunta.required &&(
			<span className={styles.requiredTitle}>*</span>
		)}
	</strong>
);

export default QuestionTitle;
