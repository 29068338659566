// hooks/useUserArea.js
import { useState, useEffect } from 'react';

function useUserArea() {
  const [area, setArea] = useState(null);
  const [error, setError] = useState(null);

  const fetchArea = async () => {
    try {
      const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/me/area', {
        method: 'GET',
        credentials: 'include', // Incluir cookies en la solicitud
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log('Área obtenida en Hook useUserArea:', data.area);
        setArea(data.area);
      } else {
        console.error('Error al obtener el área del usuario');
        setError('Error al obtener el área del usuario');
      }
    } catch (error) {
      console.error('Error en la solicitud de área:', error);
      setError('Error en la solicitud de área');
    }
  };

  // Llamamos a `fetchArea` en el primer renderizado del hook
  useEffect(() => {
    fetchArea();
  }, []);

  // Devuelve `fetchArea` como `refetchArea` para llamar después del login
  return { area, error, refetchArea: fetchArea };
}

export default useUserArea;
