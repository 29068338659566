import React from 'react';
import { questionStyles } from '../../styles/questionStyles';
import { t } from 'i18next';

const GhgEmissionsQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const handleChange = (e) => {
		const value = e.target.value;

		// Por ahora igual que integer, solo permitimos números enteros
		if (value === '' || /^-?\d*$/.test(value)) {
			handleEditorChange(pregunta.id, formulario.FormularioId, value);
		}
	};

	const inputClasses = `
		${questionStyles.ghgEmissions.input}
		${
			pregunta.required
				? questionStyles.ghgEmissions.required
				: questionStyles.ghgEmissions.optional
		}
		${questionStyles.ghgEmissions.text}
		${!canEdit || isRedirigida ? questionStyles.ghgEmissions.disabled : ''}
	`;

	return (
		<div className={questionStyles.ghgEmissions.container}>
			<input
				type='text'
				value={
					respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
				}
				onChange={handleChange}
				className={inputClasses}
				placeholder={t('ghgQuestionPlaceholder')}
				readOnly={!canEdit || isRedirigida}
			/>
		</div>
	);
};

export default GhgEmissionsQuestion;
