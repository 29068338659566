//frontend/src/reportWebVitals.js
import { metricsService } from './services/metricsService';

const reportWebVitals = (onPerfEntry) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS((metric) => {
				onPerfEntry(metric);
				metricsService.recordPerformanceMetric('CLS', metric.value);
			});
			getFID((metric) => {
				onPerfEntry(metric);
				metricsService.recordPerformanceMetric('FID', metric.value);
			});
			getFCP((metric) => {
				onPerfEntry(metric);
				metricsService.recordPerformanceMetric('FCP', metric.value);
			});
			getLCP((metric) => {
				onPerfEntry(metric);
				metricsService.recordPerformanceMetric('LCP', metric.value);
			});
			getTTFB((metric) => {
				onPerfEntry(metric);
				metricsService.recordPerformanceMetric('TTFB', metric.value);
			});
		});
	}
};

export default reportWebVitals;
