//frontend/src/styles/vistaFormularioStyles.js
export const vistaFormularioStyles = {
	container: 'max-w-[1200px] mx-auto p-5 flex flex-col items-center gap-5',
	title: 'text-3xl mb-8 text-gray-800 text-center',
	errorText: 'text-red-600 font-bold text-center mt-3',
	formularioCard:
		'bg-gray-100 p-5 mt-10 rounded-lg shadow-md text-left w-full max-w-[1000px] min-w-[700px] min-h-[150px] flex flex-col justify-between',
	preguntasList: 'grid grid-cols-1 gap-5 mt-5',
	preguntaItem:
		'bg-fondo p-4 rounded-lg shadow-md flex flex-col items-start text-left',
	preguntaTitle: 'text-lg font-semibold mb-3',
	buttonContainer: 'flex gap-3 items-center mt-16',
	button: {
		base: 'px-4 py-2 rounded-md font-bold transition-colors duration-300 focus:outline-none',
		primary:
			'bg-blue-600 text-fondo hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed',
		success: 'bg-success text-fondo hover:bg-opacity-80 mr-3',
	},
	toggleButton:
		'bg-blue-600 text-fondo border-none rounded px-4 py-2 cursor-pointer text-base font-bold transition-colors duration-300 hover:bg-blue-700 mt-3 block',
	editor: {
		container: 'w-[800px] mt-3 h-[300px] bg-fondo mb-0 flex flex-col',
		toolbar: 'border-none bg-fondo',
		content: 'border-none bg-fondo p-3 min-h-[150px]',
		required: 'bg-red-100',
	},
	containerClasses:'p-4 border border-secundario bg-secundario/80 rounded-lg relative',
	headerClasses:'text-lg font-medium text-white mb-1',
	bodyClasses:'text-white',
	closeButtonClasses:'pl-5 text-white hover:text-primario transition-colors duration-200 text-xl font-bold'
};
