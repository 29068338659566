// hooks/useUserCompany.js
import { useState, useEffect } from 'react';

function useUserCompany() {
  const [empresa, setEmpresa] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserCompany = async () => {
      try {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/me/company', {
          method: 'GET',
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setEmpresa(data.empresa);
        } else {
          console.error('Error al obtener la empresa del usuario');
          setError('Error al obtener la empresa');
        }
      } catch (error) {
        console.error('Error en la solicitud de empresa:', error);
        setError('Error en la solicitud de empresa');
      }
    };

    fetchUserCompany();
  }, []);

  return { empresa, error };
}

export default useUserCompany;
