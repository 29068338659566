import React, { useState } from "react";
import { modalStyles as styles } from "../styles/revisionModalStyles";
import { t } from "i18next";

const RevisionModal = ({ isOpen, onClose, onSubmit }) => {
  const [mensajerevision, setMensajerevision] = useState('');

  const handleSubmit = () => {
    onSubmit(mensajerevision);
    setMensajerevision('');
  };

  if (!isOpen) return null;

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <h3 className={styles.title}>{t('reviewModal.reviewReason')}</h3>
        <div className="mt-2">
          <textarea
            className={styles.textarea}
            placeholder={t('reviewModal.textAreaPlaceholder')}
            value={mensajerevision}
            onChange={(e) => setMensajerevision(e.target.value)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <button type="button" className={styles.cancelButton} onClick={onClose}>
            {t('reviewModal.back')}
          </button>
          <button
            type="button"
            className={styles.submitButton}
            onClick={handleSubmit}
            disabled={!mensajerevision.trim()}
          >
            {t('reviewModal.send')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RevisionModal;
