import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TablaProcesos from '../../componentes/TablaProcesos';
import useUserCompany from '../../hooks/user/useUserCompany';
import useUserId from '../../hooks/user/useUserId';
import useI18n from '../../hooks/i18n/useI18n';
import { processListStyles as styles } from '../../styles/processListStyles';
import {
	ROUTE_COMPANY_CREATE_WITH_FORMS,
	ROUTE_COMPANY_CHECK_ESRS2,
	ROUTE_PROCESSES,
} from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function ListaDeProcesos() {
	const navigate = useNavigate();
	const [procesos, setProcesos] = useState([]);
	const [error, setError] = useState(null);
	const [selectedYear, setSelectedYear] = useState(null);
	const [esrs2Data, setEsrs2Data] = useState(null);

	const { empresa, error: empresaError } = useUserCompany();
	const { userId, error: userIdError } = useUserId();
	const { t, language, languageError } = useI18n();

	const ESRS2_ID = 1;

	const checkEsrs2Existence = async () => {
		if (!selectedYear || !empresa) {
			debug('Datos faltantes:', { selectedYear, empresa });
			return;
		}

		try {
			const response = await fetchWithAuth(ROUTE_COMPANY_CHECK_ESRS2, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					nombreEmpresa: empresa,
					anyo: selectedYear,
				}),
			});

			const text = await response.text();
			let data;
			try {
				data = JSON.parse(text);
				setEsrs2Data(data);
			} catch (parseError) {
				throw new Error('Respuesta JSON inválida');
			}
		} catch (error) {
			debugError('Error al verificar ESRS 2:', error);
			setError('Error al verificar ESRS 2');
		}
	};

	const startEsrs2 = async () => {
		try {
			const response = await fetchWithAuth(ROUTE_COMPANY_CREATE_WITH_FORMS, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					nombreEmpresa: empresa,
					procesosId: ESRS2_ID,
					userId: userId,
					anyo: selectedYear,
				}),
			});

			if (response.ok) {
				const data = await response.json();

				if (data.entries?.[0]?.id) {
					setTimeout(() => {
						navigate(`/process/${ESRS2_ID}/forms/${data.entries[0].id}`);
					}, 1000);
				} else if (data.empresaId) {
					setTimeout(() => {
						navigate(`/process/${ESRS2_ID}/forms/${data.empresaId}`);
					}, 1000);
				} else {
					setError('Error en la estructura de la respuesta del servidor');
				}
			}
		} catch (error) {
			debugError('Error al iniciar ESRS 2:', error);
			setError('Error al iniciar ESRS 2');
		}
	};

	useEffect(() => {
		if (selectedYear && empresa) {
			checkEsrs2Existence();
		}
	}, [selectedYear, empresa]);

	const handleYearChange = (event) => {
		const year = event.target.value;
		setSelectedYear(year);
		localStorage.setItem('selectedYear', year);
	};

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	return (
		<div className={styles.container}>
			{/* Selector de año - esto siempre se muestra */}
			<div className={styles.yearSelect.container}>
				<div className={styles.yearSelect.wrapper}>
					<label htmlFor='year-select'>{t('listaProcesos.yearSelect')}</label>
					<select
						id='year-select'
						onChange={handleYearChange}
						value={selectedYear || ''}
						className={styles.yearSelect.select}>
						<option
							value=''
							disabled>
							{t('listaProcesos.yearPlaceholder')}
						</option>
						<option value='2024'>2024</option>
						<option value='2025'>2025</option>
						<option value='2026'>2026</option>
					</select>
				</div>
			</div>

			{/* Solo el contenedor de ESRS2 */}
			{selectedYear && (
				<div className={styles.esrs2Container}>
					<h2>{t('listaProcesos.esrs2Title')}</h2>
					{!esrs2Data?.exists ? (
						<button
							onClick={startEsrs2}
							className={styles.startButton}>
							{t('listaProcesos.startEsrs2')}
						</button>
					) : (
						<Link
							to={
								esrs2Data.status === 'Completada'
									? `/empresas/${esrs2Data.empresaId}/procesos/${ESRS2_ID}/vista-proceso-acabado`
									: `/process/${ESRS2_ID}/forms/${esrs2Data.empresaId}`
							}>
							<button
								className={styles.actionButton}
								disabled={['Acabada', 'Finalizada'].includes(
									esrs2Data?.status
								)}>
								{t('listaProcesos.rellenarRequisito')}
							</button>
						</Link>
					)}
					{['Acabada', 'Finalizada'].includes(esrs2Data?.status) && (
						<div className={styles.successMessage}>
							<p className={styles.successText}>
								{t('listaProcesos.successMessage')}
							</p>
						</div>
					)}
				</div>
			)}

			{/* Errores */}
			{(error || empresaError || userIdError || languageError) && (
				<div className={styles.error}>
					{error || empresaError || userIdError || languageError}
				</div>
			)}
		</div>
	);
}

export default ListaDeProcesos;
