import { ROUTE_RESPONSES } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

//frontend/src/hooks/formularios/useGuardarTodo.js
export default function useGuardarTodo(
	formularios,
	empresaId,
	procesoId,
	respuestas,
	respuestasEnviadas,
	setRespuestasEnviadas,
	esRespuestaValida,
	selectedArea,
	selectedDepartamento,
	userId
) {
	// Obtiene el año del formulario
	const obtenerAnyo = (formulario) => {
		return formulario.fechaCreacion
			? new Date(formulario.fechaCreacion).getFullYear()
			: new Date().getFullYear();
	};

	// Construye el objeto de respuesta para guardar
	const construirRespuestaObj = (pregunta, formulario, anyo, isRedirigida) => {
		return {
			preguntasid: pregunta.id,
			empresasid: parseInt(empresaId),
			procesosid: parseInt(procesoId),
			formularioid: formulario.FormularioId,
			respuesta: isRedirigida
				? null
				: {
						text:
							respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text ||
							'',
						image:
							respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.image ||
							'',
				  },
			redirigira: isRedirigida
				? `${selectedArea[pregunta.id]} - ${selectedDepartamento[pregunta.id]}`
				: null,
			estadopregunta: isRedirigida ? 'Redirigida' : 'Contestada',
			respondidoporuserid: isRedirigida ? null : userId,
			contestadafecha: isRedirigida ? null : new Date().toISOString(),
			redirigidafecha: isRedirigida ? new Date().toISOString() : null,
			anyo: anyo,
		};
	};

	// Envía una respuesta al servidor
	const guardarRespuesta = async (respuestaObj, respuestaKey) => {
		const requestMethod = respuestasEnviadas[respuestaKey] ? 'PUT' : 'POST';
		const requestUrl = respuestasEnviadas[respuestaKey]
			? `${ROUTE_RESPONSES}/${respuestasEnviadas[respuestaKey].id}`
			: ROUTE_RESPONSES;

		try {
			const response = await fetchWithAuth(requestUrl, {
				method: requestMethod,
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(respuestaObj),
			});

			if (!response.ok) {
				throw new Error(
					`Error al ${
						requestMethod === 'POST' ? 'enviar' : 'actualizar'
					} la respuesta`
				);
			}

			const data = await response.json();
			setRespuestasEnviadas((prev) => ({
				...prev,
				[respuestaKey]: data,
			}));
			return data;
		} catch (error) {
			debugError(
				`Error al ${
					requestMethod === 'POST' ? 'enviar' : 'actualizar'
				} la respuesta:`,
				error
			);
			throw error;
		}
	};

	// Procesa las preguntas de un formulario
	const procesarPreguntas = (formulario, anyo) => {
		const promesas = [];

		formulario.preguntas.forEach((pregunta) => {
			const respuestaKey = `${formulario.FormularioId}-${empresaId}-${procesoId}-${pregunta.id}`;

			// Verificar si la respuesta es obligatoria y válida
			if (
				pregunta.required &&
				!esRespuestaValida(formulario.FormularioId, pregunta)
			) {
				return;
			}

			const isRedirigida =
				selectedArea[pregunta.id] && selectedDepartamento[pregunta.id];

			const respuestaObj = construirRespuestaObj(
				pregunta,
				formulario,
				anyo,
				isRedirigida
			);

			promesas.push(guardarRespuesta(respuestaObj, respuestaKey));
		});

		return promesas;
	};

	const handleGuardarTodo = () => {
		const savePromises = [];

		formularios.forEach((formulario) => {
			const anyo = obtenerAnyo(formulario);
			debug(`Procesando formulario ${formulario.FormularioId}, año ${anyo}`);

			const promesasFormulario = procesarPreguntas(formulario, anyo);
			savePromises.push(...promesasFormulario);
		});

		Promise.all(savePromises)
			.then(() => {
				debug('Respuestas válidas han sido guardadas');
				alert('Respuestas guardadas con éxito.');
			})
			.catch((error) => {
				debugError('Error al guardar todas las respuestas:', error);
			});
	};

	return handleGuardarTodo;
}
