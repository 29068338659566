import { useState, useEffect } from 'react';

function useFormularioData(empresaId) {
  const [formularios, setFormularios] = useState([]);
  const [error, setError] = useState(null);
  const [procesoId, setProcesoId] = useState(null);  // Agregar procesoId en el estado

  useEffect(() => {
    if (empresaId) {
      console.log("EmpresaId recibida en useFormularioData:", empresaId); // Log cuando cambia empresaId
      fetchFormularios();
    }
  }, [empresaId]);

  // Función para obtener los formularios de una empresa y sus preguntas asociadas
  const fetchFormularios = async () => {
    try {
      console.log("Llamada a fetchFormularios iniciada");
      const response = await fetch('https://risincome.aicrumit.com/backend/api/empresas/list');
      if (!response.ok) throw new Error('Error al obtener la empresa');
      const data = await response.json();
      console.log("Datos de empresas obtenidos:", data); // Muestra los datos de empresas obtenidos

      const empresaData = data.find((item) => item.id === parseInt(empresaId));
      console.log("Datos de la empresa seleccionada:", empresaData); // Muestra los datos de la empresa seleccionada

      if (empresaData) {
        const { procesosId, nombreEmpresa, fechaCreacion } = empresaData;
        setProcesoId(procesosId);  // Establecer procesoId en el estado
        console.log("ProcesoId establecido:", procesosId);

        // Convertir `fechaCreacion` de la empresa en un valor de tiempo para comparar
        const fechaReferencia = new Date(fechaCreacion).getTime();

        const formulariosRelacionados = data.filter((item) => {
          const itemFecha = new Date(item.fechaCreacion).getTime();
          const diferenciaSegundos = Math.abs(itemFecha - fechaReferencia) / 1000;

          return (
            item.procesosId === procesosId &&
            item.nombreEmpresa === nombreEmpresa &&
            diferenciaSegundos <= 10  // ±10 segundos de intervalo
          );
        });
        console.log("Formularios relacionados filtrados por fecha:", formulariosRelacionados);

        const formulariosConPreguntas = await Promise.all(
          formulariosRelacionados.map(async (formulario) => {
            const preguntas = await fetchPreguntas(formulario.FormularioId);
            console.log(`Preguntas obtenidas para Formulario ID ${formulario.FormularioId}:`, preguntas);
            return { ...formulario, preguntas };
          })
        );

        console.log("Formularios con preguntas añadidas:", formulariosConPreguntas); // Muestra formularios completos con preguntas
        setFormularios(formulariosConPreguntas);
      } else {
        setError('Empresa no encontrada');
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      setError('No se pudo cargar los formularios de la empresa.');
    }
  };

  // Función para obtener preguntas asociadas a un formulario específico
  const fetchPreguntas = async (formularioId) => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/formularios/${formularioId}/preguntas`);
      if (!response.ok) throw new Error('Error al obtener preguntas del formulario');
      const data = await response.json();
      return data.preguntas.map((pregunta) => ({
        ...pregunta,
        link: pregunta.link || '',
        required: pregunta.required || false,
      }));
    } catch (error) {
      console.error(`Error al obtener preguntas para Formulario ID ${formularioId}:`, error);
      return [];
    }
  };

  return { formularios, error, procesoId };  // Devuelve procesoId junto con formularios y error
}

export default useFormularioData;
