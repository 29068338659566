// FormularioCard/index.js
import React from 'react';
import useI18n from '../../hooks/i18n/useI18n';
import { formularioCardStyles as styles } from '../../styles/formularioCardStyles';
import PreguntaItem from './PreguntaItem';
import { obtenerNombreDescriptivo } from '../../config/formularioNombres';
import { obtenerNombreDescriptivoProceso } from '../../config/procesosNombres';
import { debug, debugError } from '../../utils/debugHelper';

const FormularioCard = ({
	formulario,
	mostrarPreguntas,
	togglePreguntas,
	empresaId,
	procesoId,
	respuestasEnviadas,
	selectedArea = {},
	selectedDepartamento = {},
	language: propLanguage, // Aceptamos language como prop para compatibilidad
	...props
}) => {
	// Usamos i18n en lugar de recibir labels como prop
	const { t, language: i18nLanguage } = useI18n();

	// Si se pasa language como prop, la usamos, sino usamos la de i18n
	const language = propLanguage || i18nLanguage || 'ES';

	// Crear un objeto labels compatible para pasar a PreguntaItem
	// (hasta que ese componente sea actualizado también para usar i18n)
	const labels = {
		mostrarPreguntas: t(
			'vistaFormulario.mostrarPreguntas',
			'Mostrar Preguntas'
		),
		esconderPreguntas: t(
			'vistaFormulario.esconderPreguntas',
			'Esconder Preguntas'
		),
		guardarRespuesta: t(
			'vistaFormulario.guardarRespuesta',
			'Guardar Respuesta'
		),
		editarRespuesta: t('vistaFormulario.editarRespuesta', 'Editar Respuesta'),
		redirigirPregunta: t(
			'vistaFormulario.redirigirPregunta',
			'Redirigir Pregunta'
		),
		quedaRedirigida: t('vistaFormulario.quedaRedirigida', 'Queda Redirigida'),
		soloVista: t('vistaFormulario.soloVista', 'Solo vista'),
		seleccionarArea: t('vistaFormulario.seleccionarArea', 'Selecciona un área'),
		seleccionarDepartamento: t(
			'vistaFormulario.seleccionarDepartamento',
			'Selecciona un departamento'
		),
		finalizarProceso: t(
			'vistaFormulario.finalizarProceso',
			'Finalizar Proceso'
		),
		cargandoFormulario: t(
			'vistaFormulario.cargandoFormulario',
			'Cargando formularios de la empresa...'
		),
		noPreguntasDisponibles: t(
			'vistaFormulario.noPreguntasDisponibles',
			'No hay preguntas disponibles para este formulario.'
		),
		preguntaTexto: t('vistaFormulario.preguntaTexto', 'Texto no disponible'),
		anio: t('vistaFormulario.anio', 'Año'),
		preguntasContestadas: t(
			'vistaFormulario.preguntasContestadas',
			'Preguntas contestadas'
		),
		nombreProceso: t('vistaFormulario.nombreProceso', 'Nombre del Proceso'),
		nombreFormulario: t(
			'vistaFormulario.nombreFormulario',
			'Nombre del Formulario'
		),
		estadoFormulario: t(
			'vistaFormulario.estadoFormulario',
			'Estado del Formulario'
		),
		estados: {
			Pendiente: t('vistaFormulario.estados.Pendiente', 'Pendiente'),
			Acabada: t('vistaFormulario.estados.Acabada', 'Acabada'),
			Completada: t('vistaFormulario.estados.Completada', 'Completada'),
			EnProceso: t('vistaFormulario.estados.EnProceso', 'En Proceso'),
			Enviada: t('vistaFormulario.estados.Enviada', 'Enviada'),
			Revisada: t('vistaFormulario.estados.Revisada', 'Revisada'),
			Revision: t('vistaFormulario.estados.Revision', 'Revisión'),
		},
	};

	// Registramos que estamos usando este componente con i18n
	debug('FormularioCard - Usando i18n, idioma actual:', language);

	// Obtener el año del formulario - CORREGIDO para usar anyo directamente
	const getFormularioYear = () => {
		if (formulario.anyo) {
			return formulario.anyo;
		}
		// Si no hay campo anyo, mostrar error o valor por defecto
		debugError(
			`Error: No se encontró el año para el formulario ${formulario.FormularioId}`
		);
		return 'N/A'; // Mostrar N/A en lugar de usar un valor potencialmente incorrecto
	};

	const preguntasContestadas =
		formulario.preguntas?.filter(
			(p) =>
				respuestasEnviadas[
					`${formulario.FormularioId}-${empresaId}-${procesoId}-${p.id}`
				]?.estadopregunta === 'Contestada'
		).length || 0;

	const totalPreguntas = formulario.preguntas?.length || 0;

	// Verificar si es el formulario específico IRO-2 del proceso ESRS 2
	const isIRO2ESRS2 =
		formulario.formulario?.nombre === 'IRO-2' &&
		formulario.proceso?.nombre === 'ESRS 2';

	// Obtenemos el nombre descriptivo del formulario
	const codigoFormulario = formulario.formulario?.nombre || '';
	const nombreDescriptivo = obtenerNombreDescriptivo(
		codigoFormulario,
		language
	);

	// Combinamos el código original con la descripción para el formulario
	const nombreFormularioCompleto = `${codigoFormulario} ${nombreDescriptivo}`;

	// Obtenemos el nombre descriptivo del proceso
	const codigoProceso = formulario.proceso?.nombre || '';
	const nombreProcesoCompleto = obtenerNombreDescriptivoProceso(
		codigoProceso,
		language
	);

	// Función para traducir el estado del formulario
	const getEstadoTraducido = (estado) => {
		return t(`vistaFormulario.estados.${estado}`, estado);
	};

	return (
		<div className={styles.formularioCard}>
			<div className={styles.headerGrid}>
				{/* Proceso - Ahora con nombre descriptivo */}
				<div className={styles.headerItem}>
					<div className={styles.infoLabel}>
						{t('vistaFormulario.nombreProceso', 'Nombre del Proceso')}
					</div>
					<div className={styles.infoValue}>
						{nombreProcesoCompleto ||
							t('vistaFormulario.sinNombre', 'Sin nombre')}
					</div>
				</div>

				{/* Formulario - Con código y nombre descriptivo */}
				<div className={styles.headerItem}>
					<div className={styles.infoLabel}>
						{t('vistaFormulario.nombreFormulario', 'Nombre del Formulario')}
					</div>
					<div className={styles.infoValue}>
						{nombreFormularioCompleto ||
							t('vistaFormulario.sinNombre', 'Sin nombre')}
					</div>
				</div>

				{/* Estado */}
				<div className={styles.headerItem}>
					<div className={styles.infoLabel}>
						{t('vistaFormulario.estadoFormulario', 'Estado del Formulario')}
					</div>
					<div className={styles.infoValue}>
						<span
							className={
								formulario.estadoEmpresas === 'Pendiente'
									? styles.statusBadge.pendiente
									: styles.statusBadge.completado
							}>
							{getEstadoTraducido(formulario.estadoEmpresas)}
						</span>
					</div>
				</div>

				{/* Año - Actualizado para usar la traducción */}
				<div className={styles.headerItem}>
					<div className={styles.infoLabel}>
						{t('vistaFormulario.anio', 'Año')}
					</div>
					<div className={styles.infoValue}>
						<span className={styles.yearBadge}>{getFormularioYear()}</span>
					</div>
				</div>

				{/* Preguntas contestadas - Actualizado para usar la traducción */}
				<div className={styles.headerItem}>
					<div className={styles.infoLabel}>
						{t('vistaFormulario.preguntasContestadas', 'Preguntas contestadas')}
					</div>
					<div className={styles.questionCounter}>
						<span>{preguntasContestadas}</span>
						<span>/</span>
						<span>{totalPreguntas}</span>
					</div>
				</div>

				{/* Botón de mostrar preguntas */}
				<div className={styles.toggleContainer}>
					<button
						onClick={() => togglePreguntas(formulario.FormularioId)}
						className={styles.toggleButton}
						type='button'>
						{mostrarPreguntas[formulario.FormularioId] ? (
							<>
								<span>
									{t('vistaFormulario.esconderPreguntas', 'Esconder Preguntas')}
								</span>
								<svg
									className='w-5 h-5'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M19 9l-7 7-7-7'
									/>
								</svg>
							</>
						) : (
							<>
								<span>
									{t('vistaFormulario.mostrarPreguntas', 'Mostrar Preguntas')}
								</span>
								<svg
									className='w-5 h-5'
									fill='none'
									stroke='currentColor'
									viewBox='0 0 24 24'>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M9 5l7 7-7 7'
									/>
								</svg>
							</>
						)}
					</button>
				</div>
			</div>

			{mostrarPreguntas[formulario.FormularioId] && (
				<div className='animate-fadeIn mx-auto'>
					{/* Contenedor principal que contiene tanto la introducción como las preguntas 
                       con márgenes y padding consistentes */}
					<div className='max-w-full mx-auto'>
						{isIRO2ESRS2 && (
							<div className={styles.IRO2ESRS2}>
								{language === 'EN'
									? 'Indicate the disclosure requirements you comply with in your sustainability report, which are derived from the result of your materiality assessment.'
									: 'Indique los requisitos de divulgación con los que cumple en su informe de sostenibilidad y que se derivan del resultado de su evaluación de materialidad.'}
							</div>
						)}

						{formulario.preguntas && formulario.preguntas.length > 0 ? (
							<>
								{/* Eliminamos la clase preguntasList para tener más control sobre el espaciado */}
								<div className='space-y-4 w-full'>
									{formulario.preguntas.map((pregunta, idx) => (
										<div
											key={`${formulario.FormularioId}-pregunta-${idx}`}
											className='w-full'>
											<PreguntaItem
												pregunta={pregunta}
												formulario={formulario}
												labels={labels} // Pasamos labels para compatibilidad
												empresaId={empresaId}
												procesoId={procesoId}
												respuestasEnviadas={respuestasEnviadas}
												selectedArea={selectedArea}
												selectedDepartamento={selectedDepartamento}
												language={language} // Añadir explícitamente el parámetro language
												{...props}
											/>
										</div>
									))}
								</div>
								<div className='flex justify-center mt-6'>
									<button
										onClick={() => togglePreguntas(formulario.FormularioId)}
										className={styles.toggleButton}>
										{t(
											'vistaFormulario.esconderPreguntas',
											'Esconder Preguntas'
										)}
									</button>
								</div>
							</>
						) : (
							<p className={styles.noQuestionsText}>
								{t(
									'vistaFormulario.noPreguntasDisponibles',
									'No hay preguntas disponibles para este formulario.'
								)}
							</p>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default FormularioCard;
