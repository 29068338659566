// frontend/src/styles/revisionModalStyles.js
export const modalStyles = {
    overlay: "fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center p-4 z-50",
    container: "bg-fondo rounded-lg p-6 max-w-md w-full",
    title: "text-lg font-medium leading-6 text-gray-900 mb-4",
    textarea: "w-full h-32 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario",
    buttonContainer: "mt-4 flex justify-end gap-2",
    cancelButton: "px-4 py-2 text-sm font-medium text-gray-700 bg-warn/10 rounded-md hover:text-white hover:bg-primario/70 transition-colors duration-200",
    submitButton: "px-4 py-2 text-sm font-medium text-white bg-secundario rounded-md hover:bg-primario disabled:bg-secundario/70 transition-colors duration-200",
  };
  