//frontend\src\componentes\FormularioCard\componentes\RedirectSection.js
import React from 'react';
import { formularioCardStyles as styles } from '../../../styles/formularioCardStyles';
import { areaOptions } from '../../../config/areaOptions';
import { debug } from '../../../utils/debugHelper';

const RedirectSection = ({
	preguntaId,
	redirigirPregunta,
	isRedirigida,
	canEdit,
	selectedArea,
	selectedDepartamento,
	handleAreaChange,
	handleDepartamentoChange,
	labels,
	language,
}) => {
	debug('RedirectSection Render Details:', {
		preguntaId,
		redirigirPreguntaState: redirigirPregunta,
		redirigirPreguntaForId: redirigirPregunta[preguntaId],
		isRedirigida,
		canEdit,
		selectedArea,
		selectedAreaForId: selectedArea[preguntaId],
		selectedDepartamento,
		selectedDepartamentoForId: selectedDepartamento[preguntaId],
		availableAreas: Object.keys(areaOptions),
	});

	// Si la pregunta está redirigida o no se puede editar, no mostrar nada
	if (isRedirigida || !canEdit) {
		debug('RedirectSection early return due to:', {
			isRedirigida,
			canEdit,
		});
		return null;
	}

	// Solo mostrar si redirigirPregunta[preguntaId] es true
	if (!redirigirPregunta[preguntaId]) {
		debug(
			'RedirectSection early return due to redirigirPregunta being false for id:',
			preguntaId
		);
		return null;
	}

	debug('RedirectSection will render selects');

	return (
		<div className={styles.redirigirContainer}>
			<select
				value={selectedArea[preguntaId] || ''}
				onChange={(e) => {
					debug('Area select change:', e.target.value);
					handleAreaChange(preguntaId, e.target.value);
				}}
				className={styles.selectInput}>
				<option value=''>{labels.seleccionarArea}</option>
				{Object.keys(areaOptions)
					.filter((area) => area !== 'System')
					.map((area) => (
						<option
							key={area}
							value={area}>
							{area}
						</option>
					))}
			</select>

			{selectedArea[preguntaId] && (
				<select
					value={selectedDepartamento[preguntaId] || ''}
					onChange={(e) => {
						debug('Departamento select change:', e.target.value);
						handleDepartamentoChange(preguntaId, e.target.value);
					}}
					className={styles.selectInput}>
					<option value=''>{labels.seleccionarDepartamento}</option>
					{areaOptions[selectedArea[preguntaId]]?.map((departamento) => (
						<option
							key={departamento}
							value={departamento}>
							{departamento}
						</option>
					))}
				</select>
			)}
		</div>
	);
};

export default RedirectSection;
