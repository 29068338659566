import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import { useConditionalQuestion } from '../../hooks/questions/useQuestionType/useConditionalQuestion';
import { EDITOR_MODULES, EDITOR_FORMATS } from '../../config/editorConfig';
import { questionStyles } from '../../styles/questionStyles';
import { quillStyles } from '../../styles/quillStyles';
import {
	questionOptions,
	getTranslatedOption,
} from '../../config/questionConfig';
import useI18n from '../../hooks/i18n/useI18n';
import {
	checkIfStartsWithOption,
	getTextFromHtml,
} from '../../utils/questionUtils';

// Función segura para verificar si el HTML solo contiene etiquetas sin contenido
const hasOnlyHTMLTags = (html) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(html, 'text/html');
	const textContent = doc.body.textContent || '';
	return textContent.trim() === '';
};

// Componente auxiliar para el editor de ReactQuill
const ConditionalEditor = ({
	editorContent,
	customHandleEditorChange,
	isEditorReadOnly,
	pregunta,
	selectedOption,
}) => {
	return (
		<div className={quillStyles.wrapper}>
			<div
				className={`${quillStyles.editor.container} ${
					pregunta.required
						? quillStyles.editor.required
						: quillStyles.editor.default
				} ${
					!selectedOption
						? quillStyles.editor.disabled || 'opacity-50 cursor-not-allowed'
						: ''
				}`}>
				<ReactQuill
					value={editorContent}
					onChange={customHandleEditorChange}
					modules={EDITOR_MODULES}
					formats={EDITOR_FORMATS}
					className={quillStyles.editor.base}
					readOnly={isEditorReadOnly}
					theme='snow'
				/>
			</div>
		</div>
	);
};

// Componente auxiliar para las opciones del select
const OptionSelector = ({
	selectedOption,
	handleChange,
	selectClassName,
	availableOptions,
	canEdit,
	isRedirigida,
	selectRef,
	t,
}) => {
	return (
		<>
			<select
				ref={selectRef}
				value={selectedOption}
				onChange={handleChange}
				className={selectClassName}
				disabled={!canEdit || isRedirigida}>
				{availableOptions.map((option, index) => (
					<option
						key={index}
						value={option.value}>
						{option.label}
					</option>
				))}
			</select>

			{!selectedOption && canEdit && !isRedirigida && (
				<div
					className={
						questionStyles.conditional.warningMessage ||
						'text-red-500 text-sm mt-2 mb-2'
					}>
					{t(
						'questionConditional.warningMessage',
						'Por favor, selecciona una opción antes de continuar.'
					)}
				</div>
			)}
		</>
	);
};

// Componente auxiliar para renderizar etiquetas
const ConditionalLabels = ({
	selectedOption,
	questionConfig,
	positiveLabel,
	negativeLabel,
}) => {
	if (!questionConfig) return null;

	if (
		selectedOption === questionConfig.positiveOption &&
		questionConfig.showLabelOnPositive &&
		positiveLabel
	) {
		return (
			<div className={questionStyles.conditional.labelAboveSelect}>
				{positiveLabel}
			</div>
		);
	}

	if (
		selectedOption === questionConfig.negativeOption &&
		questionConfig.showLabelOnNegative &&
		negativeLabel
	) {
		return (
			<div className={questionStyles.conditional.labelAboveSelect}>
				{negativeLabel}
			</div>
		);
	}

	return null;
};

// Función para preparar las opciones disponibles
const prepareAvailableOptions = (
	questionConfig,
	options,
	language,
	translateOption,
	selectPrompt,
	t
) => {
	if (questionConfig) {
		return [
			{ value: '', label: selectPrompt },
			{
				value: questionConfig.positiveOption,
				label:
					language === 'EN'
						? translateOption(questionConfig.positiveOption)
						: questionConfig.positiveOption,
			},
			{
				value: questionConfig.negativeOption,
				label:
					language === 'EN'
						? translateOption(questionConfig.negativeOption)
						: questionConfig.negativeOption,
			},
		];
	}

	return [
		{ value: '', label: selectPrompt },
		{
			value: 'compliant',
			label: options?.positiveOption || t('questionConditional.yes', 'Si'),
		},
		{
			value: 'non-compliant',
			label: options?.negativeOption || t('questionConditional.no', 'No'),
		},
	];
};

// Componente principal
const ConditionalQuestion = ({
	pregunta,
	formulario,
	procesoId,
	respuestasEnviadas,
	respuestas,
	options,
	handleEditorChange,
	canEdit,
	isRedirigida,
	language = 'ES',
}) => {
	// Hooks y estado
	const { t, i18n } = useI18n();
	const [selectedOption, setSelectedOption] = useState('');
	const [editorContent, setEditorContent] = useState('');
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [lastSavedOption, setLastSavedOption] = useState('');
	const lastOptionSelectedRef = useRef('');

	// Configuración de la pregunta
	const configKey = `${formulario.FormularioId}-${procesoId}-${pregunta.id}`;
	const questionConfig = questionOptions[configKey];
	const preguntaParaHook = { ...pregunta };

	// Hook para funcionalidad condicional
	const { selectRef, handleSubmitWithConditional } = useConditionalQuestion(
		respuestasEnviadas,
		formulario.FormularioId,
		procesoId,
		pregunta.id,
		preguntaParaHook
	);

	// Determinar si es una pregunta condicional
	const isConditional =
		pregunta?.type === 'conditional' ||
		pregunta?.questionType === 'conditional' ||
		questionConfig != null;

	// Determinar si el editor debe estar en modo solo lectura
	const isEditorReadOnly = !canEdit || isRedirigida || !selectedOption;

	// Importamos todas las funciones auxiliares que ya están definidas
	const { determineInitialOption, handleChange, customHandleEditorChange } =
		useConditionalHelpers({
			pregunta,
			preguntaParaHook,
			formulario,
			procesoId,
			respuestas,
			options,
			questionConfig,
			handleEditorChange,
			handleSubmitWithConditional,
			selectedOption,
			setSelectedOption,
			lastOptionSelectedRef,
			editorContent,
			setEditorContent,
			language,
			translateOption: translateOptionFunc,
		});

	// Función mejorada para traducir opciones
	function translateOptionFunc(option) {
		if (language === 'EN') {
			// Quitar los dos puntos al final para buscar en las traducciones
			const normalizedOption = option.endsWith(':')
				? option.slice(0, -1)
				: option;

			// Obtener directamente del objeto de traducciones
			const options = t('questionConditional.options', { returnObjects: true });

			if (options && typeof options === 'object' && options[normalizedOption]) {
				return options[normalizedOption];
			}

			// Para Si/No básicos usar las traducciones estándar
			if (option === 'Si') return t('questionConditional.yes', 'Yes');
			if (option === 'No') return t('questionConditional.no', 'No');
		}

		return option;
	}

	//Carga inicial - determinar la opción seleccionada basada en respuestas guardadas
	useEffect(() => {
		if (!isInitialLoad) return;

		const respuestaKey = `${formulario.FormularioId}-${pregunta.id}`;

		// Buscar respuesta guardada con prioridad
		const respuestaDirecta = respuestas[respuestaKey]?.text;
		const respuestaEnBaseDeDatos = Object.values(respuestasEnviadas).find(
			(r) =>
				r.formularioid === formulario.FormularioId &&
				r.procesosid === procesoId &&
				r.preguntasid === pregunta.id
		);

		// Priorizar respuesta en memoria sobre la de base de datos
		const respuestaText =
			respuestaDirecta || respuestaEnBaseDeDatos?.respuesta?.text || '';

		// Extrae el texto plano para determinar la opción
		const textoPlano = getTextFromHtml(respuestaText).trim();

		// Determinar la opción inicial
		const initialOption = determineInitialOption(
			textoPlano,
			questionConfig,
			options
		);

		// Importante: si el formulario está en estado final, asegurarnos de que
		// se muestra correctamente la opción seleccionada
		if (
			formulario.estadoEmpresas === 'Acabada' ||
			formulario.estadoEmpresas === 'Completada' ||
			formulario.estadoEmpresas === 'Revision'
		) {
			// Asegurarnos de que hay una opción seleccionada aunque la lógica no la haya encontrado
			if (!initialOption && respuestaText) {
				// Si hay respuesta pero no se determinó opción, usar la primera disponible como fallback
				const fallbackOption = questionConfig
					? questionConfig.positiveOption
					: options?.positiveOption || 'Si';

				setSelectedOption(fallbackOption);
				setLastSavedOption(fallbackOption);
				lastOptionSelectedRef.current = fallbackOption;
			} else {
				setSelectedOption(initialOption);
				setLastSavedOption(initialOption);
				lastOptionSelectedRef.current = initialOption;
			}
		} else {
			// Comportamiento normal para formularios en edición
			setSelectedOption(initialOption);
			setLastSavedOption(initialOption);
			lastOptionSelectedRef.current = initialOption;
		}

		setEditorContent(respuestaText);
		setIsInitialLoad(false);
	}, [
		isInitialLoad,
		respuestasEnviadas,
		formulario.FormularioId,
		procesoId,
		pregunta.id,
		questionConfig,
		options,
		determineInitialOption,
		formulario.estadoEmpresas, // Añadir esta dependencia
	]);
	// Los efectos adicionales se mantienen igual...
	useEffect(() => {
		// Solo para preguntas controladas por otras
		if (!pregunta.conditional?.controlledBy) return;

		const controllingId = pregunta.conditional.controlledBy;
		const controllingConfigKey = `${formulario.FormularioId}-${procesoId}-${controllingId}`;
		const controllingConfig = questionOptions[controllingConfigKey];

		// Verificar si la configuración de control existe y la pregunta actual está en la lista
		const isControlledQuestion =
			controllingConfig &&
			controllingConfig.controlledQuestions &&
			controllingConfig.controlledQuestions.includes(pregunta.id.toString());

		if (!isControlledQuestion) return;

		// Verificar si la respuesta mostrada en el editor es la misma que en respuestas
		const currentRespuestaKey = `${formulario.FormularioId}-${pregunta.id}`;
		const currentRespuestaInState = respuestas[currentRespuestaKey]?.text || '';

		// Si el contenido del editor no coincide con lo que está en el estado, actualiza el editor
		if (editorContent !== currentRespuestaInState) {
			setEditorContent(currentRespuestaInState);
		}
	}, [
		respuestas,
		pregunta.id,
		pregunta.conditional,
		formulario.FormularioId,
		procesoId,
		editorContent,
	]);

	useEffect(() => {
		// Si esta es una pregunta controlada (dependiente)
		if (!pregunta.conditional?.controlledBy) return;

		// Obtener la respuesta actual del estado
		const respuestaKey = `${formulario.FormularioId}-${pregunta.id}`;
		const respuestaActual = respuestas[respuestaKey]?.text || '';

		// Si hay una discrepancia entre lo que se muestra y lo que está en el estado
		if (respuestaActual !== editorContent) {
			setEditorContent(respuestaActual);
		}
	}, [
		respuestas,
		pregunta.id,
		pregunta.conditional,
		formulario.FormularioId,
		editorContent,
	]);

	// Validación temprana - si no es condicional, no renderizar nada
	if (!isConditional) {
		return null;
	}

	// Obtener etiquetas traducidas
	let positiveLabel = null;
	let negativeLabel = null;

	if (questionConfig) {
		if (questionConfig.positiveLabel && language === 'EN') {
			positiveLabel = t(
				`questionConditional.labels.${questionConfig.positiveLabel}`,
				questionConfig.positiveLabel
			);
		} else {
			positiveLabel = questionConfig.positiveLabel;
		}

		if (questionConfig.negativeLabel && language === 'EN') {
			negativeLabel = t(
				`questionConditional.labels.${questionConfig.negativeLabel}`,
				questionConfig.negativeLabel
			);
		} else {
			negativeLabel = questionConfig.negativeLabel;
		}
	}

	// Usar las traducciones para las opciones
	const selectPrompt = t(
		'questionConditional.selectPrompt',
		'Por favor selecciona tu respuesta'
	);

	// Construir opciones disponibles usando la función auxiliar
	const availableOptions = prepareAvailableOptions(
		questionConfig,
		options,
		language,
		translateOptionFunc,
		selectPrompt,
		t
	);

	const selectClassName = `${questionStyles.conditional.select} ${
		!canEdit || isRedirigida ? questionStyles.conditional.selectDisabled : ''
	}`;

	// Renderizado del componente
	return (
		<div className={questionStyles.conditional.container}>
			<ConditionalLabels
				selectedOption={selectedOption}
				questionConfig={questionConfig}
				positiveLabel={positiveLabel}
				negativeLabel={negativeLabel}
			/>

			<OptionSelector
				selectedOption={selectedOption}
				handleChange={handleChange}
				selectClassName={selectClassName}
				availableOptions={availableOptions}
				canEdit={canEdit}
				isRedirigida={isRedirigida}
				selectRef={selectRef}
				t={t}
			/>

			<ConditionalEditor
				editorContent={editorContent}
				customHandleEditorChange={customHandleEditorChange}
				isEditorReadOnly={isEditorReadOnly}
				pregunta={pregunta}
				selectedOption={selectedOption}
			/>
		</div>
	);
};

// Hook personalizado para todas las funciones auxiliares
function useConditionalHelpers({
	pregunta,
	preguntaParaHook,
	formulario,
	procesoId,
	respuestas,
	options,
	questionConfig,
	handleEditorChange,
	handleSubmitWithConditional,
	selectedOption,
	setSelectedOption,
	lastOptionSelectedRef,
	editorContent,
	setEditorContent,
	language,
	translateOption,
}) {
	// Función para determinar opción inicial
	const determineInitialOption = (respuestaText, questionConfig, options) => {
		// Si hay configuración específica para la pregunta
		if (questionConfig) {
			const positiveMatch = respuestaText
				.toLowerCase()
				.includes(questionConfig.positiveOption.toLowerCase());
			const negativeMatch = respuestaText
				.toLowerCase()
				.includes(questionConfig.negativeOption.toLowerCase());

			if (positiveMatch) return questionConfig.positiveOption;
			if (negativeMatch) return questionConfig.negativeOption;
			return '';
		}

		// Caso predeterminado
		const positiveOption = options?.positiveOption || 'Si';
		const negativeOption = options?.negativeOption || 'No';

		const positiveMatch = respuestaText
			.toLowerCase()
			.includes(positiveOption.toLowerCase());
		const negativeMatch = respuestaText
			.toLowerCase()
			.includes(negativeOption.toLowerCase());

		if (positiveMatch) return 'compliant';
		if (negativeMatch) return 'non-compliant';
		return '';
	};

	// Función para limpiar preguntas controladas
	const cleanControlledQuestions = (
		questionConfig,
		option,
		handleEditorChange,
		formularioId
	) => {
		if (!questionConfig?.controlledQuestions) return;

		const shouldCleanControlled =
			(questionConfig.controlBehavior === 'positive' &&
				option !== questionConfig.positiveOption) ||
			(questionConfig.controlBehavior === 'negative' &&
				option !== questionConfig.negativeOption);

		if (shouldCleanControlled) {
			questionConfig.controlledQuestions.forEach((controlledId) => {
				handleEditorChange(controlledId, formularioId, '');
			});
		}
	};

	// Función para ejecutar el autorellenado
	const executeAutofill = (
		pregunta,
		formulario,
		respuestas,
		handleEditorChange,
		procesoId,
		options,
		content
	) => {
		const respuestaObj = {
			text: content,
			image: '',
		};

		return handleSubmitWithConditional(
			pregunta,
			formulario,
			respuestas,
			handleEditorChange,
			(preguntaId, formularioId, respObj) => {
				// Evitar llamar a handleEditorChange para la pregunta original
				if (preguntaId !== pregunta.id) {
					return handleEditorChange(preguntaId, formularioId, respObj.text);
				}
				return true;
			},
			options,
			procesoId,
			respuestaObj
		);
	};

	// Función auxiliar para obtener el texto de la opción
	const getOptionText = (option, config, opts, lang, translator) => {
		let text;

		if (config) {
			text = option;
		} else {
			text =
				option === 'compliant'
					? opts?.positiveOption || 'Si'
					: opts?.negativeOption || 'No';
		}

		// Traducir si es necesario
		if (lang === 'EN') {
			return translator(text);
		}

		return text;
	};

	// Función para crear el contenido HTML apropiado según la opción seleccionada
	const createContentForOption = (option) => {
		if (!option) return ''; // No crear contenido para una opción vacía

		let optionText;

		if (questionConfig) {
			optionText = option;
		} else {
			optionText =
				option === 'compliant'
					? options?.positiveOption || 'Si'
					: options?.negativeOption || 'No';
		}

		// Traducir para inglés si es necesario
		if (language === 'EN') {
			const translatedOption = translateOption(optionText);
			optionText = translatedOption;
		}

		return `<p>${optionText}</p>`;
	};

	// Restablecer editor
	const resetEditor = () => {
		setSelectedOption('');
		lastOptionSelectedRef.current = '';
		setEditorContent('');
		handleEditorChange(pregunta.id, formulario.FormularioId, '');
	};

	// Obtener opción formateada
	const getFormattedOption = (option, config, opts, lang, translator) => {
		if (config) {
			return lang === 'EN' ? translator(option) : option;
		}
		return option === 'compliant'
			? opts?.positiveOption || 'Si'
			: opts?.negativeOption || 'No';
	};

	// Verificar si el contenido comienza con la opción
	const checkContentStartsWithOption = (value, option, lang) => {
		// Extraer el texto plano del HTML para hacer una comparación más precisa
		const plainTextValue = getTextFromHtml(value).trim();

		// Casos especiales para traducciones Yes/Si
		const isSiYesCase =
			(plainTextValue.toLowerCase().startsWith('yes') &&
				(option.toLowerCase() === 'si' || option.toLowerCase() === 'sí')) ||
			(plainTextValue.toLowerCase().startsWith('si') &&
				option.toLowerCase() === 'yes');

		return isSiYesCase || checkIfStartsWithOption(plainTextValue, option, lang);
	};

	// Corregir contenido del editor
	const correctEditorContent = () => {
		const correctedContent = createContentForOption(selectedOption);
		setEditorContent(correctedContent);
		handleEditorChange(pregunta.id, formulario.FormularioId, correctedContent);
	};

	// Guardar y procesar contenido
	const saveAndProcessContent = (value) => {
		// Guardar localmente y en el sistema
		setEditorContent(value);
		handleEditorChange(pregunta.id, formulario.FormularioId, value);

		// Ejecutar autorellenado
		executeAutofill(
			preguntaParaHook,
			formulario,
			respuestas,
			handleEditorChange,
			procesoId,
			options,
			value
		);
	};

	// Manejar cambio de opción
	const handleChange = async (e) => {
		const option = e.target.value;
		const oldOption = selectedOption;

		// Actualizar estados inmediatamente
		setSelectedOption(option);
		lastOptionSelectedRef.current = option;

		// Si no hay opción seleccionada, simplemente limpiar y salir
		if (!option) {
			setEditorContent('');
			handleEditorChange(pregunta.id, formulario.FormularioId, '');
			return;
		}

		// Determinar el texto para la opción
		let optionText = getOptionText(
			option,
			questionConfig,
			options,
			language,
			translateOption
		);

		// Crear y guardar el contenido
		const initialContent = `<p>${optionText}</p>`;

		// Actualizar estados del editor
		setEditorContent(initialContent);
		handleEditorChange(pregunta.id, formulario.FormularioId, initialContent);

		// Limpiar preguntas controladas si es necesario
		cleanControlledQuestions(
			questionConfig,
			option,
			handleEditorChange,
			formulario.FormularioId
		);

		// Ejecutar autorellenado
		executeAutofill(
			preguntaParaHook,
			formulario,
			respuestas,
			handleEditorChange,
			procesoId,
			options,
			initialContent
		);
	};

	// Manejar cambios en el editor
	const customHandleEditorChange = (value) => {
		// Si no hay opción seleccionada, no hacer nada
		if (!selectedOption) return;

		// Verificar si el editor está casi vacío
		if (hasOnlyHTMLTags(value)) {
			resetEditor();
			return;
		}

		// Obtener la opción actual en formato adecuado
		const optionToCheck = getFormattedOption(
			selectedOption,
			questionConfig,
			options,
			language,
			translateOption
		);

		// Verificar si el contenido comienza con la opción correcta
		const shouldCorrectContent = !checkContentStartsWithOption(
			value,
			optionToCheck,
			language
		);

		if (shouldCorrectContent) {
			correctEditorContent();
			return;
		}

		// Guardar y procesar el contenido válido
		saveAndProcessContent(value);
	};

	return {
		determineInitialOption,
		cleanControlledQuestions,
		executeAutofill,
		getOptionText,
		translateOption,
		createContentForOption,
		resetEditor,
		getFormattedOption,
		checkContentStartsWithOption,
		correctEditorContent,
		saveAndProcessContent,
		handleChange,
		customHandleEditorChange,
	};
}

export default ConditionalQuestion;
