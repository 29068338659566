// frontend/src/utils/handlers/userHandlers/createUserHandlers.js
import { ROUTE_USERS } from '../../../config/routeConfig';
import { commonUserHandlers } from './commonUserHandlers';
import { debugError } from '../../debugHelper';
import { fetchWithAuth } from '../../fetchUtils';

export const createUserHandlers = {
	...commonUserHandlers,

	handleSubmit: async (e, formData, clearForm, language) => {
		e.preventDefault();

		try {
			// Procesar los departamentos antes de enviar
			const processedDepartamentos = {};

			// Convertir la estructura anidada por área a una estructura plana por departamento
			Object.entries(formData.departamentos).forEach(([area, depts]) => {
				Object.entries(depts).forEach(([dept, perms]) => {
					processedDepartamentos[dept] = {
						ver: perms.ver || false,
						editar: perms.editar || false,
						enviar: perms.enviar || false,
					};
				});
			});

			// Crear el objeto de datos procesado
			const processedData = {
				...formData,
				departamentos: processedDepartamentos,
			};

			const response = await fetchWithAuth(ROUTE_USERS, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(processedData),
			});

			if (response.ok) {
				alert(
					language === 'EN'
						? 'User created successfully'
						: 'Usuario creado con éxito'
				);
				clearForm();
			} else {
				// Leer el mensaje de error específico del backend
				const errorData = await response.json();
				alert(
					errorData.error ||
						(language === 'EN'
							? 'Error creating user'
							: 'Error al crear el usuario')
				);
			}
		} catch (error) {
			debugError('Error al crear usuario:', error);
			alert(
				language === 'EN'
					? 'There was an error, please try again.'
					: 'Hubo un error, por favor intenta nuevamente.'
			);
		}
	},
};
