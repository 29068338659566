import React from 'react';
import useUserLanguage from '../hooks/useUserLanguage';
import useUserPermissions from '../hooks/useUserPermissions';
import useUserArea from '../hooks/useUserArea';
import useUserCompany from '../hooks/useUserCompany';
import styles from './Sidebar.module.css';

function Sidebar({ onFilterChange, selectedOption }) {
  const { language: userLanguage, error: languageError } = useUserLanguage();
  const { permissions, error: permissionsError } = useUserPermissions();
  const { area } = useUserArea();
  const { empresa, error: companyError } = useUserCompany();

  const translations = {
    openCases: { ES: 'Casos Abiertos', EN: 'Open Cases' },
    completedCases: { ES: 'Casos Completados', EN: 'Completed Cases' },
    receivedCases: { ES: 'Casos Recibidos', EN: 'Received Cases' },
    finishedCases: { ES: 'Casos Acabados', EN: 'Finished Cases' },
    redirectedQuestions: { ES: 'Preguntas Redirigidas', EN: 'Redirected Questions' },
  };

  const optionToFilterValue = {
    openCases: 'Pendiente',
    completedCases: 'Completada',
    receivedCases: 'Recibida',
    finishedCases: 'Acabada',
    redirectedQuestions: 'Redirigida',
  };

  // Función para verificar permisos y opciones según el área y prefijo
  const getOptionsForArea = (areaKey, permissionPrefix) => {
    if (!permissions) return []; // Añadimos la comprobación aquí

    const areaPermissions = Object.entries(permissions).filter(
      ([dept, perm]) => dept.includes(permissionPrefix)
    );

    const hasView = areaPermissions.some(([_, perm]) => perm.ver);
    const hasEdit = areaPermissions.some(([_, perm]) => perm.editar);
    const hasSend = areaPermissions.some(([_, perm]) => perm.enviar);

    if (hasView && !hasEdit && !hasSend) return ['openCases', 'finishedCases'];
    if (hasView && hasEdit && !hasSend) return ['openCases', 'finishedCases', 'redirectedQuestions'];
    if (hasView && hasEdit && hasSend) {
      return ['openCases', 'finishedCases', 'receivedCases', 'completedCases', 'redirectedQuestions'];
    }
    return [];
  };

  // Determinar opciones en la barra lateral basado en el área, permisos y empresa
  const getSidebarOptions = () => {
    if (!empresa) {
      console.warn("Empresa del usuario no definida, omitiendo casos.");
      return [];
    }

    const options = [];

    // ESG Area
    if (Array.isArray(area) && area.includes('ESG') && permissions) {
      const hasView = Object.values(permissions).some((perm) => perm.ver);
      const hasEdit = Object.values(permissions).some((perm) => perm.editar);
      const hasSend = Object.values(permissions).some((perm) => perm.enviar);

      if (hasView) options.push('finishedCases');
      if (hasView && !hasEdit && !hasSend) options.push('openCases');
      if (hasView && hasEdit && hasSend) {
        options.push('openCases', 'completedCases', 'receivedCases', 'redirectedQuestions');
      }
    }

    // Áreas con prefijos específicos
    const areaConfig = [
      { key: 'MedioAmbiental', prefix: 'ESRS E' },
      { key: 'EstandaresSociales', prefix: 'ESRS S' },
      { key: 'GobiernoCorporativo', prefix: 'ESRS G' },
    ];

    areaConfig.forEach(({ key, prefix }) => {
      if (Array.isArray(area) && area.includes(key)) {
        options.push(...getOptionsForArea(key, prefix));
      }
    });

    return [...new Set(options)]; // Elimina duplicados
  };

  const sidebarOptions = getSidebarOptions();

  return (
    <div className={styles.sidebar}>
      <ul className={styles['sidebar-list']}>
        {sidebarOptions.includes('openCases') && (
          <li
            className={`${styles['sidebar-item']} ${selectedOption === 'Pendiente' ? styles.active : ''}`}
            onClick={() => onFilterChange(optionToFilterValue.openCases)}
          >
            {translations.openCases[userLanguage]}
          </li>
        )}
        {sidebarOptions.includes('completedCases') && (
          <li
            className={`${styles['sidebar-item']} ${selectedOption === 'Completada' ? styles.active : ''}`}
            onClick={() => onFilterChange(optionToFilterValue.completedCases)}
          >
            {translations.completedCases[userLanguage]}
          </li>
        )}
        {sidebarOptions.includes('receivedCases') && (
          <li
            className={`${styles['sidebar-item']} ${selectedOption === 'Recibida' ? styles.active : ''}`}
            onClick={() => onFilterChange(optionToFilterValue.receivedCases)}
          >
            {translations.receivedCases[userLanguage]}
          </li>
        )}
        {sidebarOptions.includes('finishedCases') && (
          <li
            className={`${styles['sidebar-item']} ${selectedOption === 'Acabada' ? styles.active : ''}`}
            onClick={() => onFilterChange(optionToFilterValue.finishedCases)}
          >
            {translations.finishedCases[userLanguage]}
          </li>
        )}
        {sidebarOptions.includes('redirectedQuestions') && (
          <li
            className={`${styles['sidebar-item']} ${selectedOption === 'Redirigida' ? styles.active : ''}`}
            onClick={() => onFilterChange(optionToFilterValue.redirectedQuestions)}
          >
            {translations.redirectedQuestions[userLanguage]}
          </li>
        )}
      </ul>

      {languageError && <p style={{ color: 'red' }}>{languageError}</p>}
      {permissionsError && <p style={{ color: 'red' }}>{permissionsError}</p>}
      {companyError && <p style={{ color: 'red' }}>{companyError}</p>}
    </div>
  );
}

export default Sidebar;
