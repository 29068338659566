import React from 'react';
import { questionStyles } from '../../styles/questionStyles';
import BaseNumericInput from './BaseNumericInput';
import { t } from 'i18next';

const IntensityQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const handleChange = (value) => {
		handleEditorChange(pregunta.id, formulario.FormularioId, value);
	};

	const inputClasses = `
		${questionStyles.intensity.input}
		${
			pregunta.required
				? questionStyles.intensity.required
				: questionStyles.intensity.optional
		}
		${questionStyles.intensity.text}
		${!canEdit || isRedirigida ? questionStyles.intensity.disabled : ''}
	`;

	return (
		<div className={questionStyles.intensity.container}>
			<BaseNumericInput
				value={
					respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
				}
				onChange={handleChange}
				validationOptions={{
					allowDecimals: true,
					allowNegative: true,
				}}
				className={inputClasses}
				placeholder={t('intensityQuestionPlaceholder')}
				readOnly={!canEdit || isRedirigida}
			/>
		</div>
	);
};

export default IntensityQuestion;
