import { ROUTE_USERS } from '../../../config/routeConfig';
import { debugError } from '../../debugHelper';
import { fetchWithAuth } from '../../fetchUtils';

// frontend/src/utils/handlers/userHandlers/deleteUserHandlers.js
export const deleteUserHandlers = {
	fetchTargetUserCompany: async (
		id,
		labels,
		setError,
		setTargetUserCompany
	) => {
		if (!id) {
			setError(labels.invalidIdMessage);
			return { found: false, company: null };
		}

		try {
			const response = await fetchWithAuth(`${ROUTE_USERS}/${id}`, {
				method: 'GET',
			});
			const data = await response.json();

			if (response.ok) {
				setTargetUserCompany(data.empresa);
				return { found: true, company: data.empresa };
			} else {
				setError(labels.notFoundMessage);
				setTargetUserCompany(null);
				return { found: false, company: null };
			}
		} catch (error) {
			debugError(labels.companyFetchError, error);
			setError(labels.companyFetchError);
			setTargetUserCompany(null);
			return { found: false, company: null };
		}
	},

	handleDeleteUser: async (
		userId,
		targetUserCompany,
		userCompany,
		labels,
		setError,
		setSuccessMessage,
		setUserId,
		setTargetUserCompany
	) => {
		if (!userId) {
			setError(labels.invalidIdMessage);
			return false;
		}

		if (targetUserCompany !== userCompany) {
			setError(labels.companyErrorMessage);
			return false;
		}

		try {
			const response = await fetchWithAuth(`${ROUTE_USERS}/${userId}`, {
				method: 'DELETE',
			});

			if (response.ok) {
				setSuccessMessage(labels.successMessage);
				setError('');
				setUserId('');
				setTargetUserCompany(null);
				return true;
			} else {
				const errorData = await response.json();
				debugError('Error response:', errorData);
				setError(labels.errorMessage);
				setSuccessMessage('');
				return false;
			}
		} catch (error) {
			debugError('Error deleting user:', error);
			setError(labels.errorMessage);
			setSuccessMessage('');
			return false;
		}
	},
};
