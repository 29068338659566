// areaLabels.js
export const areaLabels = {
    System: {
      ES: "System",
      EN: "System"
    },
    ESG: {
      ES: "ESG",
      EN: "ESG"
    },
    MedioAmbiental: {
      ES: "Medio Ambiental",
      EN: "Environmental"
    },
    EstandaresSociales: {
      ES: "Estándares Sociales",
      EN: "Social Standards"
    },
    GobiernoCorporativo: {
      ES: "Gobierno Corporativo",
      EN: "Corporate Governance"
    },
    Financiero: {
      ES: "Financiero",
      EN: "Financial"
    }
  };
  