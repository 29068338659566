// frontend/src/hooks/questions/useQuestionType/useBooleanQuestion.js
import { useState } from 'react';

export const useBooleanQuestion = (options, handleEditorChange) => {
	const [selectedOption, setSelectedOption] = useState('');

	const handleBooleanChange = (value, preguntaId, formularioId) => {
		setSelectedOption(value);
		handleEditorChange(
			preguntaId,
			formularioId,
			`<p>${
				value === 'positive' ? options.positiveOption : options.negativeOption
			}</p>`
		);
	};

	return {
		selectedOption,
		handleBooleanChange,
	};
};
