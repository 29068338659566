import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setError('');
    setMessage('');
  
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
        credentials: 'include' // Enviar cookies con la solicitud
      });
  
      const data = await response.json();
      console.log('Datos de respuesta del servidor:', data); // Verifica los datos de la respuesta
  
      if (response.ok) {
        setMessage('Login exitoso');
        onLogin(); // Llama a onLogin para actualizar el estado de autenticación en el padre
  
        // Verificar si el área se incluye correctamente
        if (data.area) {
          console.log(`Área obtenida en login: ${data.area}`);
        } else {
          console.log("Área no presente en la respuesta");
        }
  
        // Redirigir según el área del usuario
        if (Array.isArray(data.area)) {
          if (data.area.includes("Extraccion")) {
            console.log('Redirigiendo a /extraccion');
            navigate('/extraccion'); // Redirige a la vista específica para "Extraccion"
          } else if (data.area.includes("System")) {
            console.log('Redirigiendo a /system-dashboard');
            navigate('/system-dashboard'); // Redirige al dashboard de sistema
          } else {
            console.log('Redirigiendo a /casos');
            navigate('/casos'); // Redirige al dashboard genérico para otras áreas
          }
        } else {
          console.log('Redirigiendo a /casos');
          navigate('/casos'); // Redirige al dashboard genérico si no hay áreas específicas
        }
      } else {
        setError(data.message || 'Error al iniciar sesión');
      }
    } catch (err) {
      console.error('Error en la conexión con el servidor:', err);
      setError('Error en la conexión con el servidor');
    }
  };

  return (
    <div className="login-container">
      <header className="header-bar">
        <p>Bienvenido a Aicrumit Applications</p>
      </header>

      <div className="login-box">
        <div className="login-left">
          <img src="/login-logo.png" alt="RIS Income Logo" className="login-logo" />
        </div>

        <div className="login-right">
          <h3>Formulario de login</h3>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {message && <p style={{ color: 'green' }}>{message}</p>}
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                placeholder="Usuario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="login-btn">LOGIN</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
