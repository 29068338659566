//frontend/src/componentes/PreguntasTable.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserPermissions from '../hooks/user/useUserPermissions';
import useUserCompany from '../hooks/user/useUserCompany';
import useI18n from '../hooks/i18n/useI18n';
import { preguntasTableStyles as styles } from '../styles/preguntasTableStyles';
import { handleAnswerQuestion } from '../utils/preguntasTableHelpers';

function PreguntasTable({ preguntas }) {
	const navigate = useNavigate();
	const { permissions } = useUserPermissions();
	const { empresa: userCompany } = useUserCompany();
	const { t, languageError } = useI18n();

	// Si hay un error con el idioma, mostramos un error
	if (languageError) {
		return (
			<div
				className='bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
				role='alert'>
				<p>{languageError}</p>
			</div>
		);
	}

	// Esperar a que los permisos estén disponibles
	if (!permissions) {
		return (
			<div className={styles.container}>
				<table className={styles.table}>
					<thead className={styles.header}>
						<tr>
							<th className={styles.headerCell}>
								{t('preguntasTable.idPregunta')}
							</th>
							<th className={styles.headerCell}>
								{t('preguntasTable.empresa')}
							</th>
							<th className={styles.headerCell}>
								{t('preguntasTable.proceso')}
							</th>
							<th className={styles.headerCell}>
								{t('preguntasTable.departamento')}
							</th>
							<th className={styles.headerCell}>
								{t('preguntasTable.fechaRedirigida')}
							</th>
							<th className={styles.headerCell}>
								{t('preguntasTable.acciones')}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td
								colSpan='6'
								className={styles.noData}>
								{t('common.loading', 'Cargando...')}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}

	// Filtrar las preguntas basado en permisos y empresa
	const preguntasFiltradas = preguntas.filter((pregunta) => {
		// Verificar si la pregunta pertenece a la empresa del usuario actual
		const empresaCoincide = pregunta.empresa?.nombreEmpresa === userCompany;

		// Si la empresa no coincide, filtrar la pregunta
		if (userCompany && !empresaCoincide) {
			return false;
		}

		// Verificar permisos por departamento
		const redirigiraStr = pregunta?.redirigira || '';
		const depto = redirigiraStr.includes(' - ')
			? redirigiraStr.split(' - ').pop()
			: '';
		const permisoDepto = permissions[depto] || {};

		// Solo mostrar si tiene al menos un permiso true
		return permisoDepto.ver || permisoDepto.editar || permisoDepto.enviar;
	});

	return (
		<div className={styles.container}>
			<table className={styles.table}>
				<thead className={styles.header}>
					<tr>
						<th className={styles.headerCell}>
							{t('preguntasTable.idPregunta')}
						</th>
						<th className={styles.headerCell}>{t('preguntasTable.empresa')}</th>
						<th className={styles.headerCell}>{t('preguntasTable.proceso')}</th>
						<th className={styles.headerCell}>
							{t('preguntasTable.departamento')}
						</th>
						<th className={styles.headerCell}>
							{t('preguntasTable.fechaRedirigida')}
						</th>
						<th className={styles.headerCell}>
							{t('preguntasTable.acciones')}
						</th>
					</tr>
				</thead>
				<tbody>
					{!preguntasFiltradas || preguntasFiltradas.length === 0 ? (
						<tr>
							<td
								colSpan='6'
								className={styles.noData}>
								{t('preguntasTable.sinPreguntas')}
							</td>
						</tr>
					) : (
						preguntasFiltradas.map((pregunta) => {
							const redirigiraStr = pregunta?.redirigira || '';
							const depto = redirigiraStr.includes(' - ')
								? redirigiraStr.split(' - ').pop()
								: '';
							const permisoDepto = permissions[depto] || {};

							return (
								<tr
									key={pregunta.id}
									className={styles.row}>
									<td className={styles.cell}>{pregunta.preguntasid}</td>
									<td className={styles.cell}>
										{pregunta.empresa?.nombreEmpresa || ''}
									</td>
									<td className={styles.cell}>
										{pregunta.proceso?.nombre || ''}
									</td>
									<td className={styles.cell}>{redirigiraStr}</td>
									<td className={styles.cell}>
										{pregunta.redirigidafecha
											? new Date(pregunta.redirigidafecha).toLocaleDateString()
											: ''}
									</td>
									<td className={styles.cell}>
										<div className={styles.buttonContainer}>
											{permisoDepto.editar ? (
												<button
													onClick={() =>
														handleAnswerQuestion(
															navigate,
															pregunta.preguntasid,
															pregunta.empresasid,
															pregunta.procesosid,
															pregunta.id
														)
													}
													className={`${styles.button.base} ${styles.button.primary}`}>
													{t('preguntasTable.contestar')}
												</button>
											) : (
												<button
													disabled
													className={`${styles.button.base} ${styles.button.disabled}`}>
													{t('preguntasTable.contestar')}
												</button>
											)}
										</div>
									</td>
								</tr>
							);
						})
					)}
				</tbody>
			</table>
		</div>
	);
}

export default PreguntasTable;
