import { useState } from "react";

const useExportarWord = () => {
  const [error, setError] = useState(null);

  const exportarWord = async (dataParaExportar) => {
    try {
      console.log("Exportando como PDF con detalles:", dataParaExportar);

      // Cambiamos la ruta al endpoint que genera PDF
      const response = await fetch("https://risincome.aicrumit.com/backend/api/exportar/word", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataParaExportar),
      });

      if (!response.ok) throw new Error("Error al generar el archivo PDF");

      // Cambiamos el tipo de archivo descargado a .pdf
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Reporte_${dataParaExportar[0]?.nombreEmpresa || 'SinNombre'}.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error al exportar documento PDF:", error);
      setError("Error al exportar documento PDF.");
    }
  };

  return { exportarWord, error };
};

export default useExportarWord;
