// frontend/src/components/Questions/DecimalQuestion.js
import React, { useState, useEffect } from 'react';
import BaseNumericInput from './BaseNumericInput';
import { t } from 'i18next';

const DecimalQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	// Estado local para el valor mostrado
	const [inputValue, setInputValue] = useState(
		respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
	);

	// Actualizar el valor local cuando cambian las respuestas
	useEffect(() => {
		setInputValue(
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
		);
	}, [respuestas, formulario.FormularioId, pregunta.id]);

	const handleChange = (value) => {
		setInputValue(value);
		handleEditorChange(pregunta.id, formulario.FormularioId, value);
	};

	const baseClasses = `
    w-full 
    max-w-xs 
    h-10 
    px-3 
    py-2 
    border 
    rounded-md 
    focus:outline-none 
    focus:ring-2 
    focus:ring-blue-500 
    transition-colors 
    duration-200
	
  `;

	const bgColorClass = pregunta.required ? 'bg-error/50' : 'bg-white';
	const borderColorClass = pregunta.required
		? 'border-error'
		: 'border-gray-300';
	const textColorClass = 'text-gray-700';
	const disabledClass =
		!canEdit || isRedirigida ? 'bg-gray-100 cursor-not-allowed' : '';

	// Desactiva completamente el autocompletado creando un nuevo input en cada render
	const uniqueId = `decimal-${formulario.FormularioId}-${pregunta.id}`;

	return (
		<div className='w-full rounded-lg'>
			{canEdit && !isRedirigida ? (
				<BaseNumericInput
					value={inputValue}
					onChange={handleChange}
					validationOptions={{
						allowDecimals: true,
						allowNegative: true,
						allowComma: true,
					}}
					className={`
						${baseClasses}
						${bgColorClass}
						${borderColorClass}
						${textColorClass}
					`}
					placeholder={t('decimalQuestionPlaceholder')}
					createUniqueId={uniqueId}
				/>
			) : (
				// Versión de solo lectura - usar un div en lugar de input
				<div
					className={`
						${baseClasses}
						${bgColorClass}
						${borderColorClass}
						${textColorClass}
						${disabledClass}
					`}>
					{inputValue || 'Sin valor'}
				</div>
			)}
		</div>
	);
};

export default DecimalQuestion;
