import { ROUTE_AUTH_LOGOUT } from '../config/routeConfig';
import { debugError } from '../utils/debugHelper';
import { fetchWithAuth } from '../utils/fetchUtils';

//frontend/src/utils/navBarHelpers.js
export const handleLogout = async (setSelectedOption, navigate) => {
	try {
		const response = await fetchWithAuth(ROUTE_AUTH_LOGOUT, {
			method: 'POST',
		});

		if (response.ok) {
			setSelectedOption(null);
			navigate('/');
			window.location.reload();
		}
	} catch (error) {
		debugError('Error en la solicitud de logout:', error);
	}
};

export const getHasESGPermissions = (area, permissions) =>
	Array.isArray(area) &&
	area.includes('ESG') &&
	permissions &&
	Object.values(permissions).some((perm) => perm.ver && perm.editar);

export const getFilteredOptions = (area, hasESGPermissions, areaOptions) =>
	Array.isArray(area)
		? Array.from(
				new Set(
					area.flatMap((a) => {
						const options = [...(areaOptions[a] || [])];
						if (a === 'ESG' && hasESGPermissions) {
							options.push('processes');
						}
						return options;
					})
				)
		  )
		: areaOptions[area] || [];
