import React from 'react';
import useUserLanguage from '../hooks/useUserLanguage';
import useUserPermissions from '../hooks/useUserPermissions';
import styles from './PreguntasTable.module.css';

function PreguntasTable({ preguntas, handleAnswerQuestion }) {
  const { language } = useUserLanguage();
  const { permissions } = useUserPermissions();

  // Traducciones para las etiquetas de la tabla y botones
  const labels = {
    idPregunta: language === 'EN' ? 'Question ID' : 'ID Pregunta',
    empresa: language === 'EN' ? 'Company' : 'Empresa',
    proceso: language === 'EN' ? 'Process' : 'Proceso',
    departamento: language === 'EN' ? 'Department' : 'Departamento',
    fechaRedirigida: language === 'EN' ? 'Redirected Date' : 'Fecha Redirigida',
    acciones: language === 'EN' ? 'Actions' : 'Acciones',
    contestar: language === 'EN' ? 'Answer' : 'Contestar',
    sinPreguntas: language === 'EN' ? 'No questions found.' : 'No se encontraron preguntas.',
  };

  return (
    <table className={styles.preguntasTable}>
      <thead>
        <tr>
          <th>{labels.idPregunta}</th>
          <th>{labels.empresa}</th>
          <th>{labels.proceso}</th>
          <th>{labels.departamento}</th>
          <th>{labels.fechaRedirigida}</th>
          <th>{labels.acciones}</th>
        </tr>
      </thead>
      <tbody>
        {preguntas.length === 0 ? (
          <tr>
            <td colSpan="6">{labels.sinPreguntas}</td>
          </tr>
        ) : (
          preguntas.map((pregunta) => {
            // Extracción del nombre del departamento exacto para que coincida con permissions
            const depto = (pregunta.redirigira || '').split(' - ').pop(); // toma la última parte después de '-'
            const permisoDepto = permissions[depto] || {}; // Obtener permisos específicos del departamento

            // Console logs para depuración
            console.log("Departamento redirigido:", depto);
            console.log("Permisos del departamento:", permisoDepto);
            console.log("Permiso de edición:", permisoDepto.editar);

            return (
              <tr key={pregunta.id}>
                <td>{pregunta.preguntasid}</td>
                <td>{pregunta.empresa?.nombreEmpresa}</td>
                <td>{pregunta.proceso?.nombre}</td>
                <td>{pregunta.redirigira}</td>
                <td>{new Date(pregunta.redirigidafecha).toLocaleDateString()}</td>
                <td>
                  {/* Solo mostrar el botón "Contestar" si el permiso de edición está habilitado */}
                  {permisoDepto.editar ? (
                    <button
                      onClick={() => handleAnswerQuestion(pregunta.preguntasid, pregunta.empresasid, pregunta.procesosid, pregunta.id)}
                    >
                      {labels.contestar}
                    </button>
                  ) : (
                    <span>{labels.contestar}</span> // Opción deshabilitada
                  )}
                </td>
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default PreguntasTable;
