import React, { useState, useEffect } from 'react';
import TablaProcesos from '../../componentes/TablaProcesos';
import useUserCompany from '../../hooks/useUserCompany';
import useUserId from '../../hooks/useUserId';
import useUserLanguage from '../../hooks/useUserLanguage'; // Importa el hook de idioma
import './ListaDeProcesos.css';

function ListaDeProcesos() {
  const [procesos, setProcesos] = useState([]);
  const [error, setError] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  // Obtén el nombre de la empresa, el userId y el idioma del usuario
  const { empresa, error: empresaError } = useUserCompany();
  const { userId, error: userIdError } = useUserId();
  const { language, error: languageError } = useUserLanguage();

  // Definir etiquetas traducidas según el idioma
  const labels = {
    yearSelect: language === 'EN' ? 'Select a year:' : 'Selecciona un año:',
    yearPlaceholder: language === 'EN' ? 'Choose a year' : 'Seleccione un año',
    activateAllButton: language === 'EN' ? 'Activate All Processes' : 'Activar Todos los Procesos',
    noProcessesFound: language === 'EN' ? 'No processes found.' : 'No se encontraron procesos.',
    pageTitle: language === 'EN' ? 'Requirements for the year' : 'Requisitos del año',
  };

  const handleActivateAll = async () => {
    if (!selectedYear) {
      alert(labels.yearSelect);
      return;
    }

    if (!empresa) {
      alert('No se ha encontrado el nombre de la empresa.');
      return;
    }

    if (!userId) {
      alert('No se ha encontrado el ID del usuario.');
      return;
    }

    try {
      for (let proceso of procesos) {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/empresas/create-with-forms', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            nombreEmpresa: empresa,
            procesosId: proceso.id,
            userId: userId,
            anyo: selectedYear,
          }),
        });

        if (!response.ok) {
          console.error(`Error al crear la empresa para el proceso ${proceso.id}`);
        }
      }

      alert(labels.activateAllButton);
    } catch (error) {
      console.error('Error al activar todos los procesos:', error);
      setError('Error al activar todos los procesos.');
    }
  };

  useEffect(() => {
    const fetchProcesos = async () => {
      try {
        console.log("Intentando obtener los procesos desde el servidor...");
        const response = await fetch(`https://risincome.aicrumit.com/backend/api/procesos?year=${selectedYear}`, {
          method: 'GET',
          credentials: 'include',
        });
        
        if (!response.ok) throw new Error('Error al cargar los procesos');

        const data = await response.json();
        if (Array.isArray(data)) {
          const sortedData = data.sort((a, b) => a.id - b.id);
          setProcesos(sortedData);
        } else {
          setError('No se encontraron procesos válidos.');
        }
      } catch (error) {
        setError('Error al cargar los procesos.');
        console.error('Error al cargar los procesos:', error);
      }
    };

    if (selectedYear) {
      fetchProcesos();
    }
  }, [selectedYear]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    setSelectedYear(selectedYear);
    localStorage.setItem('selectedYear', selectedYear);
  };

  return (
    <div className="process-list-container">
      <div className="year-select-container">
        <div className="year-select-wrapper">
          <label htmlFor="year-select">{labels.yearSelect}</label>
          <select id="year-select" onChange={handleYearChange} value={selectedYear || ''}>
            <option value="" disabled>{labels.yearPlaceholder}</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
      </div>

      {selectedYear && (
        <div className="process-list-content">
          <h1>{`${labels.pageTitle} ${selectedYear}`}</h1>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {empresaError && <p style={{ color: 'red' }}>{empresaError}</p>}
          {userIdError && <p style={{ color: 'red' }}>{userIdError}</p>}
          {languageError && <p style={{ color: 'red' }}>{languageError}</p>}
          {procesos.length > 0 ? (
            <>
              <div className="activate-all-button-container">
                <button onClick={handleActivateAll} className="activate-all-button">
                  {labels.activateAllButton}
                </button>
              </div>
              <TablaProcesos procesos={procesos} />
            </>
          ) : (
            <p>{labels.noProcessesFound}</p>
          )}
        </div>
      )}
    </div>
  );
}

export default ListaDeProcesos;
