// frontend/src/styles/deleteUserStyles.js
export const deleteUserStyles = {
	container: 'max-w-6xl mx-auto py-8 px-4',
	card: 'bg-white rounded-xl shadow-lg overflow-hidden',
	header: 'bg-gradient-to-r from-error to-error/75 p-6 ',
	form: 'w-full max-w-lg mx-auto p-8',
	formGroup: 'space-y-6',
	input:
		'w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-error focus:border-error transition-all duration-200',
	label: 'block text-fondo text-lg font-medium mb-4 text-center',
	button:
		'bg-white hover:bg-error text-red-600 px-6 rounded-lg font-medium transition-all duration-200 shadow-lg hover:shadow-xl flex items-center justify-center min-w-[160px]',
	titleContainer: 'text-center mb-8',
	titleText: 'text-4xl font-bold text-error mb-2',
	titleUnderline:
		'h-1 w-24 bg-gradient-to-r from-error to-transparent mx-auto rounded-full',
	errorMessage:
		'bg-error/30 border-l-4 border-error text-error p-4 mx-6 my-4 rounded',
	errorText: 'text-sm font-medium',
	successMessage:
		'bg-success/20 border-l-4 border-success text-success p-4 mx-6 my-4 rounded',
	successText: 'text-sm font-medium',
	loader: 'flex justify-center items-center min-h-screen',
	loaderSpinner:
		'animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto',
	loaderText: 'mt-4 text-gray-600',
	errorContainer: 'p-4 bg-error/30 border-l-4 border-error text-error',
	inputGroup: 'flex space-x-4',
};
