// src/utils/debugHelper.js

/**
 * Activa o desactiva globalmente todos los mensajes de depuración
 * Cambiar a true cuando necesites depurar y a false en producción
 */
export const DEBUG_MODE = false;

/**
 * Función de log para mensajes de depuración generales
 * @param {string} message - Mensaje descriptivo
 * @param {any} data - Datos a mostrar (opcional)
 */
export const debug = (message, data) => {
	if (DEBUG_MODE) {
		if (data !== undefined) {
			console.log(message, data);
		} else {
			console.log(message);
		}
	}
};

/**
 * Función de log para mensajes de advertencia
 * @param {string} message - Mensaje descriptivo
 * @param {any} data - Datos a mostrar (opcional)
 */
export const debugWarn = (message, data) => {
	if (DEBUG_MODE) {
		if (data !== undefined) {
			console.warn(message, data);
		} else {
			console.warn(message);
		}
	}
};

/**
 * Función de log para mensajes de error
 * Estos logs se muestran incluso si DEBUG_MODE está desactivado
 * @param {string} message - Mensaje descriptivo
 * @param {any} data - Datos a mostrar (opcional)
 */
export const debugError = (message, data) => {
	// Los errores siempre se muestran por seguridad
	if (data !== undefined) {
		console.error(message, data);
	} else {
		console.error(message);
	}
};
