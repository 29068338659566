import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Casos.module.css';
import Sidebar from '../../componentes/Sidebar';
import CasosTable from '../../componentes/CasosTable';
import PreguntasTable from '../../componentes/PreguntasTable';
import useUserPermissions from '../../hooks/useUserPermissions'; // Hook para obtener permisos

function Casos() {
  const [casos, setCasos] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Pendiente');

  const { permissions } = useUserPermissions(); // Permisos del usuario
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [selectedOption]);

  const fetchData = () => {
    const url =
      selectedOption === 'Redirigida'
        ? 'https://risincome.aicrumit.com/backend/api/respuestas/redirigidas'
        : 'https://risincome.aicrumit.com/backend/api/empresas/list';

    console.log("URL de fetch:", url);

    fetch(url)
      .then((response) => {
        if (!response.ok) throw new Error('Error al obtener los casos');
        return response.json();
      })
      .then((data) => {
        if (selectedOption === 'Redirigida') {
          console.log("Datos de preguntas redirigidas recibidos:", data);
          const userDepartments = Object.keys(permissions);
          console.log("Departamentos del usuario según permisos:", userDepartments);

          // Filtra las preguntas redirigidas para que coincidan solo con los departamentos específicos del usuario
          const filteredQuestions = data.filter((pregunta) => {
            const isRedirected = pregunta.estadopregunta === 'Redirigida';
            const matchesDepartment = userDepartments.some(
              (dept) => pregunta.redirigira.includes(dept.trim()) // Verificación por inclusión
            );
            console.log(
              `Pregunta ID: ${pregunta.id}, Departamento redirigido: ${pregunta.redirigira}, ` +
              `Estado redirigido: ${isRedirected}, Coincide con departamento usuario: ${matchesDepartment}`
            );
            return isRedirected && matchesDepartment;
          });

          console.log("Preguntas redirigidas filtradas según permisos:", filteredQuestions);
          setPreguntas(filteredQuestions);
        } else {
          console.log("Datos de casos recibidos:", data);
          const filteredCases = filterCasesByDateAndName(data, selectedOption);
          setCasos(filteredCases);
        }
      })
      .catch((error) => {
        setError('No se pudieron cargar los datos.');
        console.error('Fetch Error:', error);
      });
  };

  // Filtrar casos por fecha y nombre y hora,minutos
  const filterCasesByDateAndName = (data, selectedOption) =>
    data
      .filter((caso) => caso.estadoEmpresas === selectedOption)
      .filter((caso, index, self) => {
        const casoDate = new Date(caso.fechaCreacion).getTime();
        return (
          index ===
          self.findIndex((t) => {
            const tDate = new Date(t.fechaCreacion).getTime();
            // Mismo nombre de empresa, proceso y dentro de los 5 segundos de intervalo
            return (
              t.nombreEmpresa === caso.nombreEmpresa &&
              t.proceso?.nombre === caso.proceso?.nombre &&
              Math.abs(tDate - casoDate) <= 2000
            );
          })
        );
      });

  const handleFilterChange = (status) => setSelectedOption(status);

  const handleAnswerQuestion = (preguntasId, empresaId, procesoId, respuestaId) => {
    navigate(`/process/${empresaId}/form/${procesoId}/question/${preguntasId}?respuestaId=${respuestaId}`);
  };

  const handleFillForm = (FormularioId, empresaId, procesosId) => {
    console.log('Datos al hacer clic en "Rellenar Requisito":', { FormularioId, empresaId, procesosId });
    navigate(`/process/${FormularioId}/forms/${empresaId}`);
  };

  return (
    <div className={styles.casosPage}>
      <div className={styles.layout}>
        <Sidebar onFilterChange={handleFilterChange} selectedOption={selectedOption} />
        <div className={styles.casosContainer}>
          <h1>{selectedOption === 'Redirigida' ? 'Preguntas Redirigidas' : 'Casos'}</h1>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {selectedOption === 'Redirigida' ? (
            <PreguntasTable preguntas={preguntas} handleAnswerQuestion={handleAnswerQuestion} />
          ) : (
            <CasosTable casos={casos} handleFillForm={handleFillForm} selectedOption={selectedOption} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Casos;
