import React, { useState, useEffect } from 'react';
import './DashboardSystem.css';
import useUserLanguage from '../../hooks/useUserLanguage'; // Importa el hook para el idioma

function DashboardSystem() {
  const { language } = useUserLanguage(); // Obtiene el idioma del usuario
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const usersPerPage = 10;

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchUsers = async (page) => {
    try {
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/users?page=${page}&limit=${usersPerPage}`, {
        method: 'GET',
        credentials: 'include',
      });
      const data = await response.json();

      if (Array.isArray(data.users)) {
        setUsers(data.users);
        setTotalPages(Math.ceil(data.total / usersPerPage));
      } else {
        console.error('La respuesta de la API no contiene un array de usuarios:', data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
      setUsers([]);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Objeto de traducción para etiquetas de tabla
  const labels = {
    title: language === 'EN' ? 'User List' : 'Listado de Usuarios',
    id: language === 'EN' ? 'ID' : 'ID',
    nombre: language === 'EN' ? 'Name' : 'Nombre',
    apellidos: language === 'EN' ? 'Last Name' : 'Apellidos',
    email: language === 'EN' ? 'Email' : 'Email',
    username: language === 'EN' ? 'Username' : 'Username',
    area: language === 'EN' ? 'Area' : 'Área',
    departamentos: language === 'EN' ? 'Departments' : 'Departamentos',
    idioma: language === 'EN' ? 'Language' : 'Idioma',
    sinDepartamentos: language === 'EN' ? 'No assigned departments' : 'Sin departamentos asignados',
    ver: language === 'EN' ? 'View' : 'Ver',
    editar: language === 'EN' ? 'Edit' : 'Editar',
    enviar: language === 'EN' ? 'Send' : 'Enviar',
  };

  return (
    <div className="dashboard-system">
      <h2 className="dashboard-title">{labels.title}</h2>

      <div className="table-container">
        <table className="user-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>{labels.id}</th>
              <th>{labels.nombre}</th>
              <th>{labels.apellidos}</th>
              <th>{labels.email}</th>
              <th>{labels.username}</th>
              <th>{labels.area}</th>
              <th>{labels.departamentos}</th>
              <th>{labels.idioma}</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.nombre}</td>
                <td>{user.apellidos}</td>
                <td>{user.email}</td>
                <td>{user.username}</td>
                <td>{user.area}</td>
                <td>
                  {Array.isArray(user.departamentos) ? (
                    user.departamentos
                      .filter(department =>
                        department.ver === "Sí" || 
                        department.editar === "Sí" || 
                        department.enviar === "Sí"
                      )
                      .map((department, index) => (
                        <div key={index}>
                          <strong>{department.nombre}</strong>:
                          <ul>
                            <li>{labels.ver}: {department.ver}</li>
                            <li>{labels.editar}: {department.editar}</li>
                            <li>{labels.enviar}: {department.enviar}</li>
                          </ul>
                        </div>
                      ))
                  ) : (
                    labels.sinDepartamentos
                  )}
                </td>
                <td>{user.idioma}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
}

export default DashboardSystem;
