// frontend/src/styles/questionStyles.js
export const questionStyles = {
	// Estilos comunes para contenedores de preguntas
	container: 'w-full p-4 bg-fondo rounded-lg',

	// Estilos para los inputs
	input: {
		base: `
        w-full 
        max-w-xs 
        h-10 
        px-3 
        py-2 
        border 
        rounded-md 
        focus:outline-none 
        focus:ring-2 
        focus:ring-primario 
        transition-colors 
        duration-200
      `,
		required: 'bg-error/30 border-red-300',
		optional: 'bg-fondo border-gray-300',
		disabled: 'bg-error/50 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para mensajes de error o validación
	validation: {
		error: 'text-red-500 text-sm mt-1',
		success: 'text-success text-sm mt-1',
	},

	// Estilos específicos para tipos de pregunta
	numeric: {
		container: 'flex items-center',
		unit: 'ml-2 text-gray-600',
	},

	// Estilos para selects y dropdowns
	select: {
		container: 'relative',
		base: `
        w-full 
        max-w-xs 
        h-10 
        px-3 
        py-2 
        border 
        rounded-md 
        focus:outline-none 
        focus:ring-2 
        focus:ring-primario 
        transition-colors 
        duration-200
        appearance-none
        bg-secundario/10
      `,
		arrow:
			'absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none',
	},

	// Estilos para tablas (versión Tailwind)
	table: {
		container: 'overflow-x-auto',
		table: 'min-w-full divide-y divide-gray-200',
		thead: 'bg-gray-50',
		th: 'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
		tbody: 'bg-secundario/10 divide-y divide-gray-200',
		td: 'px-6 py-4 whitespace-nowrap text-sm text-gray-500',
		checkboxCell: 'w-16 text-center',
		headerTop: 'bg-blue-50 text-center font-bold p-3',
		header: 'bg-gray-50 p-3 font-semibold',
		cell: 'p-3 border',
		pillarCell: 'max-w-xs',
		requisitosCell: 'max-w-sm',
		subtemaCell: 'flex flex-col space-y-1',
		subtemaItem: 'flex items-start',
		bullet: 'mr-1 ',
	},

	// Estilos para opciones de materialidad (versión Tailwind)
	materialityOptions: {
		container: 'mb-4',
		radioGroup: 'flex flex-wrap gap-4',
		radioLabel:
			'flex items-center cursor-pointer p-2 rounded transition-colors duration-200 hover:bg-gray-100',
		radioLabelChecked: 'bg-blue-50 border border-blue-200',
		radioInput: 'mr-2',
		radioText: 'text-gray-700',
	},

	// Estilos para botones dentro de los componentes
	button: {
		base: `
        px-4 
        py-2 
        rounded-md 
        focus:outline-none 
        focus:ring-2 
        focus:ring-primario
        transition-colors 
        duration-200
      `,
		primary: 'bg-blue-500 text-white hover:bg-blue-600',
		secondary: 'bg-gray-200 text-gray-800 hover:bg-gray-300',
		success: 'bg-success text-white hover:bg-green-600',
		danger: 'bg-error text-white hover:bg-red-600',
		disabled: 'bg-red-300 text-gray-500 cursor-not-allowed',
	},

	// Estilos para preguntas tipo boolean
	boolean: {
		container: 'w-full space-y-4',
		radioGroup: 'flex space-x-6 mb-4',
		radioOption: 'flex items-center space-x-2',
		radioInput: 'h-4 w-4  focus:ring-primario',
		radioLabel: 'text-gray-700',
		editor: 'w-full border border-gray-300 rounded-md',
		requiredField: 'border-red-300 bg-error',
	},

	// Estilos para preguntas condicionales
	conditional: {
		container: 'w-full space-y-4',
		select: `
			w-full 
			max-w-md 
			h-10 
			px-3 
			py-2 
			border 
			border-gray-300 
			rounded-md 
			focus:outline-none 
			focus:ring-2 
			focus:ring-primario 
			transition-colors 
			duration-200 
			bg-secundario/10
		`,
		selectDisabled: 'bg-gray-100 cursor-not-allowed',
		label: 'block text-sm font-medium text-gray-700 mb-2',
		labelAboveSelect: 'mt-4 mb-2 font-medium',
	},

	// Estilos para preguntas de energía
	energy: {
		container: 'w-full rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas de emisiones de gases de efecto invernadero
	ghgEmissions: {
		container: 'w-full rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas de intensidad
	intensity: {
		container: 'w-full rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas de masa
	mass: {
		container: 'w-full  rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas de volumen
	volume: {
		container: 'w-full  rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas de porcentaje
	percent: {
		container: 'w-full rounded-lg relative',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
		symbol: 'ml-2 px-3 py-1 bg-primario text-white font-medium rounded-md ',
	},

	// Estilos para preguntas numéricas
	numerical: {
		container: 'w-full bg-secundario/0 rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
	},

	// Estilos para preguntas monetarias
	monetary: {
		container: 'w-full bg-secundario/0 rounded-lg',
		inputContainer: 'flex items-center',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
		symbolButton: {
			base: 'px-3 py-1 text-sm rounded ml-2 transition-colors duration-200',
			active: 'bg-primario text-white font-medium',
			inactive: 'bg-secundario text-white hover:bg-primario',
			disabled: 'opacity-50 cursor-not-allowed',
			enabled: 'cursor-pointer',
		},
	},

	// Estilos para preguntas de año (GYear)
	gYear: {
		container: 'w-full  rounded-lg',
		input:
			'w-full max-w-xs h-10 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primario transition-colors duration-200',
		required: 'bg-error/50 border-red-300',
		optional: 'bg-secundario/10 border-gray-300',
		disabled: 'bg-gray-100 cursor-not-allowed',
		text: 'text-gray-700',
		errorMessage: 'text-red-500 text-sm mt-1',
	},

	// Versión JavaScript de tableStyles para compatibilidad
	tableJS: {
		tableContainer: `overflow-x-auto mt-5 rounded-lg shadow-sm`,
		table: `w-full border-collapse text-sm`,
		tableHeaderTop: `bg-secundario p-3 text-center text-white font-bold border-b border-r border-gray-300`,
		tableHeader: `bg-secundario/70 text-white p-2 font-semibold border-b border-r border-gray-300 text-center `,
		tableCell: `p-2 border-b  bg-fondo hover:bg-primario/20 transition-colors duration-200`,
		subtemaCell: `flex flex-col gap-1`,
		subtemaItem: `flex items-start`,
		bullet: `mr-1 text-secundario`,
		pillarCell: `max-w-[150px]`,
		requisitosCell: `max-w-[250px] hover:bg-primario/20 transition-colors duration-200`,
		checkboxCell: `w-27 text-center flex items-center self-center justify-center py-5`,
		checkbox: `cursor-pointer  w-5 h-5 self-centerrounded focus:ring-secundario accent-secundario`,
		materialityOptions: `mb-4`,
		radioGroup: `flex flex-wrap gap-3`,
		radioLabel: `flex items-center cursor-pointer p-2 rounded transition-colors hover:bg-primario/20`,
		radioLabelChecked: `bg-primario/20`,
		radioInput: `mr-2 accent-primario `,
		radioText: `text-gray-700`,
	},
};
