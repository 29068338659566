import { useCallback } from 'react';
import { ROUTE_NOTIFICATIONS_REDIRECTION } from '../../config/routeConfig';
import { debug, debugError } from '../../utils/debugHelper';
import { fetchWithAuth } from '../../utils/fetchUtils';

function useEnviarNotificacionRedireccion() {
	const enviarCorreo = useCallback(
		async (empresa, area, departamento, preguntaTexto, language = 'ES') => {
			try {
				// Log detallado
				debug('Datos recibidos para envío de correo:', {
					empresa,
					area,
					departamento,
					preguntaTexto,
					tipoPreguntaTexto: typeof preguntaTexto,
					language,
				});

				// Validar que preguntaTexto sea un string
				const textoParaEnviar =
					typeof preguntaTexto === 'string'
						? preguntaTexto
						: 'Pregunta redirigida';

				debug('Texto final para enviar:', textoParaEnviar);

				// Construir el payload simplificado
				const payload = {
					empresa,
					area,
					departamento,
					preguntaTexto: textoParaEnviar,
				};

				debug('Payload para envío de correo:', payload);

				const response = await fetchWithAuth(
					`${ROUTE_NOTIFICATIONS_REDIRECTION}`,
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify(payload),
					}
				);

				if (!response.ok) {
					const errorText = await response.text();
					debugError('Error en la respuesta del servidor:', errorText);
					throw new Error('Error al enviar la notificación por correo');
				}

				debug('Correo de notificación enviado con éxito');
				return { success: true, message: 'Notificación enviada correctamente' };
			} catch (error) {
				debugError('Error al enviar notificación por correo:', error);
				// No propagar el error para que no afecte el proceso de redirección
				return { success: false, error: error.message || 'Error desconocido' };
			}
		},
		[]
	);

	return { enviarCorreo };
}

export default useEnviarNotificacionRedireccion;
