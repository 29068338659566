import { useState, useEffect } from 'react';
import {
	ROUTE_COMPANY_LIST,
	ROUTE_RESPONSES_REDIRECTED,
} from '../config/routeConfig';
import { debug, debugError } from '../utils/debugHelper';
import useUserCompany from './user/useUserCompany';
import { fetchWithAuth } from '../utils/fetchUtils';
import { isViewOnlyUser } from '../utils/permissionsUtils'; // Añadimos esta importación

export const useCasosData = (selectedOption, permissions) => {
	const [casos, setCasos] = useState([]);
	const [preguntas, setPreguntas] = useState([]);
	const [error, setError] = useState(null);
	const { empresa: userCompany } = useUserCompany();

	useEffect(() => {
		if (userCompany) {
			fetchData();
		}
	}, [selectedOption, userCompany]);

	const fetchData = async () => {
		try {
			let url = '';

			if (selectedOption === 'Redirigida') {
				// Construir URL con el parámetro de empresa
				url = `${ROUTE_RESPONSES_REDIRECTED}?empresa=${encodeURIComponent(
					userCompany
				)}`;
				debug('useCasosData - URL con filtro de empresa:', url);
			} else {
				url = ROUTE_COMPANY_LIST;
			}

			debug('useCasosData - URL de fetch:', url);

			// Reemplazamos fetch por fetchWithAuth
			const response = await fetchWithAuth(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
				},
			});

			if (!response.ok) throw new Error('Error al obtener los datos');

			const data = await response.json();
			debug(
				'useCasosData - Datos recibidos:',
				data.length ? data.length : data
			);

			// Comprobar si el usuario solo tiene permisos de visualización
			const isViewOnly = isViewOnlyUser(permissions);

			if (selectedOption === 'Redirigida') {
				// Si es usuario de solo visualización, no debería ver preguntas redirigidas
				if (isViewOnly) {
					setPreguntas([]);
					return;
				}

				const userDepartments = Object.keys(permissions);
				debug('useCasosData - Departamentos del usuario:', userDepartments);

				const filteredQuestions = data.filter((pregunta) => {
					const isRedirected = pregunta.estadopregunta === 'Redirigida';
					const matchesDepartment = userDepartments.some((dept) =>
						pregunta.redirigira?.includes(dept.trim())
					);
					return isRedirected && matchesDepartment;
				});

				debug('useCasosData - Preguntas filtradas:', filteredQuestions.length);
				setPreguntas(filteredQuestions);
			} else {
				// Si es usuario de solo visualización y está intentando ver opciones no permitidas
				if (
					isViewOnly &&
					(selectedOption === 'Pendiente' || selectedOption === 'Revision')
				) {
					setCasos([]);
					return;
				}

				const filteredCases = filterCasesByDateAndName(data, selectedOption);
				debug('useCasosData - Casos filtrados:', filteredCases.length);
				setCasos(filteredCases);
			}
		} catch (error) {
			setError('No se pudieron cargar los datos.');
			debugError('useCasosData - Fetch Error:', error);
		}
	};

	const filterCasesByDateAndName = (data, selectedOption) => {
		// Primero filtrar por estado
		const filteredByState = data.filter(
			(caso) => caso.estadoEmpresas === selectedOption
		);

		// Agrupar por empresa, proceso y año
		const gruposPorEmpresaProcesoAnyo = {};

		filteredByState.forEach((caso) => {
			// Crear una clave única para cada combinación de empresa, proceso y año
			const clave = `${caso.nombreEmpresa}-${caso.proceso?.nombre || ''}-${
				caso.anyo || ''
			}`;

			// Si no existe el grupo o encontramos un ID menor, actualizar el caso para ese grupo
			if (
				!gruposPorEmpresaProcesoAnyo[clave] ||
				caso.id < gruposPorEmpresaProcesoAnyo[clave].id
			) {
				gruposPorEmpresaProcesoAnyo[clave] = caso;
			}
		});

		// Convertir de vuelta a un array
		return Object.values(gruposPorEmpresaProcesoAnyo);
	};

	return { casos, preguntas, error };
};
