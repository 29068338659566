// frontend/src/hooks/questions/useQuestionType/useTableQuestion.js
import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { tableConfigs } from '../../../config/questionConfig';
import { clearVisibilityCache } from '../../../utils/questionUtils';
import { debug, debugError } from '../../../utils/debugHelper';
import { t } from 'i18next';

export const useTableQuestion = (
	pregunta,
	formularioId,
	procesoId,
	respuestas,
	handleEditorChange,
	handleSubmit
) => {
	debug('useTableQuestion - Inicialización del hook');
	debug('  - pregunta:', pregunta.id);
	debug('  - formularioId:', formularioId);
	debug('  - procesoId:', procesoId);

	const tableKey = `${formularioId}-${procesoId}-${pregunta.id}`;
	debug('  - tableKey calculada:', tableKey);

	const isInfoOnlyTable = tableKey === '3-1-22';
	debug('  - isInfoOnlyTable:', isInfoOnlyTable);

	// Usar useMemo para la configuración de la tabla para evitar recálculos
	const tableConfig = useMemo(() => tableConfigs[tableKey], [tableKey]);
	debug('  - tableConfig obtenido:', tableConfig);

	// Mantener una referencia a las operaciones pendientes para gestionar actualizaciones por lotes
	const pendingUpdatesRef = useRef([]);

	// Función de procesamiento por lotes para actualizar múltiples preguntas de una vez
	const processBatchUpdates = useCallback(() => {
		debug(
			`⚙️ [processBatchUpdates] Procesando actualizaciones pendientes: ${pendingUpdatesRef.current.length}`
		);

		if (pendingUpdatesRef.current.length === 0) {
			debug(`  - No hay actualizaciones pendientes, finalizando`);
			return;
		}

		// Realizar todas las actualizaciones pendientes
		const updates = [...pendingUpdatesRef.current];
		pendingUpdatesRef.current = []; // Limpiar la cola

		debug(`  - Copiadas ${updates.length} actualizaciones para procesar`);
		debug(`  - Cola de pendientes limpiada`);

		// Agrupar actualizaciones por preguntaId para evitar actualizaciones redundantes
		const uniqueUpdates = updates.reduce((acc, update) => {
			// Usar un identificador único para cada combinación de pregunta y formulario
			const key = `${update.preguntaId}-${update.formularioId}`;
			acc[key] = update; // Sobrescribe con la actualización más reciente
			return acc;
		}, {});

		debug(
			`  - Actualizaciones únicas a procesar: ${
				Object.keys(uniqueUpdates).length
			}`
		);

		// Mostrar todas las actualizaciones que se van a procesar
		Object.values(uniqueUpdates).forEach((update) => {
			debug(
				`  - Pregunta ${update.preguntaId}: "${update.value.substring(0, 20)}${
					update.value.length > 20 ? '...' : ''
				}"`
			);
		});

		// Ejecutar las actualizaciones únicas
		Object.values(uniqueUpdates).forEach((update) => {
			debug(
				`  - Actualizando pregunta ${
					update.preguntaId
				} con valor: "${update.value.substring(0, 30)}..."`
			);

			try {
				handleEditorChange(
					update.preguntaId,
					update.formularioId,
					update.value
				);
				debug(`  - ✅ Actualización aplicada correctamente`);
			} catch (error) {
				debugError(
					`  - ❌ Error al actualizar pregunta ${update.preguntaId}:`,
					error
				);
			}

			// Verificar el estado después de la actualización
			setTimeout(() => {
				const currentValue =
					respuestas[`${update.formularioId}-${update.preguntaId}`]?.text;
				debug(
					`  - [+50ms] Valor actual para pregunta ${update.preguntaId}: "${
						currentValue?.substring(0, 30) || ''
					}"`
				);

				if (currentValue !== update.value) {
					debug(`  - ⚠️ El valor no se actualizó correctamente`);
					debug(`  - Esperado: "${update.value.substring(0, 30)}..."`);
					debug(`  - Actual: "${currentValue?.substring(0, 30) || ''}"`);
				}
			}, 50);
		});
	}, [handleEditorChange, respuestas]);

	// Añadir una actualización a la cola con opción de ejecutar inmediatamente
	const queueUpdate = useCallback(
		(preguntaId, formularioId, value, immediate = false) => {
			debug(`📋 [queueUpdate] Llamada para pregunta ${preguntaId}`);
			debug(
				`  - Valor a guardar: "${value.substring(0, 30)}${
					value.length > 30 ? '...' : ''
				}"`
			);
			debug(`  - ¿Actualización inmediata?: ${immediate}`);

			// Verificar si ya existe esta actualización pendiente
			const existingUpdateIndex = pendingUpdatesRef.current.findIndex(
				(update) =>
					update.preguntaId === preguntaId &&
					update.formularioId === formularioId
			);

			if (existingUpdateIndex !== -1) {
				debug(
					`  - Ya existe una actualización pendiente para esta pregunta, reemplazando`
				);
				pendingUpdatesRef.current[existingUpdateIndex].value = value;
			} else {
				debug(`  - Añadiendo nueva actualización a la cola`);
				pendingUpdatesRef.current.push({ preguntaId, formularioId, value });
			}

			debug(
				`  - Cola actual: ${pendingUpdatesRef.current.length} actualizaciones pendientes`
			);

			if (immediate || pendingUpdatesRef.current.length >= 10) {
				debug(
					`  - Procesando inmediatamente (immediate=${immediate}, queue=${pendingUpdatesRef.current.length})`
				);
				processBatchUpdates(); // Procesar inmediatamente si es urgente o hay muchas actualizaciones
			} else if (pendingUpdatesRef.current.length === 1) {
				// Si es la primera actualización en la cola, programar un procesamiento pronto
				debug(
					`  - Programando procesamiento en 16ms (primera actualización en cola)`
				);
				setTimeout(processBatchUpdates, 16); // ~1 frame (60fps)
			}
		},
		[processBatchUpdates]
	);

	// Limpiar cualquier actualización pendiente al desmontar
	useEffect(() => {
		return () => {
			if (pendingUpdatesRef.current.length > 0) {
				debug(
					`useEffect cleanup - Procesando ${pendingUpdatesRef.current.length} actualizaciones pendientes antes de desmontar`
				);
				processBatchUpdates();
			}
		};
	}, [processBatchUpdates]);

	// Pre-calcular las preguntas controladas por esta tabla
	const controlledQuestions = useMemo(() => {
		debug('useTableQuestion - Calculando preguntas controladas para', tableKey);

		if (!tableConfig) {
			debug('  - No hay tableConfig, retornando estructura vacía');
			return {
				byCheckbox: {},
				showOnNegative: null,
				all: [],
			};
		}

		// Si es tabla informativa, no tiene preguntas controladas
		if (isInfoOnlyTable) {
			debug('  - Es tabla informativa, no tiene preguntas controladas');
			return {
				byCheckbox: {},
				showOnNegative: null,
				all: [],
			};
		}

		// Para una tabla normal, organizar las preguntas controladas
		const byCheckbox = {};
		let showOnNegative = null;
		const all = [];

		// Pregunta mostrada cuando se selecciona "No es material"
		if (tableConfig.showConditions?.onNegative?.showQuestion) {
			showOnNegative =
				tableConfig.showConditions.onNegative.showQuestion.questionId;
			all.push(showOnNegative);
			debug(
				'  - Encontrada pregunta a mostrar cuando es "No es material":',
				showOnNegative
			);
		} else {
			debug('  - No se encontró configuración onNegative.showQuestion');
			debug('  - tableConfig.showConditions:', tableConfig.showConditions);
		}

		// Clasificar las preguntas controladas por índice de checkbox
		if (tableConfig.rows) {
			debug(
				'  - Procesando filas para obtener preguntas controladas por checkbox'
			);
			tableConfig.rows.forEach((row, index) => {
				const checkboxIndex = index + 1;
				if (!byCheckbox[checkboxIndex]) {
					byCheckbox[checkboxIndex] = [];
				}
			});
		}

		debug('  - Resultado final controlledQuestions:', {
			byCheckbox,
			showOnNegative,
			all,
		});
		return { byCheckbox, showOnNegative, all };
	}, [tableConfig, isInfoOnlyTable]);

	const [selectedTableOption, setSelectedTableOption] = useState('');
	const [showTable, setShowTable] = useState(isInfoOnlyTable); // Siempre true para la tabla informativa

	// REFACTORIZACIÓN 1: initialTableData
	// Función auxiliar para analizar la respuesta guardada
	const parseCheckedIndices = (savedResponse) => {
		if (!savedResponse || savedResponse === t('tableQuestion.informAll')) {
			return [];
		}

		const checksArray = [];
		if (savedResponse !== 'negative') {
			savedResponse.split(', ').forEach((item) => {
				const parts = item.split(' - ');
				if (parts.length === 2 && parts[1] === 'True') {
					const index = parseInt(parts[0]);
					if (!isNaN(index)) {
						checksArray.push(index);
					}
				}
			});
		}

		return checksArray;
	};

	// Función principal refactorizada
	const initialTableData = useMemo(() => {
		debug('useTableQuestion - Inicializando tableData');

		if (pregunta.questionType !== 'table' || !tableConfig?.rows) {
			debug('  - No es una pregunta tipo tabla o no tiene filas configuradas');
			return [];
		}

		const savedResponse = respuestas[`${formularioId}-${pregunta.id}`]?.text;
		debug(`  - Respuesta guardada para ${pregunta.id}: ${savedResponse}`);

		const checksArray = parseCheckedIndices(savedResponse);
		debug(`  - Índices marcados: ${checksArray.join(', ')}`);

		// Inicializar tableData con los checkboxes marcados según corresponda
		return tableConfig.rows.map((row, index) => ({
			...row,
			defaultChecked: checksArray.includes(index + 1),
		}));
	}, [
		pregunta.questionType,
		tableConfig?.rows,
		respuestas,
		formularioId,
		pregunta.id,
	]);

	const [tableData, setTableData] = useState(initialTableData);

	// Actualizar tableData cuando cambian los datos iniciales
	useEffect(() => {
		debug('useTableQuestion - useEffect para actualizar tableData');

		setTableData(initialTableData);
	}, [initialTableData]);

	// Función para encontrar preguntas afectadas por un checkbox específico (precalculada)
	const getQuestionsAffectedByCheckbox = useCallback(
		(rowIndex, formulario) => {
			debug(`getQuestionsAffectedByCheckbox - fila ${rowIndex}`);

			const checkboxIndex = rowIndex + 1;
			const affectedIds = controlledQuestions.byCheckbox[checkboxIndex] || [];

			const affectedQuestions = formulario.preguntas.filter(
				(p) =>
					p.conditional?.controlledBy === pregunta.id &&
					p.conditional?.checkboxIndex === checkboxIndex
			);

			debug(`  - Encontradas ${affectedQuestions.length} preguntas afectadas`);
			if (affectedQuestions.length > 0) {
				debug(
					'  - IDs de preguntas afectadas:',
					affectedQuestions.map((p) => p.id)
				);
			}
			return affectedQuestions;
		},
		[controlledQuestions.byCheckbox, pregunta.id]
	);

	// Función optimizada para encontrar la pregunta específica mostrada cuando es "No es material"
	const getNegativeOptionQuestion = useCallback(
		(formulario) => {
			debug('getNegativeOptionQuestion llamado');
			debug(
				'  - controlledQuestions.showOnNegative:',
				controlledQuestions.showOnNegative
			);

			if (!controlledQuestions.showOnNegative) {
				debug('  - No hay pregunta para mostrar en caso negativo');
				return null;
			}

			const question = formulario.preguntas.find(
				(p) => p.id === controlledQuestions.showOnNegative
			);

			debug('  - Pregunta encontrada:', question?.id);
			return question;
		},
		[controlledQuestions.showOnNegative]
	);

	// Actualizar lotes de preguntas controladas con el mismo valor
	const updateControlledQuestions = useCallback(
		(formulario, filter, value) => {
			debug('updateControlledQuestions llamado');
			debug(`  - valor a establecer: ${value}`);

			const matchingQuestions = formulario.preguntas.filter(filter);
			debug(
				`  - ${matchingQuestions.length} preguntas coincidentes encontradas`
			);

			if (matchingQuestions.length > 0) {
				debug(
					'  - IDs de preguntas a actualizar:',
					matchingQuestions.map((p) => p.id)
				);
			}

			matchingQuestions.forEach((p) => {
				queueUpdate(p.id, formularioId, value);
			});
		},
		[formularioId, queueUpdate]
	);

	// REFACTORIZACIÓN 2: handleCheckboxChange
	// Función auxiliar para obtener la respuesta formateada
	const getFormattedTableResponse = (
		tableData,
		rowIndex,
		newCheckedState,
		isInfoOnlyTable
	) => {
		const checkedIndices = [];
		tableData.forEach((row, idx) => {
			// Para el índice que cambió, usar el nuevo estado
			// Para los demás, usar el estado actual
			const isChecked = idx === rowIndex ? newCheckedState : row.defaultChecked;
			if (isChecked) {
				checkedIndices.push(idx + 1);
			}
		});

		if (checkedIndices.length === 0) {
			return isInfoOnlyTable ? '' : t('tableQuestion.informAll');
		}

		return checkedIndices.map((index) => `${index} - True`).join(', ');
	};

	// Función auxiliar para actualizar preguntas afectadas
	const updateAffectedQuestions = (
		affectedQuestions,
		newCheckedState,
		formularioId,
		queueUpdate
	) => {
		if (affectedQuestions.length === 0) return;

		debug(`  - Actualizando ${affectedQuestions.length} preguntas afectadas`);

		affectedQuestions.forEach((p) => {
			if (newCheckedState) {
				debug(`  - Limpiando contenido de pregunta ${p.id} (checkbox MARCADO)`);
				queueUpdate(p.id, formularioId, '');
			} else {
				debug(
					`  - Estableciendo contenido de pregunta ${p.id} a "Va a informar sobre este punto" (checkbox DESMARCADO)`
				);
				queueUpdate(
					p.id,
					formularioId,
					`<p>${t('tableQuestion.isInforming')}</p>`
				);
			}
		});
	};

	// Función principal refactorizada
	const handleCheckboxChange = useCallback(
		(rowIndex, formulario) => {
			debug(`🔲 [handleCheckboxChange] Cambio en checkbox de fila ${rowIndex}`);

			// Primero obtenemos el estado actual antes de cambiarlo
			const currentCheckedState = tableData[rowIndex].defaultChecked;
			// El nuevo estado será lo opuesto al actual
			const newCheckedState = !currentCheckedState;

			debug(
				`  - Estado actual: ${
					currentCheckedState ? 'MARCADO ✓' : 'DESMARCADO ✗'
				}`
			);
			debug(
				`  - Nuevo estado: ${newCheckedState ? 'MARCADO ✓' : 'DESMARCADO ✗'}`
			);

			// Actualizar el estado del checkbox
			setTableData((prevData) => {
				const newData = [...prevData];
				newData[rowIndex] = {
					...newData[rowIndex],
					defaultChecked: newCheckedState,
				};
				return newData;
			});

			// Obtener y actualizar la respuesta formateada
			const formattedResponse = getFormattedTableResponse(
				tableData,
				rowIndex,
				newCheckedState,
				isInfoOnlyTable
			);
			queueUpdate(pregunta.id, formularioId, formattedResponse, true); // Inmediata

			// Actualizar preguntas afectadas si no es tabla informativa
			if (!isInfoOnlyTable) {
				debug('  - Buscando preguntas afectadas por este checkbox');
				const affectedQuestions = getQuestionsAffectedByCheckbox(
					rowIndex,
					formulario
				);
				updateAffectedQuestions(
					affectedQuestions,
					newCheckedState,
					formularioId,
					queueUpdate
				);
			}
		},
		[
			tableData,
			isInfoOnlyTable,
			pregunta.id,
			formularioId,
			getQuestionsAffectedByCheckbox,
			queueUpdate,
		]
	);

	// REFACTORIZACIÓN 3: handleTableOptionChange
	// Función auxiliar para limpiar el caché de visibilidad
	const clearCacheWithDelay = () => {
		if (typeof clearVisibilityCache !== 'function') {
			debug('  - ⚠️ clearVisibilityCache no está disponible como función');
			return;
		}

		debug('  - Limpiando caché de visibilidad para forzar reevaluación');
		clearVisibilityCache();

		// Programar limpiezas adicionales
		setTimeout(() => {
			debug('  - Limpieza del caché secundaria (después de 100ms)');
			clearVisibilityCache();

			setTimeout(() => {
				debug('  - Limpieza del caché terciaria (después de 300ms)');
				clearVisibilityCache();
			}, 200);
		}, 100);
	};

	// Función auxiliar para manejar la opción negativa
	const handleNegativeOption = (
		pregunta,
		formularioId,
		formulario,
		tableConfig,
		getNegativeOptionQuestion,
		updateControlledQuestions,
		queueUpdate
	) => {
		debug('  - Opción negativa seleccionada, se ocultará la tabla');

		// Actualizar el estado local de la pregunta principal
		queueUpdate(pregunta.id, formularioId, 'negative', true);
		debug('  - Valor "negative" guardado para pregunta principal');

		// Verificar configuración
		debug(
			'  - Verificando showConditions.onNegative:',
			tableConfig?.showConditions?.onNegative
		);

		// Obtener la pregunta que se muestra en caso negativo
		const negativeQuestion = getNegativeOptionQuestion(formulario);
		debug('  - negativeQuestion encontrada:', negativeQuestion?.id);

		// Actualizar preguntas controladas por checkbox
		debug('  - Actualizando preguntas controladas por checkbox');
		updateControlledQuestions(
			formulario,
			(p) =>
				p.conditional?.controlledBy === pregunta.id &&
				p.conditional?.showOnOption === 'checkbox' &&
				(!negativeQuestion || p.id !== negativeQuestion?.id),
			`<p>${t('tableQuestion.noPolicy')}</p>`
		);

		// Limpiar caché de visibilidad
		clearCacheWithDelay();
	};

	// Función auxiliar para manejar la opción positiva
	const handlePositiveOption = (
		pregunta,
		formularioId,
		formulario,
		getNegativeOptionQuestion,
		updateControlledQuestions,
		queueUpdate
	) => {
		debug('  - Opción positiva seleccionada, se mostrará la tabla');

		// Actualizar el estado de la pregunta principal
		queueUpdate(pregunta.id, formularioId, t('tableQuestion.informAll'), true);
		debug('  - Valor "Va a informar de todo" guardado para pregunta principal');

		// Obtener la pregunta negativa
		const negativeQuestion = getNegativeOptionQuestion(formulario);
		debug(
			'  - negativeQuestion obtenida para opción positiva:',
			negativeQuestion?.id
		);

		// Actualizar preguntas relevantes
		debug('  - Actualizando todas las preguntas relevantes');
		updateControlledQuestions(
			formulario,
			(p) =>
				(p.conditional?.controlledBy === pregunta.id &&
					p.conditional?.showOnOption === 'checkbox') ||
				(negativeQuestion && p.id === negativeQuestion.id),
			`<p>${t('tableQuestion.isInforming')}</p>`
		);

		// Limpiar caché
		if (typeof clearVisibilityCache === 'function') {
			clearVisibilityCache();
		}
	};

	// Función principal refactorizada
	const handleTableOptionChange = useCallback(
		(option, formulario) => {
			debug('useTableQuestion - handleTableOptionChange llamado');
			debug('  - opción:', option);
			debug('  - preguntaId:', pregunta.id);
			debug('  - formulario:', formulario.FormularioId);
			debug('  - tableKey:', tableKey);

			// No hacer nada si es la tabla informativa
			if (isInfoOnlyTable) {
				debug('  - Es tabla informativa, no se toma ninguna acción');
				return;
			}

			// Guardar el valor seleccionado
			setSelectedTableOption(option);

			// Mostrar u ocultar la tabla según la opción
			const showTableValue = option === 'positive';
			setShowTable(showTableValue);
			debug('  - showTable establecido a:', showTableValue);

			// Resetear checkboxes si la opción es negativa
			if (option === 'negative') {
				setTableData((prevData) =>
					prevData.map((row) => ({ ...row, defaultChecked: false }))
				);
				handleNegativeOption(
					pregunta,
					formularioId,
					formulario,
					tableConfig,
					getNegativeOptionQuestion,
					updateControlledQuestions,
					queueUpdate
				);
			} else {
				handlePositiveOption(
					pregunta,
					formularioId,
					formulario,
					getNegativeOptionQuestion,
					updateControlledQuestions,
					queueUpdate
				);
			}

			debug('  - handleTableOptionChange completado');
		},
		[
			isInfoOnlyTable,
			pregunta.id,
			formularioId,
			tableKey,
			tableConfig,
			getNegativeOptionQuestion,
			updateControlledQuestions,
			queueUpdate,
		]
	);

	// Optimización: Usar useEffect con dependencias correctas
	useEffect(() => {
		debug('useTableQuestion - useEffect para inicializar estado');

		if (!isInfoOnlyTable) {
			const savedResponse = respuestas[`${formularioId}-${pregunta.id}`]?.text;
			debug('  - respuesta guardada:', savedResponse);

			if (savedResponse) {
				if (savedResponse === 'negative') {
					debug('  - Estableciendo opción "negative" y ocultando tabla');
					setSelectedTableOption('negative');
					setShowTable(false);

					// NUEVO: Al inicializar como negative, también limpiar el caché
					if (typeof clearVisibilityCache === 'function') {
						clearVisibilityCache();
					}
				} else {
					debug('  - Estableciendo opción "positive" y mostrando tabla');
					setSelectedTableOption('positive');
					setShowTable(true);
				}
			}
		}
	}, [respuestas, formularioId, pregunta.id, isInfoOnlyTable]);

	// Esto será útil para que el componente TableQuestion pueda utilizar esta información
	const tableInfo = useMemo(() => {
		return {
			tableConfig,
			negativeQuestionId: controlledQuestions.showOnNegative,
		};
	}, [tableConfig, controlledQuestions.showOnNegative]);

	return {
		selectedTableOption,
		showTable,
		tableData,
		tableInfo, // Exponer la información de la tabla
		setTableData,
		handleCheckboxChange,
		handleTableOptionChange,
	};
};
