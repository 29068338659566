// useUserId.js
import { useState, useEffect } from 'react';

function useUserId() {
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/auth/me/id', {
          method: 'GET',
          credentials: 'include', // Incluir cookies en la solicitud
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log('ID obtenido en Hook useUserId:', data.id); // Mostrar el ID obtenido
          setUserId(data.id); // Almacenar el ID en el estado
        } else {
          console.error('Error al obtener el id del usuario');
          setError('Error al obtener el id del usuario');
        }
      } catch (error) {
        console.error('Error en la solicitud de id:', error);
        setError('Error en la solicitud de id');
      }
    };

    fetchUserId();
  }, []);

  return { userId, error };
}

export default useUserId;
