// frontend/src/components/Questions/BaseNumericInput.js
import React from 'react';
import { isValidNumber } from '../../utils/numberValidation';
import { t } from 'i18next';

/**
 * Componente base para inputs numéricos con validación segura
 */
const BaseNumericInput = ({
	value,
	onChange,
	validationOptions = {},
	placeholder = t('numericQuestionPlaceholder'),
	className = '',
	readOnly = false,
	createUniqueId = null,
	...restProps
}) => {
	const handleChange = (e) => {
		const newValue = e.target.value;

		if (isValidNumber(newValue, validationOptions)) {
			onChange(newValue);
		}
	};

	// Generar un ID único si es necesario (para evitar autocompletado)
	const uniqueProps = createUniqueId
		? {
				key: createUniqueId,
				id: createUniqueId,
				name: createUniqueId,
				autoComplete: 'off',
				autoCorrect: 'off',
				autoCapitalize: 'off',
				spellCheck: 'false',
		  }
		: {};

	return (
		<input
			type='text'
			value={value}
			onChange={handleChange}
			className={className}
			placeholder={placeholder}
			readOnly={readOnly}
			{...uniqueProps}
			{...restProps}
		/>
	);
};

export default BaseNumericInput;
