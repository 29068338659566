import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useUserArea from '../hooks/user/useUserArea';
import { debug } from '../utils/debugHelper';
import { t } from 'i18next';

const ProtectedRoute = ({ element, allowedAreas, authChecked, isLoggedIn }) => {
	const { area } = useUserArea();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (area !== null && area !== undefined) {
			setLoading(false);
			debug('Área obtenida en ProtectedRoute:', area);
		}
	}, [area]);

	if (!authChecked) {
		return <p>{t('common.verifyAuth')}</p>;
	}

	if (!isLoggedIn) {
		return <Navigate to='/' />;
	}

	if (loading) {
		return <p>{t('common.loading')}</p>;
	}

	// Verificar que area es un array antes de llamar a some
	const hasAccess =
		allowedAreas &&
		Array.isArray(area) &&
		area.some((userArea) => allowedAreas.includes(userArea));

	debug(
		'Tiene acceso:',
		hasAccess,
		'Área del usuario:',
		area,
		'Áreas permitidas:',
		allowedAreas
	);

	if (!hasAccess) {
		return <Navigate to='/casos' />;
	}

	return element;
};

export default ProtectedRoute;
