// frontend/src/styles/dashboardSystemStyles.js
export const dashboardSystemStyles = {
	container: 'p-8',
	innerContainer: 'max-w-7xl mx-auto',
	title: 'text-3xl font-bold text-primario text-center mb-8',

	// Contenedor principal y tabla
	tableContainer: 'rounded-lg shadow overflow-hidden',
	tableWrapper: 'overflow-x-auto shadow-lg rounded-lg',
	table: 'min-w-full border-collapse',

	// Estilos del encabezado de la tabla
	tableHeader: 'bg-fondo',
	headerCell:
		'px-6 py-4 text-left text-xs font-semibold text-acentuado uppercase tracking-wider ',

	// Estilos de las filas y celdas
	tableBody: 'bg-white divide-y divide-gray-200',
	tableRow: 'hover:bg-fondosuavizado/30 transition-colors duration-150',

	// Celdas específicas
	idCell: 'px-6 py-4 whitespace-nowrap ',
	idBadge:
		'px-2 py-1 text-xs font-medium bg-secundario bg-opacity-20 text-secundario rounded-full',

	standardCell: 'px-6 py-4',
	standardText: 'text-sm font-medium',
	plainText: 'text-sm ',
	emailText: 'text-sm text-blue-600',

	// Áreas y departamentos
	areaContainer: 'flex flex-col space-y-1',
	areaBadge:
		'px-3 py-1 text-sm font-medium bg-secundario bg-opacity-20 text-secundario rounded-full w-fit',

	departmentContainer: 'flex flex-col space-y-1',
	departmentItem: 'text-sm text-secundario bg-secundario bg-opacity-10 px-2 py-1 rounded-md',
	departmentName: 'font-medium',
	permissionsContainer: 'flex flex-col space-y-0.5 mt-1',
	permissionText: 'text-xs text-primario',
	noDepartments: 'text-sm text-secundario italic',

	languageCell: 'px-6 py-4',
	languageBadge:
		'px-3 py-1 text-xs font-medium bg-secundario bg-opacity-20 text-secundario rounded-full',

	// Paginación
	paginationContainer: 'mt-6 flex justify-center space-x-2',
	paginationButton: {
		base: 'px-4 py-2 text-sm font-medium rounded-md',
		active: 'bg-secundario text-white',
		inactive: 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300',
	},

	// Estados de carga y error
	loadingContainer: 'flex justify-center items-center min-h-screen',
	loadingInner: 'text-center',
	spinner:
		'animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto',
	loadingText: 'mt-4 text-gray-600',
	errorContainer: 'p-4 bg-error/30 border-l-4 border-error text-error',
};
