// frontend/src/styles/datePickerStyles.js
export const datePickerStyles = {
    container: `relative `,
    input: `w-fit border border-gray-300 p-2 px-3 rounded flex items-center bg-secundario/10 hover:bg-primario/50 cursor-pointer transition-colors duration-200`,
    inputField: `border-none outline-none ml-2 cursor-pointer bg-secundario/0 `,
    calendarContainer: `absolute top-full left-0 mt-1 bg-fondo border border-gray-300 rounded shadow-md p-4 w-[300px] z-50`,
    header: `flex justify-between items-center mb-3`,
    arrowButton: `bg-none border-none cursor-pointer   px-2 text-secundario text-2xl  hover:text-primario transition-colors duration-200 rounded-full`,
    daysGrid: `grid grid-cols-7 gap-0.5`,
    dayButton: `w-9 h-9  selected:text-white border-none flex items-center font-sm justify-center cursor-pointer text-sm p-0 m-0  rounded-full hover:bg-primario/20 transition-colors duration-200 `,
    selectedDay: `bg-primario/20`,
};
