//frontend/src/config/routeConfig.js
export const pathToOption = {
	'/dashboard': 'tasks',
	'/casos': 'openCases',
	'/procesos': 'processes',
	'/other-requests': 'otherRequests',
	'/system-dashboard': 'systemDashboard',
	'/create-user': 'createUser',
	'/update-user': 'updateUser',
	'/delete-user': 'deleteUser',
	'/extraccion': 'extraccion',
};

export const optionToPath = {
	tasks: '/dashboard',
	openCases: '/casos',
	processes: '/procesos',
	otherRequests: '/other-requests',
	systemDashboard: '/system-dashboard',
	createUser: '/create-user',
	updateUser: '/update-user',
	deleteUser: '/delete-user',
	extraccion: '/extraccion',
};

export const areaOptions = {
	System: ['systemDashboard', 'createUser', 'updateUser', 'deleteUser'],
	ESG: ['openCases'],
	MedioAmbiental: ['openCases'],
	EstandaresSociales: ['openCases'],
	GobiernoCorporativo: ['openCases'],
	Financiero: ['openCases'],
	Extraccion: ['extraccion'],
};

//Cambiar por el puerto local para testing local
export const BASE_URL = 'https://risincome.aicrumit.com/backend',
	ROUTE_METRICS = `${BASE_URL}/metrics`,
	ROUTE_API = `${BASE_URL}/api`,
	ROUTE_COMPANY_COMPLETE = `${ROUTE_API}/empresas/completar`,
	ROUTE_COMPANY_LIST = `${ROUTE_API}/empresas/list`,
	ROUTE_COMPANY_CREATE_WITH_FORMS = `${ROUTE_API}/empresas/create-with-forms`,
	ROUTE_COMPANY_FINISHED_COMPANIES = `${ROUTE_API}/empresas/empresas-acabadas`,
	ROUTE_COMPANY_CHECK_ESRS2 = `${ROUTE_API}/empresas/check-esrs2`,
	ROUTE_COMPANY_EXPORT_DETAILS = `${ROUTE_API}/empresas/exportar-detalles`,
	ROUTE_AUTH_LOGIN = `${ROUTE_API}/auth/login`,
	ROUTE_AUTH_LOGOUT = `${ROUTE_API}/auth/logout`,
	ROUTE_AUTH_ME_AREA = `${ROUTE_API}/auth/me/area`,
	ROUTE_AUTH_ME_COMPANY = `${ROUTE_API}/auth/me/company`,
	ROUTE_AUTH_ME_ID = `${ROUTE_API}/auth/me/id`,
	ROUTE_AUTH_ME_LANGUAGE = `${ROUTE_API}/auth/me/language`,
	ROUTE_AUTH_ME_PERMISSIONS = `${ROUTE_API}/auth/me/permissions`,
	ROUTE_FORMS = `${ROUTE_API}/formularios`,
	ROUTE_RESPONSES = `${ROUTE_API}/respuestas`,
	ROUTE_RESPONSES_COMPANY = `${ROUTE_RESPONSES}/empresa`,
	ROUTE_RESPONSES_REDIRECTED = `${ROUTE_RESPONSES}/redirigidas`,
	ROUTE_USERS = `${ROUTE_API}/users`,
	ROUTE_NOTIFICATIONS = `${ROUTE_API}/notificaciones`,
	ROUTE_NOTIFICATIONS_REQUIREMENT_ACTIVATED = `${ROUTE_NOTIFICATIONS}/requisito-activado`,
	ROUTE_NOTIFICATIONS_REDIRECTION = `${ROUTE_NOTIFICATIONS}/redireccion`,
	ROUTE_EXPORT = `${ROUTE_API}/exportar`,
	ROUTE_EXPORT_PDF = `${ROUTE_EXPORT}/pdf`,
	ROUTE_EXPORT_WORD = `${ROUTE_EXPORT}/word`,
	ROUTE_RESTRICTED_EXPORT = `${ROUTE_API}/restricted-export`,
	ROUTE_PROCESSES = `${ROUTE_API}/procesos`;
