import React, { useEffect, useState } from 'react';
import { questionStyles } from '../../styles/questionStyles';
import BaseNumericInput from './BaseNumericInput';
import { t } from 'i18next';

export const PercentQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	// Estado local para manejar el valor sin el símbolo %
	const [localValue, setLocalValue] = useState('');

	// Efecto para inicializar el valor local desde respuestas
	useEffect(() => {
		const savedValue =
			respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || '';
		// Quitamos el % si existe para mostrar solo el número
		setLocalValue(savedValue.replace('%', ''));
	}, [respuestas, formulario.FormularioId, pregunta.id]);

	const handleChange = (value) => {
		setLocalValue(value);
		const standardizedValue = value.replace(',', '.');
		const finalValue = value ? `${standardizedValue}%` : '';
		handleEditorChange(pregunta.id, formulario.FormularioId, finalValue);
	};

	const inputClasses = `
		${questionStyles.percent.input}
		${
			pregunta.required
				? questionStyles.percent.required
				: questionStyles.percent.optional
		}
		${questionStyles.percent.text}
		${!canEdit || isRedirigida ? questionStyles.percent.disabled : ''}
	`;

	return (
		<div className={questionStyles.percent.container}>
			<BaseNumericInput
				value={localValue}
				onChange={handleChange}
				validationOptions={{
					allowDecimals: true,
					allowNegative: true,
					allowComma: true,
				}}
				className={inputClasses}
				placeholder={t('percentQuestionPlaceholder')}
				readOnly={!canEdit || isRedirigida}
			/>
			<span className={questionStyles.percent.symbol}>%</span>
		</div>
	);
};

export default PercentQuestion;
