// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_sidebar__DjWCj {
  width: 250px;
  background-color: #f4f4f4;
  padding: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Sidebar_sidebar-list__KjVW8 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block; /* Asegurarse de que esté en vertical */
}

.Sidebar_sidebar-item__ZwuLb {
  padding: 12px 10px;
  cursor: pointer;
  color: #333;
  transition: background-color 0.3s ease;
  width: 100%; /* Ocupa el ancho completo */
  margin-bottom: 10px; /* Espacio entre cada botón */
  text-align: left;
  display: flex;
  align-items: center;
}

.Sidebar_sidebar-item__ZwuLb:hover {
  background-color: #ddd;
}

.Sidebar_sidebar-item__ZwuLb.Sidebar_active__a80ST {
  background-color: #007BFF; /* Color para la opción activa */
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/componentes/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,cAAc,EAAE,uCAAuC;AACzD;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,sCAAsC;EACtC,WAAW,EAAE,4BAA4B;EACzC,mBAAmB,EAAE,6BAA6B;EAClD,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,yBAAyB,EAAE,gCAAgC;EAC3D,YAAY;AACd","sourcesContent":[".sidebar {\n  width: 250px;\n  background-color: #f4f4f4;\n  padding: 20px;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n\n.sidebar-list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: block; /* Asegurarse de que esté en vertical */\n}\n\n.sidebar-item {\n  padding: 12px 10px;\n  cursor: pointer;\n  color: #333;\n  transition: background-color 0.3s ease;\n  width: 100%; /* Ocupa el ancho completo */\n  margin-bottom: 10px; /* Espacio entre cada botón */\n  text-align: left;\n  display: flex;\n  align-items: center;\n}\n\n.sidebar-item:hover {\n  background-color: #ddd;\n}\n\n.sidebar-item.active {\n  background-color: #007BFF; /* Color para la opción activa */\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar_sidebar__DjWCj`,
	"sidebar-list": `Sidebar_sidebar-list__KjVW8`,
	"sidebar-item": `Sidebar_sidebar-item__ZwuLb`,
	"active": `Sidebar_active__a80ST`
};
export default ___CSS_LOADER_EXPORT___;
