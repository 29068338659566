import React, { useState, useEffect } from 'react';
import './ExtraccionDashboard.css';

// Mapa de procesosId a nombre de proceso
const procesoNombres = {
  1: "ESRS E1",
  2: "ESRS E2",
  3: "ESRS E3",
  4: "ESRS E4",
  5: "ESRS E5",
  6: "ESRS S1",
  7: "ESRS S2",
  8: "ESRS S3",
  9: "ESRS S4",
  10: "ESRS G1",
  11: "ESRS 1",
  12: "ESRS 2",
};

const ExtraccionDashboard = () => {
  const [anio, setAnio] = useState('');
  const [procesoId, setProcesoId] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [exportStatus, setExportStatus] = useState('');
  const [procesosDisponibles, setProcesosDisponibles] = useState([]);
  const [empresasDisponibles, setEmpresasDisponibles] = useState([]);
  const [aniosDisponibles, setAniosDisponibles] = useState([]);

  useEffect(() => {
    const fetchEmpresasDisponibles = async () => {
      try {
        const response = await fetch('https://risincome.aicrumit.com/backend/api/empresas/empresas-acabadas', { credentials: 'include' });
        if (response.ok) {
          const empresas = await response.json();
          console.log("Empresas con estado 'Acabada' (API response):", empresas);

          setEmpresasDisponibles(empresas.map(({ nombreEmpresa }) => nombreEmpresa));
          const uniqueAnios = new Set();
          const uniqueProcesos = new Map();

          empresas.forEach((empresa) => {
            const year = new Date(empresa.fechaCreacion).getFullYear();
            console.log(`Año extraído para ${empresa.nombreEmpresa}:`, year);
            
            if (!isNaN(year)) uniqueAnios.add(year);

            if (!uniqueProcesos.has(empresa.nombreEmpresa)) {
              uniqueProcesos.set(empresa.nombreEmpresa, []);
            }
            uniqueProcesos.get(empresa.nombreEmpresa).push({
              id: empresa.procesosId,
              anio: year
            });
          });

          setAniosDisponibles([...uniqueAnios].sort());
          console.log("Años disponibles:", [...uniqueAnios].sort());
          setProcesosDisponibles(uniqueProcesos);
          console.log("Procesos disponibles (Map):", uniqueProcesos);
        } else {
          console.error("Error al cargar las empresas disponibles.");
        }
      } catch (error) {
        console.error("Error en la carga de empresas disponibles:", error);
      }
    };

    fetchEmpresasDisponibles();
  }, []);

  const procesosFiltrados = empresa && anio
    ? procesosDisponibles.get(empresa)?.filter(proceso => proceso.anio === parseInt(anio)) || []
    : [];
  console.log(`Procesos filtrados para empresa ${empresa} y año ${anio}:`, procesosFiltrados);

  const handleExport = async () => {
    try {
      const queryParams = new URLSearchParams({
        anio,
        procesoId,
        empresa,
      }).toString();
  
      const response = await fetch(`https://risincome.aicrumit.com/backend/api/restricted-export?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${document.cookie.token}`,  
        },
        credentials: 'include'
      });
  
      if (response.ok) {
        const data = await response.json();
        const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
  
        const a = document.createElement('a');
        a.href = url;
        a.download = `export_${anio || 'todos'}_${procesoId || 'todos'}_${empresa || 'todas'}.json`;
        a.click();
        URL.revokeObjectURL(url);
  
        setExportStatus('Exportación completada con éxito.');
      } else {
        setExportStatus('Error en la exportación.');
      }
    } catch (error) {
      console.error('Error en el proceso de exportación:', error);
      setExportStatus('Error en el proceso de exportación.');
    }
  };

  return (
    <div className="dashboard-container">
      <h1>Área de Extracción</h1>
      <p>Bienvenido al panel de Extracción.</p>

      {/* Selector de Empresa */}
      <div className="form-group">
        <label>Empresa:</label>
        <select value={empresa} onChange={(e) => setEmpresa(e.target.value)}>
          <option value="">Seleccione una empresa</option>
          {empresasDisponibles.map((nombre, index) => (
            <option key={index} value={nombre}>
              {nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Selector de Año */}
      {empresa && (
        <div className="form-group">
          <label>Año:</label>
          <select value={anio} onChange={(e) => setAnio(e.target.value)}>
            <option value="">Seleccione un año</option>
            {aniosDisponibles.map((anio, index) => (
              <option key={index} value={anio}>
                {anio || 'Año no válido'}
              </option>
            ))}
          </select>
        </div>
      )}

      {/* Selector de Proceso */}
      {empresa && anio && (
        <div className="form-group">
          <label>Proceso:</label>
          <select value={procesoId} onChange={(e) => setProcesoId(e.target.value)}>
            <option value="">Todos los procesos</option>
            {procesosFiltrados.map((proceso) => (
              <option key={proceso.id} value={proceso.id}>
                {procesoNombres[proceso.id] || proceso.id} {/* Muestra el nombre mapeado */}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className="button-container">
        <button onClick={handleExport}>Exportar Datos</button>
      </div>
      {exportStatus && <p className="status-message">{exportStatus}</p>}
    </div>
  );
};

export default ExtraccionDashboard;
