import React from 'react';
import { questionStyles } from '../../styles/questionStyles';
import BaseNumericInput from './BaseNumericInput';
import { t } from 'i18next';

const NumericalQuestion = ({
	pregunta,
	formulario,
	respuestas,
	handleEditorChange,
	canEdit,
	isRedirigida,
}) => {
	const handleChange = (value) => {
		handleEditorChange(pregunta.id, formulario.FormularioId, value);
	};

	const inputClasses = `
		${questionStyles.numerical.input}
		${
			pregunta.required
				? questionStyles.numerical.required
				: questionStyles.numerical.optional
		}
		${questionStyles.numerical.text}
		${!canEdit || isRedirigida ? questionStyles.numerical.disabled : ''}
	`;

	return (
		<div className={questionStyles.numerical.container}>
			<BaseNumericInput
				value={
					respuestas[`${formulario.FormularioId}-${pregunta.id}`]?.text || ''
				}
				onChange={handleChange}
				validationOptions={{
					allowDecimals: pregunta.questionType !== 'integer',
					allowNegative: true,
				}}
				className={inputClasses}
				placeholder={
					pregunta.questionType === 'integer'
						? t('integerNumericalQuestionPlaceholder')
						: t('numericalQuestionPlaceholder')
				}
				readOnly={!canEdit || isRedirigida}
			/>
		</div>
	);
};

export default NumericalQuestion;
