export const extraccionStyles = {
	container: `
      max-w-3xl mx-auto my-8 p-8
      bg-fondo rounded-xl shadow-lg
      font-sans
    `,

	header: `
      text-center mb-6 text-primario
    `,

	title: `
      text-3xl font-bold mb-2 
    `,

	subtitle: `
      text-lg  mb-6  
    `,

	formRow: `
      flex justify-between gap-4
    `,

	formGroup: `
      flex-1 flex flex-col mb-6
    `,

	label: `
      text-sm font-semibold text-secundario mb-1
    `,

	select: `
      p-2.5 text-base border rounded-lg
      focus:border-secundario focus:ring-2 focus:ring-secundario
      transition duration-200 accent-secundario
    `,

	buttonContainer: `
      flex justify-center mt-6
    `,

	button: `
  px-6 py-3 text-base font-medium text-white
  bg-secundario hover:bg-primario
  rounded-lg cursor-pointer
  transition-colors duration-300
`,

	statusMessage: `
      text-center text-base text-gray-700 mt-4
    `,
};
