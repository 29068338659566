export const casosTableStyles = {
	container: 'w-full overflow-x-auto', // Evita que la tabla haga desbordar la página
	filterContainer: 'mb-8 flex justify-center items-center space-x-4', 
	select: `
    px-6 py-3
    min-w-[200px]
    border-2 border-primario
    rounded-lg 
    bg-fondo text-acentuado
    font-medium
    shadow-sm
    hover:border-acentuado
    focus:border-acentuado 
    transition-all duration-200
`,
	table: 'w-auto max-w-full min-w-full divide-y divide-gray-200', // Evita que la tabla sea más ancha que el contenedor
	header: 'bg-fondosuavizado',
	headerCell: 'px-6 py-3 text-left text-sm font-semibold text-acentuado',
	row: 'hover:bg-fondosuavizado/30 transition-colors duration-150',
	cell: 'px-6 py-4 text-sm  h-12 break-words', // Permite que el texto haga salto de línea si es necesario
  stateCell:(state)=>{
    switch(state){
      case "Pendiente":{
        return 'p-2 rounded-full bg-warn/15 text-warn text-sm font-medium'
      }
      case "Acabada":{
        return 'p-2 rounded-full bg-success/15 text-success text-sm font-medium'
      }
      case "Completada":{
        return 'p-2 rounded-full bg-success/15 text-success text-sm font-medium'
      }
      case "Revision":{
        return 'p-2 rounded-full bg-error/15 text-error text-sm font-medium'
      }
      default:{
        return 'p-2 rounded-full bg-warn/15 text-warn text-sm font-medium'
      }
    }
  },
  stateCellPending:'p-2 rounded-full bg-warn/15 text-warn text-sm font-medium',
  stateCellSuccess:'p-2 rounded-full bg-success/15 text-success text-sm font-medium',
	buttonContainer: 'flex items-center justify-start gap-2',
	exportButtonsContainer: 'flex flex-col gap-2 mt-2',
	exportGroup: 'flex gap-2',
	button: {
		base: `
        inline-flex items-center justify-center
        px-4 py-2 rounded-lg font-medium text-sm
        transition-all duration-200
        focus:outline-none focus:ring-2 focus:ring-offset-2
        shadow-md hover:shadow-lg
    `,
		visualizar: `
        bg-secundario text-fondo
        hover:bg-primario
        focus:ring-primario
      `,
		export: {
			es: `
          min-w-[183px]
          bg-[#3093b4] text-fondo
          hover:bg-primario
          focus:ring-primario
        `,
			en: `
          min-w-[183px]
          bg-[#0E5C81] text-fondo
          hover:bg-primario
          focus:ring-secundario
        `,
		},
	},
	error: 'text-error bg-error/30 p-3 rounded-lg mb-4',
	noData: 'text-center py-4 text-secundario bg-gray-50',
};
