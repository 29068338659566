export const loginStyles = {
	container: `
      flex min-h-screen 
      bg-gradient-to-br from-primario via-fondosuavizado to-primario
    `,

	mainContent: `
      w-full min-h-screen flex items-center justify-center px-4
    `,

	loginBox: `
      flex w-full max-w-5xl bg-fondo rounded-2xl overflow-hidden
      shadow-[0_20px_50px_rgba(0,0,0,0.3)] relative
    `,

	leftSection: `
      relative w-1/2 hidden lg:block
      bg-secundario
      p-12 flex flex-col items-center text-center
    `,

	leftContent: `
      flex flex-col items-center justify-between h-full w-full
    `,

	brandingTop: `
      flex flex-col items-center space-y-6 w-full
    `,

	logo: `
      w-48 h-auto mb-6 drop-shadow-xl
    `,

	brandTitle: `
      text-white text-3xl font-bold tracking-tight
    `,

	brandText: `
      text-white/90 text-lg max-w-sm leading-relaxed mt-4
    `,

	brandingBottom: `
      text-white/80 text-sm mt-auto pt-8
    `,

	rightSection: `
      w-full lg:w-1/2 bg-white p-12 flex flex-col justify-center
    `,

	loginForm: `
      max-w-md w-full mx-auto space-y-8
    `,

	formHeader: `
      text-center space-y-3
    `,

	formTitle: `
      text-2xl font-bold text-gray-900
    `,

	formSubtitle: `
      text-gray-500 text-sm
    `,

	inputGroup: `
      space-y-2
    `,

	inputLabel: `
      block text-sm font-medium text-gray-700
    `,

	input: `
      w-full px-4 h-12 bg-gray-50 border border-gray-200 rounded-lg
      focus:bg-white focus:border-[#d81e05] focus:ring-1 focus:ring-[#d81e05]
      transition duration-200 text-base
    `,

	loginButton: `
  w-full h-12 bg-secundario text-white rounded-lg font-medium
  hover:bg-primario transition-colors duration-200
  transform hover:shadow-lg active:transform-none
  text-base
`,

	errorMessage: `
      p-3 bg-error/30 border-l-4 border-error text-error
      rounded-r-lg text-sm font-medium
    `,

	successMessage: `
      p-3 bg-green-50 border-l-4 border-green-500 text-green-700
      rounded-r-lg text-sm font-medium
    `,

	divider: `
      relative my-6 text-center
    `,

	dividerText: `
      text-sm text-secundario
    `,
};
