import {
	ROUTE_COMPANY_EXPORT_DETAILS,
	ROUTE_FORMS,
	ROUTE_RESPONSES,
} from '../config/routeConfig';
import { debug, debugError, debugWarn } from '../utils/debugHelper';
import { fetchWithAuth } from '../utils/fetchUtils';
import { isUserESG, hasSendPermission } from '../utils/permissionsUtils'; // Importar funciones de permisos

export const fetchPreguntas = async (formularioId) => {
	try {
		const response = await fetchWithAuth(
			`${ROUTE_FORMS}/${formularioId}/preguntas`
		);
		if (!response.ok)
			throw new Error('Error al obtener preguntas del formulario');
		const data = await response.json();
		return data.preguntas || [];
	} catch (error) {
		debugError(
			`Error al obtener preguntas para Formulario ID ${formularioId}:`,
			error
		);
		return [];
	}
};

/**
 * Filtra casos por año seleccionado
 * @param {Array} casos - Lista de casos a filtrar
 * @param {string|number} selectedYear - Año seleccionado
 * @returns {Array} - Casos filtrados por año
 */
const filtrarPorAnyo = (casos, selectedYear) => {
	if (!selectedYear) {
		return casos;
	}
	const yearInt = parseInt(selectedYear);
	debug(`filtrarPorAnyo - Filtrando por año: ${yearInt}`);

	const filtrados = casos.filter((caso) => caso.anyo === yearInt);
	debug(`filtrarPorAnyo - Después de filtrar: ${filtrados.length} casos`);
	return filtrados;
};

/**
 * Verifica permisos de acceso a un caso
 * @param {Object} caso - Caso a verificar
 * @param {Object} permissionsObj - Objeto de permisos
 * @param {string} userCompany - Empresa del usuario
 * @param {Array} safeArea - Áreas del usuario
 * @returns {boolean} - True si tiene acceso, false si no
 */
const tieneAccesoACaso = (caso, permissionsObj, userCompany, safeArea) => {
	const departamentoCaso = caso.proceso?.departamento || null;
	const permisoDept = departamentoCaso
		? permissionsObj[departamentoCaso]
		: null;
	const isSameCompany = caso.nombreEmpresa === userCompany;
	const isESG = Array.isArray(safeArea) && safeArea.includes('ESG');
	const canSend = Object.values(permissionsObj || {}).some(
		(perm) => perm.enviar
	);

	// Verificar siempre que sea la misma empresa
	if (!isSameCompany) {
		debug(
			`Caso ${caso.id} filtrado: no pertenece a la empresa del usuario (${userCompany})`
		);
		return false;
	}

	// Si es usuario ESG con permiso enviar, permitir acceso a todos los casos de su empresa
	if (isESG && canSend) {
		debug(`Caso ${caso.id} permitido: usuario ESG con permiso enviar`);
		return true;
	}

	// Verificar que tiene acceso al departamento del caso
	if (!permisoDept || !permisoDept.ver) {
		debug(
			`Caso ${caso.id} filtrado: sin permisos para el departamento ${departamentoCaso}`
		);
		return false;
	}

	// Usuario ESG con caso completado o acabado
	if (
		isESG &&
		(caso.estadoEmpresas === 'Completada' || caso.estadoEmpresas === 'Acabada')
	) {
		debug(`Caso ${caso.id} permitido: usuario ESG con caso completado/acabado`);
		return true;
	}

	// Usuario solo con permiso ver solo puede ver completados y acabados
	if (permisoDept.ver && !permisoDept.editar && !permisoDept.enviar) {
		const allowed =
			caso.estadoEmpresas === 'Completada' || caso.estadoEmpresas === 'Acabada';
		debug(
			`Caso ${caso.id} ${
				allowed ? 'permitido' : 'filtrado'
			}: usuario solo-ver en caso ${caso.estadoEmpresas}`
		);
		return allowed;
	}

	// Usuario con permisos sobre el departamento
	const hasPermission =
		permisoDept.ver || permisoDept.editar || permisoDept.enviar;

	debug(
		`Caso ${caso.id} ${
			hasPermission ? 'permitido' : 'filtrado'
		}: permisos básicos en departamento`
	);
	return hasPermission;
};

export const filterCasos = (
	casos,
	selectedYear,
	permissions,
	userCompany,
	area
) => {
	// Asegurarse de que todas las entradas sean valores válidos
	const permissionsObj = permissions || {};
	const safeArea = area || [];

	debug('filterCasos - Entrada:', {
		casos: casos?.length || 0,
		selectedYear,
		permissions:
			permissionsObj && typeof permissionsObj === 'object'
				? Object.keys(permissionsObj)
				: [],
		userCompany,
		area: Array.isArray(safeArea) ? safeArea : [],
	});

	// Aplicar primero el filtro por año para asegurar que tenga prioridad
	let casosFiltrados = casos;

	if (selectedYear) {
		const yearInt = parseInt(selectedYear);
		debug(`filterCasos - Filtrando por año específico: ${yearInt}`);

		casosFiltrados = casos.filter((caso) => {
			const match = caso.anyo === yearInt;
			if (!match) {
				debug(
					`Caso ${caso.id} descartado: año ${caso.anyo} no coincide con el seleccionado ${yearInt}`
				);
			}
			return match;
		});

		debug(
			`filterCasos - Después de filtrar por año: ${casosFiltrados.length} casos`
		);

		// Log detallado para diagnóstico
		const anyosPresentes = [...new Set(casosFiltrados.map((c) => c.anyo))];
		debug(
			`filterCasos - Años presentes después del filtro: ${anyosPresentes.join(
				', '
			)}`
		);
	}

	// Verificar si es un usuario ESG con permiso de enviar
	const esESG = isUserESG(safeArea);
	const tienePermisoEnviar = hasSendPermission(permissionsObj);

	// Si es un usuario ESG con permiso de enviar, solo filtrar por empresa (después del filtro por año)
	if (esESG && tienePermisoEnviar) {
		debug('Usuario ESG con permiso enviar - filtrando por empresa');
		const filtrados = casosFiltrados.filter(
			(caso) => caso.nombreEmpresa === userCompany
		);
		debug(
			`Filtrados ${filtrados.length} casos para usuario ESG con permiso enviar`
		);
		return filtrados;
	}

	// Agregar departamento a cada caso
	const casosConDepartamento = casosFiltrados.map((caso) => ({
		...caso,
		departamento: caso.proceso?.departamento || null,
	}));

	// Filtrar por permisos
	const filtrados = casosConDepartamento.filter((caso) =>
		tieneAccesoACaso(caso, permissionsObj, userCompany, safeArea)
	);

	debug(
		`Filtrados ${filtrados.length} casos de ${casosFiltrados.length} para usuario regular`
	);

	// Log final de verificación
	if (selectedYear) {
		const yearInt = parseInt(selectedYear);
		const casosDeOtrosAnos = filtrados.filter((caso) => caso.anyo !== yearInt);

		if (casosDeOtrosAnos.length > 0) {
			debugError(
				`ADVERTENCIA: Se encontraron ${casosDeOtrosAnos.length} casos que no son del año ${yearInt}`
			);
			casosDeOtrosAnos.forEach((caso) => {
				debugError(
					`- Caso ID: ${caso.id}, Año: ${caso.anyo}, Proceso: ${caso.procesosId}`
				);
			});
		} else {
			debug(
				`CORRECTO: Todos los ${filtrados.length} casos son del año ${yearInt}`
			);
		}
	}

	return filtrados;
};

// Función mejorada para obtener solo las respuestas relevantes
const fetchRespuestasParaProceso = async (empresaId, procesoId, anyo) => {
	try {
		// Construir la URL base
		let url = `${ROUTE_RESPONSES}?empresaId=${empresaId}&procesoId=${procesoId}`;

		// Agregar el filtro por año si se proporciona
		if (anyo) {
			url += `&anyo=${anyo}`;
			debug(`Incluyendo filtro de año: ${anyo} en la URL: ${url}`);
		}

		const response = await fetchWithAuth(url);
		if (!response.ok)
			throw new Error('Error al obtener respuestas para este proceso');
		const data = await response.json();
		debug(
			`Obtenidas ${data.length} respuestas${anyo ? ` para el año ${anyo}` : ''}`
		);

		// Si hay datos, mostrar una muestra para debugging
		if (data.length > 0) {
			debug('Ejemplo de primera respuesta:', data[0]);
			debug('Valor de respuesta.respuesta:', data[0].respuesta);
		}

		const respuestasMap = {};
		data.forEach((respuesta) => {
			if (respuesta.respuesta) {
				const respuestaKey = `${respuesta.formularioid}-${respuesta.empresasid}-${respuesta.procesosid}-${respuesta.preguntasid}`;

				// Intentar parsear la respuesta que está como string JSON
				try {
					// Si es un string JSON, parsearlo
					if (typeof respuesta.respuesta === 'string') {
						respuestasMap[respuestaKey] = JSON.parse(respuesta.respuesta);
					}
					// Si ya es un objeto, usarlo directamente
					else {
						respuestasMap[respuestaKey] = respuesta.respuesta;
					}
				} catch (e) {
					debugError(`Error al parsear respuesta para ${respuestaKey}:`, e);
					// Si hay error al parsear, usar la respuesta original
					respuestasMap[respuestaKey] = {
						text: 'Error al procesar respuesta',
						image: null,
					};
				}
			}
		});
		return respuestasMap;
	} catch (error) {
		debugError('Error obteniendo respuestas:', error);
		return {};
	}
};

// Función común para preparar los datos para la exportación (PDF o Word)
const prepareExportData = async (caso, language) => {
	debug('Preparando datos para exportación:', caso);
	debug('Idioma seleccionado:', language);
	debug('Año solicitado para exportación:', caso.anyo);

	// Asegurar que el año esté definido
	if (!caso.anyo) {
		debug('ADVERTENCIA: Año no definido en caso, verificando alternativas.');
		// Intentar determinar el año a partir de la fecha de creación o usar año actual
		caso.anyo = caso.fechaCreacion
			? new Date(caso.fechaCreacion).getFullYear()
			: new Date().getFullYear();
		debug(`Utilizando año alternativo: ${caso.anyo}`);
	}

	// 1. Obtener todos los formularios de esta empresa y proceso
	const urlFormularios = `${ROUTE_COMPANY_EXPORT_DETAILS}/${caso.id}?procesosId=${caso.procesosId}&anyo=${caso.anyo}`;
	debug('Obteniendo formularios desde:', urlFormularios);

	const responseFormularios = await fetchWithAuth(urlFormularios);
	if (!responseFormularios.ok)
		throw new Error('Error al obtener los formularios completados');

	const empresasDetalles = await responseFormularios.json();
	debug(
		`Obtenidos ${empresasDetalles.length} formularios para el proceso ${caso.procesosId}`
	);

	// 2. Obtener solo las respuestas relevantes para estos formularios
	const filteredResponses = await fetchRespuestasParaProceso(
		caso.id,
		caso.procesosId,
		caso.anyo // Pasar el año
	);
	debug(`Obtenidas ${Object.keys(filteredResponses).length} respuestas`);

	// 3. Para cada formulario, obtener sus preguntas
	const preguntasPorFormulario = await Promise.all(
		empresasDetalles.map(async (formulario) => {
			const preguntas = await fetchPreguntas(formulario.FormularioId);
			const preguntasFiltradas = preguntas.map((p) => ({
				id: p.id,
				text: p.text[language] || p.text['EN'] || p.text,
			}));

			return {
				FormularioId: formulario.FormularioId,
				preguntas: preguntasFiltradas,
				empresaId: caso.id,
				procesosId: formulario.procesosId,
			};
		})
	);

	// 4. Combinar los formularios con sus preguntas y respuestas
	const dataParaExportar = empresasDetalles.map((detalle) => {
		const formularioPreguntas = preguntasPorFormulario.find(
			(p) => p.FormularioId === detalle.FormularioId
		);

		if (!formularioPreguntas) {
			debugWarn(
				`No se encontraron preguntas para el formulario ${detalle.FormularioId}`
			);
			return { ...detalle, preguntas: [] };
		}

		const preguntasConRespuestas = formularioPreguntas.preguntas.map(
			(pregunta) => {
				const respuestaKey = `${detalle.FormularioId}-${caso.id}-${caso.procesosId}-${pregunta.id}`;
				const respuesta = filteredResponses[respuestaKey] || {
					text: 'Sin respuesta',
					image: null,
				};

				return {
					...pregunta,
					respuestaText: respuesta.text || 'Sin respuesta',
					respuestaImage: respuesta.image || null,
				};
			}
		);

		return { ...detalle, preguntas: preguntasConRespuestas };
	});

	// 5. Ordenar los formularios por FormularioId para mantener consistencia
	dataParaExportar.sort((a, b) => a.FormularioId - b.FormularioId);

	return dataParaExportar;
};

export const handleExportToPdf = async (
	caso,
	language,
	exportarPdf,
	setError
) => {
	try {
		debug('Iniciando exportación PDF para:', caso);

		// Preparar los datos para la exportación
		const dataParaExportar = await prepareExportData(caso, language);

		debug(`Exportando ${dataParaExportar.length} formularios al PDF`);
		debug('Pasando idioma:', language);

		// Exportar a PDF - Asegurarnos de esperar que se complete
		await exportarPdf(dataParaExportar, language);

		debug('Exportación a PDF completada con éxito');
	} catch (error) {
		debugError('Error en el proceso de exportación PDF:', error);
		setError('Error al generar el PDF: ' + error.message);
	}
};

export const handleExportToWord = async (
	caso,
	language,
	exportarWord,
	setError
) => {
	try {
		debug('Iniciando exportación Word para:', caso);

		// Preparar los datos para la exportación (reutilizamos la misma función)
		const dataParaExportar = await prepareExportData(caso, language);

		debug(`Exportando ${dataParaExportar.length} formularios a Word`);
		debug('Pasando idioma:', language);

		// Exportar a Word
		await exportarWord(dataParaExportar, language);

		debug('Exportación a Word completada con éxito');
	} catch (error) {
		debugError('Error en el proceso de exportación Word:', error);
		setError('Error al generar el documento Word: ' + error.message);
	}
};

// Función actualizada para incluir el año en la URL
export const handleViewAcabadoProcess = (
	navigate,
	empresaId,
	procesoId,
	anyo
) => {
	debug(
		`casosTableHelpers - handleViewAcabadoProcess: empresaId=${empresaId}, procesoId=${procesoId}, anyo=${anyo}`
	);

	// Comprobar si anyo es undefined o null
	if (anyo === undefined || anyo === null) {
		debugWarn(
			'handleViewAcabadoProcess - ADVERTENCIA: anyo es undefined o null, usando la URL sin parámetro de año'
		);
		navigate(
			`/empresas/${empresaId}/procesos/${procesoId}/vista-proceso-acabado`
		);
	} else {
		const url = `/empresas/${empresaId}/procesos/${procesoId}/vista-proceso-acabado?anyo=${anyo}`;
		debug(`casosTableHelpers - Navegando a URL: ${url}`);
		navigate(url);
	}
};
